import React, {useState} from 'react';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import Calender from '../FeedBack/Calender';
import moment from 'moment';
import {getMaxDateFromToday} from '../../Utils/commonUtils';
import {Form} from 'react-bootstrap';
import Input from '../Input/Input';

const MilestoneListing = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  listing,
  setIsEditing,
  setTitle,
  type,
  searchListing,
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [description, setDescription] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);

  const today = new Date();
  console.log(listing, 'listinglistinglistinglistinglisting');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {listing?.length > 0 && (
        listing
          .filter(item =>
            item.name.toLowerCase().includes(searchListing.toLowerCase()),
          )
          .map((item, index) => {
            const isActive = index === activeIndex;
            return (
              <div key={index} className="billing-detail mt-2">
                <div
                  className="pt-2 pb-2 as-link"
                  style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h1
                    onClick={() => {
                      setShowDetail(!showDetail);
                      setActiveIndex(isActive ? null : index);
                    }}
                    className="mb-0">
                    {item.name}
                  </h1>
                  <p
                    onClick={() => {
                      // if (!type) {
                        setTitle(item.name);
                        setIsEditing({
                          editing: true,
                          item: item,
                        });
                      // }
                    }}
                    className="as-link cross-icon-field"
                    style={{padding: 2}}>
                    <EditPencilSvg fill={'#a39f8d'} />
                  </p>
                </div>
                <div
                  className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
                    isActive ? 'max-h-screen' : 'max-h-0'
                  }`}>
                  <hr class="border-t border-gray-300 pb-3"></hr>
                  {item?.tasks?.length > 0 && (
                    <Input
                      label=""
                      name={'search'}
                      value={search}
                      onChange={e => handleSearch(e)}
                      placeholder={'Search for tasks'}
                      required={false}
                      type={'text'}
                    />
                  )}
                  {item?.tasks?.length > 0 &&
                    item.tasks
                      .filter(task =>
                        task.name.toLowerCase().includes(search.toLowerCase()),
                      )
                      .map(tasks => (
                        <>
                          <div className="roi-form-input">
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <p>{tasks.name}</p>
                              <span>Due Date</span>
                            </div>
                          </div>
                          <hr class="border-t border-gray-300 pb-3"></hr>
                        </>
                      ))}

                  <Form.Group className="fieldset mt-2">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter Description"
                      value={description}
                      onChange={e => {
                        setDescription(e.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Feedback is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!type &&
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Associate Invoice</p>
                      <span style={{color: '#52bd41'}}>Find Invoice</span>
                    </div>
                  </div>
          }
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Start Date</p>
                      <div onClick={() => setIsOpen(true)}>
                        {startDate === '' ? (
                          <span className="template-text">
                            {' '}
                            <i className="fa-solid fa-calendar-days" />
                          </span>
                        ) : (
                          <span
                            style={{color: '#52bd41'}}
                            className="template-text as-link">
                            {moment(startDate)
                              .format('MMM DD, YYYY')
                              .toString()}
                          </span>
                        )}
                      </div>
                      <div className="invoice_calender-wrapper">
                        {isOpen && (
                          <Calender
                            maxDate={getMaxDateFromToday}
                            minDate={moment(today).format('YYYY-MM-DD')}
                            setSelectedDate={e => {
                              if (e) {
                                setStartDate(e);
                                setIsOpen(false);
                              } else {
                                setStartDate('');
                                setIsOpen(false);
                              }
                            }}
                            selectedDate={startDate ? new Date(startDate) : ''}
                            type={'project'}
                            showDate={true}
                            showTime={false}
                            isOpenCalendar={isOpen}
                            setIsOpenCalendar={setIsOpen}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>End Date</p>
                      <div onClick={() => setOpenEndDate(true)}>
                        {endDate === '' ? (
                          <span className="template-text">
                            {' '}
                            <i className="fa-solid fa-calendar-days" />
                          </span>
                        ) : (
                          <span
                            style={{color: '#52bd41'}}
                            className="template-text as-link">
                            {moment(endDate).format('MMM DD, YYYY').toString()}
                          </span>
                        )}
                      </div>{' '}
                      <div className="invoice_calender-wrapper">
                        {openEndDate && (
                          <Calender
                            minDate={moment(today).format('YYYY-MM-DD')}
                            setSelectedDate={e => {
                              if (e) {
                                setEndDate(e);
                                setOpenEndDate(false);
                              } else {
                                setEndDate('');
                                setOpenEndDate(false);
                              }
                            }}
                            selectedDate={endDate ? new Date(endDate) : ''}
                            type={'project'}
                            showDate={true}
                            showTime={false}
                            isOpenCalendar={openEndDate}
                            setIsOpenCalendar={setOpenEndDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Team</p>
                      <span style={{color: '#52bd41'}}>5 Members</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
      )}
    </>
  );
};

export default MilestoneListing;
