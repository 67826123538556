import React, {useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import { capitalizeFirst } from '../../Utils/commonUtils';

const PropertyFilters = (props)=>{

    const {
        handleCheckAll, 
        handleCheckboxChange, 
        filterListArray, 
        isAllChecked, 
        handleSubmit, 
        filter
    } = props;

    return (

        <div className="filter-wrapper customer-search-filter-holder">
        <div className="filter-headings text-center">
          <h2>Property Filter</h2>
        </div>
        <div className="filter-category-list">
          <ul>
            <li className={isAllChecked ? 'active' : ''}>
              <Form.Label>
                All
                <input
                  type="checkbox"
                  onChange={handleCheckAll}
                  checked={isAllChecked}
                />
                <span />
              </Form.Label>
            </li>
            {filterListArray?.map(role => (
              <li
                key={role.id}
                className={filter.includes(role.id) ? 'active' : ''}>
                <Form.Label>
                  {capitalizeFirst(role.title)}
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(role.id)}
                    checked={filter.includes(role.id)}
                  />
                  <span />
                </Form.Label>
              </li>
            ))}
          </ul>
          <div className="text-center bg_transparent">
            <Button className="btn-dark" type="submit" onClick={handleSubmit}>
              Filters
            </Button>
          </div>
        </div>
      </div>
    )
}

export default PropertyFilters;