import React, {useState} from 'react';
import CustomDropdown from '../CustomDropdown';
import {propertNotesDropdownValues} from '../../Utils/commonUtils';
import {Form} from 'react-bootstrap';
import {Scrollbars} from 'react-custom-scrollbars';
import {Pin} from '../../Constants/Images';

function PropertyNotes() {
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editingNoteIndex, setEditingNoteIndex] = useState(null);
  const [notesId, setNotesId] = useState('');

  const handleNoteChange = e => {
    setNote(e.target.value);
  };

  const handleSaveNote = () => {
    if (editMode) {
      const updatedNotes = [...notes];
      updatedNotes[editingNoteIndex] = note;
      setNotes(updatedNotes);
      setEditMode(false);
      setEditingNoteIndex(null);
    } else {
      setNotes([...notes, note]);
    }
    setNote('');
  };

  const handleEditNote = index => {
    setNote(notes[index]);
    setEditMode(true);
    setEditingNoteIndex(index);
  };

  const handleDeleteNote = index => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);
  };

  return (
    <div className=" p-6 rounded-lg">
      <Scrollbars autoHide style={{height: 300}}>
        {/* Dropdown for Type */}
        <div className="mb-4">
          <Form.Label className="block text-gray-700">Type</Form.Label>
          <CustomDropdown
            dropdownId={notesId}
            setDropdownId={value => setNotesId(value)}
            DropDownValues={propertNotesDropdownValues}
            itemName={['title']}
          />
        </div>

        {/* Note Textarea */}
        <Form.Group className="mb-4" controlId="notes">
          <textarea
            rows={5}
            name="notes"
            placeholder="Type a note"
            value={note}
            onChange={handleNoteChange}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </Form.Group>

        {/* Action Buttons */}
        <div className="flex items-center justify-start mb-4 space-x-2">
          <button className="bg-[#F1EFE8] hover:bg-[#F1EFE8] text-gray-700 font-bold py-2 px-4 rounded-full">
            <i className="fa-solid fa-circle fa-rotate-270 text-red-500"></i>
          </button>
          <button className="bg-[#F1EFE8] hover:bg-[#F1EFE8] text-gray-700 font-bold py-2 px-4 rounded-full">
            <i className="fa-solid fa-camera text-gray-700"></i>
          </button>
        </div>
        <div>
          <button
            className="bg-black hover:bg-black text-white font-bold py-2 px-4"
            style={{
              borderRadius: '6px',
            }}
            onClick={handleSaveNote}>
            Save Note
          </button>
        </div>

        {/* Notes List with Scroll */}
        <div className="mt-6">
          {notes.map((note, index) => (
            <div
              key={index}
              className="mb-4 p-4 bg-[#fff] rounded-lg shadow-sm">
              <div className="flex items-center justify-between">
                <p className="text-gray-700">{note}</p>
                <img src={Pin} alt="" />
              </div>
              <div className="flex items-center justify-between mt-2">
                <p className="text-xs text-gray-500">Name - 12/28/24 5:22PM</p>
                <div className="space-x-4">
                  <button className="" onClick={() => handleEditNote(index)}>
                    Edit
                  </button>
                  {/* <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => handleDeleteNote(index)}
                  >
                    Delete
                  </button> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );
}

export default PropertyNotes;
