import React, {useState, useEffect, useRef} from 'react';
import CustomModal from '../CustomModal';
import * as Twilio from '@twilio/voice-sdk';
import commonApi from '../../Services/CommonService';
import {formatTimeee} from '../../Utils/commonUtils';
import {ringTone} from '../../Constants/Images';

const CallScreenModal = ({
  closeModel,
  phoneNumber,
  check_user_number_exist,
}) => {
  const [client, setClient] = useState(null);
  const [connection, setConnection] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [speakerEnabled, setSpeakerEnabled] = useState(false);
  const [callStatus, setCallStatus] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [isOnRecord, setIsOnRecord] = useState(false);
  const [recording_sid, setRecording_sid] = useState('');
  const [callSid, setCallSid] = useState('');
  const [callDuration, setCallDuration] = useState(0);

  const [isRecording, setIsRecording] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [dataProcess, setDataProccess] = useState(false);
  const audioRef = useRef(new Audio(ringTone));

  const getEnvironment = () => {
    const origin = window.location.origin; // Gets the browser's origin
    if (origin.includes('localhost') || origin.includes('dev')) {
      return 'https://devapi.roilevelup.com';
    } else if (origin.includes('staging')) {
      return 'https://stagingapi.roilevelup.com';
    } else {
      return 'https://liveapi.roilevelup.com';
    }
  };

  const toggleAudioPlay = isPlaying => {
    // if (isPlaying === "stop") {
    //   audioRef.current.pause(); // Pause the audio
    //   audioRef.current.currentTime = 0; // Reset the audio to the start
    // } else {
    //   audioRef.current.loop = true;
    //   audioRef.current.play(); // Play the audio
    // }
  };

  useEffect(() => {
    if (isRecording && startTime && !isOnHold) {
      const interval = setInterval(() => {
        const durationsss = Math.floor((Date.now() - startTime) / 1000); // Calculate duration in seconds
        const getTime = duration + durationsss;
        setDuration(getTime); // Update the call duration
      }, 1000); // Update every second

      return () => clearInterval(interval); // Cleanup on unmount or state change
    } else {
      setIsRecording(false);
    }
  }, [isRecording, startTime, isOnHold]);

  // Format timer display for call duration
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds,
    ).padStart(2, '0')}`;
  };

  // Start timer on call connect
  useEffect(() => {
    if (callStatus === 'Connected') {
      setCallDuration(0);
      const timer = setInterval(() => setCallDuration(prev => prev + 1), 1000);
      return () => clearInterval(timer);
    }
  }, [callStatus]);

  // Fetch Twilio token on component mount
  useEffect(() => {
    initializeTwilioClient(localStorage.getItem('twillio_Token'), phoneNumber);
  }, []);

  // Initialize Twilio Client
  const initializeTwilioClient = async (token, phoneNumber) => {
    try {
      const client = new Twilio.Device(token, {
        codecPreferences: ['opus', 'pcmu'],
        enableRingingState: true,
      });
      // Setup Twilio device event listeners
      client.on('ready', () => console.log('Twilio client ready'));
      client.on('connect', conn => handleConnect(conn));
      client.on('disconnect', handleDisconnect);
      client.on('error', handleError);
      client.on('ringing', () => setCallStatus('Ringing...'));

      // client.on('incoming', call => {
      //   setCallStatus('Incoming call...');
      //   setConnection(call);
      //   call.accept(); // Automatically accept the incoming call
      //   setCallStatus('Connected');
      //   setStartTime(new Date());
      // });

      setClient(client);
      startCall(client, phoneNumber);
    } catch (error) {
      console.error('Error initializing Twilio:', error);
    }
  };
  const handleSetConnection = async conn => {
    // alert('aa')
    // Save Call SID for tracking
    const Id = await conn.parameters.CallSid;

    console.log(conn, 'connconnconn');

    // conn.on('accept', () => {
    //   alert('lkj')
    const callSid = conn.parameters?.CallSid;
    if (callSid) {
      setCallSid(callSid);
      localStorage.setItem('callSid', callSid);

      setIsRecording(true);
      setStartTime(new Date());
      setCallStatus('Connected');
      toggleAudioPlay('stop');
      console.log('CallSid on accept event:', callSid);
    } else {
      console.warn('CallSid is still undefined on accept event.');
    }
    // });

    setConnection(conn);
    console.log(conn.reject(), 'conn.reject()');

    // console.log(conn,'IdIdIdIdIdIdIdId')
  };
  // Start Call
  const startCall = async (clientInstance, phoneNumber) => {
    setCallStatus('Connecting...');

    const conn = await clientInstance.connect({
      params: {To: phoneNumber, userId: localStorage.getItem('id')},
    });

    conn.on('disconnect', () => {
      // alert('8')
      handleDisconnect();
    }); // Handle disconnect events
    conn.on('accept', () => {
      // alert('9')
      console.log('Call accepted:', conn.status());
      const callSid = conn.parameters?.CallSid;
      if (callSid) {
        setCallSid(callSid);
        localStorage.setItem('callSid', callSid);
        console.log('CallSid on accept event:', callSid);
        toggleAudioPlay('start');
        // if (conn?.parameters) {
        handleSetConnection(conn);
        // }
      }
    });
    conn.on('ringing', () => {
      setCallStatus('Ringing...');
      // alert('0')
    });
    console.log(conn.status(), 'conn.status()');
    console.log(conn.reject(), 'conn.reject()');
  };

  // Handle Call Connection
  const handleConnect = conn => {
    // setIsRecording(true);
    // setStartTime(new Date());
    // setConnection(conn);
    setCallStatus('Connected');
    // setCallSid(conn.parameters.CallSid);
    conn.on('disconnect', handleDisconnect);
  };

  // Start Recording Call
  const start_recording_call = () => {
    const payload = {
      auth_token: localStorage.getItem('twillio_Token'),
      call_sid: connection?.parameters.CallSid,
    };
    commonApi
      .start_recording_call(payload)
      .then(res => {
        if (res.status === 200) {
          setRecording_sid(res.recording_sid); // Save recording SID
          setIsOnRecord(true);
        }
      })
      .catch(err => console.error('Error starting call recording:', err));
  };

  // Stop Recording Call
  const stop_recording_call = type => {
    const payload = {
      auth_token: localStorage.getItem('twillio_Token'),
      call_sid: connection?.parameters.CallSid,
      recording_sid,
    };
    commonApi
      .stop_recording_call(payload)
      .then(res => {
        if (res.status === 200) {
          setIsOnRecord(false); // Update recording state
        }
        if (type === 'endCall') {
          endCall();
        }
      })
      .catch(err => console.error('Error stopping call recording:', err));
  };

  // Update Call Status in Backend
  const update_twilio_call = () => {
    const payload = {
      call_sid: localStorage.getItem('callSid')
        ? localStorage.getItem('callSid')
        : callSid,
      recording_sid: '',
    };

    commonApi
      .update_twilio_call(payload)
      .then(res => {
        if (res.status === 200) {
          setCallStatus('Disconnected');

          setConnection(null);
          localStorage.removeItem('callSid');

          // setCallStatus('Call Ended');
          check_user_number_exist(phoneNumber);
          setTimeout(() => {
            closeModel();
          }, 100);
        }
      })
      .catch(err => console.error('Error updating Twilio call:', err));
  };

  // End Call with Optional Recording Stop
  const endCallWithStopRecording = () => {
    stop_recording_call('endCall');
  };

  const endCall = () => {
    if (client) {
      // client.status()
      client.disconnectAll();
      setDataProccess(true);
      setTimeout(() => {
        update_twilio_call();
      }, 1500);
    } else {
      console.log('No active client or calls to disconnect.');
    }
  };

  // Mute/Unmute Toggle
  const toggleMute = () => {
    if (connection) {
      const newMuteState = !isMuted;
      connection.mute(newMuteState);
      setIsMuted(newMuteState);
    } else {
      console.warn('No active connection to mute/unmute.');
    }
  };

  // Hold/Resume Toggle
  const toggleHold = () => {
    if (connection) {
      const newHoldState = !isOnHold;
      connection.mute(newHoldState); // Mute for hold, unmute for resume
      setIsOnHold(newHoldState);
      setCallStatus(newHoldState ? 'On Hold' : 'Connected');
      setStartTime(new Date());
      setIsRecording(true);
    }
  };

  // Handle Disconnect Event
  const handleDisconnect = () => {
    // alert('l')
    setConnection(null);
    setIsMuted(false);
    setIsOnHold(false);
    setSpeakerEnabled(false);
    if (localStorage.getItem('callSid')) {
      update_twilio_call();
    }
  };

  // Handle Errors from Twilio
  const handleError = error => {
    console.error('Twilio Error:', error);
    if (error.code === 486) {
      setCallStatus('Busy (486 Busy Here)');
      endCall();
    } else {
      setCallStatus(`Error: ${error.message}`);
    }
  };

  // Handle Recording Toggle
  const handleRecord = () => {
    if (isOnRecord) {
      stop_recording_call();
    } else {
      start_recording_call();
    }
  };

  return (
    <>
      <CustomModal
        size="small"
        hideCrossIcon={true}
        onClose={() => {
          closeModel();
        }}>
        <div className="call-screen">
          <h3 style={{lineBreak: 'anywhere'}} className="phone-number">
            {phoneNumber}
          </h3>
          <p className="status-text">
            {client === 'Connected'
              ? formatTime(callDuration)
              : dataProcess
              ? 'Please wait, processing data..'
              : callStatus}
          </p>
          <p className="status-text" style={{marginBottom: '0px'}}>
            {duration ? formatTimeee(duration) : ''}
          </p>

          <div className="controls">
            <button className="control-button" onClick={toggleMute}>
              {!isMuted ? (
                <svg
                  viewBox="0 0 384 512"
                  fill="currentColor"
                  height="21px"
                  width="21px">
                  <path d="M192 0c-53 0-96 43-96 96v160c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464h-48c-13.3 0-24 10.7-24 24s10.7 24 24 24h144c13.3 0 24-10.7 24-24s-10.7-24-24-24h-48v-33.6c85.8-11.7 152-85.3 152-174.4v-40c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128S64 326.7 64 256v-40z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21px"
                  height="21px"
                  fill="currentColor"
                  class="bi bi-mic-mute"
                  viewBox="0 0 16 16">
                  <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4 4 0 0 0 12 8V7a.5.5 0 0 1 1 0zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a5 5 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4m3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3" />
                  <path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607m-7.84-9.253 12 12 .708-.708-12-12z" />
                </svg>
              )}
            </button>
            <button className="control-button" onClick={toggleHold}>
              {/* <svg fill="none" viewBox="0 0 15 15" height="21px" width="21px">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M7.47 1.05a.5.5 0 01.28.45v12a.5.5 0 01-.807.395L3.221 11H1.5A1.5 1.5 0 010 9.5v-4A1.5 1.5 0 011.5 4h1.721l3.722-2.895a.5.5 0 01.527-.054zm-.72 1.472L3.7 4.895A.5.5 0 013.393 5H1.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1.893a.5.5 0 01.307.105l3.05 2.373V2.522zm3.528 1.326a.4.4 0 01.555.111 6.407 6.407 0 010 7.081.4.4 0 01-.666-.443 5.607 5.607 0 000-6.194.4.4 0 01.111-.555zm2.4-2.418a.4.4 0 00-.61.518 8.602 8.602 0 010 11.104.4.4 0 00.61.518 9.402 9.402 0 000-12.14z"
                  clipRule="evenodd"
                />
              </svg> */}

              {!isOnHold ? (
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  width="21" // Adjust as needed
                  height="21" // Adjust as needed
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="256"
                      y1="593.46"
                      x2="256"
                      y2="-81.46"
                      gradientUnits="userSpaceOnUse">
                      <stop offset="0" stopColor="#231f20" />
                      <stop offset="1" stopColor="#58595b" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M210.61,475.41A36.69,36.69,0,0,1,174,512H66.88a36.69,36.69,0,0,1-36.57-36.59V36.56A36.67,36.67,0,0,1,66.88,0H174a36.67,36.67,0,0,1,36.57,36.56ZM481.69,36.56A36.69,36.69,0,0,0,445.1,0H338a36.68,36.68,0,0,0-36.59,36.56V475.41A36.7,36.7,0,0,0,338,512H445.1a36.71,36.71,0,0,0,36.59-36.59Z"
                    fillRule="evenodd"
                    fill="url(#linear-gradient)"
                  />
                </svg>
              ) : (
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  width="21"
                  height="21">
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="256"
                      y1="593.46"
                      x2="256"
                      y2="-81.46"
                      gradientUnits="userSpaceOnUse">
                      <stop offset="0" stopColor="#231f20" />
                      <stop offset="1" stopColor="#58595b" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M210.61,475.41A36.69,36.69,0,0,1,174,512H66.88a36.69,36.69,0,0,1-36.57-36.59V36.56A36.67,36.67,0,0,1,66.88,0H174a36.67,36.67,0,0,1,36.57,36.56ZM481.69,36.56A36.69,36.69,0,0,0,445.1,0H338a36.68,36.68,0,0,0-36.59,36.56V475.41A36.7,36.7,0,0,0,338,512H445.1a36.71,36.71,0,0,0,36.59-36.59Z"
                    fillRule="evenodd"
                    fill="none" // Makes the fill transparent
                    stroke="url(#linear-gradient)" // Optional: use gradient as stroke instead
                    strokeWidth="10" // Adjust stroke width as needed
                  />
                </svg>
              )}
            </button>
            <button className="control-button" onClick={handleRecord}>
              {isOnRecord ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="none"
                  viewBox="0 0 16 16"
                  id="record">
                  <path
                    fill="red"
                    d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"></path>
                  <path
                    fill="white"
                    d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="none"
                  viewBox="0 0 16 16"
                  id="record">
                  <path
                    fill="#212121"
                    d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"></path>
                  <path
                    fill="#212121"
                    d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8Z"></path>
                </svg>
              )}
            </button>
          </div>

          <button
            className="end-call-button"
            disabled={dataProcess}
            onClick={() => {
              if (!dataProcess) {
                if (isOnRecord) {
                  endCallWithStopRecording();
                } else {
                  endCall();
                }
              }
            }}>
            <svg
              viewBox="0 0 512 512"
              fill={dataProcess ? 'gray' : 'currentColor'}
              height="22px"
              width="22px">
              <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" />
            </svg>
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default CallScreenModal;
