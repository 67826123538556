import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';

import {errorMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {RoiLogo} from '../../Constants/Images';
import CustomTabs from '../CustomTabs';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {
  capitalizeFirst,
  checkUserPermissionsAdd,
  WithBottomClass,
} from '../../Utils/commonUtils';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import TransparentButton from '../TransparentButton/TransparentButton';

const CreateTag = props => {
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState('');
  const [discription, setDiscription] = useState('');
  const buttonRef = useRef(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [file, setFile] = useState(null);
  const [deletedId, setDeletedId] = useState('');
  const [showModel, setShowModel] = useState('');
  const [selectedData, setSelectedData] = useState('');
  const [ticketImg, setTicketImg] = useState([]);
  const [sliderImage, setSliderImage] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [selectedAllAlias, setSelectedAllAlias] = useState([]);
  const [showSearchRoles, setShowSearchRoles] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('crop_modal');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      const allValid = selectedAllAlias.every(
        alias => alias.read || alias.update,
      );

      if (title === '' && sliderImage.length === 0) {
        errorMessage('Title or image is required');
      } else if (selectedAllAlias.length === 0) {
        errorMessage('Please select one role');
      } else if (!allValid) {
        errorMessage('Select permission first.');
      } else {
        setshowLoader(true);
        if (props.editTag?.id) {
          editTag();
        } else {
          createTag();
        }
      }

      setValidated(false);
    }
  };

  const createTag = () => {
    const FilterReadIds = selectedAllAlias
      .filter(alias => alias.read)
      .map(alias => alias.id);

    const UpdateIds = selectedAllAlias
      .filter(alias => alias.update)
      .map(alias => alias.id);

    var payLoad = {
      title: title,
      description: discription,
      icon: sliderImage.length > 0 ? sliderImage.toString(',') : '',
      user_id: props.user_id,
      read_role_ids: FilterReadIds,
      update_role_ids: UpdateIds,
    };

    commonApi
      .create_company_tags(payLoad)
      .then(res => {
        if (res.status === 200 && res.success) {
          const newData = res.tag;
          const result = checkUserPermissionsAdd(
            selectedAllAlias,
            props.userPermesions,
          );
          if (result) {
            props.setTagsList(previousTagsList => [
              newData,
              ...previousTagsList,
            ]);
          }

          successMessage(res.message);
          props.onClose();
          setshowLoader(false);
        } else {
          errorMessage(res.message);
          setshowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const editTag = () => {
    const FilterReadIds = selectedAllAlias
      .filter(alias => alias.read)
      .map(alias => alias.id);

    const UpdateIds = selectedAllAlias
      .filter(alias => alias.update)
      .map(alias => alias.id);

    var payLoad = {
      tag_id: props.editTag?.id,
      title: title,
      description: discription,
      icon: sliderImage.length > 0 ? sliderImage.toString(',') : '',
      read_role_ids: FilterReadIds,
      update_role_ids: UpdateIds,
    };

    commonApi
      .create_company_tags(payLoad)
      .then(res => {
        if (res.status === 200 && res.success) {
          const newData = res.tag;
          props.setTagsList(previousTagsList => {
            return previousTagsList.map(item => {
              // Assuming newData contains a single item
              // Replace `id` with the appropriate key for identification
              return item.id === props.editTag?.id ? newData : item;
            });
          });
          props.editTagHandle(res.tag);

          successMessage(res.message);
          props.onClose();
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (props.editTag?.id) {
      setTitle(props.editTag.title);
      setDiscription(props.editTag.description);
      setSliderImage(props.editTag.icon ? [props.editTag.icon] : []);
      setSelectedAllAlias(props.editTag?.role_titles);
    }
  }, [props.editTag]);

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {Object.keys(props.editTag).length > 0 ? (
          <span
            className=" as-link"
            onClick={() => {
              setDeletedId(props.editTag);
              setShowModel('show_tag_delete_model');
            }}>
            <i className="fa-solid fa-trash-can" />
          </span>
        ) : (
          <img src={RoiLogo} alt="" />
        )}
      </>
    );
  };

  const get_company_users_role = value => {
    const payload = {search: value};
    commonApi
      .get_company_users_role(payload)
      .then(res => {
        if (res.status === 200) {
          setListRoles(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleAddInArray = array => {
    const idExists = selectedAllAlias.some(alias => alias.id === array.id);

    if (idExists) {
      errorMessage('This role already exists.');
    } else {
      selectedAllAlias.push({
        id: array.id,
        read: true,
        update: true,
        role_alias: array.role_alias,
      });
      setShowSearchRoles('');
      setListRoles([]);
    }
  };

  const handleChangePermissions = (type, item) => {
    setSelectedAllAlias(prevState =>
      prevState.map(alias => {
        if (alias.id === item.id) {
          let newAlias = {...alias, [type]: !alias[type]};

          // If `update` is being set to true, ensure `read` is true
          if (type === 'update' && newAlias.update) {
            newAlias.read = true;
          }

          // If `read` is being set to false, ensure `update` is false
          if (type === 'read' && !newAlias.read) {
            newAlias.update = false;
          }

          return newAlias;
        }
        return alias;
      }),
    );
  };

  const handleShowModel = item => {
    setSelectedData(item);
    setShowModel('show_delete_model');
  };
  const removeAliasRole = () => {
    setSelectedAllAlias(prevState =>
      prevState.filter(alias => alias.id !== selectedData.id),
    );
    setShowModel('');
  };

  console.log(selectedAllAlias, 'selectedAllAlias');

  const bottom10px ={bottom:'10px'}
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="serach-filter-wrapper">
          <div className="m-0 w-100 d-flex align-items-center justify-content-between fix-width-forms pl-23 mt-3">
            <div className="d-flex">
              <p className="p-bold m-0 f_600_h">
                {props.editTag?.id ? 'Update Tag' : ' Create Tag'}
              </p>
            </div>
            <div className="d-flex"></div>
          </div>
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="create-ticket-tabs">
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={item => setTabType(item)}
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.ADVANCE[selected_lang],
                ]}
              />
            </div>
          </div>
          <Scrollbars className="custom-scroll" autoHide>
            <div className="fix-width-inputs black-head-form-pad-left" style={{paddingBottom: '71px'}}>
              <div className="messages-users-list">
                <div className="create-ticket-form pt-2">
                  {tabType === labels.sessions_type_general[selected_lang] && (
                    <Form noValidate validated={validated} onSubmit={handle}>
                      <>
                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Title
                          </Form.Label>
                          <Form.Control
                            name="title"
                            value={title}
                            pattern="^\S.*$"
                            // required
                            placeholder="Add tag title"
                            onChange={e => {
                              setTitle(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Title is required.
                          </Form.Control.Feedback>
                        </div>

                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Description (Optional)
                          </Form.Label>
                          <Form.Control
                            value={discription}
                            as="textarea"
                            rows={6}
                            type="text"
                            placeholder="Let others know what to expect."
                            onChange={e => {
                              setDiscription(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Description is required.
                          </Form.Control.Feedback>
                        </div>

                        <Col sm={2}>
                          <div className="ticket-img-wrapper mb-3">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={e => changeTicketImg(e)}
                            />

                            {sliderImage && sliderImage.length > 0 && (
                              <img src={sliderImage[0]} alt="" />
                            )}
                            <i className="icon-camera" />
                          </div>
                        </Col>

                        <Button
                          className="btn-dark"
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}>
                          {props.editTag?.id ? 'Update Tag' : 'Create Tag'}
                        </Button>
                      </>
                    </Form>
                  )}
                  {tabType === labels.ADVANCE[selected_lang] && (
                    <>
                      <div className="invite-page-search">
                        <div className="search-form mt-4">
                          <Form.Label
                            controlid="validationCustom01"
                            style={{
                              fontSize: '14px',
                              color: '#a0a0a0',
                              fontWeight: 400,
                              lineHeight: '1.2em',
                            }}>
                            Roles
                          </Form.Label>
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              value={showSearchRoles}
                              onChange={e => {
                                setShowSearchRoles(e.target.value);
                                get_company_users_role(e.target.value);
                              }}
                              placeholder={'Search role'}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      {showSearchRoles && listRoles.length > 0 && (
                        <ul className="custom-suggestions invoices_suggestion">
                          {listRoles.map(suggestion => (
                            <li
                              className="as-link"
                              key={'suggestion' + suggestion.id}
                              onClick={() => {
                                handleAddInArray(suggestion);
                              }}>
                              {capitalizeFirst(suggestion.role_alias)}
                            </li>
                          ))}
                        </ul>
                      )}
                      {Object.entries(selectedAllAlias).length > 0 ? (
                        selectedAllAlias.map((item, index) => (
                          <div
                            class="d-flex align-items-center mt-3"
                            key={index}>
                            <div className="pipeline_alias_role">
                              <div className="alias_name">
                                {capitalizeFirst(item.role_alias)}
                              </div>

                              <div className="alias_permission_flex">
                                <Form.Group className="fieldset payment-checkbox m-0">
                                  <Form.Check
                                    type={'checkbox'}
                                    label={'Read'}
                                    id={`Read_${index}`}
                                    // value={'read'}
                                    checked={item.read}
                                    onChange={() => {
                                      handleChangePermissions('read', item);
                                    }}
                                  />
                                </Form.Group>

                                <Form.Group className="fieldset payment-checkbox m-0">
                                  <Form.Check
                                    type={'checkbox'}
                                    label={'Update'}
                                    id={`Update_${index}`}
                                    // value={'update'}
                                    checked={item.update}
                                    onChange={() => {
                                      handleChangePermissions('update', item);
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <span
                              class="as-link ms-2 pipeline-cross-icon"
                              onClick={() => {
                                handleShowModel(item);
                              }}>
                              <i
                                class="fas fa-times"
                                style={{fontSize: '1.5rem'}}></i>
                            </span>
                          </div>
                        ))
                      ) : (
                        <></>
                        // <> No Permissions Found </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
          {tabType === labels.sessions_type_general[selected_lang] && (
            <TransparentButton
              onClick_button={() => {
                buttonRef.current.click();
              }}
              buttonName={props.editTag?.id ? 'Update Tag' : 'Create Tag'}
              disable={false}
              className={'filter-btn-wrapper pt-1 pb-1 create-session-btn fixed-width-inputs mx-0 bg_transparent bottom-1'}
              styleForButton={bottom10px}
            />
          )}
        </div>
      </SlidingPanel>

      {showModel === 'show_delete_model' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to delete this Role?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeAliasRole();
          }}
        />
      )}

      {showModel === 'crop_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="tags"
            ratio={25 / 24}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}

      {showModel === 'show_tag_delete_model' && deletedId && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to delete this tag?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            props.handleDelete(deletedId);
            setShowModel('');
          }}
        />
      )}
    </>
  );
};

export default CreateTag;
