import React, {useRef, useState} from 'react';

import {getTimeFormatForChat} from '../../Services/AppServices';
import {DEFAULT_IMAGE, S3_EMOJI_DEV} from '../../Utils/env';
import SmartChatReplyBox from '../../Components/Common/SmartChatReplyBox';
import moment from 'moment/moment';
import {
  fixName,
  getDateWithTimezone,
  checkTimeDurationWithDate,
  filteringUsers,
  arrayColumn,
  formatString,
} from '../../Utils/commonUtils';
import {getItem} from '../../Utils/LocalStorage';


const SmartLiveChatBox = ({ allChat,
    allChatUsers,
    handleChatBoxSignal,
    chatStatus,
    chatModule,
    lastMsgTime,
    loginUserId,
    chatEnableStatus,
    ShowSelectedComponent,
    handleOpenChatRoom
}) => {

    
  const [currentComponent, setCurrentComponent] = useState('');

 

  const [chatData, setChatData] = useState([]);

  const messagesEndRef = useRef(null);

  const currentUrl = window.location.href;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const newSubstring = currentUrl.substring(lastSlashIndex + 1);

  const handleSelectReply = (key, item, cKey, pKey) => {
    if (key === 'update-poll') {
      handleChatBoxSignal('update-poll', item, cKey, pKey);
    } else if (key === 'reply') {
      
      handleChatBoxSignal('add-replies', item, cKey, pKey);
    } else if (key === 'emoji') {
      handleChatBoxSignal('add-emoji', item, cKey, pKey);
    } else if (key === 'best-answer') {
      handleChatBoxSignal('best-answer', item, cKey, pKey);
    } else if (key === 'show-participants') {
      handleChatBoxSignal('show-participants', item, cKey, pKey);
    } else if (key === 'show-reply-users') {
      handleChatBoxSignal('show-reply-users', item, cKey, pKey);
    }
  };

 
  const getLastNonPrivateObject = arr => {
    const id = localStorage.getItem('chatUserId' ) ? localStorage.getItem('chatUserId') : loginUserId;
    let count = 0;
    let lastNonPrivateObj = null;

   
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].isPrivate === false) {
        count++;
        lastNonPrivateObj = arr[i];
        // break;
      } else if (
        id &&
        arr[i].isPrivate &&
        (arr[i].sent_by === id || arr[i].privateChatData.sent_to === id)
      ) {
        count++;
        lastNonPrivateObj = arr[i];
        // break;
      }
    }

    return {
      lastNonPrivateObj,
      count,
    };
  };

  const getEmojiObject = arr => {
    return arr
      .reduce((acc, curr) => {
        const found = acc.find(item => item.emojiID === curr.emojiID);
        if (found) {
          found.count++;
        } else {
          acc.push({
            emojiID: curr.emojiID,
            image: S3_EMOJI_DEV + curr.skinTone + '/' + curr.emojiName + '.svg',
            count: 1,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
  };

  const getRepliesObject = arr => {
    return arr
      .reduce((acc, curr) => {
        const found = acc.find(item => item.sent_by === curr.sent_by);
        if (found) {
          found.count++;
        } else {
          const found = allChatUsers.find(
            objs =>
              objs.id === curr.sent_by &&
              (!curr.isPrivate ||
                (curr.isPrivate && curr.sent_by === getItem('chatUserId') ? getItem('chatUserId') : loginUserId )),
          );
          if (typeof found === 'object') {
            acc.push({
              sent_by: curr.sent_by,
              profile_img: found.profile_img
                ? found.profile_img
                : DEFAULT_IMAGE,
            });
          }
        }
        return acc;
      }, [])
      .slice(-3);
  };


  console.log(allChatUsers,'loginUserId')
  console.log(allChat,'allChatUsers in box')
  return (
   <>
  
        <div>
          <div
            className={
              newSubstring === 'history' ? 'messages history-chat' : 'messages'
            }>
            {allChat.length > 0 &&
              allChat.map((item, index) => (
                <div
                  className="messages-list live_chat_list"
                  key={`msg-list-${item[0]}-${index}`}>
                  <div className="message-day-saparator">
                    {moment(item[0]).calendar({
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      nextWeek: 'dddd',
                      lastDay: '[Yesterday]',
                      lastWeek: 'dddd',
                      sameElse: 'DD/MM/YYYY',
                    })}
                  </div>
                  {Object.entries(item[1]).length > 0 &&
                    Object.entries(item[1]).map((chat, index) => {
                      if (chat[1].isCorrect) {
                        localStorage.setItem(
                          'isCorrectPath',
                          item[0] + '/' + chat[0],
                        );
                        localStorage.setItem(
                          'isCorrectMessage',
                          JSON.stringify(chat[1]),
                        );
                      }
                      return (
                        // (!lastMsgTime || lastMsgTime > chat[1].send_date) &&
                        (!lastMsgTime ||
                          checkTimeDurationWithDate(
                            lastMsgTime,
                            getDateWithTimezone(chat[1].send_date),
                          ) < 3000) &&
                        (chat[1].sent_by === (localStorage.getItem('chatUserId') ? localStorage.getItem('chatUserId') : loginUserId)  ? (
                          <div
                            key={`msg-${chat[1].send_date}-${index}`}
                            className={`message-item mb-0 new-msg-item reply-message  ${
                              chat[1].isPrivate ? 'private-msg' : ''
                            }`}>
                            <div className="msg-container login_user_msg">
                              <div className="msg-content">
                               
                                <div className="msg-content-right">
                                  <h2>
                                    {allChatUsers.find(
                                      objs => objs.id === chat[1].sent_by,
                                    )?.first_name &&
                                      fixName(
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        )?.first_name,
                                        allChatUsers.find(
                                          objs => objs.id === chat[1].sent_by,
                                        )?.last_name,
                                      )}{' '}
                                    <span className="msg-date">
                                      {getTimeFormatForChat(chat[1].send_date)}
                                    </span>
                                    {chat[1].isCorrect && (
                                      <span className="icon">
                                        {' '}
                                        <i className="fa-solid fa-circle-check"></i>
                                      </span>
                                    )}
                                  </h2>
                                  <div className="msg-content-text">
                                    <p>
                                      {chat[1].type !== 'poll' &&
                                        !chat[1].type !== 'emoji' &&
                                        chat[1].message}
                                    </p>
                                    <div className="emoji-icons-wrapper">
                                      <div className="emoji-icons d-flex align-items-center">
                                        {chat[1].type &&
                                          chat[1].type === 'emoji' && (
                                            <div className="emoji-icons-item">
                                              {chat[1].emoji?.skinTone &&
                                                chat[1].emoji?.emojiName && (
                                                  <img
                                                    src={
                                                      S3_EMOJI_DEV +
                                                      chat[1].emoji.skinTone +
                                                      '/' +
                                                      chat[1].emoji.emojiName +
                                                      '.svg'
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                
                                   
                                   
                                    <div className="snapshot-imgs-wrapper">
                                 

                                      {chat[1].type === 'image' && (
                                        <div
                                          className="snapshot-imgs-item"
                                          onClick={() => {
                                            handleChatBoxSignal(
                                              'show-image',
                                              chat[1].picture_url,
                                            );
                                          }}>
                                          <img
                                            src={chat[1].picture_url}
                                            alt=""
                                          />
                                        </div>
                                      )}

                                      {chat[1].type === 'video' &&
                                        chat[1].video_url && (
                                          <div className="snapshot-imgs-item">
                                            <video
                                              src={chat[1].video_url}
                                              playsInline
                                              autoPlay
                                              controls
                                            />
                                          </div>
                                        )}

                                  

                                      
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="emoji-icons-wrapper emoji-icons-live-chat-wrapper">
                                <div className="emoji-icons d-flex align-items-center">
                                  {chat[1].hasOwnProperty('emojis') &&
                                    chat[1].emojis.length > 0 &&
                                    getEmojiObject(chat[1].emojis).map(
                                      (emojiObj, index) => (
                                        <div
                                          onClick={() => {
                                            handleChatBoxSignal(
                                              'show-emojis',
                                              chat[1].emojis,
                                            );
                                          }}
                                          className={
                                            emojiObj.count > 1
                                              ? 'emoji-icons-item with-counts'
                                              : 'emoji-icons-item'
                                          }
                                          style={{height: emojiObj.count > 1 ? '30px' : '',  width: emojiObj.count > 1 ? '30px' : ''}}
                                          key={`${emojiObj.emojiName}-${index}`}>
                                          <img src={emojiObj.image} alt="" />
                                          {emojiObj.count > 1 && (
                                            <span className="reaction-count">
                                              {emojiObj.count}
                                            </span>
                                          )}
                                        </div>
                                      ),
                                    )}

                                  <div
                                    className="emoji-icons-item add-new-reaction"
                                    onClick={() => {
                                      if (chatStatus) {
                                        handleSelectReply(
                                          'emoji',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }
                                    }}>
                                    <i className="icon-smiley-plus" />
                                  </div>
                                </div>

                                <span
                                  className="icons dot-options msg_option as-link"
                                  onClick={() => {
                                    if (chatStatus) {
                                      handleChatBoxSignal(
                                        'replyOptions',
                                        chat[1],
                                        chat[0],
                                        item[0],
                                      );
                                    }
                                  }}>
                                  <i className="fas fa-ellipsis-vertical" />
                                </span>
                              </div>

                              {chat[1].hasOwnProperty('replies') &&
                                chat[1].replies.length > 0 &&
                                getLastNonPrivateObject(chat[1].replies)
                                  .lastNonPrivateObj && (
                                  <SmartChatReplyBox
                                    reply={
                                      getLastNonPrivateObject(chat[1].replies)
                                        .lastNonPrivateObj
                                    }
                                    user={allChatUsers.find(
                                      objs =>
                                        objs.id ===
                                        chat[1].replies.slice(-1)[0]?.sent_by,
                                    )}
                                  />
                                )}

                              <div className="msg-user-replies-wrapper">
                                {chat[1].hasOwnProperty('replies') &&
                                  chat[1].replies.length > 0 &&
                                  getRepliesObject(chat[1].replies).map(
                                    (obj, i) => (
                                      <div
                                        className="person-img"
                                        key={`repImg-${i}`}
                                        onClick={() => {
                                          // handleChatBoxSignal(
                                          //   'show-profile',
                                          //   allChatUsers.find(
                                          //     objs => objs.id == obj.sent_by,
                                          //   ),
                                          // );
                                          handleSelectReply(
                                            'show-reply-users',
                                            chat[1],
                                            chat[0],
                                            item[0],
                                          );
                                        }}>
                                        <img src={obj.profile_img} alt="" />
                                      </div>
                                    ),
                                  )}

                                {chat[1].hasOwnProperty('replies') &&
                                  chat[1].replies.length > 0 &&
                                  getLastNonPrivateObject(chat[1].replies)
                                    .count > 0 && (
                                    <span
                                      className="reaction-count as-link"
                                      onClick={() => {
                                        // setReplies(chat[1]);
                                        setCurrentComponent('show-replies');
                                        // if (chat[1].type === 'poll') {
                                        //   setHandleData({
                                        //     key: 'update-poll',
                                        //     item: chat[1],
                                        //     cKey: chat[0],
                                        //     pKey: item[0],
                                        //   });
                                        // }
                                        handleChatBoxSignal(
                                          'show-replies',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }}>
                                      {getLastNonPrivateObject(chat[1].replies)
                                        .count === 1
                                        ? '1 Reply'
                                        : `${
                                            getLastNonPrivateObject(
                                              chat[1].replies,
                                            ).count - 1
                                          }+ Replies`}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : (
                         
                          (!chat[1].isPrivate ||
                            (chat[1].isPrivate &&
                              chat[1].privateChatData.sent_to ===
                                localStorage.getItem('chatUserId') ? localStorage.getItem('chatUserId') : loginUserId)) && (
                            <div
                              key={`msg-${chat[1].send_date}-${index}`}
                              className={`message-item new-msg-item ${
                                chat[1].isPrivate ? 'private-msg' : ''
                              }`}>
                              <div className="msg-container">
                                <div className="msg-content">
                                  <div className="msg-content-left">
                                    <div
                                      className="msg-usr-img"
                                      onClick={() => {
                                        // handleChatBoxSignal(
                                        //   'show-profile',
                                        //   allChatUsers.find(
                                        //     objs => objs.id === chat[1].sent_by,
                                        //   ),
                                        // );
                                      }}>
                                      <img
                                        src={
                                          allChatUsers.find(
                                            objs => objs.id === chat[1].sent_by,
                                          )?.profile_img
                                            ? allChatUsers.find(
                                                objs =>
                                                  objs.id === chat[1].sent_by,
                                              )?.profile_img
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="msg-content-right">
                                    <h2>
                                      {allChatUsers.find(
                                        objs => objs.id === chat[1].sent_by,
                                      )?.first_name &&
                                        fixName(
                                          allChatUsers.find(
                                            objs => objs.id === chat[1].sent_by,
                                          )?.first_name,
                                          allChatUsers.find(
                                            objs => objs.id === chat[1].sent_by,
                                          )?.last_name,
                                        )}{' '}
                                      <span className="msg-date">
                                        {getTimeFormatForChat(
                                          chat[1].send_date,
                                        )}
                                      </span>
                                      {chat[1].isCorrect && (
                                        <span className="icon">
                                          {' '}
                                          <i className="fa-solid fa-circle-check"></i>
                                        </span>
                                      )}
                                    </h2>
                                    <div className="msg-content-text">
                                      <p>
                                        {chat[1].type !== 'poll' &&
                                          !chat[1].type !== 'emoji' &&
                                          chat[1].message}
                                      </p>
                                      <div className="emoji-icons-wrapper">
                                        <div className="emoji-icons d-flex align-items-center">
                                          {chat[1].type === 'emoji' && (
                                            <div className="emoji-icons-item">
                                              <img
                                                src={
                                                  S3_EMOJI_DEV +
                                                  chat[1].emoji.skinTone +
                                                  '/' +
                                                  chat[1].emoji.emojiName +
                                                  '.svg'
                                                }
                                                alt=""
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="snapshot-imgs-wrapper">
                                       

                                        {chat[1].type === 'image' && (
                                          <div
                                            className="snapshot-imgs-item"
                                            onClick={() => {
                                              handleChatBoxSignal(
                                                'show-image',
                                                chat[1].picture_url,
                                              );
                                            }}>
                                            <img
                                              src={chat[1].picture_url}
                                              alt=""
                                            />
                                          </div>
                                        )}

                                        {chat[1].type === 'video' &&
                                          chat[1].video_url && (
                                            <div className="snapshot-imgs-item">
                                              <video
                                                src={chat[1].video_url}
                                                playsInline
                                                autoPlay
                                                controls
                                              />
                                            </div>
                                          )}

                                        
                                        
                                        
                                        
                                        
                                        
                                        

                                     

                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="emoji-icons-wrapper">
                                  <div className="emoji-icons d-flex align-items-center">
                                    {chat[1].hasOwnProperty('emojis') &&
                                      chat[1].emojis.length > 0 &&
                                      getEmojiObject(chat[1].emojis).map(
                                        (emojiObj, index) => (
                                          <div
                                            onClick={() => {
                                              handleChatBoxSignal(
                                                'show-emojis',
                                                chat[1].emojis,
                                              );
                                            }}
                                            className={
                                              emojiObj.count > 1
                                                ? 'emoji-icons-item with-counts'
                                                : 'emoji-icons-item'
                                            }

                                            style={{height: emojiObj.count > 1 ? '30px' : '',  width: emojiObj.count > 1 ? '30px' : ''}}
                                            key={`${emojiObj.emojiName}-${index}`}>
                                            <img src={emojiObj.image} alt="" />
                                            {emojiObj.count > 1 && (
                                              <span className="reaction-count">
                                                {emojiObj.count}
                                              </span>
                                            )}
                                          </div>
                                        ),
                                      )}

                                    <div
                                      className="emoji-icons-item add-new-reaction"
                                      onClick={() => {
                                        if (chatStatus) {
                                          handleSelectReply(
                                            'emoji',
                                            chat[1],
                                            chat[0],
                                            item[0],
                                          );
                                        }
                                      }}>
                                      <i className="icon-smiley-plus" />
                                    </div>
                                  </div>

                                  <span
                                    className="icons dot-options msg_option as-link"
                                    onClick={() => {
                                      if (chatStatus) {
                                        handleChatBoxSignal(
                                          'replyOptions',
                                          chat[1],
                                          chat[0],
                                          item[0],
                                        );
                                      }
                                    }}>
                                    <i className="fas fa-ellipsis-vertical" />
                                  </span>
                                </div>

                                {chat[1].hasOwnProperty('replies') &&
                                  chat[1].replies.length > 0 &&
                                  getLastNonPrivateObject(chat[1].replies)
                                    .lastNonPrivateObj && (
                                    <SmartChatReplyBox
                                      reply={
                                        getLastNonPrivateObject(chat[1].replies)
                                          .lastNonPrivateObj
                                      }
                                      user={allChatUsers.find(
                                        objs =>
                                          objs.id ===
                                          chat[1].replies.slice(-1)[0]?.sent_by,
                                      )}
                                    />
                                  )}

                                <div className="msg-user-replies-wrapper">
                                  {chat[1].hasOwnProperty('replies') &&
                                    chat[1].replies.length > 0 &&
                                    getRepliesObject(chat[1].replies).map(
                                      (obj, i) => (
                                        <div
                                          className="person-img"
                                          key={`repImg-${i}`}
                                          onClick={() => {
                                            // handleChatBoxSignal(
                                            //   'show-profile',
                                            //   allChatUsers.find(
                                            //     objs => objs.id == obj.sent_by,
                                            //   ),
                                            // );
                                            handleSelectReply(
                                              'show-reply-users',
                                              chat[1],
                                              chat[0],
                                              item[0],
                                            );
                                          }}>
                                          <img src={obj.profile_img} alt="" />
                                        </div>
                                      ),
                                    )}

                                  {chat[1].hasOwnProperty('replies') &&
                                    chat[1].replies.length > 0 &&
                                    getLastNonPrivateObject(chat[1].replies)
                                      .count > 0 && (
                                      <span
                                        className="reaction-count as-link"
                                        onClick={() => {
                                          // setReplies(chat[1]);
                                          setCurrentComponent('show-replies');
                                          // if (chat[1].type === 'poll') {
                                          //   setHandleData({
                                          //     key: 'update-poll',
                                          //     item: chat[1],
                                          //     cKey: chat[0],
                                          //     pKey: item[0],
                                          //   });
                                          // }
                                          handleChatBoxSignal(
                                            'show-replies',
                                            chat[1],
                                            chat[0],
                                            item[0],
                                          );
                                        }}>
                                        {getLastNonPrivateObject(
                                          chat[1].replies,
                                        ).count === 1
                                          ? '1 Reply'
                                          : `${
                                              getLastNonPrivateObject(
                                                chat[1].replies,
                                              ).count - 1
                                            }+ Replies`}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>

                           
                          )
                        ))
                      );
                    })}
                </div>
              ))}
            <div ref={messagesEndRef} />
          </div>
        </div>
   
   </>
  )
}

export default SmartLiveChatBox