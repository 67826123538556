import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';

import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import Sidebar from '../Sidebar';
import commonApi from '../../Services/CommonService';
import Moment from 'react-moment';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import MessageSearchFilter from '../../Components/Modals/MessagesSearchFilter';
import {Scrollbars} from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CreateSession from '../../Components/SessionDetails/CreateSession';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import CreateGroup from '../../Components/Messages/CreateGroup';
import OtherUserImages from '../../Components/Common/OtherUserImages';
import {
  CustomSlidingButtons,
  DiscoverBuilders,
  Pannels,
} from '../../Components';
import OpportunityScreen from '../../Components/FrontScreens/OpportunityScreen';
import {useSelector} from 'react-redux';
import {
  chatroomInfo,
  filteringUsers,
  arrayColumn,
  formatString,
  checkPermissions,
  getBuilders,
} from '../../Utils/commonUtils';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
// import {NoConvos, NoMessages} from '../../Constants/Images';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {fixName} from '../../Utils/commonUtils';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import ScrollLoader from '../../Components/Modals/ScrollLoader';
import Slider from 'react-slick';
import {rewardStar} from '../../Constants/Images';
import AppointmentTabs from '../../Components/AppointmentPage/AppointmentTabs';
import SuggestionsListing from './StaticPages/SuggestionsListing';
import CallScreenModal from '../../Components/Modals/CallScreenModal';
import SendInvitation from './StaticPages/SendInvitation';
import AllBuilders from './StaticPages/AllBuilders';
import CallMessages from '../../Components/Messages/CallMessages';
import TwillioSMS from '../../Components/CallCenter/TwillioSMS';
import moment from 'moment';
import {getTimeFormatForChat} from '../../Services/AppServices';
const TwillioMessges = ({
  setChatDetails,
  setSubTabType,
  chatRoomId,
  chatPropType,
  setAllChatUsers,
  workspaceData,
  convosData,
  setConvosData,
  setChatRoomId,
  setChatRoomName,
  setChatPropType,
  setChatDetailItem,
  subTabType,
  showBuildersComponent,
  setShowBuildersComponent
}) => {
  // const [showBuildersComponent, setShowBuildersComponent] = useState('');
  // const [chatDetails, setChatDetails] = useState([]);
  // const [chatDetailItem, setChatDetailItem] = useState([]);
  // const [allChatUsers, setAllChatUsers] = useState([]);
  // const [chatRoomName, setChatRoomName] = useState('');
  // const [chatRoomId, setChatRoomId] = useState('');
  // const [chatPropType, setChatPropType] = useState('');

  const [tabType, setTabType] = useState('chats');
  const [liveChatTab, setLiveChatTab] = useState('active');
  //   const [invites, setInvites] = useState([]);
  const [page, setPage] = useState(1);
  const [chatList, setChatList] = useState([]);
  // const [convosData, setConvosData] = useState([]);
  const [liveChatData, setLiveChatData] = useState([]);
  const [queueliveChatData, setQueueLiveChatData] = useState([]);
  const [closedChatData, setClosedChatData] = useState([]);
  // const [userInfo, setUserInfo] = useState([]);

  const [showSpinner, setShowSpinner] = useState(true);
  const [searchFilter, setSearchFilterModal] = useState(false);
  // const [groupChat, setGroupChat] = useState('');
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [liveChatQry, setLiveChatQry] = useState('');
  // const [showComponent, setShowComponent] = useState('');
  const [filter, setFilter] = useState({
    event: true,
    opportunity: true,
    personal: true,
    session: true,
    question: true,
  });
  // const [show, setShow] = useState(false);
  const [threeDots, setThreeDots] = useState(false);
  const [liveChatDots, setLiveChatDots] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  // const [workspaceData, setWorkspaceData] = useState([]);
  const [liveChatToggle, setLiveChatToggle] = useState(false);
  const [smsToggle, setSmsToggle] = useState(false);
  const [liveChatType, setLiveChatType] = useState('accepted');
  const [buildersUserId, setBuildersUserId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [callModal, setCallModal] = useState(false);
  const [filterType, setFilterType] = useState('All');
  const [showMessage, setShowMessage] = useState('');
  const [userChat, setUserChat] = useState({});
  const [checkUserHitApi, setCheckUserHitApi] = useState(false);

  // const [subTabType, setSubTabType] = useState('SMS');
  const [phoneDetail, setPhoneDetail] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useRef(false);
  const hasMoreData = useRef(false);

  const locationWeb = useLocation();
  const history = useHistory();
  const {id} = useParams();

  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const currentURL = window.location.href;
  const allBuilderList = useSelector(state => state.AllBuilderList);

  // Create a URL object from the current URL
  const url = new URL(currentURL);

  // Get the 'id' parameter value
  const groupId = url.searchParams.get('id');

  const hitApi = useSelector(state => state.chatListing);
  const menuPermesions = useSelector(state => state.menuPermesionList);
  const menuItems = useSelector(state => state.menuActiveList);
  const [messages, setMessages] = useState([]);
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollLock, setScrollLock] = useState(false);

  useEffect(() => {
    setShowBuildersComponent('');
    // setChatRoomId('');
  }, [liveChatToggle, subTabType]);

  useEffect(() => {
    setPhoneNumber('');
    setSuggestions([]);
    setShowBuildersComponent('');
  }, [subTabType]);

  const check_users_in_company = searchQuery => {
    const payload = {search_query: searchQuery};

    commonApi
      .check_users_in_company(payload)
      .then(res => {
        setSuggestions(res?.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleSuggestionsClick = (item, cleanedPhoneNumber) => {
    setPhoneNumber(cleanedPhoneNumber);
    setPhoneDetail(item);
    setSuggestions([]);
  };

  const TabList = [
    {id: 'All', title: 'All'},
    {id: 'Recent', title: 'Recent'},
    {id: 'Customers', title: 'Customers'},
    {id: 'Team', title: 'Team'},
  ];

  const handleType = e => {
    const {id, value} = e.target;
    setFilterType(value);
  };

  const handleChange = e => {
    const value = e.target.value;

    setPhoneNumber(value);
    const getBuiders = getBuilders(value, allBuilderList, 'messages');
    setSuggestions(getBuiders);
  };

  // const handleClose = () => setShow(false);

  // const closeChatRoom = () => {
  //   var payLoad = {
  //     chat_room_id: groupChat.id,
  //   };
  //   commonApi
  //     .close_chat_room(payLoad)
  //     .then(res => {
  //       if (res.status === 200) {
  //         successMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const setGalleryType = type => {
  //   if (tabType !== type) {
  //     setShowSpinner(true);
  //   }
  //   setTabType(type);
  //   //  getInvites(tabType);
  //
  //   // setShowSpinner(true);
  //   // getConservation(tabType);
  // };

  const checkNewUsersInGroup = id => {
    var data = {group_id: id};
    commonApi
      .check_new_users_in_group(data)
      .then(res => {
        if (res.group_chat_detail) {
          setChatDetails(res.group_chat_detail);
          setAllChatUsers(res.group_chat_detail.all_users);
          setChatRoomName(res.group_chat_detail.chat_room_name);
          setChatRoomId(res.group_chat_detail.id);
          // const chatItem = res.group_chat_detail;
          // chatItem.id = res.group_chat_detail.type_id;
          // setChatDetailItem(chatItem);
          setShowBuildersComponent('chat-component');
          // setShowUserInfo(true);
        } else {
          errorMessage(labels.group_chat_Not_found[selected_lang]);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const getUserData = id => {
  //   var data = {user_id: id};
  //   commonApi
  //     .user_info(data)
  //     .then(res => {
  //       if (res) {
  //         setUserInfo(res.data);
  //         // setShowUserInfo(true);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getConservation = (msgType, sQry, types, pageN, type) => {
    // setShowSpinner(true);

    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    if (type !== 'accept') {
      if (hasMoreData.current || isFetching.current) {
        return; // Don't fetch if no more data or already fetching
      } else {
        if (pageN > 1) {
          setIsLoading(true);
        }
      }
    }

    // setIsFetching(true);
    isFetching.current = !isFetching.current;

    var data = {
      filter: types,
      page: pageN,
      search_query: sQry,
      status: msgType,
    };
    commonApi
      .get_conversation(data)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false);

          if (pageN > 1) {
            if (res.all_data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setConvosData([...convosData, ...res.all_data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setConvosData(res.all_data);
          }
        }

        // setShowSpinner(false);
        // setIsFetching(false);
        // setIsLoading(true);

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          // setIsFetching(false);
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getInvites = (msgType, sQry, types, pageN) => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 1000);
    if (hasMoreData.current || isFetching.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    // setIsFetching(true);
    isFetching.current = !isFetching.current;

    var data = {
      filter: types,
      page: pageN,
      search_query: sQry,
      status: msgType,
    };
    commonApi
      .get_conversation(data)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (res.all_data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setChatList([...chatList, ...res.all_data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setChatList(res.all_data);
          }
        }

        // setIsFetching(false);
        // setIsLoading(true);

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          // setIsFetching(false);
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const manageOppoChatInvitation = (id, o_id, status) => {
    if (status === 'rejected') {
      setChatList(prev => prev.filter(item => item.id !== id));
    }

    let payload = {
      // chat_room_id: id,
      // chat_status: type,
      // status: status,
      opportunity_id: o_id,
      chat_id: id,
      status: status,
    };
    commonApi
      .accept_opportunity_invitation(payload)
      .then(response => {
        if (response) {
          successMessage(response.message);
          // getConservation(tabType, chatSearchQry, filter, 1);
          if (status === 'accepted') {
            amplitudeEvent('ACCEPT_CHAT_INVITE');
          } else {
            amplitudeEvent('REJECT_CHAT_INVITE');
          }
        }

        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };
  const manageChatInvitation = (id, type, status) => {
    setChatList(prev => prev.filter(item => item.id !== id));
    let payload = {
      chat_room_id: id,
      chat_status: type,
      status: status,
    };
    commonApi
      .manage_chat_invitation(payload)
      .then(response => {
        if (response) {
          successMessage(response.message);

          if (liveChatToggle) {
            setLiveChatTab('active');
          }

          // getConservation(tabType, chatSearchQry, filter, 1);
          if (status === 'accepted') {
            amplitudeEvent('ACCEPT_CHAT_INVITE');
          } else {
            amplitudeEvent('REJECT_CHAT_INVITE');
          }
        }

        setShowSpinner(false);
      })
      .catch(error => {
        return {type: 'error', message: error.message};
      });
  };

  const collectingUsers = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator =
      item.chat_type === 'opportunity'
        ? 'team_member_details'
        : 'moderator_detail';
    const audience =
      item.chat_type === 'opportunity'
        ? 'other_persons_opportunity_chat_room'
        : 'audience_detail';

    const chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience].length > 0) {
      item[audience].forEach(obj => {
        if (
          !item.hasOwnProperty('group_detail_ids') ||
          item.group_detail_ids.includes(obj.id)
        ) {
          obj.type = 'audience';
          chatUsers.push(obj);
        }
      });
    }
    if (item[moderator].length > 0) {
      item[moderator].forEach(obj => {
        if (
          !item.hasOwnProperty('group_detail_ids') ||
          item.group_detail_ids.includes(obj.id)
        ) {
          obj.type = 'moderator';
          chatUsers.push(obj);
        }
      });
    }
    return chatUsers;
  };

  const handleOpenChatRoom = chatDetail => {
    if (liveChatToggle && liveChatTab === 'queue') {
      setQueueLiveChatData(prev =>
        prev.filter(item => item.id !== chatDetail.id),
      );
      setLiveChatType('accepted');
    } else {
      setChatList(prev => prev.filter(item => item.id !== chatDetail.id));
    }

    if (
      (tabType === 'chats' && !liveChatToggle) ||
      (liveChatTab === 'active' && liveChatToggle)
    ) {
      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      if (chatDetail.chat_type === 'opportunity') {
        if (chatDetail.creator_id === localStorage.getItem('id')) {
          setChatPropType('mine-opportunity');
        } else if (chatDetail.team_member_ids) {
          const myArray = chatDetail.team_member_ids.split(',');
          if (myArray.includes(localStorage.getItem('id'))) {
            setChatPropType('mine-opportunity');
          } else {
            setChatPropType('other-opportunity');
          }
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        if (
          chatDetail.chat_type === 'poc_team' ||
          chatDetail.chat_type === 'poc_customer'
        ) {
          setChatPropType('Poc_chat');
        } else {
          if (chatDetail.chat_room_type === 'group') {
            setChatPropType('personal-group');
          } else {
            setChatPropType('personal-single');
          }
        }
      }

      const chatItem = chatDetail;
      chatItem.id = chatDetail.type_id;
      setChatDetailItem(chatItem);

      setShowBuildersComponent('chat-component');
    } else if (tabType === 'subInvites' || liveChatTab === 'queue') {
      if (chatDetail.chat_type === 'opportunity') {
        manageOppoChatInvitation(
          chatDetail.id,
          chatDetail.opportunity_id,
          'accepted',
        );
      } else {
        manageChatInvitation(
          chatDetail.id,
          chatDetail.invitation_type,
          'accepted',
        );
      }

      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      // const chatItem = chatDetail;
      // chatItem.id = chatDetail.type_id;
      // setChatDetailItem(chatItem);

      if (chatDetail.chat_type === 'opportunity') {
        setChatPropType('other-opportunity');
      } else {
        if (chatDetail.chat_room_type === 'group') {
          setChatPropType('personal-group');
        } else {
          setChatPropType('personal-single');
        }
      }

      setTimeout(() => {
        setShowBuildersComponent('chat-component');
      }, 100);
    } else if (liveChatTab === 'closed') {
      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.chat_id);

      if (chatDetail.chat_type === 'opportunity') {
        setChatPropType('other-opportunity');
      } else {
        if (chatDetail.chat_room_type === 'group') {
          setChatPropType('personal-group');
        } else {
          setChatPropType('personal-single');
        }
      }

      setTimeout(() => {
        setShowBuildersComponent('chat-component');
      }, 100);
    }
  };


  const checkNewUsersInOpportunity = id => {
    let data = {chat_id: id};
    commonApi
      .check_opportunity_new_user(data)
      .then(res => {
        if (res) {
          // const {pathname} = locationWeb;
          res.group_detail.chat_type = 'opportunity';
          // res.group_detail.opportunity_id = res.group_detail.opportunity_id;
          res.chat_type = 'opportunity';
          res.status = res.group_detail.status;
          res.id = res.group_detail.opportunity_id;
          let data = res;

          if (res.group_detail.creator_id === localStorage.getItem('id')) {
            setChatPropType('mine-opportunity');
          } else if (res.team_member_ids) {
            const myArray = res.team_member_ids.split(',');
            if (myArray.includes(localStorage.getItem('id'))) {
              setChatPropType('mine-opportunity');
            } else {
              setChatPropType('other-opportunity');
            }
          } else {
            setChatPropType('other-opportunity');
          }

          setChatDetails(data);
          setAllChatUsers(collectingUsers(res));
          setChatRoomName(res.group_detail.chat_room_name);
          setChatRoomId(res.group_detail.id);
          setShowBuildersComponent('chat-component');

          // history.replace({pathname: pathname, key: Date.now()});
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleNotificationDirection = () => {
    const {pathname, state} = locationWeb;

    if (state && state.typeIs === 'chats') {
      setChatDetails(state.chatDetail);
      setAllChatUsers(collectingUsers(state.chatDetail));
      setChatRoomName(state.chatDetail.chat_room_name);
      setChatRoomId(state.chatDetail.id);
      setShowBuildersComponent('chat-component');

      if (state.chatDetail.type === 'question') {
        manageChatInvitation(state.chatDetail.id, 'group_chat', 'accepted');
      }

      history.replace({pathname: pathname, key: Date.now()});
    }
    if (state && state.typeIs === 'opportunityMessages') {
      state.chatDetail.chat_type = 'opportunity';
      state.chatDetail.opportunity_id = state.chatDetail.id;
      state.chatDetail.chat_detail.chat_type = 'opportunity';
      for (let x in state.chatDetail.chat_detail) {
        if (!state.chatDetail[x]) {
          state.chatDetail[x] = state.chatDetail.chat_detail[x];
        }
      }
      let data = state.chatDetail;
      data.id = state.chatDetail.chat_detail.id;

      if (state.chatDetail.created_by === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (state.chatDetail.team_member_ids) {
        const myArray = state.chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }

      setChatDetails(data);
      setAllChatUsers(collectingUsers(state.chatDetail));
      setChatRoomName(state.chatDetail.chat_detail.chat_room_name);
      setChatRoomId(state.chatDetail.chat_detail.id);
      setShowBuildersComponent('chat-component');

      history.replace({pathname: pathname, key: Date.now()});
    }

    if (state && state.typeIs === 'subInvites') {
      // setGalleryType('subInvites');
      setTabType('subInvites');
      amplitudeEvent('CHECK_CHAT_INVITES');
      history.replace({pathname: pathname});
    }
    if (state && state.typeIs === 'opportunity_call') {
      checkNewUsersInOpportunity(state.chat_room_id);
    }
    if (state && state.typeIs === 'opportunity_emoji') {
      checkNewUsersInOpportunity(state.id);
    }
    if (state && state.typeIs === 'emoji') {
      checkNewUsersInGroup(state.id);
    }
    if (state && state.typeIs === 'create-group') {
      setShowBuildersComponent('group-component');
    }
  };

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        getBackgroundData();
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundData = () => {
    const DBOpenRequest = window.indexedDB.open('Build', 4);

    DBOpenRequest.onsuccess = event => {
      const db = DBOpenRequest.result;

      getData(db);
    };

    function getData(db) {
      const transaction = db.transaction(['Table1'], 'readwrite');

      transaction.oncomplete = event => {};

      transaction.onerror = event => {
        console.log(transaction.error, 'transaction error');
      };

      const objectStore = transaction.objectStore('Table1');

      const objectStoreRequest = objectStore.get('1');

      objectStoreRequest.onsuccess = event => {
        const myRecord = objectStoreRequest.result;

        var Payload = myRecord.value;
        let type;

        if (
          [
            'personalMessage',
            'sessionChatInvitation',
            'personalChatAccept',
          ].includes(Payload.type)
        ) {
          type = 'chats';
        } else if (
          ['personalChatInvitation', 'opportunityChatInvitation'].includes(
            Payload.type,
          )
        ) {
          type = 'subInvites';
        } else if (['opportunityMessage'].includes(Payload.type)) {
          type = 'opportunityMessages';
        } else if (['opportunity_call'].includes(Payload.type)) {
          type = Payload.type;
        } else if (['emoji', 'opportunity_emoji'].includes(Payload.type)) {
          type = Payload.type;
        }

        handleBackgroundNotification(type, Payload);

        setTimeout(() => {
          deleteDataFromIndexDb(db);
        }, 3000);
      };
    }
  };

  const deleteDataFromIndexDb = db => {
    let transaction = db.transaction(['Table1'], 'readwrite');
    transaction.objectStore('Table1').delete('1');

    transaction.oncomplete = () => {};
  };
  const handleBackgroundNotification = (type, Payload) => {
    if (type === 'chats') {
      const chatDetail = Payload.chat_detail
        ? Payload.chat_detail
        : Payload.group_chat_detail;

      setChatDetails(chatDetail);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_room_name);
      setChatRoomId(chatDetail.id);
      setShowBuildersComponent('chat-component');

      if (type === 'question') {
        manageChatInvitation(Payload.chatDetail.id, 'group_chat', 'accepted');
      }
    }
    if (type === 'opportunityMessages') {
      const chatDetail = Payload.opportunity_detail;

      chatDetail.chat_type = 'opportunity';
      chatDetail.opportunity_id = chatDetail.id;
      chatDetail.chat_detail.chat_type = 'opportunity';
      for (let x in chatDetail.chat_detail) {
        if (!chatDetail[x]) {
          chatDetail[x] = chatDetail.chat_detail[x];
        }
      }
      let data = chatDetail;
      data.id = chatDetail.chat_detail.id;

      if (chatDetail.created_by === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }

      setChatDetails(data);
      setAllChatUsers(collectingUsers(chatDetail));
      setChatRoomName(chatDetail.chat_detail.chat_room_name);
      setChatRoomId(chatDetail.chat_detail.id);
      setShowBuildersComponent('chat-component');
    }

    if (type === 'subInvites') {
      // setGalleryType('subInvites');
      setTabType('subInvites');
      amplitudeEvent('CHECK_CHAT_INVITES');
      // history.replace({pathname: pathname});
    }
    if (type === 'opportunity_call') {
      checkNewUsersInOpportunity(Payload.chat_room_id);
    }
    if (type === 'opportunity_emoji') {
      checkNewUsersInOpportunity(Payload.type_id);
    }
    if (type === 'emoji') {
      checkNewUsersInGroup(Payload.type_id);
    }
    if (type === 'create-group') {
      setShowBuildersComponent('group-component');
    }
  };

  useEffect(() => {
    amplitudeEvent('VISIT_MESSAGES');
    // getTabstatus();

    handleNotificationDirection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groupId && urlParts[2] === 'group-chat') {
      checkNewUsersInGroup(groupId);
    }
  }, []);

  useEffect(() => {
    if (tabType === 'chats' && !liveChatToggle && subTabType !== 'SMS') {
      getConservation(tabType, chatSearchQry, filter, 1, 'accept');
    } else if (!liveChatToggle && subTabType !== 'SMS') {
      getInvites(tabType, chatSearchQry, filter, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabType,
    chatSearchQry,
    filter,
    showBuildersComponent,
    hitApi,
    liveChatToggle,
    subTabType,
  ]);

  useEffect(() => {
    if (showBuildersComponent !== '' && showBuildersComponent !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showBuildersComponent]);

  useEffect(() => {
    if (liveChatToggle) {
      getLiveChatData();
    }
  }, [liveChatToggle, liveChatTab, liveChatQry, hitApi]);

  let debounceTimer;

  const handleScroll = values => {
    // Increment the page number and call the API
    if (subTabType === 'SMS') {
      if (scrollLock) return;

      const target = values.target;

      const tolerance = 5;
      const bottom =
        target.scrollHeight - target.scrollTop <=
        target.clientHeight + tolerance;

      if (bottom) {
        clearTimeout(debounceTimer);

        debounceTimer = setTimeout(() => {
          setPageNumber(prevPage => prevPage + 1);
          get_user_chats(pageNumber + 1);
        }, 300);
      }
    } else {
      const {clientHeight, scrollHeight, scrollTop} = values.target;
      // // Check if the user has reached the end of the content
      if (clientHeight + scrollTop >= scrollHeight) {
        getConservation(tabType, chatSearchQry, filter, page + 1);
      }
    }
  };

  useEffect(() => {
    if (workspaceData?.length > 0) {
      setTimeout(() => {
        getConservation(tabType, chatSearchQry, filter, 1);
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        if (res.status === 200) {
          // setShowBuildersComponent('firstVist');
          const tabStatus = res.tab_status;
          if (tabStatus && tabStatus.length === 0) {
            setShowBuildersComponent('firstVist');
          } else {
            const found = tabStatus.find(element => element.tab === 'chats');
            if (typeof found === 'object') {
            } else {
              setShowBuildersComponent('firstVist');
            }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getLiveChatData = () => {
    var payload = {
      status: liveChatType,
      search_query: liveChatQry,
    };
    commonApi
      .get_live_chatrooms(payload)
      .then(res => {
        if (res) {
          if (liveChatType === 'accepted') {
            setLiveChatData(res.conversations);
          } else if (liveChatType === 'pending') {
            setQueueLiveChatData(res.conversations);
          } else if (liveChatType === 'closed') {
            setClosedChatData(res.conversations);
          }
          if (liveChatToggle) {
            setTimeout(() => {
              setShowSpinner(false);
            }, 300);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Response time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Messages',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Frequency',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
    // Add other styles as needed
  };

  const MessagesPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Messages');

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuPermesions]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        workspaceData && Object.keys(workspaceData)?.length > 0 &&
        urlPart.length === 1 &&
        showBuildersComponent !== ''
      ) {
        setShowBuildersComponent('');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000);
  }, [workspaceData]);

  const buildersHandle = (t, id, item) => {
    if (t === 'profileComponent') {
      if (item?.phone) {
        const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
        const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);

        if (cleanedPhoneNumber && onlyNumnbers) {
          const message = {
            first_name: item?.first_name,
            id: item?.id,
            last_name: item?.last_name,
            phone: item?.phone,
            profile_img: item?.profile_img,
          };

          setPhoneDetail(message);
          setPhoneNumber(cleanedPhoneNumber);
        } else {
          setPhoneNumber('');
          errorMessage('User not contain number ');
        }
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setPhoneNumber('');
      setBuildersUserId(id);
      setShowBuildersComponent(t);
    }
  };

  const handleMessgaes = () => {
    if (phoneNumber && phoneNumber !== '') {
      check_user_number_exist(phoneNumber);
    } else {
      errorMessage('Please enter number or name');
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef.current]);

  const get_user_chats = page => {
    const payload = {
      // search_query: '',
      filter_type: filterType.toLowerCase(),
      page: page ? page : 1,
    };

    commonApi
      .get_user_chats(payload)
      .then(res => {
        if (res?.status === 200) {
          setShowSpinner(false);

          const fetchedData = res?.data || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setMessages(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setMessages(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setMessages([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        setShowSpinner(false);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (subTabType === 'SMS') {
      setPageNumber(1);
      get_user_chats(1);
      setShowSpinner(true);
      setScrollLock(false);
    }
  }, [subTabType, filterType]);

  const handleLastMsg = (item, userName) => {
    let messageExists = false;

    // Update existing messages
    let updatedMessages = messages.map(message => {
      if (message?.last_message?.receiver_id === item?.message?.receiver_id) {
        messageExists = true;
        setFilterType('All');
        return {
          ...message,
          last_message: {
            ...message.last_message,
            ...item.message,
            timestamp: item?.message?.timestamp,
          },
        };
      }
      return message;
    });

    // Add new message if it doesn't exist
    if (!messageExists) {
      setFilterType('All');
      updatedMessages.push({
        last_message: {
          receiver_id: item?.message?.receiver_id,
          sender_id: item?.message?.sender_id,
          message: item?.message?.message,
          timestamp: item?.message?.timestamp,
          type: item?.message?.type,
        },
        chat_with: {
          first_name: userName?.first_name,
          id: userName?.id,
          last_name: userName?.last_name,
          phone: userName?.phone,
          profile_img: userName?.profile_img,
        },
      });
    }

    // Move the last updated or newly added message to the first index
    const lastMessageIndex = updatedMessages.findIndex(
      message =>
        message.last_message.receiver_id === item?.message?.receiver_id,
    );

    if (lastMessageIndex > -1) {
      const [lastMessageObject] = updatedMessages.splice(lastMessageIndex, 1); // Remove the message
      updatedMessages = [lastMessageObject, ...updatedMessages]; // Add it to the beginning
    }

    // Update the state
    setMessages(updatedMessages);
  };

  const check_user_number_exist = phone_number => {
    const payload = {phone_number: phone_number};

    commonApi
      .check_user_in_company(payload)

      .then(res => {
        if (res.status === 200 && res.user_found) {
          setShowBuildersComponent('showMessage');
          setUserChat(phoneDetail);
          setPhoneNumber('');
        } else {
          errorMessage('User not exist in app');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const ticketTabs = [
    labels.business_type_messages[selected_lang],
    ...(MessagesPermessions &&
    (MessagesPermessions === 'allow' || MessagesPermessions?.live_chat === true)
      ? [labels.business_type_LiveChat[selected_lang]]
      : []),
  ];

  useEffect(() => {
    if (showBuildersComponent === 'group-component') {
      setChatRoomId('');
    }
  }, [showBuildersComponent]);

  return (
    //{/* {showLoader && <LoaderModal show={showLoader} />} */}

    <>
      <div className="primary_panel_wraper h-100">
        <div className="sessions-content-wrapper">
          <div className="  show-on-public ">
            <div className="d-flex align-items-center justify-content-center ">
              <div className="d-flex align-items-center justify-content-center w-100 three-buttons">
                <div className="create-ticket-tabs w-100">
                  <AppointmentTabs
                    darkmode
                    tabs={ticketTabs}
                    active={subTabType}
                    onSelect={item => {
                      setSubTabType(item);

                      if (item === 'Convos') {
                        setLiveChatToggle(false);
                      } else if (item !== 'SMS' && item !== 'Convos') {
                        setLiveChatToggle(true);
                      } else if (item === 'SMS') {
                        setLiveChatToggle(false);
                      }

                      setLiveChatQry('');
                      liveChatToggle && setLiveChatTab('active');

                      setChatSearchQry('');

                      setShowSpinner(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {!liveChatToggle && subTabType !== 'SMS' && (
            <div className="messages-header">
              {/* <div className="event-tabs dark-tabs"> */}
              <div className="event-tabs tabs-style-2 mb-0">
                {' '}
                {/* Merging */}
                <ul>
                  <li>
                    <Button
                      className={tabType === 'chats' ? 'active' : ''}
                      onClick={() => {
                        // setGalleryType('chats');
                        setTabType('chats');
                        setChatList([]);
                        setShowSpinner(true);
                      }}>
                      {labels.message_type_convos[selected_lang]}
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={tabType === 'subInvites' ? 'active' : ''}
                      onClick={() => {
                        // setGalleryType('subInvites');

                        setShowSpinner(true);
                        setTabType('subInvites');
                        setConvosData();
                        amplitudeEvent('CHECK_CHAT_INVITES');
                      }}>
                      {labels.sessions_type_invites[selected_lang]}
                    </Button>
                  </li>
                </ul>
              </div>

              {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setShowBuildersComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

              <div className="search-tabs-icons">
                <span
                  className={
                    showBuildersComponent === 'group-component'
                      ? 'icon active'
                      : 'icon'
                  }>
                  {MessagesPermessions &&
                    (MessagesPermessions === 'allow' ||
                      MessagesPermessions?.create === true) && (
                      <i
                        className="icon-plus"
                        onClick={() => {
                          if (showBuildersComponent !== 'group-component') {
                            setShowBuildersComponent('group-component');
                          } else {
                            setShowBuildersComponent('');
                          }
                        }}
                      />
                    )}
                </span>

                <span
                  onClick={() => {
                    setThreeDots(!threeDots);
                  }}
                  className={
                    threeDots ? 'icon active threeDots' : 'icon threeDots'
                  }>
                  <i className="fas fa-ellipsis-vertical" />
                </span>
              </div>
            </div>
          )}

          {liveChatToggle && subTabType !== 'SMS' && (
            <div className="messages-header">
              <div className="event-tabs tabs-style-2 mb-0">
                {' '}
                <ul>
                  <li>
                    <Button
                      className={liveChatTab === 'active' ? 'active' : ''}
                      onClick={() => {
                        setShowSpinner(true);
                        setLiveChatQry('');
                        setLiveChatTab('active');
                        setLiveChatType('accepted');
                      }}>
                      {labels.livechat_type_active[selected_lang]}
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={liveChatTab === 'queue' ? 'active' : ''}
                      onClick={() => {
                        setLiveChatQry('');
                        setLiveChatType('pending');
                        setLiveChatTab('queue');
                        setShowSpinner(true);
                      }}>
                      {labels.livechat_type_queue[selected_lang]}
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={liveChatTab === 'closed' ? 'active' : ''}
                      onClick={() => {
                        setLiveChatQry('');
                        setShowSpinner(true);
                        setLiveChatTab('closed');
                        setLiveChatType('closed');
                      }}>
                      {labels.livechat_type_closed[selected_lang]}
                    </Button>
                  </li>
                </ul>
              </div>

              <div className="search-tabs-icons">
                {/* <span
                                className={
                                  

                                  'icon'
                                }>
                                
                                <i
                                  className="icon-plus"
                                  
                                />
                             
                              </span> */}

                <span
                  onClick={() => {
                    setLiveChatDots(!liveChatDots);
                  }}
                  className={
                    liveChatDots ? 'icon active threeDots' : 'icon threeDots'
                  }>
                  <i className="fas fa-ellipsis-vertical" />
                </span>
              </div>
            </div>
          )}

          {threeDots && !liveChatToggle && subTabType !== 'SMS' && (
            <div className="person-search-wrapper mb-0 messages-users-wrapper">
              <div className="search-form d-flex">
                <Form.Group className="search-input">
                  <Form.Control
                    type="text"
                    placeholder="Who are you looking for?"
                    value={chatSearchQry}
                    onChange={e => {
                      setChatSearchQry(e.target.value);
                    }}
                  />
                </Form.Group>

                <Button>
                  <i
                    className="icon-filter-icon"
                    onClick={() => setSearchFilterModal(true)}
                  />
                </Button>
              </div>
            </div>
          )}

          {liveChatDots && liveChatToggle && (
            <div className="person-search-wrapper mb-0 messages-users-wrapper">
              <div className="search-form d-flex">
                <Form.Group className="search-input">
                  <Form.Control
                    type="text"
                    placeholder="Who are you looking for?"
                    value={liveChatQry}
                    onChange={e => {
                      setLiveChatQry(e.target.value);
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          )}

          <div className="person-search-wrapper m-0 messages-users-wrapper h-100">
            <div className="refferal-list-wrapper">
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} style={{display: 'none'}} />
                )}
                // Hide the vertical scrollbar
                renderTrackVertical={props => (
                  <div {...props} style={{display: 'none'}} />
                )}
                onScroll={handleScroll}
                className="custom-scroll"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '-webkit-fill-available',
                }}>
                {!liveChatToggle && subTabType !== 'SMS' && (
                  <>
                    {tabType === 'chats' && (
                      <>
                        {showSpinner && <LoaderSpinner />}
                        {!showSpinner && (
                          <>
                            <div className="messages-users-list h-auto custom-messages-users-list-holder">
                              {convosData?.length > 0 &&
                              Object.entries(convosData).length > 0 ? (
                                Object.entries(convosData).length > 0 &&
                                convosData.map((item, index) => {
                                  const newMessage =
                                    chatRoomId === item.id || item.read
                                      ? ''
                                      : 'new-message';
                                  const isActive =
                                    chatRoomId === item.id ? 'active' : '';
                                  const userIs =
                                    parseInt(item.creator_id) ===
                                    parseInt(localStorage.getItem('user'))
                                      ? 'user_detail'
                                      : item.hasOwnProperty('creator_details')
                                      ? 'creator_details'
                                      : 'creator_detail';
                                  return (
                                    <div
                                      key={`msg-${tabType}-${index}`}
                                      className={`message-user-item no-link likes-dislikes-msgs ${isActive} ${newMessage}`}>
                                      <div
                                        className="user-img as-link"
                                        onClick={() => {
                                          amplitudeEvent('OPEN_CHAT_ROOM');
                                          if (
                                            chatRoomId !== item.id &&
                                            !isActive
                                          ) {
                                            handleOpenChatRoom(item);
                                          }
                                        }}>
                                        <img
                                          src={
                                            tabType === 'subInvites'
                                              ? item[userIs]['profile_img']
                                                ? item[userIs]['profile_img']
                                                : DEFAULT_IMAGE
                                              : item.group_image !== ''
                                              ? item.group_image
                                              : item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                        {item.chat_type === 'question' && (
                                          <span>
                                            <i className="fa-solid fa-q"></i>
                                          </span>
                                        )}
                                        {item.chat_type === 'opportunity' && (
                                          <span className="icon icon-posts"></span>
                                        )}
                                        {item.chat_type === 'event' && (
                                          <span className="icon-tickets"></span>
                                        )}
                                        {item.chat_type === 'session' && (
                                          <span className="icon-video-camera-2"></span>
                                        )}
                                      </div>
                                      <div
                                        className="user-content as-link"
                                        onClick={() => {
                                          if (
                                            chatRoomId !== item.id &&
                                            !isActive
                                          ) {
                                            handleOpenChatRoom(item);
                                          }
                                        }}>
                                        <h2 className="single-line-text">
                                          {tabType === 'subInvites'
                                            ? fixName(
                                                item[userIs]['first_name'],
                                                item[userIs]['last_name'],
                                              )
                                            : item.group_name !== ''
                                            ? item.group_name
                                            : fixName(
                                                item[userIs]['first_name'],
                                                item[userIs]['last_name'],
                                              )}
                                        </h2>

                                        {tabType === 'subInvites'
                                          ? item.group_name !== ''
                                            ? 'Just invited you to chat.'
                                            : 'Just invited you to a group chat.'
                                          : formatString(item.last_message)}

                                        <div className="message-date-info">
                                          <span className="message-date">
                                            <Moment format="MM/DD/YY HH:mm A">
                                              {item.last_message_time
                                                ? item.last_message_time
                                                : item.created_at}
                                            </Moment>
                                          </span>
                                        </div>
                                      </div>
                                      {item.hasOwnProperty(
                                        'opportunity_invitaion_status',
                                      ) &&
                                        tabType === 'subInvites' && (
                                          <div className="stage-likes-icons">
                                            <span
                                              className="as-link"
                                              onClick={e => {
                                                e.stopPropagation();
                                                if (
                                                  chatRoomId !== item.id &&
                                                  !isActive
                                                ) {
                                                  handleOpenChatRoom(item);
                                                }
                                              }}>
                                              <i className="icon-like-icon" />
                                            </span>
                                            <span
                                              className="as-link"
                                              onClick={e => {
                                                e.stopPropagation();
                                                manageOppoChatInvitation(
                                                  item.id,
                                                  item.opportunity_id,
                                                  'rejected',
                                                );
                                              }}>
                                              <i className="icon-unlike" />
                                            </span>
                                          </div>
                                        )}
                                      {item.hasOwnProperty(
                                        'invitation_type',
                                      ) && (
                                        <div className="stage-likes-icons">
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();

                                              handleOpenChatRoom(item);
                                            }}>
                                            <i className="icon-like-icon" />
                                          </span>
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();

                                              manageChatInvitation(
                                                item.id,
                                                item.invitation_type,
                                                'rejected',
                                              );
                                            }}>
                                            <i className="icon-unlike" />
                                          </span>
                                        </div>
                                      )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        item.hasOwnProperty('group_detail') && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.audience_detail.concat(
                                                item.moderator_detail,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                item.group_detail,
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        !item.hasOwnProperty(
                                          'opportunity_invitaion_status',
                                        ) &&
                                        item.hasOwnProperty('opportunity_id') &&
                                        item.chat_type === 'opportunity' && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.other_persons_opportunity_chat_room.concat(
                                                item.team_member_details,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                [
                                                  ...item.other_persons_opportunity_chat_room,
                                                  ...item.team_member_details,
                                                  item.creator_details,
                                                ],
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  <div className="no-results-wrapper mt-5">
                                    <div className="img-bg-wrapper">
                                      <img
                                        src={require('../../Assets/images/norecord/no-convos.webp')}
                                        alt=""
                                      />
                                    </div>
                                    <div className="no-results-content">
                                      <h2>
                                        {
                                          labels.MANAGE_GROUP_CHAT[
                                            selected_lang
                                          ]
                                        }
                                      </h2>
                                      <p>
                                        {labels.Create_to_Invite[selected_lang]}
                                      </p>

                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className="btn btn-dark"
                                          onClick={() => {
                                            setShowBuildersComponent(
                                              'group-component',
                                            );
                                          }}>
                                          {labels.Create_Table[selected_lang]}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {tabType === 'subInvites' && (
                      <>
                        {showSpinner && <LoaderSpinner />}
                        {!showSpinner && (
                          <>
                            <div className="messages-users-list h-auto pr-1">
                              {chatList?.length > 0 &&
                              Object.entries(chatList).length > 0 ? (
                                Object.entries(chatList).length > 0 &&
                                chatList.map((item, index) => {
                                  const newMessage =
                                    chatRoomId === item.id || item.read
                                      ? ''
                                      : 'new-message';
                                  const isActive =
                                    chatRoomId === item.id ? 'active' : '';
                                  const userIs =
                                    parseInt(item.creator_id) ===
                                    parseInt(localStorage.getItem('user'))
                                      ? 'user_detail'
                                      : item.hasOwnProperty('creator_details')
                                      ? 'creator_details'
                                      : 'creator_detail';
                                  return (
                                    <div
                                      key={`msg-${tabType}-${index}`}
                                      className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                      <div
                                        className="user-img as-link"
                                        onClick={() => {
                                          amplitudeEvent('OPEN_CHAT_ROOM');
                                          handleOpenChatRoom(item);
                                        }}>
                                        <img
                                          src={
                                            tabType === 'subInvites'
                                              ? item[userIs]['profile_img']
                                                ? item[userIs]['profile_img']
                                                : DEFAULT_IMAGE
                                              : item.group_image !== ''
                                              ? item.group_image
                                              : item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                        {item.chat_type === 'question' && (
                                          <span>
                                            <i className="fa-solid fa-q"></i>
                                          </span>
                                        )}
                                        {item.chat_type === 'opportunity' && (
                                          <span className="icon icon-posts"></span>
                                        )}
                                        {item.chat_type === 'event' && (
                                          <span className="icon-tickets"></span>
                                        )}
                                        {item.chat_type === 'session' && (
                                          <span className="icon-video-camera-2"></span>
                                        )}
                                      </div>
                                      <div
                                        className="user-content as-link"
                                        onClick={() => {
                                          handleOpenChatRoom(item);
                                        }}>
                                        <h2 className="single-line-text">
                                          {tabType === 'subInvites'
                                            ? fixName(
                                                item[userIs]['first_name'],
                                                item[userIs]['last_name'],
                                              )
                                            : item.group_name !== ''
                                            ? item.group_name
                                            : fixName(
                                                item[userIs]['first_name'],
                                                item[userIs]['last_name'],
                                              )}
                                        </h2>

                                        {tabType === 'subInvites'
                                          ? item.group_name !== ''
                                            ? 'Just invited you to chat.'
                                            : 'Just invited you to a group chat.'
                                          : item.last_message ===
                                            formatString(item.last_message)}

                                        <div className="message-date-info">
                                          <span className="message-date">
                                            <Moment format="MM/DD/YY HH:mm A">
                                              {item.last_message_time
                                                ? item.last_message_time
                                                : item.created_at}
                                            </Moment>
                                          </span>
                                          {/* {renderMessageUsers(item.moreUsers)} */}
                                        </div>
                                      </div>
                                      {item.hasOwnProperty(
                                        'opportunity_invitaion_status',
                                      ) &&
                                        tabType === 'subInvites' && (
                                          <div className="stage-likes-icons">
                                            <span
                                              className="as-link"
                                              onClick={e => {
                                                e.stopPropagation();
                                                handleOpenChatRoom(item);
                                              }}>
                                              <i className="icon-like-icon" />
                                            </span>
                                            <span
                                              className="as-link"
                                              onClick={e => {
                                                e.stopPropagation();
                                                manageOppoChatInvitation(
                                                  item.id,
                                                  item.opportunity_id,
                                                  'rejected',
                                                );
                                              }}>
                                              <i className="icon-unlike" />
                                            </span>
                                          </div>
                                        )}
                                      {item.hasOwnProperty(
                                        'invitation_type',
                                      ) && (
                                        <div className="stage-likes-icons">
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();

                                              handleOpenChatRoom(item);
                                            }}>
                                            <i className="icon-like-icon" />
                                          </span>
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();

                                              manageChatInvitation(
                                                item.id,
                                                item.invitation_type,
                                                'rejected',
                                              );
                                            }}>
                                            <i className="icon-unlike" />
                                          </span>
                                        </div>
                                      )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        item.hasOwnProperty('group_detail') && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.audience_detail.concat(
                                                item.moderator_detail,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                item.group_detail,
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                      {!item.hasOwnProperty(
                                        'invitation_type',
                                      ) &&
                                        !item.hasOwnProperty(
                                          'opportunity_invitaion_status',
                                        ) &&
                                        item.hasOwnProperty('opportunity_id') &&
                                        item.chat_type === 'opportunity' && (
                                          <OtherUserImages
                                            otherUsers={filteringUsers(
                                              item.other_persons_opportunity_chat_room.concat(
                                                item.team_member_details,
                                                item.creator_detail
                                                  ? [item.creator_detail]
                                                  : [item.creator_details],
                                              ),
                                              arrayColumn(
                                                [
                                                  ...item.other_persons_opportunity_chat_room,
                                                  ...item.team_member_details,
                                                  item.creator_details,
                                                ],
                                                'id',
                                              ),
                                            )}
                                          />
                                        )}
                                    </div>
                                  );
                                })
                              ) : (
                                <>
                                  <div className="no-results-wrapper mt-5">
                                    <div className="img-bg-wrapper">
                                      <img
                                        src={require('../../Assets/images/norecord/no-convos.webp')}
                                        alt=""
                                      />
                                    </div>
                                    <div className="no-results-content">
                                      <h2>
                                        {
                                          labels.NO_INVITES_PENDING[
                                            selected_lang
                                          ]
                                        }
                                      </h2>
                                      <p>
                                        {
                                          labels.NO_INVITES_PENDING_INFO[
                                            selected_lang
                                          ]
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {liveChatToggle && subTabType !== 'SMS' && (
                  <>
                    {showSpinner && <LoaderSpinner />}
                    {!showSpinner && (
                      <>
                        {liveChatTab === 'active' && (
                          <div className="messages-users-list h-auto">
                            {Object.entries(liveChatData).length > 0 ? (
                              Object.entries(liveChatData).length > 0 &&
                              liveChatData.map((item, index) => {
                                const newMessage =
                                  chatRoomId === item.id || item.read
                                    ? ''
                                    : 'new-message';
                                const isActive =
                                  chatRoomId === item.id ? 'active' : '';
                                const userIs =
                                  parseInt(item.creator_id) ===
                                  parseInt(localStorage.getItem('user'))
                                    ? 'user_detail'
                                    : item.hasOwnProperty('creator_details')
                                    ? 'creator_details'
                                    : 'creator_detail';
                                return (
                                  <div
                                    key={`msg-${tabType}-${index}`}
                                    className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                    <div
                                      className="user-img as-link"
                                      onClick={() => {
                                        amplitudeEvent('OPEN_CHAT_ROOM');
                                        if (
                                          chatRoomId !== item.id &&
                                          !isActive
                                        ) {
                                          handleOpenChatRoom(item);
                                        }
                                      }}>
                                      <img
                                        src={
                                          liveChatTab === 'queue'
                                            ? item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                            : item.group_image !== ''
                                            ? item.group_image
                                            : item[userIs]['profile_img']
                                            ? item[userIs]['profile_img']
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                      {item.chat_type === 'question' && (
                                        <span>
                                          <i className="fa-solid fa-q"></i>
                                        </span>
                                      )}
                                      {item.chat_type === 'opportunity' && (
                                        <span className="icon icon-posts"></span>
                                      )}
                                      {item.chat_type === 'event' && (
                                        <span className="icon-tickets"></span>
                                      )}
                                      {item.chat_type === 'session' && (
                                        <span className="icon-video-camera-2"></span>
                                      )}
                                    </div>
                                    <div
                                      className="user-content as-link"
                                      onClick={() => {
                                        if (
                                          chatRoomId !== item.id &&
                                          !isActive
                                        ) {
                                          handleOpenChatRoom(item);
                                        }
                                      }}>
                                      <h2 className="single-line-text">
                                        {liveChatTab === 'queue'
                                          ? fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )
                                          : item.group_name !== ''
                                          ? item.group_name
                                          : fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )}
                                      </h2>

                                      {liveChatTab === 'queue'
                                        ? item.group_name !== ''
                                          ? 'Just invited you to chat.'
                                          : 'Just invited you to a group chat.'
                                        : formatString(item.last_message)}

                                      <div className="message-date-info">
                                        <span className="message-date">
                                          <Moment format="MM/DD/YY HH:mm A">
                                            {item.last_message_time
                                              ? item.last_message_time
                                              : item.created_at}
                                          </Moment>
                                        </span>
                                      </div>
                                    </div>
                                    {item.hasOwnProperty(
                                      'opportunity_invitaion_status',
                                    ) &&
                                      liveChatTab === 'queue' && (
                                        <div className="stage-likes-icons">
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();
                                              if (
                                                chatRoomId !== item.id &&
                                                !isActive
                                              ) {
                                                handleOpenChatRoom(item);
                                              }
                                            }}>
                                            <i className="icon-like-icon" />
                                          </span>
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();
                                              manageOppoChatInvitation(
                                                item.id,
                                                item.opportunity_id,
                                                'rejected',
                                              );
                                            }}>
                                            <i className="icon-unlike" />
                                          </span>
                                        </div>
                                      )}
                                    {item.hasOwnProperty('invitation_type') && (
                                      <div className="stage-likes-icons">
                                        <span
                                          className="as-link"
                                          onClick={e => {
                                            e.stopPropagation();

                                            handleOpenChatRoom(item);
                                          }}>
                                          <i className="icon-like-icon" />
                                        </span>
                                        <span
                                          className="as-link"
                                          onClick={e => {
                                            e.stopPropagation();

                                            manageChatInvitation(
                                              item.id,
                                              item.invitation_type,
                                              'rejected',
                                            );
                                          }}>
                                          <i className="icon-unlike" />
                                        </span>
                                      </div>
                                    )}
                                    {!item.hasOwnProperty('invitation_type') &&
                                      item.hasOwnProperty('group_detail') && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.audience_detail.concat(
                                              item.moderator_detail,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              item.group_detail,
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                    {!item.hasOwnProperty('invitation_type') &&
                                      !item.hasOwnProperty(
                                        'opportunity_invitaion_status',
                                      ) &&
                                      item.hasOwnProperty('opportunity_id') &&
                                      item.chat_type === 'opportunity' && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.other_persons_opportunity_chat_room.concat(
                                              item.team_member_details,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              [
                                                ...item.other_persons_opportunity_chat_room,
                                                ...item.team_member_details,
                                                item.creator_details,
                                              ],
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                <div className="no-results-wrapper mt-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    <h2>No Active Chats</h2>

                                    <p>
                                      {liveChatQry
                                        ? 'Active Chats not found'
                                        : "Seems you don't have any active conservation going on."}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {liveChatTab === 'queue' && (
                          <div className="messages-users-list h-auto">
                            {Object.entries(queueliveChatData).length > 0 ? (
                              Object.entries(queueliveChatData).length > 0 &&
                              queueliveChatData.map((item, index) => {
                                // const newMessage = item.read ? '' : 'new-message';
                                const newMessage =
                                  chatRoomId === item.id || item.read
                                    ? ''
                                    : 'new-message';
                                const isActive =
                                  chatRoomId === item.id ? 'active' : '';
                                const userIs =
                                  parseInt(item.creator_id) ===
                                  parseInt(localStorage.getItem('user'))
                                    ? 'user_detail'
                                    : item.hasOwnProperty('creator_details')
                                    ? 'creator_details'
                                    : 'creator_detail';
                                return (
                                  <div
                                    key={`msg-${tabType}-${index}`}
                                    className={`message-user-item no-link ${isActive} ${newMessage}`}>
                                    <div
                                      className="user-img as-link"
                                      onClick={() => {
                                        amplitudeEvent('OPEN_CHAT_ROOM');
                                        handleOpenChatRoom(item);
                                      }}>
                                      <img
                                        src={
                                          liveChatTab === 'queue'
                                            ? item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                            : item.group_image !== ''
                                            ? item.group_image
                                            : item[userIs]['profile_img']
                                            ? item[userIs]['profile_img']
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                      {item.chat_type === 'question' && (
                                        <span>
                                          <i className="fa-solid fa-q"></i>
                                        </span>
                                      )}
                                      {item.chat_type === 'opportunity' && (
                                        <span className="icon icon-posts"></span>
                                      )}
                                      {item.chat_type === 'event' && (
                                        <span className="icon-tickets"></span>
                                      )}
                                      {item.chat_type === 'session' && (
                                        <span className="icon-video-camera-2"></span>
                                      )}
                                    </div>
                                    <div
                                      className="user-content as-link"
                                      onClick={() => {
                                        handleOpenChatRoom(item);
                                      }}>
                                      <h2 className="single-line-text">
                                        {liveChatTab === 'queue'
                                          ? fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )
                                          : item.group_name !== ''
                                          ? item.group_name
                                          : fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )}
                                      </h2>

                                      {liveChatTab === 'queue'
                                        ? item.group_name !== ''
                                          ? 'Just requested to you for a live chat.'
                                          : 'Just requested to you for a live chat.'
                                        : item.last_message ===
                                          formatString(item.last_message)}

                                      <div className="message-date-info">
                                        <span className="message-date">
                                          <Moment format="MM/DD/YY HH:mm A">
                                            {item.last_message_time
                                              ? item.last_message_time
                                              : item.created_at}
                                          </Moment>
                                        </span>
                                      </div>
                                    </div>

                                    {!item.hasOwnProperty('invitation_type') &&
                                      item.hasOwnProperty('group_detail') && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.audience_detail.concat(
                                              item.moderator_detail,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              item.group_detail,
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                    {!item.hasOwnProperty('invitation_type') &&
                                      !item.hasOwnProperty(
                                        'opportunity_invitaion_status',
                                      ) &&
                                      item.hasOwnProperty('opportunity_id') &&
                                      item.chat_type === 'opportunity' && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.other_persons_opportunity_chat_room.concat(
                                              item.team_member_details,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              [
                                                ...item.other_persons_opportunity_chat_room,
                                                ...item.team_member_details,
                                                item.creator_details,
                                              ],
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                <div className="no-results-wrapper mt-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    <h2>
                                      {labels.NO_REQUEST_PENDING[selected_lang]}
                                    </h2>
                                    <p>
                                      There are no pending requests of Live
                                      Chats
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {liveChatTab === 'closed' && (
                          <div className="messages-users-list h-auto">
                            {Object.entries(closedChatData).length > 0 ? (
                              Object.entries(closedChatData).length > 0 &&
                              closedChatData.map((item, index) => {
                                // const newMessage = item.read ? '' : 'new-message';
                                const newMessage =
                                  chatRoomId === item.id || item.read
                                    ? ''
                                    : 'new-message';
                                const isActive =
                                  chatRoomId === item.id ? 'active' : '';
                                const userIs =
                                  parseInt(item.creator_id) ===
                                  parseInt(localStorage.getItem('user'))
                                    ? 'user_detail'
                                    : item.hasOwnProperty('creator_details')
                                    ? 'creator_details'
                                    : 'creator_detail';
                                return (
                                  <div
                                    // onClick={() => {
                                    //   // getUserData(item.user_id);
                                    //   // checkNewUsersInGroup(item.id);
                                    //   handleOpenChatRoom(item);
                                    // }}
                                    key={`msg-${tabType}-${index}`}
                                    className={`message-user-item no-link `}>
                                    <div
                                      className="user-img as-link"
                                      onClick={() => {
                                        amplitudeEvent('OPEN_CHAT_ROOM');
                                        if (
                                          chatRoomId !== item.id &&
                                          !isActive
                                        ) {
                                          handleOpenChatRoom(item);
                                        }
                                      }}>
                                      <img
                                        src={
                                          liveChatTab === 'queue'
                                            ? // !item.hasOwnProperty('read')
                                              item[userIs]['profile_img']
                                              ? item[userIs]['profile_img']
                                              : DEFAULT_IMAGE
                                            : item.group_image !== ''
                                            ? item.group_image
                                            : item[userIs]['profile_img']
                                            ? item[userIs]['profile_img']
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                      {item.chat_type === 'question' && (
                                        <span>
                                          <i className="fa-solid fa-q"></i>
                                        </span>
                                      )}
                                      {item.chat_type === 'opportunity' && (
                                        <span className="icon icon-posts"></span>
                                      )}
                                      {item.chat_type === 'event' && (
                                        <span className="icon-tickets"></span>
                                      )}
                                      {item.chat_type === 'session' && (
                                        <span className="icon-video-camera-2"></span>
                                      )}
                                      {/* <span className="fa-solid fa-shop"></span> */}
                                    </div>
                                    <div
                                      className="user-content as-link"
                                      onClick={() => {
                                        if (
                                          chatRoomId !== item.id &&
                                          !isActive
                                        ) {
                                          handleOpenChatRoom(item);
                                        }
                                      }}>
                                      <h2 className="single-line-text">
                                        {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                        {liveChatTab === 'queue'
                                          ? fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )
                                          : item.group_name !== ''
                                          ? item.group_name
                                          : fixName(
                                              item[userIs]['first_name'],
                                              item[userIs]['last_name'],
                                            )}
                                      </h2>

                                      {/* className={
        tabType === 'subInvites'
          ? ''
          : 'single-line-text'
      } */}
                                      {/* {tabType === 'subInvites' &&
      !item.hasOwnProperty('read') */}
                                      {liveChatTab === 'queue'
                                        ? item.group_name !== ''
                                          ? 'Just invited you to chat.'
                                          : 'Just invited you to a group chat.'
                                        : formatString(item.last_message)}

                                      <div className="message-date-info">
                                        <span className="message-date">
                                          <Moment format="MM/DD/YY HH:mm A">
                                            {item.last_message_time
                                              ? item.last_message_time
                                              : item.created_at}
                                          </Moment>
                                        </span>
                                        {/* {renderMessageUsers(item.moreUsers)} */}
                                      </div>
                                    </div>
                                    {item.hasOwnProperty(
                                      'opportunity_invitaion_status',
                                    ) &&
                                      liveChatTab === 'queue' && (
                                        <div className="stage-likes-icons">
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();
                                              if (
                                                chatRoomId !== item.id &&
                                                !isActive
                                              ) {
                                                handleOpenChatRoom(item);
                                              }
                                            }}>
                                            <i className="icon-like-icon" />
                                          </span>
                                          <span
                                            className="as-link"
                                            onClick={e => {
                                              e.stopPropagation();
                                              manageOppoChatInvitation(
                                                item.id,
                                                item.opportunity_id,
                                                'rejected',
                                              );
                                            }}>
                                            <i className="icon-unlike" />
                                          </span>
                                        </div>
                                      )}
                                    {item.hasOwnProperty('invitation_type') && (
                                      <div className="stage-likes-icons">
                                        <span
                                          className="as-link"
                                          onClick={e => {
                                            e.stopPropagation();

                                            // setShowSpinner(true);
                                            handleOpenChatRoom(item);
                                            // manageChatInvitation(
                                            //   item.id,
                                            //   item.invitation_type,
                                            //   'accepted',
                                            // );
                                          }}>
                                          <i className="icon-like-icon" />
                                        </span>
                                        <span
                                          className="as-link"
                                          onClick={e => {
                                            e.stopPropagation();

                                            // setShowSpinner(true);
                                            manageChatInvitation(
                                              item.id,
                                              item.invitation_type,
                                              'rejected',
                                            );
                                          }}>
                                          <i className="icon-unlike" />
                                        </span>
                                      </div>
                                    )}
                                    {!item.hasOwnProperty('invitation_type') &&
                                      item.hasOwnProperty('group_detail') && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.audience_detail.concat(
                                              item.moderator_detail,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              item.group_detail,
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                    {!item.hasOwnProperty('invitation_type') &&
                                      !item.hasOwnProperty(
                                        'opportunity_invitaion_status',
                                      ) &&
                                      item.hasOwnProperty('opportunity_id') &&
                                      item.chat_type === 'opportunity' && (
                                        <OtherUserImages
                                          otherUsers={filteringUsers(
                                            item.other_persons_opportunity_chat_room.concat(
                                              item.team_member_details,
                                              item.creator_detail
                                                ? [item.creator_detail]
                                                : [item.creator_details],
                                            ),
                                            arrayColumn(
                                              [
                                                ...item.other_persons_opportunity_chat_room,
                                                ...item.team_member_details,
                                                item.creator_details,
                                              ],
                                              'id',
                                            ),
                                          )}
                                        />
                                      )}
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                <div className="no-results-wrapper mt-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    {/* <h2>
                                                {
                                                  labels
                                                    .MANAGE_GROUP_CHAT[
                                                    selected_lang
                                                  ]
                                                }
                                              </h2> */}

                                    <h2>No Closed Chats</h2>

                                    <p>
                                      {liveChatQry
                                        ? 'Closed Chats not found'
                                        : "Seems you don't have any closed conservation."}
                                    </p>

                                    {/* <div className="btn-wrapper mt-3">
                                                <Button
                                                  className="btn btn-dark"
                                                  onClick={() => {
                                                    setShowBuildersComponent(
                                                      'group-component',
                                                    );
                                                  }}>
                                                  {
                                                    labels.Create_Table[
                                                      selected_lang
                                                    ]
                                                  }
                                                </Button>
                                              </div> */}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {subTabType === 'SMS' && (
                  <>
                    <TwillioSMS
                      showSpinner={showSpinner}
                      messages={messages}
                      setShowBuildersComponent={setShowBuildersComponent}
                      setUserChat={setUserChat}
                    />
                  </>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
        {isLoading && <ScrollLoader />}
      </div>

      {searchFilter && (
        <MessageSearchFilter
          show={searchFilter}
          onClose={() => setSearchFilterModal(false)}
          setFilter={setFilter}
          filter={filter}
          setshowLoader={setShowSpinner}
        />
      )}
    </>
  );
};

export default TwillioMessges;

// {
//   showBuildersComponent === 'chat-component' && (
//     <SmartChatPage
//       allmsg={true}
//       type={chatPropType}
//       item={chatDetailItem}
//       chatroomInfo={chatroomInfo(chatDetails)}
//       oppoDetails={chatDetails}
//       chatDetails={chatDetails}
//       allChatUsers={allChatUsers}
//       chatRoomName={chatRoomName}
//       chatRoomId={chatRoomId}
//       workSpaceItem={workspaceData}
//       // chatRoomStatus={chatDetails.status}
//       onClose={() => {
//         setShowBuildersComponent('');
//         setChatRoomId('');
//       }}
//       handleCommonChat={(data, type) => {
//         handleCommonChat(data, type);
//       }}
//       paddingBottom={true}
//       setConvosData={setConvosData}
//       convosData={convosData}
//     />
//   );
// }

// {
//   showBuildersComponent === 'group-component' && (
//     <CreateGroup
//       onClose={() => {
//         setShowBuildersComponent('');
//       }}
//       chatGroupType={'create'}
//     />
//   );
// }
