import React, {useEffect, useState} from 'react';
import CustomSlidingButtons from '../CustomSlidingButtons';

import {listForCustomer, listForEmployee} from '../../Constants';
import ProfileTransformation from './ProfileTransformation';
import {Button, ProgressBar} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {
  calculateMilestoneProgress,
  fixName,
  truncateTitle,
} from '../../Utils/commonUtils';
import NorecordFound from '../NoRecord/NorecordFound';
import {NoProjectsFound} from '../../Utils/NoRecordConstant';
import {useSelector} from 'react-redux';
import ListingOfProjects from '../../Pages/Front/ListingOfProjects';

const ProfileProjects = props => {
  console.log('---------------', props.userId);
  const listForDropDown =
    props.userData?.user_role === 'Customer' &&
    localStorage.getItem('id') !== props.userId
      ? listForCustomer
      : listForEmployee;

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);
  const projectListing = useSelector(
    state => state.ProjectListingReducer?.data,
  );

  const dropDownSelection = e => {
    const {id, value} = e.target;

    setAreas_Filter(id);
    if(props.setProjectFilters){
    props.setProjectFilters([value]);
    }
  };

  useEffect(() => {
    if(props.setProjectFilters){
    props.setProjectFilters([listForDropDown[0].title]);
    }
  }, []);

  return (
    <>
      <div className="serach-filter-wrapper create-sessionEvents">
        {props.threeDots && (
          <div className="multiselection_sliding_btns tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
            <CustomSlidingButtons
              items={listForDropDown}
              activeButtons={areas_Filter}
              onItemClick={e => {
                dropDownSelection(e);
              }}
              showItems={props.userData ? 2 : 2}
            />
          </div>
        )}
        <div className="Business_profile_page">
          {areas_Filter.includes(listForDropDown[0].id) &&
            listForDropDown[0].title == 'Transformations' && (
              <ProfileTransformation
                setTransformationList={props.setTransformationList}
                transformationList={props.transformationList}
                userId={props.userId}
                companyData={props.companyData}
                handleTransFormItem={props.handleTransform}
                threeDots={props.threeDots}
                showComponent={props.showComponent}
                Profile={props.Profile}
              />
            )}
          {areas_Filter.includes(listForDropDown[0].id) &&
            listForDropDown[0].title != 'Transformations' && localStorage.getItem('id') !== props.userId && (
              // <div className="no-results-wrapper no-appointments my-5">
              /* <div className="no-results-content"> */
              /* <p className={'noRecord-desc'}>No Completed Projectddds</p> */

              <ListingOfProjects
                projectListing={projectListing}
                handleProject={props.handleProject}
              />

              //  { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              //   <div className=" filter-btn-wrapper btn-wrapper mt-3">
              //   <Button
              //     className="btn btn-dark"
              //     onClick={() => {

              //     }}>
              //     Add Project
              //   </Button>
              // </div>

              //  }
              /* </div> */
              // </div>
            )}

          {areas_Filter.includes(listForDropDown[1].id) &&
            listForDropDown[1].title == 'Customers' && (
              <div className="no-results-wrapper no-appointments my-5">
                <div className="no-results-content">
                  <p className={'noRecord-desc'}>No Customers Added</p>
                  {localStorage.getItem('id') === props.userId &&
                    !props.companyData && (
                      <div className=" filter-btn-wrapper btn-wrapper mt-3">
                        <Button className="btn btn-dark" onClick={() => {}}>
                          Add Customer
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            )}

          {areas_Filter.includes(listForDropDown[1].id) &&
            listForDropDown[1].title != 'Customers' && localStorage.getItem('id') !== props.userId && (
              // <div className="no-results-wrapper no-appointments my-5">
              //   <div className="no-results-content">
              //     <p className={'noRecord-desc'}>No Projects Pending</p>
              //     { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              //   <div className=" filter-btn-wrapper btn-wrapper mt-3">
              //   <Button
              //     className="btn btn-dark"
              //     onClick={() => {

              //     }}>
              //     Add Project
              //   </Button>
              // </div>

              //  }
              //   </div>
              // </div>

              <ListingOfProjects
                projectListing={projectListing}
                handleProject={props.handleProject}
              />
            )}

          {areas_Filter.includes(listForDropDown[2].id) &&
            listForDropDown[2].title == 'Pending' && (
              <div className="no-results-wrapper no-appointments my-5">
                <div className="no-results-content">
                  <p className={'noRecord-desc'}>No Projects Pending</p>
                  {localStorage.getItem('id') === props.userId &&
                    !props.companyData && (
                      <div className=" filter-btn-wrapper btn-wrapper mt-3">
                        <Button className="btn btn-dark" onClick={() => {}}>
                          Add Project
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            )}

          {areas_Filter.includes(listForDropDown[2].id) &&
            listForDropDown[2].title != 'Pending' && localStorage.getItem('id') !== props.userId && (
              // <div className="no-results-wrapper no-appointments my-5">
              //   <div className="no-results-content">
              //     <p className={'noRecord-desc'}>No Offerings Added</p>
              //     { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              //   <div className=" filter-btn-wrapper btn-wrapper mt-3">
              //   <Button
              //     className="btn btn-dark"
              //     onClick={() => {

              //     }}>
              //     Add Offerings
              //   </Button>
              // </div>

              //  }
              //   </div>
              // </div>

              <ListingOfProjects
                projectListing={projectListing}
                handleProject={props.handleProject}
              />
            )}

          {areas_Filter.includes(listForDropDown[3].id) &&
            listForDropDown[3].title == 'Potential' && (
              <div className="no-results-wrapper no-appointments my-5">
                <div className="no-results-content">
                  <p className={'noRecord-desc'}>No Projects</p>
                  {localStorage.getItem('id') === props.userId &&
                    !props.companyData && (
                      <div className=" filter-btn-wrapper btn-wrapper mt-3">
                        <Button className="btn btn-dark" onClick={() => {}}>
                          Add Project
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            )}

          {areas_Filter.includes(listForDropDown[3].id) &&
            listForDropDown[3].title == 'On Hold' && localStorage.getItem('id') !== props.userId && (
              // <div className="no-results-wrapper no-appointments my-5">
              //   <div className="no-results-content">
              //     <p className={'noRecord-desc'}>No Projects On Hold</p>
              //     { localStorage.getItem('id') ===  props.userId && !props.companyData &&
              //   <div className=" filter-btn-wrapper btn-wrapper mt-3">
              //   <Button
              //     className="btn btn-dark"
              //     onClick={() => {

              //     }}>
              //     Add Project
              //   </Button>
              // </div>

              //  }
              //   </div>
              // </div>
              <ListingOfProjects
                projectListing={projectListing}
                handleProject={props.handleProject}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default ProfileProjects;
