const ProjectListingReducer = (state = [], action) => {
  switch (action.type) {
    case "Project_Listing":
      return {
        ...state,
        data: action.payload.item, // Directly setting the new data
      };

    default:
      return state;
  }
};

export default ProjectListingReducer;
