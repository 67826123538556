import { dummyImg } from './Images';
export const weekDay = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const monthsText = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'September',
  'October',
  'November',
  'December',
];

export const apointTimes = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

export const colorPalette = [
  {
    class: 'palette-1',
  },
  {
    class: 'palette-2',
  },
  {
    class: 'palette-3',
  },
  {
    class: 'palette-4',
  },
  {
    class: 'palette-5',
  },
  {
    class: 'palette-6',
  },
  {
    class: 'palette-7',
  },
  {
    class: 'palette-8',
  },
  {
    class: 'palette-9',
  },
  {
    class: 'palette-10',
  },
  {
    class: 'palette-11',
  },
  {
    class: 'palette-12',
  },
  {
    class: 'palette-13',
  },
  {
    class: 'palette-14',
  },
  {
    class: 'palette-15',
  },
  {
    class: 'palette-16',
  },
  {
    class: 'palette-17',
  },
];

export const sessionBgImgs = [
  '#F7F6F2',
  '#272727',
  '#C4C6D0',
  `url(${dummyImg})`,
];


export const employeeMenu = [

  { id: '1', name: 'Credentials', tab: 'credentials' },
  {
    id: '2',
    name: 'Projects',
    tab: 'projects',
  },

  {
    id: '3',
    name: 'Property',
    tab: 'property',
  },
  {
    id: '4',
    name: 'Payments',
    tab: 'payment',
  },
];

export const employeeToEmployeeMenu = [
  { id: '1', name: 'Credentials', tab: 'credentials' },
  {
    id: '2',
    name: 'Projects',
    tab: 'projects',
  },
  {
    id: '4',
    name: 'Payments',
    tab: 'payment',
  },
];

export const customerMenu = [
  { id: '1', name: 'Credentials', tab: 'credentials' },

  { id: '2', name: 'Projects', tab: 'projects' },
  {
    id: '3',
    name: 'Property',
    tab: 'property',
  },
  {
    id: '4',
    name: 'Payments',
    tab: 'payment',
  },

];


export const employeeMenuP = [

  { id: '1', name: 'Overview', tab: 'overview' },
  // { id: '2', name: 'Credentials', tab: 'credentials' },
  {
    id: '3',
    name: 'Detail',
    tab: 'Detail',
  },

  {
    id: '4',
    name: 'Properties',
    tab: 'property',
  },
  {
    id: '4',
    name: 'Transformations',
    tab: 'projects',
  },
  // {
  //   id: '5',
  //   name: 'Payments',
  //   tab: 'payment',
  // },
  // {
  //   id: '6',
  //   name: 'Review',
  //   tab: 'review',
  // },
];

export const employeeToEmployeeMenuP = [
  { id: '1', name: 'Overview', tab: 'overview' },
  { id: '2', name: 'Credentials', tab: 'credentials' },
  {
    id: '3',
    name: 'Projects',
    tab: 'projects',
  },
  {
    id: '4',
    name: 'Payments',
    tab: 'payment',
  },
  // {
  //   id: '5',
  //   name: 'Review',
  //   tab: 'review',
  // },
];

export const customerMenuP = [
  { id: '1', name: 'Overview', tab: 'overview' },
  { id: '2', name: 'Credentials', tab: 'credentials' },
  {
    id: '3',
    name: 'Projects',
    tab: 'projects',
  },

  {
    id: '4',
    name: 'Property',
    tab: 'property',
  },
  {
    id: '5',
    name: 'Payments',
    tab: 'payment',
  },

  // {
  //   id: '6',
  //   name: 'Review',
  //   tab: 'review',
  // },

];



export const listForEmployee = [
  { title: 'Transformations', id: '1' },
  { title: 'Customers', id: '2' },
  { title: 'Pending', id: '3' },
  { title: 'Potential', id: '4' },
];

export const listForCustomer = [
  { title: 'Completed', id: '1' },
  { title: 'Pending', id: '2' },
  { title: 'Interested', id: '3' },
  { title: 'On Hold', id: '4' },
];


export const prompts = {
  leadership: [
    'Performance Overview',
    'Strategic Planning Document',
    'Business Plan',
    'Annual Report',
    'Leadership Meeting Minutes',
    'SWOT Analysis',
    'Balanced Scorecard',
  ],
  operations: [
    'Operations Manual',
    'Production Schedule',
    'Inventory Report',
    'Supply Chain Analysis',
    'Quality Control Report',
    'Efficiency Metrics',
  ],
  accounting: [
    'Income Statement',
    'Balance Sheet',
    'Cash Flow Statement',
    'Budget Reports',
    'Expense Reports',
    'Financial Projections',
    'Audit Reports',
  ],
  marketing: [
    'Market Research Report',
    'Marketing Plan',
    'Campaign Performance Report',
    'Brand Strategy Document',
    'Customer Segmentation Analysis',
    'Competitor Analysis',
    'Content Calendar',
    'Social Media Analytics Report',
    'SEO Audit Report',
  ],
  sales: [
    'Sales Forecast Report',
    'Sales Pipeline Report',
    'Sales Performance Metrics',
    'CRM Data Reports',
    'Sales Call/Visit Logs',
    'Customer Relationship Management Plan',
    'Quota Achievement Report',
    'Lead Conversion Report',
    'Sales Territory Plan',
  ],
  hr: [
    'Recruitment and Staffing Plan',
    'Employee Handbook',
    'Training and Development Plan',
    'Performance Appraisal Reports',
    'Employee Engagement Surveys',
    'HR Metrics Essentials',
  ],
  customer_service: [
    'Support Tickets/Logs',
    'Customer Feedback and Surveys',
    'SLA Reports',
    'CSAT Reports',
    'NPS Reports',
    'Incident Resolution Reports',
  ],
  it: [
    'Tech Stack',
    'IT Infrastructure Diagram',
    'Network Security Reports',
    'System Performance Metrics',
    'Data Backup and Recovery Plan',
    'IT Policy Documents',
    'Software License Inventories',
    'Help Desk Tickets',
  ],
  legal: [
    'Contract Management Logs',
    'Partnership Agreements',
    'Contractor Agreements',
    'Employment Agreements',
    'Legal Case Files',
    'IP Documentation',
    'NDAs',
    'Litigation Reports',
    'Legal Opinions and Memos',
    'Regulatory Filings',
    'TOS and PP Documents',
  ],
  compliance: [
    'Compliance Audit Reports',
    'Risk Assessment Reports',
    'Regulatory Compliance Reports',
    'Compliance Training Records',
    'Incident Reports',
    'Code of Conduct',
    'Compliance Checklists',
    'Internal Policies and Procedures',
  ],
};