import React, {useState, useEffect, useRef} from 'react';

import {selected_lang} from '../../Utils/env';

import {
  capitalizeFirst,
  fixName,
  getRelativeDate,
} from '../../Utils/commonUtils';

// import SnapshotDetail from './SnapshotDetail';
import {
  CheckmarkFillGrey,
  VideoGreyIcon,
  CheckmarkFillYellow,
} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';
import CustomSlidingButtons from '../CustomSlidingButtons';
// import CreateAction from './CreateAction';

import CustomModal from '../CustomModal';
import {getAllActions} from '../../Store/Actions/SessionAction';

import {useDispatch, useSelector} from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';

const Actions = props => {
  const dispatch = useDispatch();

  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([1]);
  const [filterOptionValue, setFilterOptionValue] = useState(['All']);
  const [actionType, setActionType] = useState([1]);
  const [actionTypeValue, setActionTypeValue] = useState(['All']);
  const initialRender = useRef(true);
  const scrollRef = useRef(null);

  const ActionCategories = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Me'},
    {id: 3, title: 'Watching'},
  ];

  const filterOption = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Pending'},
    {id: 3, title: 'Completed'},
    {id: 4, title: 'Expired'},
  ];

  const handleSearchQry = q => {
    getActions(q);
  };

  useEffect(()=>{
    setSearchQry('')
  },[props.showActionFilter, searchFilter, props.threeDots])


  const getActions = qry => {
    const actions = actionTypeValue.map(element => {
      return element.toLowerCase();
    });

    const options = props.showProfileActions
    ? props.filterOptionActionValue.map(element => element.toLowerCase())
    : filterOptionValue.map(element => element.toLowerCase());
  
    var Payload = {
      user_id: props.user_id ? props.user_id : localStorage.getItem('id'),
      type: props.tabType
        ? ['other']
        : options.includes('watching')
        ? ['other']
        : options,
      search_query: qry ? qry : props.serachForNotesAction,
      filter: actions === 'expired' ? ['expire'] : actions,
    };
    localStorage.setItem('action_paylaod', JSON.stringify(Payload));
    dispatch(getAllActions(Payload));
  };

  const handleClickList = (id, value) => {
    setCheckFilterOption([id]);
    setFilterOptionValue([value]);
  };

  useEffect(() => {
    getActions();
    setSearchQry('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hitApi, actionTypeValue, props.tabType, props.showComponent, props.hitActionApi]);

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const assigneDetail = item => {
    const userId = localStorage.getItem('id');
    const check = item.watchers_detail.some(obj => obj.id === userId);

    return item.created_by === userId && item.assigne !== '0' ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        From you to{' '}
        {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
      </p>
    ) : item.assigne === userId || check ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        For you from{' '}
        {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
      </p>
    ) : (
      <></>
    );
  };

  const actions = useSelector(state => state.AllActionsList);

  useEffect(() => {
    if (
      (props.serachForNotesAction || props.serachForNotesAction === '') &&
      !initialRender.current
    ) {
      handleSearchQry(props.serachForNotesAction);
    }
  }, [props.serachForNotesAction]);

  useEffect(() => {
    initialRender.current = false;
  }, []);

  useEffect(() => {
    // Scroll to top whenever the actions change
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [actions]); 

  return (
    <>
      {props.threeDots &&
        props.type !== 'MessagesWatching' &&
        props.type !== 'Messages' && (
          <div className="person-search-wrapper payment-search-wrapper mb-0 messages-users-wrapper"
          style={{padding: props.showProfileActions && '0px'}}
          >
            <div className="search-form d-flex search-tabs-icons">
              <Form.Group className="search-input">
                <Form.Control
                  type="text"
                  placeholder="Search for actions"
                  value={searchQry}
                  onChange={e => {
                    setSearchQry(e.target.value);
                    handleSearchQry(e.target.value);
                  }}
                />
              </Form.Group>
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => {setSearchFilterModal(true)
                    if(props.showProfileActions){
                      props.setShowActionFilter(true)}
                    }
                  }
                />
              </Button>
              {props.component === 'follow-up' && (
                <span
                  className={`ml-0 mt-0 ${
                    // props.showComponent === 'addAction' ? 'icon active' : 'icon'
                    'icon'
                  }`}>
                  <i
                    className="icon-plus"
                    onClick={() => {
                      props.setShowComponent('addAction');
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        )}

      <div className="mt-4 action-custom-scroll-wrapper">
        {props.threeDots && (
          <div>
            <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
              <CustomSlidingButtons
                items={filterOption}
                activeButtons={actionType}
                onItemClick={e => {
                  handleType(e);
                  props.setShowComponent('');
                }}
                showItems={3}
                value={actionTypeValue}
              />
            </div>
          </div>
        )}

        <div ref={scrollRef} className={props.scrollAction ? ' ' : "refferal-list-wrapper action-custom-scroll-holder"}>
          {/* <Scrollbars> */}
          <div className='custom-new-vertical-scroll'>
          {Object.entries(actions).length > 0 ? (
            actions.map((item, index) => (
              <div
                className="refferals-list action-listing as-link"
                onClick={() => {
                  props.setDataOfAction(item);
                  if (
                    props.type === 'Messages' ||
                    props.type === 'MessagesWatching'
                  ) {
                    props.handleChildComponent &&
                      props.handleChildComponent(item, 'action_detail');
                  } else {
                    props.setShowComponent('actionDetails');
                  }
                }}>
                <h2 className="truncate_2_lines actions-title mb-2">
                  {item.title}
                </h2>
                <div className="d-flex align-items-center session-cam mb-2">
                  <img src={VideoGreyIcon} alt="video-cam" />
                  <p className="p-reg m-0 action-company-name-txt">
                    {item.association_name}
                  </p>
                </div>
                <p className="p-reg m-0 mb-2">
                  {/* { getRelativeDatemoment(item.due_date).format( 'YYYY-MM-DD')}  {moment(item.due_time, 'HH:mm:ss').format('LT')}  */}
                  {item.due_date !== '0000-00-00 00:00:00' &&
                    'Due ' + getRelativeDate(item.due_date)}
                  {/* ({ item.priority} Priority) */}
                  {item.priority !== '' && (
                    <span>({capitalizeFirst(item.priority)} Priority)</span>
                  )}
                </p>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{alignItems: 'center'}}>
                  <div className="d-flex align-items-center">
                    {/* <p className="p-reg m-0 actionsCategories single-line-text">
                      For you from{' '}
                      {fixName(
                        item.creator_detail.first_name,
                        item.creator_detail.last_name,
                      )}
                    </p> */}

                    {assigneDetail(item)}
                    {/* <span className="redDot"></span> */}
                  </div>
                  {item.status === 'pending' ? (
                    <img
                      height={20}
                      width={20}
                      src={CheckmarkFillGrey}
                      alt="checkmark"
                      // onClick={() => {
                      //
                      //   if (props.dataOfAction.assigne == userId &&
                      //     props.dataOfAction.status == 'pending' &&
                      //   status == 'pending') {
                      //
                      //     changeActionStatus(userId);
                      //   }
                      // }}
                    />
                  ) : (
                    <img
                      height={20}
                      width={20}
                      src={CheckmarkFillYellow}
                      alt="checkmark"
                      // onClick={() => {
                      //
                      //   if (props.dataOfAction.assigne == userId) {
                      //     changeActionStatus(userId);
                      //   }
                      // }}
                    />
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="no-results-wrapper payment-no-results no-record-notification">
              <div className="img-bg-wrapper">
                <img
                  src={require('../../Assets/images/norecord/no-actions.png')}
                  alt=""
                />
              </div>

              <div className="no-results-content">
                {/* <h2>{labels.Actions_Pending[selected_lang]}</h2> */}
                <h2>{`No Tasks ${actionTypeValue.includes('All') ? 'Available' : actionTypeValue}`}</h2>
                <p>{labels.NoAction_Desc[selected_lang]}</p>
                <div className="btn-wrapper mt-3">
                  <Button
                    className="btn btn-dark"
                    onClick={() => {
                      if (props.type === 'Messages' || props.type === 'MessagesWatching') {
                        props.setShowComponentAction('create-action');
                      } else {
                        props.setShowComponent('addAction');
                      }
                    }}>
                    Create Task
                  </Button>
                </div>
              </div>
            </div>
          )}
            {/* </Scrollbars> */}
            </div>
        </div>
      </div>

      {!props.setShowActionFilter && searchFilter && (
        <CustomModal
          className="shareables-workspace-modal"
          size="small"
          onClose={() => setSearchFilterModal(false)}
          heading={'Filter Actions'}
          >
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul>
                {/* <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li> */}

                {ActionCategories.map((item, index) => (
                  <li
                    key={`sessionC-${index}`}
                    className={
                      checkFilterOption.includes(item.id) ||
                      filterOptionValue.includes(item.title)
                        ? 'active'
                        : ''
                    }>
                    <Form.Label>
                      {item.title}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.title}
                        onChange={e => {
                          handleClickList(item.id, e.target.value);
                        }}
                        checked={
                          checkFilterOption.includes(item.id) ||
                          filterOptionValue.includes(item.title)
                        }
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
              </ul>

              {/* <Button
                // type="submit"
                className="btn-dark"
                onClick={() => {
                  getActions();
                  setSearchFilterModal(false);
                }}>
                Filter
              </Button> */}
              <div className="text-center">
                <Button
                  type="submit"
                  className="btn-dark "
                  onClick={() => {
                    getActions();
                    setSearchFilterModal(false);
                  }}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default Actions;
