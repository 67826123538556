import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import moment from 'moment';
import {Pannels, SlidingPanel} from '../../../Components';
import SmartChatPage from '../../../Components/Common/SmartChatPage';
import {
  arrayColumn,
  capitalizeFirst,
  chatroomInfo,
  chatroomInfoOppoRefer,
  filteringUsers,
  formatString,
  goToZoom,
} from '../../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../../Constants/Translations';
import {DEFAULT_IMAGE, selected_lang} from '../../../Utils/env';
import Sidebar from '../../Sidebar';
import commonApi from '../../../Services/CommonService';
import Moment from 'react-moment';
import OpportunityScreen from '../../../Components/FrontScreens/OpportunityScreen';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import ReferProfile from '../../../Components/Referral/ReferProfile';
import SendFeedBack from '../../../Components/Referral/SendFeedBack';
import SnapshotDetail from '../../../Components/Snapshot/SnapshotDetail';
import {RenderHTMLBlock} from '../../../Utils/Helpers';
import OpportunityMessageList from '../../../Components/OpportunitiesList/OpportunityMessageList';

import EventDetail from '../../../Components/Referral/EventDetail';
import {errorMessage} from '../../../Utils/ToastMessages';
import API from '../../../Services/SessionServices';
import PurchaseTicket from '../../../Components/Referral/PurchaseTicket';
import OpportunityDetail from '../../../Components/OpportunitiesList/OpportunityDetail';
import ReferNotes from '../../../Components/Referral/ReferNotes';
import EditOpportunities from '../../../Components/OpportunitiesList/EditOpportunities';
import CreateSession from '../../../Components/SessionDetails/CreateSession';
import SessionDetail from '../../../Components/SessionDetails/SessionDetail';
import {rewardStar} from '../../../Constants/Images';
import ReferOpportunity from '../../../Components/Referral/ReferOpportunity';
import {formatAmount} from '../../../Utils/commonUtils';

import {fixName} from '../../../Utils/commonUtils';
import {amplitudeEvent} from '../../../Services/AmplitudeServices';
import SendInvitation from './SendInvitation';

import CustomModal from '../../../Components/CustomModal';
import {useSelector, useDispatch} from 'react-redux';
import {getSessionCategory} from '../../../Store/Actions/SessionAction';
import {getReferralListing} from '../../../Store/Actions/ReferralAction';
import ShowMore from '../../../Components/Common/ShowMore';
import {getItem} from '../../../Utils/LocalStorage';

import CreateWorkSpace from '../../../Components/WorkSpace/CreateWorkSpace';
import OtherUserImages from '../../../Components/Common/OtherUserImages';
import ScheduleAppointmemt from '../../../Components/AppointmentPage/ScheduleAppointmemt';
import Slider from 'react-slick';

const ReferralPage = props => {
  const [subTabType, setSubTabsType] = useState('all');
  const [showMore, toggleShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';
  const [profileImgScrollBar, setProfileImgScrollBar] = useState('');

  const [arrowButton, setArrowButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilter, setshowFilter] = useState(false);

  // const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [allCheckedValues, setallCheckedValues] = useState([
    'events',
    'sessions',
    'builders',
    // 'questions',
    'opportunities',
    'snapshots',
    'appointment',
    'group_chat',
  ]);
  // const [list, setList] = useState([]);
  const [freq, setFreq] = useState([]);
  const [virtual, setVirtual] = useState(false);
  const [covid, setCovid] = useState(false);
  const [allData, setAllData] = useState([]);
  const [amount, setAmount] = useState('');
  const [locationS, setLocationS] = useState('');
  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [work, setWork] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  const [userData, setUserData] = useState([]);
  const [accomplishmentId, setAccomplishmentId] = useState('');
  const [showRefer, setShowRefer] = useState(true);
  const [dataa, setData] = useState([]);

  const [opportunityData, setOpportunityData] = useState([]);
  const [qaItem, setQaItem] = useState({});

  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);

  const [eventItem, setEventItem] = useState([]);

  const [notes, setNotes] = useState('');
  const [page, setPage] = useState(1);

  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  const [showComponent, setShowComponent] = useState('');
  const [referSession, setReferSession] = useState([]);
  // const [active, setActive] = useState(false);

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [serverTime, setServerTime] = useState('');
  const [referTypeIds, setReferTypeIds] = useState('');
  const [appointmentData, setAppointmentData] = useState({});

  const [showSpinner, setShowSpinner] = useState(true);

  const [chatDetailItem, setChatDetailItem] = useState([]);
  const [chatPropType, setChatPropType] = useState('');
  const [chatDetails, setChatDetails] = useState([]);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const dynamicStyle = {
    padding: '0px',
    // Add other styles as needed
  };

  const dispatch = useDispatch();
  const referralData = useSelector(state => state.referralListing);
  const hitApi = useSelector(state => state.referralApi);

  const history = useHistory();
  // const joinTheSession = item => {
  //   var data = {user_id: loID, conference_id: item.refer_type_id};
  //   API.is_session_started(data)
  //     .then(response => {
  //       if (response.started === true) {
  //         localStorage.setItem('conferenceId', response.session_id);
  //         localStorage.setItem('conf_id', item.refer_type_id);
  //         history.push('/session-details');
  //       } else {
  //         errorMessage(response.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const eventHandle = item => {
    setEventItem(item);
    setShowComponent('event');
  };
  const breakDown = item => {
    setReferSession(item);
    setShowComponent('session');
  };
  const AllActive =
    allCheckedValues.includes('builders') &&
    allCheckedValues.includes('sessions') &&
    allCheckedValues.includes('opportunities') &&
    allCheckedValues.includes('events') &&
    // allCheckedValues.includes('questions') &&
    allCheckedValues.includes('snapshots') &&
    allCheckedValues.includes('appointment') &&
    allCheckedValues.includes('group_chat');

  const getReferrals = async (type, qry, arrow, pageN) => {
    // setShowSpinner(true);
    var data = {
      builder_filter_data: {
        allies: 'no',
        appointmentTypes: appointmentId,
        backgrounds: categoriesId,
        characteristics: [],
        homeLatitude: '',
        homeLongitude: '',
        homeTown: locationS,
        travelLatitude: '',
        travelLocation: locationS,
        travelLongitude: '',
      },
      category_ids: isCheck.toString(),
      opportunity_filter_data: {
        address: '',
        allies: '',
        amount: amount,
        frequencyIds: freq,
        latitude: '',
        longitude: '',
        opportunityIds: isCheck,
        vaccinated: covid,
        virtual: virtual,
        // "search_query": ""
      },

      page: pageN,
      referred_by_me: arrow,
      search_query: qry,
      //  type: 'all',
      type: allCheckedValues,
    };

    const response = await dispatch(getReferralListing(data));

    if (response) {
      if (pageN > 1) {
        if (response.data.length > 0) {
          setPage(pageN);
        }
      } else {
        setPage(1);
      }

      // setShowSpinner(false);
      setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
    }
  };

  const getUserData = () => {
    var data = {user_id: localStorage.getItem('user')};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          // setUserInfo(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    // setShowSpinner(true);

    getReferrals(subTabType, '', arrowButton, 1);

    getUserData();
    serverTimeApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTabType, arrowButton, showComponent, hitApi]);

  useEffect(() => {
    setSearchQuery('');
  }, [subTabType, arrowButton]);

  useEffect(() => {
    dispatch(getSessionCategory());
    // getTabstatus();
    handleNotificationDirection();
    amplitudeEvent('VISIT_REFERRALS_RECIEVED');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detailAccomplishment = item => {
    var payLoad = {
      accomplishment_id: item.refer_type_id,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          // setImages(res.accomplishment_detail.images);
          setData(res.accomplishment_detail);

          setShowComponent('snapshot');
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handle = item => {
    setReferTypeIds(item.refer_type_id);
    setShowComponent('sendRefer');
    setUserData(item);
    setAccomplishmentId(item.accomplishment_category_id);
    if (item.accomplishment_category_id !== undefined) {
      detailAccomplishment(item);
    }
  };

  // const collectingUsers = item => {
  //   const oppoUsers = [
  //     {
  //       id: item.creator_detal,
  //       first_name: item.creator_detail.first_name,
  //       last_name: item.creator_detail.last_name,
  //       profile_img: item.creator_detail.profile_img,
  //       type: 'creator',
  //     },
  //   ];
  //   if (item.other_persons_opportunity_chat_room.length > 0) {
  //     item.other_persons_opportunity_chat_room.map(item => {
  //       item.type = 'audience';
  //       oppoUsers.push(item);
  //     });
  //   }
  //   if (item.team_member_details.length > 0) {
  //     item.team_member_details.map(item => {
  //       item.type = 'moderator';
  //       oppoUsers.push(item);
  //     });
  //   }
  //   return oppoUsers;
  // };

  const collectingUsers = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator =
      item.chat_type === 'opportunity'
        ? 'team_member_details'
        : 'moderator_detail';
    const audience =
      item.chat_type === 'opportunity'
        ? 'other_persons_opportunity_chat_room'
        : 'audience_detail';

    const chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience] && item[audience].length > 0) {
      item[audience].forEach(item => {
        item.type = 'audience';
        chatUsers.push(item);
      });
    }
    if (item[moderator] && item[moderator].length > 0) {
      item[moderator].forEach(item => {
        item.type = 'moderator';
        chatUsers.push(item);
      });
    }
    return chatUsers;
  };

  const collectingOppoUsers = item => {
    const creator = item.hasOwnProperty('creator_details')
      ? 'creator_details'
      : 'creator_detail';
    const moderator =
      item.refer_type === 'opportunity'
        ? 'team_member_details'
        : 'moderator_detail';
    const audience =
      item.refer_type === 'opportunity'
        ? 'other_persons_opportunity_chat_room'
        : 'audience_detail';

    const chatUsers = [
      {
        id: item[creator]['id'],
        first_name: item[creator]['first_name'],
        last_name: item[creator]['last_name'],
        profile_img: item[creator]['profile_img'],
        type: 'creator',
      },
    ];
    if (item[audience] && item[audience].length > 0) {
      item[audience].forEach(item => {
        item.type = 'audience';
        chatUsers.push(item);
      });
    }
    if (item[moderator] && item[moderator].length > 0) {
      item[moderator].forEach(item => {
        item.type = 'moderator';
        chatUsers.push(item);
      });
    }
    return chatUsers;
  };

  // const handleScroll = values => {
  //   const {clientHeight, scrollHeight, scrollTop} = values.target;
  //   // // Check if the user has reached the end of the content
  //   if (clientHeight + scrollTop >= scrollHeight) {
  //     // Increment the page number and call the API

  //     // user_session_list(page + 1);
  //     getReferrals(subTabType, searchQuery, arrowButton, page + 1);
  //   }
  // };

  const getTabstatus = () => {
    // setShowSpinner(true);
    commonApi
      .get_tab_status()
      .then(res => {
        // setShowSpinner(false);
        // console.log("fffffffffff",res.frequencies );
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];
            if (Object.entries(item).length > 0) {
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });
            }
            const found = newArray.find(element => element === 'refer');
            if (found !== 'refer') {
              setShowComponent('firstVist');
            }

            // if (newArray === ) {

            // }
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const handleClick = e => {
    const {id, checked} = e.target;
    setallCheckedValues([...allCheckedValues, id]);
    if (!checked) {
      setallCheckedValues(allCheckedValues.filter(item => item !== id));
    }
  };
  const doAllCheckedValues = e => {
    const {checked} = e.target;
    if (checked) {
      var aa = [
        'events',
        'sessions',
        'builders',
        // 'questions',
        'opportunities',
        'snapshots',
        'appointment',
        'group_chat',
      ];
      aa.forEach(tab => {
        setallCheckedValues(current => [...current, tab]);
      });
    } else {
      setallCheckedValues([]);
    }
  };
  // useEffect(() => {

  //   if(allCheckedValues.length > 1){
  //     setShowSpinner(true)
  //   }else{
  //     setShowSpinner(false)
  //   }
  // }, [allCheckedValues]);

  // const eventDetail = id => {
  //   var payLoad = {
  //     event_id: id,
  //   };
  //   commonApi
  //     .event_detail(payLoad)
  //     .then(response => {
  //       if (response.status === 200) {
  //         response.event_detail.refer_type_id = response.event_detail.id;
  //         // response.event_detail.creator_detail.profile_img =  response.event_detail.creator_image;

  //         setEventItem(response.event_detail);
  //         setShowComponent('event');
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const getOpportunityDetails = id => {
  //   var payLoad = {
  //     opportunity_id: id,
  //     page: 1,
  //     search_query: '',
  //     status: 'pending',
  //   };
  //   commonApi
  //     .get_opportunity_details(payLoad)
  //     .then(response => {
  //       if (response.status === 200) {
  //         response.available_opportunity.forEach(item => {
  //           if (item.id === id) {
  //             setOpportunityData(item);
  //             setShowComponent('opportunity');
  //           }
  //         });
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleNotificationDirection = () => {
    // if (state && state.typeIs === 'referEvent') {
    //   console.log(state.referred_id, 'state.referred_idstate.referred_id');
    //   eventDetail(state.referredId);
    // }
    // if (state && state.typeIs === 'referQuestion') {
    // }
    // if (state && state.typeIs === 'feedback') {
    // }
    // if (state && state.typeIs === 'referOpportunity') {
    //   getOpportunityDetails(state.referredId);
    // }
    // if (state && state.typeIs === 'referSession') {
    // }
    // if (state && state.typeIs === 'referBuilder') {
    // }
  };

  const serverTimeApi = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const joinTheSession = async item => {
    var CheckLastSession = '';

    if (CheckLastSession && CheckLastSession === item.refer_type_id) {
      let payload = {conference_id: item.refer_type_id};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', item.refer_type_id);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: item.refer_type_id};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', item.refer_type_id);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (CheckLastSession && CheckLastSession !== item.refer_type_id) {
      errorMessage(
        'Your are already in a session ' +
          localStorage.getItem('session_started_name') +
          '.',
      );
    }
  };

  useEffect(() => {
    if (showComponent !== '' && showComponent !== 'firstVist') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);
  useEffect(() => {
    if (work) {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [work]);

  const newDecision = () => {
    if (opportunityData.login_user && opportunityData.login_user.status) {
      return opportunityData.login_user.status;
    } else if (opportunityData.chat_detail.hasOwnProperty('status')) {
      return opportunityData.chat_detail.status;
    } else {
      return 'pending';
    }
  };

  const _renderAudiImg = (person, speaking) => {
    return (
      <div key={`p-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  const handleOpenChatRoom = chatDetail => {
    setChatDetails(chatDetail);
    setAllChatUsers(collectingUsers(chatDetail));
    setChatRoomName(chatDetail.chat_room_name);
    setChatRoomId(chatDetail.chat_id);

    if (chatDetail.chat_type === 'opportunity') {
      if (chatDetail.creator_id === localStorage.getItem('id')) {
        setChatPropType('mine-opportunity');
      } else if (chatDetail.team_member_ids) {
        const myArray = chatDetail.team_member_ids.split(',');
        if (myArray.includes(localStorage.getItem('id'))) {
          setChatPropType('mine-opportunity');
        } else {
          setChatPropType('other-opportunity');
        }
      } else {
        setChatPropType('other-opportunity');
      }
    } else {
      if (chatDetail.chat_room_type === 'group') {
        setChatPropType('personal-group');
      } else {
        setChatPropType('personal-single');
      }
    }

    const chatItem = chatDetail;
    chatItem.id = chatDetail.type_id;
    setChatDetailItem(chatItem);

    setShowComponent('chat-component');
  };

  useEffect(() => {
    if (workspaceData.length > 0) {
      getReferrals(subTabType, '', arrowButton, 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'Quality',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Conversions',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Collected',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}

      <div className="page-wrapper">
        <Sidebar
          setWorkSpaceItem={setWorkspaceData}
          location={props.location}
          profileImgScrollBar={profileImgScrollBar}
          setShowComponent={setShowComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span
                              style={{
                                color: '#000',

                                fontFamily: 'Inter',
                                // fontSize: '10.833px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                              }}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                color: '#000',

                                fontFamily: 'Inter',
                                fontSize: '17px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                              }}>
                              {item.money}
                              {item.name === 'Rating' && (
                                <img src={rewardStar} alt="" />
                              )}
                              <i
                                class="fa-solid fa-arrow-up"
                                style={{color: '#52bd41'}}></i>
                            </span>
                            <div
                              class="message-date-info"
                              style={
                                {
                                  // marginLeft: '13px',
                                }
                              }>
                              <span
                                class="message-date"
                                style={{
                                  color: '#333',
                                  fontFamily: 'Helvetica',
                                  // fontSize: '9px',
                                  fontStyle: 'normal',
                                  fontWeight: '300',
                                  lineHeight: 'normal',
                                }}>
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  {/* {showLoader && <LoaderSpinner />} */}
                  {showComponent === 'firstVist' && (
                    <OpportunityScreen
                      onClose={() => {
                        setShowComponent('');
                      }}
                      type={'refer'}
                      setShowComponent={setShowComponent}
                    />
                  )}

                  {showComponent !== 'firstVist' && (
                    <>
                      <div className="referal_page_content primary_panel_wraper">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="event-tabs tabs-style-2 refferal-tabs-custom-style">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    arrowButton === false ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setArrowButton(!arrowButton);
                                    setShowSpinner(true);
                                    amplitudeEvent('VISIT_REFERRALS_RECIEVED');
                                    setShowComponent('');
                                  }}>
                                  Received
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    arrowButton === true ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setArrowButton(!arrowButton);
                                    setShowSpinner(true);
                                    amplitudeEvent('VISIT_REFERRALS_GIVEN');
                                    setShowComponent('');
                                  }}>
                                  Given
                                </Button>
                              </li>
                            </ul>
                          </div>
                          <div className="search-tabs-icons search-refferal-tab-icons-holder">
                            <span
                              className="icon"
                              onClick={() => {
                                setWork(true);
                              }}>
                              <i className="icon-plus"></i>
                            </span>
                          </div>
                        </div>

                        <div className="search-tabs-wrapper mt-1">
                          <div className="search-form d-flex mt-0">
                            <Form.Group className="search-input">
                              <Form.Control
                                onChange={e => {
                                  setSearchQuery(e.target.value);
                                  getReferrals(
                                    '',
                                    e.target.value,
                                    arrowButton,
                                    page,
                                  );
                                }}
                                name="allUsers"
                                type="text"
                                //placeholder={'Search for a ' + subTabType}
                                placeholder={'Search'}
                                value={searchQuery}
                              />
                            </Form.Group>

                            <Button onClick={() => setshowFilter(true)}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>

                        <div className="refferal-list-wrapper refferal-list-holder">
                          {showSpinner && <LoaderSpinner />}

                          <Scrollbars
                            className="custom-scroll referral-cards custom-refferal-vertical-scrollbar"
                            autoHide
                            // onScroll={handleScroll}
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '-webkit-fill-available',
                            }}>
                            {/* {showSpinner && <LoaderSpinner />} */}
                            {Object.entries(referralData).length > 0 ? (
                              <div className="refferals-list">
                                {Object.entries(referralData).length > 0 &&
                                  referralData.map((item, index) => (
                                    <div className="" key={`temp_${index}`}>
                                      {subTabType === 'builders' &&
                                        item.referred_detail && (
                                          <div
                                            key={`ee-www-${item.id}`}
                                            className="refferals-list-item card qa-list-item">
                                            <div
                                              className="refferal-img as-link"
                                              onClick={() => {
                                                handle(item);
                                              }}>
                                              <img
                                                src={
                                                  item.referred_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.referred_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="d-flex align-items-center referrals-dataa">
                                              <div className="refferal-content as-link w-100">
                                                {item.referred_detail && (
                                                  <h2
                                                    onClick={() => {
                                                      handle(item);
                                                    }}>
                                                    {fixName(
                                                      item.referred_detail
                                                        .first_name,
                                                      item.referred_detail
                                                        .last_name,
                                                    )}
                                                  </h2>
                                                )}
                                                <span className="refferal-auther as-link w-100">
                                                  {item.user_first_name
                                                    ? fixName(
                                                        item.user_first_name,
                                                        item.users_last_name,
                                                      )
                                                    : item.first_name &&
                                                      item.last_name
                                                    ? fixName(
                                                        item.first_name,
                                                        item.last_name,
                                                      )
                                                    : item.first_name &&
                                                      !item.last_name &&
                                                      capitalizeFirst(
                                                        item.first_name,
                                                      )}
                                                </span>

                                                {item.note && (
                                                  <span className="refferal-auther as-link w-100">
                                                    {item.note}
                                                  </span>
                                                )}
                                                {/* <p>Think there may be some synergy</p> */}
                                                <span className="refferal-date as-link">
                                                  <Moment format="MM/DD/YY H:mmA">
                                                    {item.updated_at}
                                                  </Moment>
                                                </span>

                                                {item.images && (
                                                  <div className="invited-by-wrapper as-link">
                                                    <div className="user-img">
                                                      <img
                                                        src={
                                                          !item.images
                                                            ? DEFAULT_IMAGE
                                                            : item.images[0]
                                                        }
                                                        alt=""
                                                      />
                                                      {/* <span>{smartItem.name}</span> */}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                className="refferal-like-unlike as-link"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                {item.feedback === 'like' && (
                                                  <span className="refferal-icons">
                                                    <i
                                                      className="icon-like-icon"
                                                      style={{color: '#00bf4d'}}
                                                    />
                                                  </span>
                                                )}
                                                {item.feedback ===
                                                  'dislike' && (
                                                  <span className="refferal-icons">
                                                    <i className="icon-unlike" />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <div className="bottom-icons">
                                              {item.refer_notes && (
                                                <span
                                                  onClick={() => {
                                                    setNotes(item.refer_notes);
                                                    setShowComponent('Notes');
                                                  }}>
                                                  <i className="fa-sharp fa-solid fa-book" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        )}

                                      {subTabType === 'appointment' &&
                                        item.referred_detail && (
                                          <div
                                            className="refferals-list as-link"
                                            key={'www' + index}>
                                            <div
                                              className="refferals-list-item card new-refferal-list"
                                              onClick={() => {
                                                setShowComponent(
                                                  'create-appointment',
                                                );
                                                setAppointmentData(item);
                                              }}>
                                              <div className="refferal-content">
                                                <h2>
                                                  {item.creator_details &&
                                                    fixName(
                                                      item.creator_details
                                                        .first_name,
                                                      item.creator_details
                                                        .last_name,
                                                    )}
                                                </h2>
                                                <span className="refferal-auther">
                                                  {item.appointment_type_title}
                                                </span>
                                                {/* <p>Think there may be some synergy</p> */}
                                                <span className="refferal-date">
                                                  {moment(
                                                    item.start_date,
                                                  ).format('MM/DD/YYYY')}{' '}
                                                  {item.duration + 'min'}
                                                </span>
                                              </div>
                                              <div className="refferal-img">
                                                <img
                                                  src={
                                                    item.creator_details &&
                                                    item.creator_details
                                                      .profile_img
                                                      ? item.creator_details
                                                          .profile_img
                                                      : DEFAULT_IMAGE
                                                  }
                                                  alt=""
                                                />
                                                <p className="refferal-info-que text-end">
                                                  {item.cost
                                                    ? '$' + item.cost
                                                    : 'Free'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {item.creator_detail &&
                                        subTabType === 'sessions' && (
                                          <div className="session-item card">
                                            <div
                                              className="session-heading as-link"
                                              onClick={() => {
                                                if (
                                                  new Date(serverTime) <
                                                  new Date(item.start_time)
                                                ) {
                                                  breakDown(item);
                                                } else {
                                                  joinTheSession(item);
                                                }
                                              }}>
                                              <h2>{item.session_title} </h2>
                                              <span className="users-text as-link">
                                                <i className="fas fa-circle-user" />{' '}
                                                {item.total_members + 1}/
                                                {item.total_moderators + 1}
                                              </span>
                                              <span className="category-text as-link">
                                                {item.category_name}
                                              </span>
                                              <span className="category-text mb-2 d-block as-link">
                                                <Moment format="MM/DD/YY HH:mm A">
                                                  {item.session_start_time}
                                                </Moment>
                                              </span>
                                              <h2 className="as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {item.referred_detail &&
                                                  fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                              </h2>
                                            </div>
                                            <div
                                              className="persons-img-list as-link"
                                              onClick={() => {
                                                if (
                                                  new Date(serverTime) <
                                                  new Date(item.start_time)
                                                ) {
                                                  breakDown(item);
                                                } else {
                                                  joinTheSession(item);
                                                }
                                              }}>
                                              <div className="img-item as-link">
                                                <span className="owner-icon as-link">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                <img
                                                  src={
                                                    item.creator_detail
                                                      .profile_img === ''
                                                      ? DEFAULT_IMAGE
                                                      : item.creator_detail
                                                          .profile_img
                                                  }
                                                  alt=""
                                                />
                                              </div>

                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => {
                                                    // if (index < 7) {
                                                    return _renderAudiImg(
                                                      person,
                                                      true,
                                                    );
                                                    // }
                                                  })}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => {
                                                    // if (index < 7) {
                                                    return _renderAudiImg(
                                                      person,
                                                    );
                                                    // }
                                                  })}
                                            </div>

                                            <div className="persons-name-list">
                                              <ul>
                                                <li>
                                                  <span className="owner-icon">
                                                    <i className="fas fa-crown" />
                                                  </span>
                                                  {fixName(
                                                    item.creator_detail
                                                      .creator_first_name,
                                                    item.creator_detail
                                                      .creator_last_name,
                                                  )}
                                                </li>
                                                {!(item.moderator_ids === '') &&
                                                  item.moderators_list &&
                                                  item.moderators_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.moderators_list,
                                                  ).length > 0 &&
                                                  item.moderators_list
                                                    .slice(0, 4)
                                                    .map(person => (
                                                      <li
                                                        key={`person-mod-${person.id}`}>
                                                        {fixName(
                                                          person.first_name,
                                                          person.last_name,
                                                        )}
                                                        <span className="speaker-icon">
                                                          <i className="fas fa-microphone" />
                                                        </span>
                                                      </li>
                                                    ))}
                                                {!(item.audience_ids === '') &&
                                                  item.audience_list &&
                                                  item.audience_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.audience_list,
                                                  ).length > 0 &&
                                                  item.audience_list
                                                    .slice(0, 3)
                                                    .map(person => (
                                                      // index < 5 &&
                                                      <li
                                                        key={`person-aud-${person.id}`}>
                                                        {fixName(
                                                          person.first_name,
                                                          person.last_name,
                                                        )}
                                                      </li>
                                                    ))}
                                              </ul>
                                            </div>
                                            <div className="bottom-icons">
                                              <span>
                                                <i className="fas fa-microphone" />
                                              </span>
                                              {item.refer_notes && (
                                                <span
                                                  onClick={() => {
                                                    setNotes(item.refer_notes);
                                                    setShowComponent('Notes');
                                                  }}>
                                                  <i className="fa-sharp fa-solid fa-book" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      {item.creator_detail &&
                                        subTabType === 'events' && (
                                          <>
                                            <div className="session-item card">
                                              <div
                                                className="session-heading"
                                                onClick={() => {
                                                  eventHandle(item);
                                                }}>
                                                <h2 className="as-link">
                                                  {item.session_title}
                                                </h2>
                                                <span className="category-text as-link d-block mb-2">
                                                  <Moment format="MM/DD/YY HH:mm A">
                                                    {item.start_time}
                                                  </Moment>
                                                </span>
                                                <span className="users-text as-link ">
                                                  <i className="fas fa-circle-user" />{' '}
                                                  {item.total_members + 1}/
                                                  {item.total_moderators + 1}
                                                </span>
                                                <span className="category-text as-link">
                                                  {item.category_name}
                                                </span>
                                              </div>

                                              {item.referred_detail && (
                                                <div
                                                  className="mb-3"
                                                  onClick={() => {
                                                    eventHandle(item);
                                                  }}>
                                                  <span className="reffered-text as-link">
                                                    Referred{' '}
                                                    {arrowButton ? 'to' : 'by'}{' '}
                                                    {fixName(
                                                      item.referred_detail
                                                        .first_name,
                                                      item.referred_detail
                                                        .last_name,
                                                    )}{' '}
                                                  </span>
                                                </div>
                                              )}

                                              <div
                                                className="persons-img-list"
                                                onClick={() => {
                                                  eventHandle(item);
                                                }}>
                                                <div className="img-item as-link">
                                                  <img
                                                    src={
                                                      item.creator_detail
                                                        .profile_img === ''
                                                        ? DEFAULT_IMAGE
                                                        : item.creator_detail
                                                            .profile_img
                                                    }
                                                    alt=""
                                                  />
                                                </div>

                                                {!(item.moderator_ids === '') &&
                                                  item.moderators_list &&
                                                  item.moderators_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.moderators_list,
                                                  ).length > 0 &&
                                                  item.moderators_list
                                                    .slice(0, 4)
                                                    .map(person => {
                                                      // if (index < 7) {
                                                      return _renderAudiImg(
                                                        person,
                                                        true,
                                                      );
                                                      // }
                                                    })}
                                                {!(item.audience_ids === '') &&
                                                  item.audience_list &&
                                                  item.audience_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.audience_list,
                                                  ).length > 0 &&
                                                  item.audience_list
                                                    .slice(0, 3)
                                                    .map(person => {
                                                      // if (index < 7) {
                                                      return _renderAudiImg(
                                                        person,
                                                      );
                                                      // }
                                                    })}
                                              </div>

                                              <div className="persons-name-list">
                                                <ul>
                                                  <li>
                                                    <span className="owner-icon">
                                                      <i className="fas fa-crown" />
                                                    </span>
                                                    {fixName(
                                                      item.creator_detail
                                                        .creator_first_name,
                                                      item.creator_detail
                                                        .creator_last_name,
                                                    )}
                                                  </li>
                                                  {!(
                                                    item.moderator_ids === ''
                                                  ) &&
                                                    item.moderators_list &&
                                                    item.moderators_list
                                                      .length > 0 &&
                                                    Object.entries(
                                                      item.moderators_list,
                                                    ).length > 0 &&
                                                    item.moderators_list
                                                      .slice(0, 4)
                                                      .map(person => (
                                                        <li
                                                          key={`person-mod-${person.id}`}>
                                                          {fixName(
                                                            person.first_name,
                                                            person.last_name,
                                                          )}
                                                          <span className="speaker-icon">
                                                            <i className="fas fa-microphone" />
                                                          </span>
                                                        </li>
                                                      ))}
                                                  {!(
                                                    item.audience_ids === ''
                                                  ) &&
                                                    item.audience_list &&
                                                    item.audience_list.length >
                                                      0 &&
                                                    Object.entries(
                                                      item.audience_list,
                                                    ).length > 0 &&
                                                    item.audience_list
                                                      .slice(0, 3)
                                                      .map(person => (
                                                        // index < 5 &&
                                                        <li
                                                          key={`person-aud-${person.id}`}>
                                                          {fixName(
                                                            person.first_name,
                                                            person.last_name,
                                                          )}
                                                        </li>
                                                      ))}
                                                </ul>
                                              </div>
                                              <div className="events-place-like session-address-wrapper mt-2">
                                                {item.address && (
                                                  <span
                                                    className="address as-link"
                                                    onClick={() => {}}>
                                                    <i className="fas fa-location-dot"></i>{' '}
                                                    {item.address}
                                                  </span>
                                                )}

                                                {item.price && (
                                                  <span>
                                                    {formatAmount(
                                                      item.price
                                                        ? item.price
                                                        : '',
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="bottom-icons">
                                                {item.refer_notes && (
                                                  <span
                                                    onClick={() => {
                                                      setNotes(
                                                        item.refer_notes,
                                                      );
                                                      setShowComponent('Notes');
                                                    }}>
                                                    <i className="fa-sharp fa-solid fa-book" />
                                                  </span>
                                                )}
                                                <span>
                                                  <i className="icon-tickets" />
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {subTabType === 'opportunities' &&
                                        item.opportunity_type_name && (
                                          <>
                                            <div className="opportunity-item card">
                                              <h2 className="as-link">
                                                {item.title}
                                              </h2>
                                              <div
                                                className="oppo-share-wrapper as-link"
                                                onClick={() => {
                                                  setOpportunityData(item);
                                                  setShowComponent(
                                                    'opportunity',
                                                  );
                                                }}>
                                                <span className="category as-link">
                                                  {item.business_name}
                                                </span>
                                                {/* <span className="oppo-share-icon">
                                            <i className="fas fa-share" />
                                          </span> */}
                                              </div>
                                              {item.referred_detail && (
                                                <span
                                                  className="reffered-text as-link"
                                                  onClick={() => {
                                                    setOpportunityData(item);
                                                    setShowComponent(
                                                      'opportunity',
                                                    );
                                                  }}>
                                                  Referred{' '}
                                                  {arrowButton ? 'to' : 'by'}{' '}
                                                  {fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                                </span>
                                              )}
                                              <br />
                                              <div
                                                className="as-link"
                                                onClick={() => {
                                                  setOpportunityData(item);
                                                  setShowComponent(
                                                    'opportunity',
                                                  );
                                                }}>
                                                <RenderHTMLBlock
                                                  className={`oppo-desc-content ${showMoreClass}`}
                                                  content={item.description}
                                                />
                                                {/* {item.description && (
                                                <ShowMore limit={150}>
                                                  {item.description}
                                                </ShowMore>
                                              )} */}
                                              </div>
                                              {item.description.length >
                                                110 && (
                                                <div className="oppo-desc as-link ">
                                                  <span
                                                    className="read-more"
                                                    onClick={() =>
                                                      toggleShowMore(!showMore)
                                                    }>
                                                    {!showMore
                                                      ? labels.read_more[
                                                          selected_lang
                                                        ]
                                                      : labels.read_less[
                                                          selected_lang
                                                        ]}
                                                  </span>
                                                </div>
                                              )}
                                              <div
                                                className="oppo-address as-link"
                                                onClick={() => {
                                                  setOpportunityData(item);
                                                  setShowComponent(
                                                    'opportunity',
                                                  );
                                                }}>
                                                {item.address && (
                                                  <span className="address">
                                                    <i className="icon-map-pin" />{' '}
                                                    {item.address}
                                                  </span>
                                                )}
                                                <span
                                                  className="injeaction-text as-link"
                                                  onClick={() => {
                                                    setOpportunityData(item);
                                                    setShowComponent(
                                                      'opportunity',
                                                    );
                                                  }}>
                                                  {item.vaccinate === 'yes' && (
                                                    <i className="icon-injection" />
                                                  )}{' '}
                                                  {item.opportunity_type_name}
                                                  {/* <b>
                                                {formatAmount(
                                                  item.amount
                                                    ? item.amount
                                                    : '',
                                                )}
                                              </b> */}
                                                  <b>
                                                    {item.amount &&
                                                      formatAmount(
                                                        item.amount
                                                          ? item.amount
                                                          : '',
                                                      ) + '/hr'}
                                                  </b>
                                                </span>
                                                {/* <span className="hearticon">
                                                               <i className={`${heartClass} fa-heart`} />
                                                           </span> */}
                                              </div>
                                              <div className="bottom-icons">
                                                {/* <span>
                                              <i className="fas fa-microphone" />
                                            </span> */}
                                                {item.refer_notes && (
                                                  <span
                                                    onClick={() => {
                                                      setNotes(
                                                        item.refer_notes,
                                                      );
                                                      setShowComponent('Notes');
                                                    }}>
                                                    <i className="fa-sharp fa-solid fa-book" />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {subTabType === 'group_chat' &&
                                        item.refer_type === 'group_chat' && (
                                          <>
                                            <div
                                              className="messages-users-list card-container"
                                              onClick={() => {
                                                // setShowComponent(
                                                //   'create-appointment',
                                                // );
                                                // setAppointmentData(item);
                                              }}>
                                              <div
                                                key={`msg-${index}`}
                                                className={
                                                  'message-user-item no-link padd-with-borderContaier m-0'
                                                }>
                                                <div
                                                  className="user-img as-link"
                                                  onClick={() => {
                                                    // amplitudeEvent('OPEN_CHAT_ROOM');
                                                    handleOpenChatRoom(item);
                                                  }}>
                                                  <img
                                                    src={
                                                      item.group_image !== ''
                                                        ? item.group_image
                                                        : item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['profile_img']
                                                        ? item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['profile_img']
                                                        : DEFAULT_IMAGE
                                                    }
                                                    alt=""
                                                  />
                                                  {item.chat_type ===
                                                    'question' && (
                                                    <span>
                                                      <i className="fa-solid fa-q"></i>
                                                    </span>
                                                  )}
                                                  {item.chat_type ===
                                                    'opportunity' && (
                                                    <span className="icon icon-posts"></span>
                                                  )}
                                                  {item.chat_type ===
                                                    'event' && (
                                                    <span className="icon-tickets"></span>
                                                  )}
                                                  {item.chat_type ===
                                                    'session' && (
                                                    <span className="icon-video-camera-2"></span>
                                                  )}
                                                  {/* <span className="fa-solid fa-shop"></span> */}
                                                </div>
                                                <div
                                                  className="user-content as-link"
                                                  onClick={() => {
                                                    handleOpenChatRoom(item);
                                                  }}>
                                                  <h2 className="single-line-text">
                                                    {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                                    {item.group_name !== ''
                                                      ? item.group_name
                                                      : fixName(
                                                          item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['first_name'],
                                                          item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['last_name'],
                                                        )}
                                                  </h2>

                                                  {formatString(
                                                    item.last_message,
                                                  )}

                                                  <div className="message-date-info">
                                                    <span className="message-date">
                                                      <Moment format="MM/DD/YY HH:mm A">
                                                        {item.last_message_time
                                                          ? item.last_message_time
                                                          : item.created_at}
                                                      </Moment>
                                                    </span>
                                                    {/* {renderMessageUsers(item.moreUsers)} */}
                                                  </div>
                                                </div>

                                                {!item.hasOwnProperty(
                                                  'invitation_type',
                                                ) &&
                                                  item.hasOwnProperty(
                                                    'group_detail',
                                                  ) && (
                                                    <OtherUserImages
                                                      otherUsers={filteringUsers(
                                                        item.audience_detail.concat(
                                                          item.moderator_detail,
                                                          item.creator_detail
                                                            ? [
                                                                item.creator_detail,
                                                              ]
                                                            : [
                                                                item.creator_details,
                                                              ],
                                                        ),
                                                        arrayColumn(
                                                          item.group_detail,
                                                          'id',
                                                        ),
                                                      )}
                                                    />
                                                  )}
                                                {!item.hasOwnProperty(
                                                  'invitation_type',
                                                ) &&
                                                  !item.hasOwnProperty(
                                                    'opportunity_invitaion_status',
                                                  ) &&
                                                  item.hasOwnProperty(
                                                    'opportunity_id',
                                                  ) &&
                                                  item.chat_type ===
                                                    'opportunity' && (
                                                    <OtherUserImages
                                                      otherUsers={filteringUsers(
                                                        item.other_persons_opportunity_chat_room.concat(
                                                          item.team_member_details,
                                                          item.creator_detail
                                                            ? [
                                                                item.creator_detail,
                                                              ]
                                                            : [
                                                                item.creator_details,
                                                              ],
                                                        ),
                                                        arrayColumn(
                                                          [
                                                            ...item.other_persons_opportunity_chat_room,
                                                            ...item.team_member_details,
                                                            item.creator_details,
                                                          ],
                                                          'id',
                                                        ),
                                                      )}
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'opportunity' ? (
                                        <div className="opportunity-item card">
                                          <h2
                                            className="as-link"
                                            onClick={() => {
                                              setOpportunityData(item);
                                              setShowComponent('opportunity');
                                            }}>
                                            {item.title}
                                          </h2>
                                          <div
                                            className="oppo-share-wrapper as-link"
                                            onClick={() => {
                                              setOpportunityData(item);
                                              setShowComponent('opportunity');
                                            }}>
                                            <span
                                              className="category as-link"
                                              onClick={() => {
                                                setOpportunityData(item);
                                                setShowComponent('opportunity');
                                              }}>
                                              {item.business_name}
                                            </span>
                                          </div>
                                          {item.referred_detail && (
                                            <span
                                              className="as-link reffered-text"
                                              onClick={() => {
                                                setOpportunityData(item);
                                                setShowComponent('opportunity');
                                              }}>
                                              Referred{' '}
                                              {arrowButton ? 'to' : 'by'}{' '}
                                              {fixName(
                                                item.referred_detail.first_name,
                                                item.referred_detail.last_name,
                                              )}
                                            </span>
                                          )}

                                          <div
                                            className="oppo-desc as-link"
                                            onClick={() => {
                                              setOpportunityData(item);
                                              setShowComponent('opportunity');
                                            }}>
                                            <RenderHTMLBlock
                                              className={`oppo-desc-content ${showMoreClass}`}
                                              content={item.description}
                                            />

                                            {/* {item.description && (
                                            <span className="refferal-auther as-link w-100">
                                              <ShowMore limit={150}>
                                                {item.description}
                                              </ShowMore>
                                            </span>
                                          )} */}
                                          </div>
                                          {item.description.length > 110 && (
                                            <div className="oppo-desc as-link ">
                                              <span
                                                className="read-more"
                                                onClick={() =>
                                                  toggleShowMore(!showMore)
                                                }>
                                                {!showMore
                                                  ? labels.read_more[
                                                      selected_lang
                                                    ]
                                                  : labels.read_less[
                                                      selected_lang
                                                    ]}
                                              </span>
                                            </div>
                                          )}
                                          <div
                                            className="oppo-address as-link"
                                            onClick={() => {
                                              setOpportunityData(item);
                                              setShowComponent('opportunity');
                                            }}>
                                            {item.address && (
                                              <span className="address">
                                                <i className="icon-map-pin" />{' '}
                                                {item.address}
                                              </span>
                                            )}
                                            <span className="injeaction-text as-link">
                                              {item.vaccinate === 'yes' && (
                                                <i className="icon-injection" />
                                              )}{' '}
                                              {item.opportunity_type_name}
                                              {/* <b>
                                            {formatAmount(
                                              item.amount ? item.amount : '',
                                            )}
                                          </b> */}
                                              <b>
                                                {item.amount &&
                                                  formatAmount(
                                                    item.amount
                                                      ? item.amount
                                                      : '',
                                                  ) + '/hr'}
                                              </b>
                                            </span>
                                            {/* <span className="hearticon">
                                                               <i className={`${heartClass} fa-heart`} />
                                                           </span> */}
                                          </div>
                                          <div className="bottom-icons">
                                            {/* <span>
                                            <i className="fas fa-microphone" />
                                          </span> */}
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  setNotes(item.refer_notes);
                                                  setShowComponent('Notes');
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'session' ? (
                                        <div className="session-item card">
                                          <div
                                            className="session-heading as-link"
                                            onClick={() => {
                                              if (
                                                new Date(serverTime) <
                                                new Date(item.start_time)
                                              ) {
                                                breakDown(item);
                                              } else {
                                                joinTheSession(item);
                                              }
                                            }}>
                                            <h2>{item.session_title}</h2>
                                            <span className="users-text as-link">
                                              <i className="fas fa-circle-user" />{' '}
                                              {item.total_members + 1}/
                                              {item.total_moderators + 1}
                                            </span>
                                            <span className="category-text as-link">
                                              {item.category_name}
                                            </span>
                                            <span className="category-text mb-2 d-block as-link">
                                              <Moment format="MM/DD/YY HH:mm A">
                                                {item.session_start_time}
                                              </Moment>
                                            </span>
                                            {item.referred_detail && (
                                              <h2 className="as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {fixName(
                                                  item.referred_detail
                                                    .first_name,
                                                  item.referred_detail
                                                    .last_name,
                                                )}{' '}
                                                {/* {item.referred_detail.last_name} */}
                                              </h2>
                                            )}
                                          </div>
                                          <div
                                            className="persons-img-list as-link"
                                            onClick={() => {
                                              if (
                                                new Date(serverTime) <
                                                new Date(item.start_time)
                                              ) {
                                                breakDown(item);
                                              } else {
                                                joinTheSession(item);
                                              }
                                            }}>
                                            <div className="img-item as-link">
                                              <span className="owner-icon">
                                                <i className="fas fa-crown" />
                                              </span>
                                              <img
                                                src={
                                                  item.creator_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.creator_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            {!(item.moderator_ids === '') &&
                                              item.moderators_list &&
                                              item.moderators_list.length > 0 &&
                                              Object.entries(
                                                item.moderators_list,
                                              ).length > 0 &&
                                              item.moderators_list
                                                .slice(0, 4)
                                                .map(person => {
                                                  return _renderAudiImg(
                                                    person,
                                                    true,
                                                  );
                                                })}
                                            {!(item.audience_ids === '') &&
                                              item.audience_list &&
                                              item.audience_list.length > 0 &&
                                              Object.entries(item.audience_list)
                                                .length > 0 &&
                                              item.audience_list
                                                .slice(0, 3)
                                                .map(person => {
                                                  return _renderAudiImg(person);
                                                })}
                                          </div>
                                          <div className="persons-name-list ">
                                            <ul>
                                              <li>
                                                <span className="owner-icon">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                {fixName(
                                                  item.creator_detail
                                                    .creator_first_name,
                                                  item.creator_detail
                                                    .creator_last_name,
                                                )}
                                              </li>
                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => (
                                                    <li
                                                      key={`person-mod-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                      <span className="speaker-icon">
                                                        <i className="fas fa-microphone" />
                                                      </span>
                                                    </li>
                                                  ))}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => (
                                                    // index < 5 &&
                                                    <li
                                                      key={`person-aud-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                    </li>
                                                  ))}
                                            </ul>
                                          </div>
                                          <div className="bottom-icons">
                                            <span>
                                              <i className="fas fa-microphone" />
                                            </span>
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  setNotes(item.refer_notes);
                                                  setShowComponent('Notes');
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {(subTabType === 'all' &&
                                        item.refer_type === 'profile') ||
                                      item.refer_type === 'accomplishment' ? (
                                        <div
                                          key={`ee-www-${item.id}`}
                                          className="refferals-list-item card qa-list-item">
                                          <div
                                            className="refferal-img as-link"
                                            onClick={() => {
                                              handle(item);
                                            }}>
                                            <img
                                              src={
                                                item.referred_detail
                                                  .profile_img === ''
                                                  ? DEFAULT_IMAGE
                                                  : item.referred_detail
                                                      .profile_img
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="d-flex align-items-center referrals-dataa referals-dataa-holder">
                                            <div className="refferal-content as-link w-100">
                                              {item.referred_detail && (
                                                <h2
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  {fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                                </h2>
                                              )}
                                              <span
                                                className="refferal-auther as-link w-100"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                {item.user_first_name
                                                  ? fixName(
                                                      item.user_first_name,
                                                      item.users_last_name,
                                                    )
                                                  : item.first_name &&
                                                    item.last_name
                                                  ? fixName(
                                                      item.first_name,
                                                      item.last_name,
                                                    )
                                                  : item.first_name &&
                                                    !item.last_name &&
                                                    capitalizeFirst(
                                                      item.first_name,
                                                    )}
                                              </span>

                                              {item.note && (
                                                <span className="refferal-auther as-link w-100">
                                                  <ShowMore limit={85}>
                                                    {item.note}
                                                  </ShowMore>
                                                </span>
                                              )}

                                              <span
                                                className="refferal-date as-link"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                <Moment format="MM/DD/YY H:mmA">
                                                  {item.updated_at}
                                                </Moment>
                                              </span>
                                            </div>
                                            <div className="refferal-right-side-links">
                                              {item.images && (
                                                <div
                                                  className="invited-by-wrapper as-link"
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  <div className="user-img">
                                                    <img
                                                      src={
                                                        !item.images
                                                          ? DEFAULT_IMAGE
                                                          : item.images[0]
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              <div className="refferal-bottom-right-links d-flex align-items-end justify-content-center">
                                                <div
                                                  className="refferal-like-unlike as-link"
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  {item.feedback === 'like' && (
                                                    <span className="refferal-icons">
                                                      <i
                                                        className="icon-like-icon"
                                                        style={{
                                                          color: '#00bf4d',
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                  {item.feedback ===
                                                    'dislike' && (
                                                    <span className="refferal-icons">
                                                      <i className="icon-unlike" />
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="bottom-icons">
                                                  {item.refer_notes && (
                                                    <span
                                                      onClick={() => {
                                                        setNotes(
                                                          item.refer_notes,
                                                        );
                                                        setShowComponent(
                                                          'Notes',
                                                        );
                                                      }}>
                                                      <i className="fa-sharp fa-solid fa-book" />
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'group_chat' ? (
                                        <div className="messages-users-list card-container">
                                          <div
                                            key={`msg-${index}`}
                                            className={
                                              'message-user-item no-link padd-with-borderContaier m-0'
                                            }>
                                            <div
                                              className="user-img as-link"
                                              // onClick={() => {
                                              //   amplitudeEvent('OPEN_CHAT_ROOM');
                                              //   handleOpenChatRoom(item);
                                              // }}
                                            >
                                              <img
                                                src={
                                                  item.group_image !== ''
                                                    ? item.group_image
                                                    : item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['profile_img']
                                                    ? item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['profile_img']
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              {item.chat_type ===
                                                'question' && (
                                                <span>
                                                  <i className="fa-solid fa-q"></i>
                                                </span>
                                              )}
                                              {item.chat_type ===
                                                'opportunity' && (
                                                <span className="icon icon-posts"></span>
                                              )}
                                              {item.chat_type === 'event' && (
                                                <span className="icon-tickets"></span>
                                              )}
                                              {item.chat_type === 'session' && (
                                                <span className="icon-video-camera-2"></span>
                                              )}
                                              {/* <span className="fa-solid fa-shop"></span> */}
                                            </div>
                                            <div
                                              className="user-content as-link"
                                              onClick={() => {
                                                // handleOpenChatRoom(item);
                                              }}>
                                              <h2 className="single-line-text">
                                                {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                                {item.group_name !== ''
                                                  ? item.group_name
                                                  : fixName(
                                                      item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['first_name'],
                                                      item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['last_name'],
                                                    )}
                                              </h2>

                                              {formatString(item.last_message)}

                                              <div className="message-date-info">
                                                <span className="message-date">
                                                  <Moment format="MM/DD/YY HH:mm A">
                                                    {item.last_message_time
                                                      ? item.last_message_time
                                                      : item.created_at}
                                                  </Moment>
                                                </span>
                                                {/* {renderMessageUsers(item.moreUsers)} */}
                                              </div>
                                            </div>

                                            {!item.hasOwnProperty(
                                              'invitation_type',
                                            ) &&
                                              item.hasOwnProperty(
                                                'group_detail',
                                              ) && (
                                                <OtherUserImages
                                                  otherUsers={filteringUsers(
                                                    item.audience_detail.concat(
                                                      item.moderator_detail,
                                                      item.creator_detail
                                                        ? [item.creator_detail]
                                                        : [
                                                            item.creator_details,
                                                          ],
                                                    ),
                                                    arrayColumn(
                                                      item.group_detail,
                                                      'id',
                                                    ),
                                                  )}
                                                />
                                              )}
                                            {!item.hasOwnProperty(
                                              'invitation_type',
                                            ) &&
                                              !item.hasOwnProperty(
                                                'opportunity_invitaion_status',
                                              ) &&
                                              item.hasOwnProperty(
                                                'opportunity_id',
                                              ) &&
                                              item.chat_type ===
                                                'opportunity' && (
                                                <OtherUserImages
                                                  otherUsers={filteringUsers(
                                                    item.other_persons_opportunity_chat_room.concat(
                                                      item.team_member_details,
                                                      item.creator_detail
                                                        ? [item.creator_detail]
                                                        : [
                                                            item.creator_details,
                                                          ],
                                                    ),
                                                    arrayColumn(
                                                      [
                                                        ...item.other_persons_opportunity_chat_room,
                                                        ...item.team_member_details,
                                                        item.creator_details,
                                                      ],
                                                      'id',
                                                    ),
                                                  )}
                                                />
                                              )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'appointment' ? (
                                        <div
                                          className="refferals-list as-link"
                                          key={'www' + index}
                                          onClick={() => {
                                            setShowComponent(
                                              'create-appointment',
                                            );
                                            setAppointmentData(item);
                                          }}>
                                          <div className="refferals-list-item card new-refferal-list">
                                            <div className="refferal-content">
                                              <h2>
                                                {item.creator_details &&
                                                  fixName(
                                                    item.creator_details
                                                      .first_name,
                                                    item.creator_details
                                                      .last_name,
                                                  )}
                                              </h2>
                                              <span className="refferal-auther">
                                                {item.appointment_type_title}
                                              </span>
                                              {/* <p>Think there may be some synergy</p> */}
                                              <span className="refferal-date">
                                                {moment(item.start_date).format(
                                                  'MM/DD/YYYY',
                                                )}{' '}
                                                {item.duration + 'min'}
                                              </span>
                                            </div>
                                            <div className="refferal-img">
                                              <img
                                                src={
                                                  item.creator_details &&
                                                  item.creator_details
                                                    .profile_img
                                                    ? item.creator_details
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              <p className="refferal-info-que text-end">
                                                {item.cost
                                                  ? formatAmount(item.cost)
                                                  : 'Free'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      {subTabType === 'all' &&
                                      item.refer_type === 'event' ? (
                                        <div className="session-item card">
                                          <div
                                            className="session-heading"
                                            onClick={() => {
                                              eventHandle(item);
                                            }}>
                                            <h2 className="as-link">
                                              {item.session_title}
                                            </h2>
                                            <span className="category-text as-link d-block mb-2">
                                              <Moment format="MM/DD/YY HH:mm A">
                                                {item.start_time}
                                              </Moment>
                                            </span>
                                            <span className="users-text as-link ">
                                              <i className="fas fa-circle-user" />{' '}
                                              {item.total_members + 1}/
                                              {item.total_moderators + 1}
                                            </span>
                                            <span className="category-text as-link">
                                              {item.category_name}
                                            </span>
                                          </div>

                                          {item.referred_detail && (
                                            <div
                                              className="mb-3"
                                              onClick={() => {
                                                eventHandle(item);
                                              }}>
                                              <span className="reffered-text as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {fixName(
                                                  item.referred_detail
                                                    .first_name,
                                                  item.referred_detail
                                                    .last_name,
                                                )}{' '}
                                              </span>
                                            </div>
                                          )}

                                          <div
                                            className="persons-img-list"
                                            onClick={() => {
                                              eventHandle(item);
                                            }}>
                                            <div className="img-item as-link">
                                              <img
                                                src={
                                                  item.creator_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.creator_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {!(item.moderator_ids === '') &&
                                              item.moderators_list &&
                                              item.moderators_list.length > 0 &&
                                              Object.entries(
                                                item.moderators_list,
                                              ).length > 0 &&
                                              item.moderators_list
                                                .slice(0, 4)
                                                .map(person => {
                                                  // if (index < 7) {
                                                  return _renderAudiImg(
                                                    person,
                                                    true,
                                                  );
                                                  // }
                                                })}
                                            {!(item.audience_ids === '') &&
                                              item.audience_list &&
                                              item.audience_list.length > 0 &&
                                              Object.entries(item.audience_list)
                                                .length > 0 &&
                                              item.audience_list
                                                .slice(0, 3)
                                                .map(person => {
                                                  // if (index < 7) {
                                                  return _renderAudiImg(person);
                                                  // }
                                                })}
                                          </div>

                                          <div className="persons-name-list">
                                            <ul>
                                              <li>
                                                <span className="owner-icon">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                {fixName(
                                                  item.creator_detail
                                                    .creator_first_name,
                                                  item.creator_detail
                                                    .creator_last_name,
                                                )}
                                              </li>
                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => (
                                                    <li
                                                      key={`person-mod-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                      <span className="speaker-icon">
                                                        <i className="fas fa-microphone" />
                                                      </span>
                                                    </li>
                                                  ))}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => (
                                                    // index < 5 &&
                                                    <li
                                                      key={`person-aud-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                    </li>
                                                  ))}
                                            </ul>
                                          </div>
                                          <div className="events-place-like session-address-wrapper mt-2">
                                            {item.address && (
                                              <span
                                                className="address as-link"
                                                onClick={() => {}}>
                                                <i className="fas fa-location-dot"></i>{' '}
                                                {item.address}
                                              </span>
                                            )}

                                            {item.price && (
                                              <span>
                                                {formatAmount(
                                                  item.price ? item.price : '',
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="bottom-icons">
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  setNotes(item.refer_notes);
                                                  setShowComponent('Notes');
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                            <span>
                                              <i className="icon-tickets" />
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                      {subTabType === 'all' &&
                                      item.refer_type === 'refer_type' ? (
                                        <div
                                          className="refferals-list as-link"
                                          key={'www' + index}>
                                          <div className="refferals-list-item card new-refferal-list">
                                            <div className="refferal-content">
                                              <h2>
                                                {item.creator_details &&
                                                  fixName(
                                                    item.creator_details
                                                      .first_name,
                                                    item.creator_details
                                                      .last_name,
                                                  )}
                                              </h2>
                                              <span className="refferal-auther">
                                                {item.appointment_type_title}
                                              </span>
                                              {/* <p>Think there may be some synergy</p> */}
                                              <span className="refferal-date">
                                                {moment(item.start_date).format(
                                                  'MM/DD/YYYY',
                                                )}{' '}
                                                {item.duration + 'min'}
                                              </span>
                                            </div>
                                            <div className="refferal-img">
                                              <img
                                                src={
                                                  item.creator_details &&
                                                  item.creator_details
                                                    .profile_img
                                                    ? item.creator_details
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              <p className="refferal-info-que text-end">
                                                {item.cost
                                                  ? '$' + item.cost
                                                  : 'Free'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              <>
                                {!showSpinner && (
                                  <>
                                    {subTabType === 'builders' && (
                                      <div className="no-results-wrapper Builders-NoRecord">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/no-circles.png')}
                                            alt=""
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>
                                            {
                                              labels.NoContactsTitle[
                                                selected_lang
                                              ]
                                            }
                                          </h2>
                                          <p>
                                            {
                                              labels.NoContactsDesc[
                                                selected_lang
                                              ]
                                            }
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className="btn btn-dark"
                                              onClick={() => {
                                                setWork(true);
                                              }}>
                                              {
                                                labels.DISCOVER_BUILDERS[
                                                  selected_lang
                                                ]
                                              }
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {subTabType === 'appointment' && (
                                      <div className="no-results-wrapper Builders-Appointment-NoRecord">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/no-appointments.png')}
                                            alt=""
                                          />
                                        </div>
                                        <div className="no-results-content">
                                          <h2>No Referrals Pending</h2>
                                          <p className="noRecord-desc">
                                            There is no any appointments
                                            available please explore and get
                                            referred.
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button className="btn btn-dark">
                                              <Link
                                                className="text-light"
                                                style={{textDecoration: 'none'}}
                                                to={{
                                                  pathname: '/appointmentpage',
                                                  state: {
                                                    typeIs: 'search',
                                                  },
                                                }}>
                                                Explore Appointments
                                              </Link>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!arrowButton &&
                                      subTabType === 'sessions' && (
                                        <div className="no-results-wrapper Builders-NoRecord Builders-Sessions-NoRecord">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/refer.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Get invited or referred to more
                                              sessions by sharing more interest
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to="/">
                                                  View Sessions
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {arrowButton &&
                                      subTabType === 'sessions' && (
                                        <div className="no-results-wrapper">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/refer.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              If no sessions exist in your
                                              network that you want to share,
                                              create your own
                                            </p>

                                            <div className="btn-wrapper">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/',
                                                    state: {
                                                      typeIs: 'sessionss',
                                                      data: 'createSession',
                                                    },
                                                  }}>
                                                  Create Session
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {!arrowButton &&
                                      subTabType === 'snapshots' && (
                                        <div className="no-results-wrapper Builders-NoRecord Builders-Snapshots-NoRecord">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/snapshot.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Share your needs with people
                                              because you never know who they
                                              know
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/builders',
                                                    state: {
                                                      typeIs: 'search',
                                                    },
                                                  }}>
                                                  Explore Builders
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {arrowButton &&
                                      subTabType === 'snapshots' && (
                                        <div className="no-results-wrapper">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/snapshot.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Learn more about your colleagues
                                              and their affiliations to create
                                              stronger referral networks
                                            </p>

                                            <div className="btn-wrapper">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/builders',
                                                    state: {
                                                      typeIs: 'search',
                                                    },
                                                  }}>
                                                  Explore Builders
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {!arrowButton &&
                                      subTabType === 'events' && (
                                        <div className="no-results-wrapper Builders-NoRecord">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/refer.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Get invited or referred to more
                                              events by sharing more interest
                                              {/* <Link className="no-results-link" to="/">
                                        sessions,
                                      </Link>{' '}
                                      <Link
                                        className="no-results-link"
                                        to={{
                                          pathname: '/',
                                          state: {
                                            typeIs: 'events',
                                          },
                                        }}>
                                        events
                                      </Link>{' '}
                                      and{' '}
                                      <Link
                                        className="no-results-link"
                                        to="/opportunities">
                                        opportunities
                                      </Link>{' '}
                                      to learn more about others. */}
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/',
                                                    state: {
                                                      typeIs: 'events',
                                                    },
                                                  }}>
                                                  View Events
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {arrowButton && subTabType === 'events' && (
                                      <div className="no-results-wrapper">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/refer.png')}
                                            alt=""
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>No Referrals Pending</h2>
                                          <p>
                                            If no events exist in your network
                                            that you want to share, create your
                                            own
                                            {/* <Link className="no-results-link" to="/">
                                        sessions,
                                      </Link>{' '}
                                      <Link
                                        className="no-results-link"
                                        to={{
                                          pathname: '/',
                                          state: {
                                            typeIs: 'events',
                                          },
                                        }}>
                                        events
                                      </Link>{' '}
                                      and{' '}
                                      <Link
                                        className="no-results-link"
                                        to="/opportunities">
                                        opportunities
                                      </Link>{' '}
                                      to learn more about others. */}
                                          </p>

                                          <div className="btn-wrapper">
                                            <Button className="btn btn-dark">
                                              <Link
                                                className="text-light"
                                                style={{textDecoration: 'none'}}
                                                to={{
                                                  pathname: '/',
                                                  state: {
                                                    typeIs: 'events',
                                                    data: 'show_create_event',
                                                  },
                                                }}>
                                                Create Event
                                              </Link>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!arrowButton &&
                                      subTabType === 'group_chat' && (
                                        <div className="no-results-wrapper Builders-GroupChat-NoRecord mt-5">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/no-messages.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>
                                              <h2>No Referrals Pending</h2>
                                            </h2>
                                            <p>
                                              There is no group chats there
                                              please explore and get referred.
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              {/* <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                      //  setSecondComponent('group-component');
                                      }}>
                                      {labels.Explore_Chat[selected_lang]}
                                    </Button> */}

                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/messages',
                                                    state: {
                                                      typeIs: 'search',
                                                    },
                                                  }}>
                                                  {
                                                    labels.Explore_Chat[
                                                      selected_lang
                                                    ]
                                                  }
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {arrowButton &&
                                      subTabType === 'group_chat' && (
                                        <div className="no-results-wrapper mt-5">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/no-messages.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>
                                              <h2>No Referrals Pending</h2>
                                            </h2>
                                            <p>
                                              There is no group chats there
                                              please explore and get referred.
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              {/* <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                      //  setSecondComponent('group-component');
                                      }}>
                                      {labels.Explore_Chat[selected_lang]}
                                    </Button> */}

                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to={{
                                                    pathname: '/messages',
                                                    state: {
                                                      typeIs: 'search',
                                                    },
                                                  }}>
                                                  {
                                                    labels.Explore_Chat[
                                                      selected_lang
                                                    ]
                                                  }
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {!arrowButton &&
                                      subTabType === 'opportunities' && (
                                        <div className="no-results-wrapper Builders-NoRecord">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/opportunity.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Communicate your needs with your
                                              circles and check for opportunity
                                              posts
                                            </p>

                                            <div className="btn-wrapper mt-3">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to="/opportunities">
                                                  View Opportunities
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {arrowButton &&
                                      subTabType === 'opportunities' && (
                                        <div className="no-results-wrapper">
                                          <div className="img-bg-wrapper">
                                            <img
                                              src={require('../../../Assets/images/norecord/refer.png')}
                                              alt=""
                                            />
                                          </div>

                                          <div className="no-results-content">
                                            <h2>No Referrals Pending</h2>
                                            <p>
                                              Share your opportunities or ones
                                              posted by your colleagues
                                            </p>

                                            <div className="btn-wrapper">
                                              <Button className="btn btn-dark">
                                                <Link
                                                  className="text-light"
                                                  style={{
                                                    textDecoration: 'none',
                                                  }}
                                                  to="/opportunities">
                                                  View Opportunities
                                                </Link>
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {subTabType === 'questions' && (
                                      <div className="no-results-wrapper">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/refer.png')}
                                            alt=""
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>Get Questions Answered</h2>
                                          <p>
                                            Help others get their questions
                                            answered from ciecles. There is no
                                            point in wasting time on problems
                                            already solved.
                                          </p>

                                          <div className="btn-wrapper">
                                            <Button className="btn btn-dark">
                                              <Link
                                                className="text-light"
                                                style={{textDecoration: 'none'}}
                                                to={{
                                                  pathname: '/opportunities',
                                                  state: {
                                                    typeIs: 'qa',
                                                  },
                                                }}>
                                                Explore Q&A
                                              </Link>
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {!arrowButton && subTabType === 'all' && (
                                      <div className="no-results-wrapper Builders-NoRecord">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/refer.png')}
                                            alt=""
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>Manage Referrals</h2>
                                          <p>
                                            Increase the number of referrals
                                            people send to you by referring more
                                            to others
                                            {/* <Link className="no-results-link" to="/">
                                      sessions,
                                    </Link>{' '}
                                    <Link
                                      className="no-results-link"
                                      to={{
                                        pathname: '/',
                                        state: {
                                          typeIs: 'events',
                                        },
                                      }}>
                                      events
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                      className="no-results-link"
                                      to="/opportunities">
                                      opportunities
                                    </Link>{' '}
                                    to jumpstart your refferals. */}
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className="btn btn-dark"
                                              onClick={() => {
                                                setWork(true);
                                              }}>
                                              Refer Colleague
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {arrowButton && subTabType === 'all' && (
                                      <div className="no-results-wrapper Builders-NoRecord">
                                        <div className="img-bg-wrapper">
                                          <img
                                            src={require('../../../Assets/images/norecord/refer.png')}
                                            alt=""
                                          />
                                        </div>

                                        <div className="no-results-content">
                                          <h2>Manage Referrals</h2>
                                          <p>
                                            Increase the number of referrals
                                            people send to you by referring more
                                            to others
                                            {/* <Link className="no-results-link" to="/">
                                      sessions,
                                    </Link>{' '}
                                    <Link
                                      className="no-results-link"
                                      to={{
                                        pathname: '/',
                                        state: {
                                          typeIs: 'events',
                                        },
                                      }}>
                                      events
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                      className="no-results-link"
                                      to="/opportunities">
                                      opportunities
                                    </Link>{' '}
                                    to jumpstart your refferals. */}
                                          </p>

                                          <div className="btn-wrapper mt-3">
                                            <Button
                                              className="btn btn-dark"
                                              onClick={() => {
                                                setWork(true);
                                              }}>
                                              Refer Colleague
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Scrollbars>
                        </div>
                      </div>
                    </>
                  )}

                  {/* {searchFilter && subTabType === 'builders' && (
                  <RefferalSearchFilter
                    show={searchFilter}
                    onClose={() => setSearchFilterModal(false)}
                    subTabType={subTabType}
                    data={data}
                    arrowButton={arrowButton}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    list={list}
                    setList={setList}
                    locationS={locationS}
                    setLocationS={setLocationS}
                    appointmentId={appointmentId}
                    setAppointmentId={setAppointmentId}
                    categoriesId={categoriesId}
                    setCategoriesId={setCategoriesId}
                    setActive={setActive}
                    active={active}
                    setFocusLocations={setFocusLocations}
                    setIdentiferId={setIdentiferId}
                    setEthnicityId={setEthnicityId}
                    setGender={setGender}
                    gender={gender}
                    focusLocations={focusLocations}
                    ethnicityId={ethnicityId}
                    identiferId={identiferId}
                    setLongitude={setLongitude}
                    longitude={longitude}
                    setLatitude={setLatitude}
                    latitude={latitude}
                  />
                )} */}
                  {/* {searchFilter && subTabType === 'sessions' && (
                  <SessionSearchFilter
                    show={searchFilter}
                    onClose={() => setSearchFilterModal(false)}
                    subTabType={subTabType}
                    data={data}
                    arrowButton={arrowButton}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    list={list}
                    setList={setList}
                    setActive={setActive}
                    active={active}
                    setFocusLocations={setFocusLocations}
                    setIdentiferId={setIdentiferId}
                    setEthnicityId={setEthnicityId}
                    setGender={setGender}
                    gender={gender}
                    focusLocations={focusLocations}
                    ethnicityId={ethnicityId}
                    identiferId={identiferId}
                  />
                )} */}
                  {/* {searchFilter && subTabType === 'events' && (
                  <SessionSearchFilter
                    show={searchFilter}
                    onClose={() => setSearchFilterModal(false)}
                    subTabType={subTabType}
                    data={data}
                    arrowButton={arrowButton}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    list={list}
                    setList={setList}
                    setActive={setActive}
                    active={active}
                    setFocusLocations={setFocusLocations}
                    setIdentiferId={setIdentiferId}
                    setEthnicityId={setEthnicityId}
                    setGender={setGender}
                    gender={gender}
                    focusLocations={focusLocations}
                    ethnicityId={ethnicityId}
                    identiferId={identiferId}
                  />
                )} */}
                  {showFilter && (
                    <CustomModal  className={'sessioneventFilter'} style={{height: '100%'}} onClose={() => setshowFilter(false)}
                    paddingBottomZero={true}>
                      <div className="serach-filter-wrapper">
                        <div className="filter-headings">
                          <h2
                            className="text-center">
                            Filter By Category
                          </h2>
                          {/* <p>What type of Referals are you looking for?</p> */}
                        </div>
                        <Scrollbars autoHide>
                        <div className="pb-2">
                          <div className="filter-category-list">
                            <ul>
                              <li className={AllActive ? 'active' : ''}>
                                <Form.Label>
                                  All{' '}
                                  <input
                                    type="checkbox"
                                    id="ALL"
                                    name="ALL"
                                    value="ALL"
                                    onChange={doAllCheckedValues}
                                    // onChange={handleSelectAll}
                                    checked={
                                      AllActive
                                    }
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                              <li
                                className={
                                  allCheckedValues.includes('builders')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Contacts{' '}
                                  <input
                                    type="checkbox"
                                    id="builders"
                                    name="builders"
                                    value="builders"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'builders',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                              <li
                                className={
                                  allCheckedValues.includes('sessions')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Virtual{' '}
                                  <input
                                    type="checkbox"
                                    id="sessions"
                                    name="sessions"
                                    value="sessions"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'sessions',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                              <li
                                className={
                                  allCheckedValues.includes('events')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  In-person{' '}
                                  <input
                                    type="checkbox"
                                    id="events"
                                    name="events"
                                    value="events"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'events',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                              <li
                                className={
                                  allCheckedValues.includes('opportunities')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Recruitment{' '}
                                  <input
                                    type="checkbox"
                                    id="opportunities"
                                    name="opportunities"
                                    value="opportunities"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'opportunities',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>

                              {/* <li className={'active'}>
                              <Form.Label>
                                Questions{' '}
                                <input
                                  type="checkbox"
                                  id="questions"
                                  name="questions"
                                  value="questions"
                                  onChange={handleClick}
                                  checked={allCheckedValues.includes(
                                    'questions',
                                  )}
                                />{' '}
                                <span />
                              </Form.Label>
                            </li> */}
                              <li
                                className={
                                  allCheckedValues.includes('snapshots')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Credentials{' '}
                                  <input
                                    type="checkbox"
                                    id="snapshots"
                                    name="snapshots"
                                    value="snapshots"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'snapshots',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>

                              <li
                                className={
                                  allCheckedValues.includes('appointment')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Scheduler{' '}
                                  <input
                                    type="checkbox"
                                    id="appointment"
                                    name="appointment"
                                    value="appointment"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'appointment',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                              <li
                                className={
                                  allCheckedValues.includes('group_chat')
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  Group Chat{' '}
                                  <input
                                    type="checkbox"
                                    id="group_chat"
                                    name="group_chat"
                                    value="group_chat"
                                    onChange={handleClick}
                                    checked={allCheckedValues.includes(
                                      'group_chat',
                                    )}
                                  />{' '}
                                  <span />
                                </Form.Label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        </Scrollbars>

                        <div className="filter-btn-wrapper bg_transparent text-center bottom-0 pt-2">
                          <Button
                            disabled={allCheckedValues.length === 0}
                            className="btn-dark"
                            type="submit"
                            onClick={() => {
                              setShowSpinner(true);
                              if (allCheckedValues.length > 1) {
                                setSubTabsType('all');
                              } else {
                                if (allCheckedValues) {
                                  setSubTabsType(allCheckedValues[0]);
                                }
                              }

                              setshowFilter(false);

                              getReferrals(subTabType, '', arrowButton, 1);
                            }}>
                            Filter
                          </Button>
                        </div>
                      </div>
                    </CustomModal>
                  )}
                  {/* {searchFilter && subTabType === 'opportunities' && (
                  <OpportunitiesSearchFilter
                    show={searchFilter}
                    opp={opp}
                    frequency={frequency}
                    onClose={() => setSearchFilterModal(false)}
                    subTabType={subTabType}
                    data={data}
                    arrowButton={arrowButton}
                    loader={() => {
                      console.log('setShowSpinner(true)');
                    }}
                    setLocationS={setLocationS}
                    locationS={locationS}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    list={list}
                    setList={setList}
                    freq={freq}
                    setFreq={setFreq}
                    virtual={virtual}
                    setVirtual={setVirtual}
                    covid={covid}
                    setCovid={setCovid}
                    amount={amount}
                    setAmount={setAmount}
                    setActive={setActive}
                    active={active}
                    setFocusLocations={setFocusLocations}
                    setIdentiferId={setIdentiferId}
                    setEthnicityId={setEthnicityId}
                    setGender={setGender}
                    gender={gender}
                    focusLocations={focusLocations}
                    ethnicityId={ethnicityId}
                    identiferId={identiferId}
                    setLongitude={setLongitude}
                    longitude={longitude}
                    setLatitude={setLatitude}
                    latitude={latitude}
                  />
                )} */}
                </>
              }
              secondary={
                <>
                  {showComponent === 'create-appointment' && (
                    <ScheduleAppointmemt
                      appointmentData={appointmentData}
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}

                  {showComponent === 'inviteuser' && (
                    <SendInvitation
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
                  {showComponent === 'edit-opportunity' && (
                    // <SlidingPanel
                    //   hidePanel={() => {
                    //     setShowComponent('opportunity');
                    //   }}>
                    //   <Scrollbars>
                    <EditOpportunities
                      onClose={() => {
                        setShowComponent('opportunity');
                      }}
                      item={opportunityData}
                    />
                    //   {/* </Scrollbars>
                    // </SlidingPanel> */}
                  )}

                  {showComponent === 'create-session' && (
                    // <SlidingPanel
                    //   hidePanel={() => {
                    //     setShowComponent('opportunity');
                    //   }}>
                    //   <Scrollbars>
                    <CreateSession
                      onClose={() => {
                        setShowComponent('opportunity');
                      }}
                      opportunitySession={opportunityData}
                      showCloseIcon={true}
                    />
                    //   </Scrollbars>
                    // </SlidingPanel>
                  )}

                  {showComponent === 'snapshot' &&
                    accomplishmentId !== undefined && (
                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setShowComponent('');
                      //   }}>
                      //   <Scrollbars>
                      <SnapshotDetail
                        onClose={() => {
                          setShowComponent('');
                        }}
                        userData={dataa}
                        snapshotId={dataa}
                        refersnapShotId={dataa}
                        multipleSnapShotId={referTypeIds}
                        showRefer={showRefer}
                        setShowRefer={setShowRefer}
                        showReferDetails={true}
                        referdDetail={userData}
                        arrowButton={arrowButton}
                        editProfile={
                          userData.created_by === localStorage.getItem('id')
                            ? localStorage.getItem('id')
                            : ''
                        }
                      />
                      //   </Scrollbars>
                      // </SlidingPanel>
                    )}

                  {showComponent === 'sendRefer' &&
                    accomplishmentId === undefined && (
                      <SendFeedBack
                        onClose={() => {
                          setShowComponent('');
                        }}
                        userData={userData}
                        arrowButton={arrowButton}
                      />
                    )}

                  {showComponent === 'session' &&
                    referSession.creator_id !== localStorage.getItem('id') && (
                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setShowComponent('');
                      //   }}>
                      <Scrollbars>
                        <SessionDetail
                          onClose={() => {
                            setShowComponent('');
                          }}
                          item={referSession}
                          type={showComponent}
                          refer={true}
                        />
                      </Scrollbars>
                      // </SlidingPanel>
                    )}

                  {showComponent === 'session' &&
                    referSession.creator_id === localStorage.getItem('id') && (
                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setShowComponent('');
                      //   }}>
                      //   <Scrollbars>
                      <CreateSession
                        onClose={() => {
                          setShowComponent('');
                        }}
                        editSession={referSession}
                        referType={true}
                      />
                      //   </Scrollbars>
                      // </SlidingPanel>
                    )}

                  {showComponent === 'event' &&
                    (eventItem.creator_id === localStorage.getItem('id') ||
                      eventItem.moderator_ids.includes(
                        localStorage.getItem('id'),
                      )) && (
                      <EventDetail
                        onClose={() => {
                          setShowComponent('');
                        }}
                        getReferrals={getReferrals}
                        arrowButton={arrowButton}
                        item={eventItem}
                        // arrowButton={arrowButton}
                        // sessionType={}
                        type={'refer'}
                      />
                    )}

                  {showComponent === 'event' &&
                    !(
                      eventItem.creator_id === localStorage.getItem('id') ||
                      eventItem.moderator_ids.includes(
                        localStorage.getItem('id'),
                      )
                    ) && (
                      <PurchaseTicket
                        onClose={() => {
                          setShowComponent('');
                        }}
                        getReferrals={getReferrals}
                        arrowButton={arrowButton}
                        item={eventItem}
                        type={'refer'}
                      />
                    )}

                  {/* || opportunityData.team_member_ids === localStorage.getItem('id') */}
                  {showComponent === 'opportunity' &&
                    (opportunityData.created_by ===
                      localStorage.getItem('id') ||
                    (opportunityData.team_member_ids &&
                      opportunityData.team_member_ids
                        .split(',')
                        .includes(localStorage.getItem('id'))) ? (
                      <OpportunityMessageList
                        item={opportunityData}
                        setOppoDetails={setOppoDetails}
                        oppoDetails={oppoDetails}
                        setAllChatUsers={setAllChatUsers}
                        setChatRoomId={setChatRoomId}
                        setChatRoomStatus={setChatRoomStatus}
                        chatRoomStatus={chatRoomStatus}
                        setChatRoomName={setChatRoomName}
                        getReferrals={getReferrals}
                        arrowButton={arrowButton}
                        onEditOppo={() => {
                          setShowComponent('edit-opportunity');
                        }}
                        onCreateSessionEvent={() => {
                          setShowComponent('create-session');
                        }}
                        onClose={() => {
                          setShowComponent('');
                        }}
                        onCloseExtra={() => {
                          setShowComponent('mine-opportunity');
                        }}
                      />
                    ) : Object.entries(opportunityData.chat_detail).length >
                      0 ? (
                      <SmartChatPage
                        allmsg={true}
                        item={[]}
                        type={'other-opportunity'}
                        chatDetails={opportunityData.chat_detail}
                        oppoDetails={opportunityData}
                        chatroomInfo={chatroomInfoOppoRefer(
                          opportunityData,
                          'opportunity',
                        )}
                        allChatUsers={collectingOppoUsers(opportunityData)}
                        chatRoomName={
                          opportunityData.chat_detail.chat_room_name
                        }
                        chatRoomId={opportunityData.chat_detail.id}
                        chatRoomStatus={newDecision}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    ) : (
                      <OpportunityDetail
                        item={opportunityData}
                        userData={userInfo}
                        setProfileImgScrollBar={setProfileImgScrollBar}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    ))}

                  {/* {showComponent === 'mine-opportunity' && (
                  <SlidingPanel
                    hidePanel={() => {
                      setShowComponent('opportunity');
                    }}>
                    <Scrollbars>
                      <SmartChatPage
                        allmsg={true}
                        type={'mine-opportunity'}
                        chatroomInfo={chatroomInfoOppoRefer(
                          oppoDetails,
                          'opportunity',
                        )}
                        chatDetails={oppoDetails.chat_detail}
                        oppoDetails={oppoDetails}
                        allChatUsers={allChatUsers}
                        chatRoomName={chatRoomName}
                        chatRoomId={chatRoomId}
                        chatRoomStatus={chatRoomStatus}
                        onClose={() => {
                          setShowComponent('opportunity');
                        }}
                      />
                    </Scrollbars>
                  </SlidingPanel>
                )} */}

                  {showComponent === 'Notes' && (
                    <ReferNotes
                      disabled={true}
                      notesF={notes}
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}

                  {showComponent === 'chat-component' && (
                    <SmartChatPage
                      allmsg={true}
                      type={chatPropType}
                      item={chatDetailItem}
                      chatroomInfo={chatroomInfo(chatDetails)}
                      oppoDetails={chatDetails}
                      chatDetails={chatDetails}
                      allChatUsers={allChatUsers}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      // chatRoomStatus={chatDetails.status}
                      onClose={() => {
                        setShowComponent('');
                      }}
                    />
                  )}
                  {showComponent === 'refer-opportunity' && (
                    <SlidingPanel
                      hidePanel={() => {
                        setShowComponent('');
                      }}>
                      <Scrollbars>
                        <ReferOpportunity
                          userData={allData}
                          setAllData={setAllData}
                          // Temporary states for remove warnings
                          setFreq={setFreq}
                          setVirtual={setVirtual}
                          setCovid={setCovid}
                          setAmount={setAmount}
                          setLocationS={setLocationS}
                          setAppointmentId={setAppointmentId}
                          setCategoriesId={setCategoriesId}
                          setIsCheck={setIsCheck}
                          onClose={() => {
                            setShowComponent('');
                          }}
                          type={'question'}
                        />
                      </Scrollbars>
                    </SlidingPanel>
                  )}
                  {/* {showComponent === 'show-qa-chat' && Object.entries(qaItem) && (
                  <SlidingPanel
                    hidePanel={() => {
                      setShowComponent('');
                    }}>
                    <Scrollbars>
                      <CommonChatPage
                        type={'qa-chats'}
                        item={allData}
                        oppoDetails={qaItem}
                        allChatUsers={collectingUsersQa(qaItem)}
                        chatDetails={qaItem}
                        chatRoomName={qaItem.chat_room_name}
                        // chatRoomId={qaItem.chat_id}
                        chatRoomId={qaItem.id}
                        chatRoomStatus={qaItem.chat_room_status}
                        onClose={() => {
                          setShowComponent('');
                        }}
                      />
                    </Scrollbars>
                  </SlidingPanel>
                )} */}
                  {showComponent === 'show-qa-chat' &&
                    Object.entries(qaItem) && (
                      // <SlidingPanel
                      //   hidePanel={() => {
                      //     setProperComponent('');
                      //   }}>
                      <SmartChatPage
                        allmsg={true}
                        type={'qa-chats'}
                        item={allData}
                        chatroomInfo={chatroomInfo(qaItem)}
                        oppoDetails={qaItem}
                        allChatUsers={collectingUsers(qaItem)}
                        chatDetails={qaItem}
                        chatRoomName={qaItem.chat_room_name}
                        // chatRoomId={qaItem.chat_id}
                        chatRoomId={qaItem.id}
                        chatRoomStatus={qaItem.chat_room_status}
                        setQaItem={setQaItem}
                        onClose={() => {
                          setShowComponent('');
                        }}
                        // handleCommonChat={(data, type) => {
                        //   handleCommonChat(data, type);
                        // }}
                      />
                      // </SlidingPanel>
                    )}
                  {work === true && (
                    // <SlidingPanel hidePanel={() => setWork(false)}>
                    <ReferProfile
                      onClose={() => {
                        setWork(false);
                      }}
                      // userData={userInfo}
                      type={'selectRefer'}
                      // setUserInfo={setUserInfo}
                    />
                    // </SlidingPanel>
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralPage;
