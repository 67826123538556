import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import React, {useState, useEffect} from 'react';
import Sidebar from '../../Pages/Sidebar';
import Pannels from '../Pannels';
import {Button} from 'react-bootstrap';
import ROITemplate from './ROITemplate';
import Settings from '../../Pages/Settings';
import Preferences from '../../Pages/Front/Preferences';
import PropertyFietdsType from '../Property/PropertyFietdsType';

const HomeTemplate = props => {
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [closeCompoennts, setCloseCompoennts] = useState(false);
  



  function checkIfUserIsOwner() {
    // Get the data from local storage
    const userCompanyData = JSON.parse(localStorage.getItem('activeWorkspaceItem'));
    
    // Get the user ID from local storage
    const currentUserId = localStorage.getItem('id');
    
    // Check if the data exists and compare the 'created_by' field with the 'id'
    if (userCompanyData && userCompanyData[0].created_by === currentUserId) {
      
      return true;
    }
  
    return false;
  }

  const [tabType, setTabType] = useState(
    window.innerWidth <= 650 ? '' :checkIfUserIsOwner() ?  'roi-template' : 'general'
  );
  useEffect(() => {
    if (tabType !== '') {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [tabType]);

  return (
    <div
      className="page-wrapper"
      style={{
        overflow: 'hidden',
      }}>
      <Sidebar
        location={props.location}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        showComponent={showComponent}
      />

      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper">
          <div className="roi-template-settings">
            <Pannels
              isFloated={false}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="mini-sidebar">
                    <i className="icon faUserCog" />
                    <h1 className="mb-2">
                      {' '}
                      <span className="icon-setting_preferences" /> Settings
                    </h1>

                    <div className="event-tabs tabs-style-2 mini-sidebar-tab mb-0">
                      {' '}
                      <ul>
                        {checkIfUserIsOwner() && 
                        <li>
                          <Button
                            className={
                              tabType === 'roi-template' ? 'active' : ''
                            }
                            onClick={() => {
                              setTabType('roi-template');
                              setCloseCompoennts(!closeCompoennts)
                            }}>
                            Templates
                          </Button>
                        </li>}
                        <li>
                          <Button
                            className={tabType === 'general' ? 'active' : ''}
                            onClick={() => {
                              setTabType('general');
                             
                            }}>
                            General
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'links' ? 'active' : ''}
                            onClick={() => {
                              setTabType('links');
                            }}>
                            Integrations
                          </Button>
                        </li>

                        <li>
                          <Button
                            className={tabType === 'languages' ? 'active' : ''}
                            onClick={() => {
                              setTabType('languages');
                            }}>
                            Languages
                          </Button>
                        </li>
                        {/* <li>
                          <Button
                            className={tabType === 'property' ? 'active' : ''}
                            onClick={() => {
                              setTabType('property');
                            }}>
                            Property
                          </Button>
                        </li> */}
                        <li>
                          <Button
                            className={
                              tabType === 'preferences' ? 'active' : ''
                            }
                            onClick={() => {
                              setTabType('preferences');
                            }}>
                            Preferences
                          </Button>
                        </li>
                        {/* 
                      <li>
                        <Button
                          className={tabType === 'payments' ? 'active' : ''}
                          onClick={() => {
                            setTabType('payments');
                          }}>
                          Payments
                        </Button>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                </>
              }
              secondary={
                <>
                  {' '}
                  <div className="setting-subpages">
                    {tabType === 'roi-template' && (
                      <ROITemplate workspaceData={workspaceData}
                      againClickOnTemplate={closeCompoennts} />
                    )}
                    {tabType === 'general' && (
                      <Settings tabinformation={'general'} />
                    )}
                    {tabType === 'languages' && (
                      <Settings tabinformation={'languages'} />
                    )}
                    {tabType === 'links' && (
                      <Settings tabinformation={'links'} />
                    )}
                    {tabType === 'preferences' && (
                      <Preferences hidethings={true} />
                    )}
                    {tabType === 'property' && (
                      <PropertyFietdsType hidethings={true} />
                    )}
                  </div>
                </>
              }
            />
          </div>

          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeTemplate;
