import React from 'react';
import {Form} from 'react-bootstrap';

const Input = ({
  label = 'Input Label',
  name,
  value = '',
  onChange,
  placeholder = '',
  pattern = '^\\S.*$',
  feedback = 'This field is required.',
  required = true,
  type = 'text'
}) => {
  return (
    <div className="fieldset">
      {label !== '' &&
      <Form.Label controlid="validationCustom01"> {label}</Form.Label>
}

      <Form.Control
        id={name}
        type={type}
        name={name}
        className="convo-ticket-field"
        value={value}
        pattern={pattern}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
      />
      <Form.Control.Feedback type="invalid">
        {feedback}
      </Form.Control.Feedback>
    </div>
  );
};

export default Input;
