import React, {useState, useEffect, useRef} from 'react';import { CustomModal, CustomTabs, SlidingPanel } from '../../Components';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CustomDropdown from '../../Components/CustomDropdown';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GeneralProperty = props => {

   const {validated, properties, setProperties, buttonRef, handle} = props

  const dropdownValues = [
    {title: 'Residential', id: 'Residential'},
    {title: 'Commercial', id: 'Commercial'},
    {title: 'Government', id: 'Government'}
];  

useEffect(()=>{
    setProperties((prevState) => ({
        ...prevState,
        type: 'Residential', 
      }))
},[])

  return (
    <>
                      <>
            <div className="messages-users-list">
                <div className="create-ticket-form create-action-ticket-form">
                  <Form noValidate validated={validated} onSubmit={handle}>
                        <div className="fieldset">

                        <Form.Label style={{width: 'auto'}}>
                              Type
                        </Form.Label>

                        <CustomDropdown
                            setDropdownId={(value) => 
                            setProperties((prevState) => ({
                                ...prevState,
                                type: value, 
                              }))}
                            dropdownId={properties.type}
                            DropDownValues={dropdownValues}
                            itemName={['title']}
                        />
                            
                        </div>

                        <div className="fieldset">
                        <Form.Label controlid="validationCustom01">
                            Name
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name="propertyName"
                            value={properties.propertyName}
                            required
                            placeholder="Enter Property Name"
                            onChange={e => {
                                setProperties((prevState) => ({
                                    ...prevState,
                                    propertyName: e.target.value, 
                                  }))
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Property name is required.
                          </Form.Control.Feedback>
                          </div>
                           
                        <div className="fieldset">
                            <Form.Label style={{width: 'auto'}}>
                              Location
                            </Form.Label>

                            <div className='location-field form-control form-control-with-icon create_session'>
                            <GooglePlacesAutocomplete
                            location
                            selectProps={{
                            value: properties.location,
                            onChange: (value) => {
                                setProperties((prevState) => ({
                                 ...prevState,
                                location: value, 
                             }));
                            },                              
                            name: 'city',
                            placeholder: 'Enter Location',
                            noOptionsMessage: () => null,
                            }}
                            />
                            </div>
                        </div>

                        <Form.Group className="fieldset">
                          <Row>
                            <Col md={6}>
                              <div className="fieldset  mb-0">
                                <Form.Label controlid="validationCustom01">
                                  SQFT
                                </Form.Label>
                                <Form.Control
                                name="SQFT"
                                value={properties.SQFT}
                                required
                                placeholder="Enter SQFT"
                                onChange={e => {
                                    setProperties((prevState) => ({
                                        ...prevState,
                                        SQFT: e.target.value, 
                                      }))
                                }}
                                />
                                <Form.Control.Feedback type="invalid">
                                 SQFT is required
                                </Form.Control.Feedback>
                            
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="fieldset mb-0">
                                <Form.Label controlid="validationCustom01">
                                    Floors
                                </Form.Label>
                                <Form.Control
                                type='number'
                                name="floors"
                                value={properties.floors}
                                required
                                placeholder="# of Floors "
                                onChange={e => {
                                    setProperties((prevState) => ({
                                        ...prevState,
                                        floors: e.target.value, 
                                      }))
                                }}
                            />
                                <Form.Control.Feedback type="invalid">
                                  Floor is required.
                                </Form.Control.Feedback>
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>

                        <Button
                      type="submit"
                      style={{display: 'none'}}
                      ref={buttonRef}
                      >
                      </Button>

                        </Form>
                        </div>
                        </div>

                      </>
                    
                
    </>
  );
};
export default GeneralProperty;
