import React, {useEffect, useState} from 'react';

import {Button, Col, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import FocusModel from './FocusModel';
import {useDispatch} from 'react-redux';
import {getOpportunityListing} from '../../Store/Actions/OpportunityAction';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import $ from 'jquery';
import CustomModal from '../CustomModal';

const OpportunitiesSearchFilter = props => {
  // const [show, setShow] = useState(props.show);
  const [searchFilter, setSearchFilter] = useState(false);
  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };

  const [opportunity, setOpportunity] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = event => {
    // props.loader();
    closeModal();

    let place_id =
      typeof props.locationS === 'object'
        ? props.locationS.value.place_id
        : typeof props.locationS === 'string'
        ? ''
        : '';
    if (props.shareableFilter) {
      if (place_id) {
        geocodeByPlaceId(place_id)
          .then(results => getLatLng(results[0]))
          .then(({lat, lng}) => {
            addObjectFilter(lat, lng);
          });
      } else {
        addObjectFilter();
      }
    } else {
      if (props.subTabType === 'opportunities') {
        if (place_id) {
          geocodeByPlaceId(place_id)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
              getReferrals(lat, lng);
            });
        } else {
          getReferrals();
        }
      } else {
        if (place_id) {
          geocodeByPlaceId(place_id)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
              getOpportunityData(lat, lng);
            });
        } else {
          getOpportunityData();
        }
      }
    }
  };

  const onClose = () => {
    // setShow(false);
    props.onClose();
  };

  const addObjectFilter = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    props.setOpportunityFilter({
      // ally: props.active ? 'yes' : 'no',

      // // focus:
      // on_boarding_areas: props.persnalTypesId,
      // appointment_types: props.appointmentId,
      // on_boarding_categories: props.categoriesId,
      // // gender: props.gender.toString(),
      // gender: '',
      // // otherIdentifires: props.identiferId.toString(),
      // otherIdentifires: '',
      // // ethnicities: props.ethnicityId.toString(),
      // ethnicities: '',
      // city_states: props.focusLocations,

      amount: props.amount,
      frequency: props.freq,
      latitude:
        typeof props.locationS === 'object'
          ? lat
          : typeof props.locationS === 'string'
          ? props.latitude
          : '',
      like: false,
      location:
        typeof props.locationS === 'object'
          ? props.locationS.label
          : typeof props.locationS === 'string'
          ? props.locationS
          : '',
      longitude:
        typeof props.locationS === 'object'
          ? lng
          : typeof props.locationS === 'string'
          ? props.longitude
          : '',
      opportunity_types: props.isCheck,
      page: 1,
      // paid_status: '',
      // radius: 100,
      // search_query: props.searchQry,
      // status: props.opportunityType,
      vaccinated: props.covid ? 'yes' : 'no',
      virtual: props.virtual ? 'yes' : 'no',
    });
  };

  const getOpportunityData = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    var initData = {
      ally: props.active ? 'yes' : 'no',

      // focus:
      on_boarding_areas: props.persnalTypesId,
      appointment_types: props.appointmentId,
      on_boarding_categories: props.categoriesId,
      // gender: props.gender.toString(),
      gender: '',
      // otherIdentifires: props.identiferId.toString(),
      otherIdentifires: '',
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states: props.focusLocations,

      amount: props.amount,
      frequency: props.freq,
      latitude:
        typeof props.locationS === 'object'
          ? lat
          : typeof props.locationS === 'string'
          ? props.latitude
          : '',
      like: false,
      location:
        typeof props.locationS === 'object'
          ? props.locationS.label
          : typeof props.locationS === 'string'
          ? props.locationS
          : '',
      longitude:
        typeof props.locationS === 'object'
          ? lng
          : typeof props.locationS === 'string'
          ? props.longitude
          : '',
      opportunity_types: props.isCheck,
      page: 1,
      paid_status: '',
      radius: 100,
      search_query: props.searchQry,
      status: props.opportunityType,
      vaccinated: props.covid ? 'yes' : 'no',
      virtual: props.virtual ? 'yes' : 'no',
    };
    dispatch(getOpportunityListing(initData));
    // commonApi
    //   .get_opportunity(initData)
    //   .then((res) => {
    //     //
    //     if (res.status === 200) {
    //       setOpportunities(res);

    //       props.fun(res);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Err", err);
    //     return {type: "error", message: err.message};
    //   });
  };
  // const categories = [
  //     "Select All",
  //     "Bartering",
  //     "Educational Training",
  //     "Employment",
  //     "Seeking Funds",
  //     "Interns and Internships",
  //     "Investments",
  //     "Partnership",
  // ];

  const getReferrals = (lat, lng) => {
    props.setLatitude(lat);
    props.setLongitude(lng);
    var virt = props.virtual ? 'yes' : 'no';
    var cov = props.covid ? 'yes' : 'no';

    var payload = {
      builder_filter_data: {
        allies: '',
        appointmentTypes: '',
        backgrounds: '',
        characteristics: [],
        homeLatitude: '',
        homeLongitude: '',
        homeTown: '',
        travelLatitude: '',
        travelLocation: '',
        travelLongitude: '',
      },
      category_ids: '',
      opportunity_filter_data: {
        address:
          typeof props.locationS === 'object'
            ? props.locationS.label
            : typeof props.locationS === 'string'
            ? props.locationS
            : '',
        allies: '',
        amount: props.amount,
        frequencyIds: props.freq,
        latitude:
          typeof props.locationS === 'object'
            ? lat
            : typeof props.locationS === 'string'
            ? props.latitude
            : '',
        longitude:
          typeof props.locationS === 'object'
            ? lng
            : typeof props.locationS === 'string'
            ? props.longitude
            : '',
        opportunityIds: props.isCheck,
        vaccinated: cov,
        virtual: virt,
      },
      page: 1,
      referred_by_me: props.arrowButton,
      search_query: '',
      type: props.subTabType,
    };
    commonApi

      .get_referrals(payload)
      .then(res => {
        if (res.status === 200) {
          props.data(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const myFunc = () => {
    props.setList(props.opp);
  };

  useEffect(() => {
    // setOpportunities();
    myFunc();
    if (!props.oppFilter) {
      props.setIsCheck(props.opp.map(item => item.id));
      props.setIsCheckAll(true);
      props.setOppFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(props.list.map(item => item.id));
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };

  const frequencyIdvalue = e => {
    // alert("123")
    const {value, checked} = e.target;

    if (!checked) {
      props.setFreq(props.freq.filter(item => item !== value));
    } else {
      props.setFreq([...props.freq, value]);
    }
  };

  const handleClick = e => {
    const {id, checked} = e.target;

    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
      setOpportunity(opportunity.filter(item => item !== id));
    } else {
      setOpportunity([...opportunity, id]);
      props.setIsCheck([...props.isCheck, id]);
    }
  };

  useEffect(() => {
    if (props.list.length > 0) {
      if (props.isCheck.length === props.list.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);

  let jugar =
    typeof props.locationS === 'object'
      ? props.locationS.label
      : typeof props.locationS === 'string' && props.locationS !== ''
      ? props.locationS
      : 'Type city and state';

  const clearAll = () => {
    props.setLocationS('');
    props.setLatitude('');
    props.setLongitude('');

    $('.css-1uccc91-singleValue').html('Type city and state');
  };

  return (
    <>
      <CustomModal
        className={'sessioneventFilter'}
        style={{height: '100%'}}
        onClose={() => {
          onClose();
        }}>
        <div className="serach-filter-wrapper recruitment_filter">
          <div
            className="sidebar-heading-wrapper mt-0 text-center"
            style={{display: 'inline', paddingRight: '11px'}}>
            {/* <div className="ticket-icons-options">
              <ul >
                <li className={props.active ? 'active' : ''}  onClick={() => {
                    setSearchFilter(true);
                  }}>
                <i
                  className="icon-bullseye"
                 
                />
                </li>
              </ul>
              </div> */}
            {/* <span className={props.active ? 'active' : 'target-icon'}>
              <i
                className="icon-bullseye"
                onClick={() => {
                  setSearchFilter(true);
                }}
              />
            </span> */}

            <div className="filter-headings ">
              <h2 className="text-center">Recruitment Types</h2>
            </div>
          </div>
          <Scrollbars autoHide>
            <div className="" style={{paddingBottom: '50px'}}>
              <div className="filter-category-list">
                <Form>
                  <ul>
                    <li className={props.isCheckAll ? 'active' : ''}>
                      <Form.Label>
                        All{' '}
                        <input
                          type="checkbox"
                          id="ALL"
                          name="ALL"
                          value="ALL"
                          onChange={handleSelectAll}
                          checked={props.isCheckAll}
                        />{' '}
                        <span />
                      </Form.Label>
                    </li>

                    {Object.entries(props.opp).length > 0 ? (
                      Object.entries(props.opp).length > 0 &&
                      props.opp.map((item, index) => (
                        <li
                          key={`opp-${index}`}
                          className={
                            props.isCheck.includes(item.id) ? 'active' : ''
                          }>
                          <Form.Label>
                            {item.type_name}
                            <input
                              type="checkbox"
                              id={item.id}
                              value={item.id}
                              onChange={handleClick}
                              checked={props.isCheck.includes(item.id)}
                            />{' '}
                            <span />
                          </Form.Label>
                        </li>
                      ))
                    ) : (
                      <div>{labels.no_record_found[selected_lang]}</div>
                    )}
                  </ul>

                  <div className="location-box">
                    <div className="location-heading">
                      <h2>
                        Location <i className="fas fa-location-arrow" />
                      </h2>
                      <div className="text-right d-flex align-items-center">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Virtual Only"
                          value={props.virtual ? 'yes' : 'no'}
                          onClick={() => props.setVirtual(!props.virtual)}
                          defaultChecked={props.virtual ? true : false}
                        />

                        {props.locationS !== '' && (
                          <span
                            className="clear-btn"
                            onClick={() => {
                              clearAll();
                            }}>
                            Clear
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <Form.Control 
                                name="city" 
                                placeholder="Type city and state" 
                                onChange={event => setLocation(event.target.value)}></Form.Control>  */}

                    <div className="location-field form-control">
                      <GooglePlacesAutocomplete
                        selectProps={{
                          // location,
                          onChange: props.setLocationS,
                          name: 'city',
                          placeholder: jugar,
                          noOptionsMessage: () => null,
                        }}
                      />
                    </div>
                    {/* <GooglePlacesAutocomplete
                                GooglePlacesDetailsQuery={{ fields: "geometry" }}
                                fetchDetails={true} // you need this to fetch the details object onPress
                                placeholder="Search"
                                query={{
                                key: 'AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s',
                                language: "en", // language of the results
                                }}
                                onPress={(data: any, details: any ) => {
                                
                                
                                
                                }}
                                onFail={(error) => console.error(error)} />
                                                     */}
                  </div>

                  <div className="frequency-box oppertunity-frequency-box">
                    <h2>Frequency</h2>

                    <Row className="frequency-row-box flex-column">
                      {Object.entries(props.frequency).length > 0 &&
                        props.frequency.map((item, index) => (
                          <Col
                            md={12}
                            sm={12}
                            xs={12}
                            key={`frequency-${index}`}>
                            <Form.Group className="fieldset">
                              <Form.Check
                                type="switch"
                                name="frequency"
                                label={item.title}
                                value={item.id}
                                // value={props.freq}
                                onChange={frequencyIdvalue}
                                checked={props.freq.includes(item.id)}
                                // disabled={
                                //   opportunity.includes('2') ? false : true
                                // }
                                disabled={
                                  props.isCheck.includes('2') ||
                                  props.isCheck.includes('5')
                                    ? false
                                    : true
                                }
                              />
                            </Form.Group>
                          </Col>
                        ))}
                    </Row>
                  </div>

                  <div className="frequency-box">
                    <h2>Amount</h2>

                    <Form.Group className="search-input">
                      <Form.Control
                        type="number"
                        value={props.amount}
                        placeholder="Enter the amount"
                        onChange={e => props.setAmount(e.target.value)}
                      />
                    </Form.Group>
                  </div>

                  <div className="fieldset">
                    <Form.Check
                      type="switch"
                      id="vaccination"
                      label="COVID-19 Vaccination Required"
                      value={props.covid ? 'yes' : 'no'}
                      onClick={() => props.setCovid(!props.covid)}
                      defaultChecked={props.covid ? true : false}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </Scrollbars>
          <div className="bg_transparent text-center bottom-0 py-2">
            <Button
              className="btn-dark"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}>
              Filter
            </Button>
          </div>
        </div>

        {/* { props.fun.length > 0 && (
                <OpportunitiesList
                 
                fun = {props.fun}/>
                  
            )} */}
      </CustomModal>

      {searchFilter && (
        <FocusModel
          show={searchFilter}
          setActive={props.setActive}
          active={props.active}
          setFocusLocations={props.setFocusLocations}
          setIdentiferId={props.setIdentiferId}
          setEthnicityId={props.setEthnicityId}
          setGender={props.setGender}
          setPersnalTypesId={props.setPersnalTypesId}
          setCategoriesId={props.setCategoriesId}
          setAppointmentId={props.setAppointmentId}
          onClose={() => {
            setSearchFilter(false);
          }}
        />
      )}
    </>
  );
};

export default OpportunitiesSearchFilter;
