import React, {useEffect, useState} from 'react';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {
  changeFileVariable,
  fixName,
  milestoneButtons,
} from '../../Utils/commonUtils';
import Input from '../Input/Input';
import {Dropdown, Form} from 'react-bootstrap';
import MilestoneListing from './MilestoneListing';
import {errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import Upload from './Upload';
import ViewFile from '../UplioadFile/ViewFile';

const CreateProjectMilestones = ({
  name,
  filterType,
  setFilterType,
  setFilterValue,
  filterValue,
}) => {
  const [milestones, setMilestones] = useState([]);
  const [independentDeliverables, setIndependentDeliverables] = useState([]);
  const [independentTasks, setIndependentTasks] = useState([]); // For tasks not linked to milestones/deliverables
  const [taskList, setTaskList] = useState([]); // For tasks not linked to milestones/deliverables
  const [threeDots, setThreeDots] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [uploadFilesListing, setUploadFilesListing] = useState([]);
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [editProject, setEditProject] = useState(false);
  const [isEditing, setIsEditing] = useState({
    editing: false,
    item: {},
  });
  const [searchListing, setSearchListing] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date;
  });

  const [type, setType] = useState('none');
  const [deliverableType, setDeliverableType] = useState('none');

  const handleClickButtons = e => {
    const {id, value} = e.target;
    setFilterType(id);
    setFilterValue([value]);
  };

  const handleChange = (e, type) => {
    if (type === 'search') {
      setSearchListing(e.target.value);
    } else {
      setTitle(e.target.value);
    }
  };

  const handleListing = taskitem => {
    const trimmedTitle = title.trim();
    if (trimmedTitle === '') {
      errorMessage(`${name} title cannot be empty`);
      return;
    }

    if (name === 'Milestones') {
      if (isEditing?.editing) {
        const updatedMilestones = milestones.map(milestone =>
          milestone.id === isEditing.item.id
            ? {...milestone, name: trimmedTitle}
            : milestone,
        );
        setMilestones(updatedMilestones);
      } else {
        const milestoneExists = milestones.some(m => m.name === trimmedTitle);
        if (!milestoneExists) {
          setMilestones([
            ...milestones,
            {
              name: trimmedTitle,
              id: trimmedTitle,
              deliverables: [], // Each milestone has its own deliverables
              tasks: [], // Tasks directly against the milestone
            },
          ]);
          setTitle('');
        } else {
          errorMessage('Milestone title already exists');
        }
      }
    } else if (name === 'Deliverables') {
      const newDeliverable = {
        name: trimmedTitle,
        id: trimmedTitle,
        tasks: [], // Tasks directly against the deliverable
      };

      if (isEditing?.editing) {
        if (type !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  deliverables: milestone.deliverables.map(deliverable =>
                    deliverable.id === isEditing?.item.id
                      ? {...deliverable, name: trimmedTitle}
                      : deliverable,
                  ),
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else {
          const updatedDeliverables = independentDeliverables.map(deliverable =>
            deliverable.id === isEditing?.item.id
              ? {...deliverable, name: trimmedTitle}
              : deliverable,
          );
          setIndependentDeliverables(updatedDeliverables);
        }
      } else {
        if (type !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  deliverables: [...milestone.deliverables, newDeliverable],
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else {
          setIndependentDeliverables([
            ...independentDeliverables,
            newDeliverable,
          ]);
        }

        setTitle('');
      }
    } else if (name === 'Tasks') {
      const newTask = {
        name: trimmedTitle,
        id: trimmedTitle,
      };

      if (isEditing?.editing) {
        if (type !== 'none' && deliverableType !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  deliverables: milestone.deliverables.map(deliverable =>
                    deliverable.id === deliverableType
                      ? {
                          ...deliverable,
                          tasks: deliverable.tasks.map(task =>
                            task.id === isEditing?.item.id
                              ? {...task, name: trimmedTitle}
                              : task,
                          ),
                        }
                      : deliverable,
                  ),
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else if (type !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  tasks: milestone.tasks.map(task =>
                    task.id === isEditing?.item.id
                      ? {...task, name: trimmedTitle}
                      : task,
                  ),
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else if (deliverableType !== 'none') {
          const updatedDeliverables = independentDeliverables.map(deliverable =>
            deliverable.id === deliverableType
              ? {
                  ...deliverable,
                  tasks: deliverable.tasks.map(task =>
                    task.id === isEditing?.item.id
                      ? {...task, name: trimmedTitle}
                      : task,
                  ),
                }
              : deliverable,
          );
          setIndependentDeliverables(updatedDeliverables);
        } else {
          const updatedTasks = independentTasks.map(task =>
            task.id === isEditing?.item.id
              ? {...task, name: trimmedTitle}
              : task,
          );
          setIndependentTasks(updatedTasks);
        }
      } else {
        if (type !== 'none' && deliverableType !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  deliverables: milestone.deliverables.map(deliverable =>
                    deliverable.id === deliverableType
                      ? {
                          ...deliverable,
                          tasks: [...deliverable.tasks, newTask],
                        }
                      : deliverable,
                  ),
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else if (type !== 'none') {
          const updatedMilestones = milestones.map(milestone =>
            milestone.id === type
              ? {
                  ...milestone,
                  tasks: [...milestone.tasks, newTask],
                }
              : milestone,
          );
          setMilestones(updatedMilestones);
        } else if (deliverableType !== 'none') {
          const updatedDeliverables = independentDeliverables.map(deliverable =>
            deliverable.id === deliverableType
              ? {
                  ...deliverable,
                  tasks: [...deliverable.tasks, newTask],
                }
              : deliverable,
          );
          setIndependentDeliverables(updatedDeliverables);
        } else {
          setIndependentTasks([...independentTasks, newTask]);
        }
      }
    }

    setIsEditing({
      editing: false,
      item: {},
    });
    setTitle('');
  };

  useEffect(() => {
    if (name === 'Tasks') {
      get_actions_for_project_managemnet(title);
    }
  }, [title]);

  const get_actions_for_project_managemnet = value => {
    const payload = {search_query: value};
    commonApi
      .get_actions_for_project_managemnet(payload)
      .then(res => {
        if (res.status === 200) {
          if (!value || value === '') {
            setTaskList([]);
          } else {
            setTaskList(res.all_actions);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (milestones?.length > 0) {
      setType(milestones[0].id);
    } else {
      setType('none');
    }
  }, [name, milestones]);

  useEffect(() => {
    if (milestones?.length > 0) {
      const firstDeliverables =
        type !== 'none'
          ? milestones[0]?.deliverables || []
          : independentDeliverables || [];
      console.log(
        firstDeliverables,
        'firstDeliverablesfirstDeliverablesfirstDeliverables',
      );
      if (firstDeliverables.length > 0) {
        setDeliverableType(firstDeliverables[0].id);
      } else {
        setDeliverableType('none');
      }
    } else {
      setDeliverableType('none');
    }
  }, [type, name]);

  console.log(milestones, 'milestonesmilestonesmilestonesmilestones');
  console.log(type, 'typetypetypetype');
  console.log(
    independentDeliverables,
    'independentDeliverablesindependentDeliverablesindependentDeliverables',
  );
  console.log(
    independentTasks,
    'independentTasksindependentTasksindependentTasksindependentTasks',
  );
  const decideDeliverableListing =
    type !== 'none'
      ? milestones?.find(milestone => milestone.id === type)?.deliverables || []
      : independentDeliverables || [];

  const decideTasksListing =
    type !== 'none' && deliverableType !== 'none'
      ? milestones
          ?.find(m => m.id === type)
          ?.deliverables?.find(d => d.id === deliverableType)?.tasks || []
      : type !== 'none'
      ? milestones?.find(m => m.id === type)?.tasks || []
      : deliverableType !== 'none'
      ? independentDeliverables?.find(d => d.id === deliverableType)?.tasks ||
        []
      : independentTasks || [];

  console.log(
    decideDeliverableListing,
    'decideDeliverableListingdecideDeliverableListingdecideDeliverableListing',
  );

  console.log(
    deliverableType,
    'deliverableTypedeliverableTypedeliverableTypedeliverableType',
  );

  console.log(
    decideTasksListing,
    'decideTasksListingdecideTasksListingdecideTasksListing',
  );

  const selectedMilestone = milestones.find(
    item => item.id.toString() === type,
  );
  const deliverables =
    type !== 'none'
      ? selectedMilestone?.deliverables || []
      : independentDeliverables || [];

  const getDeliverableName = () => {
    if (type !== 'none') {
      const deliverable = deliverables.find(
        d => d.id.toString() === deliverableType,
      );
      return deliverable
        ? fixName(deliverable.name || '')
        : 'No deliverable selected';
    } else if (independentDeliverables.length > 0) {
      const deliverable = independentDeliverables.find(
        item => item.id.toString() === deliverableType,
      );
      return deliverable
        ? fixName(deliverable.name)
        : 'No deliverable selected';
    }
    return 'No deliverable selected';
  };

  useEffect(() => {
    setSearchListing('');
  }, [name]);

  const handleFiles = (item, type) => {
    if (type === 'delete') {
      // setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      // setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  return (
    <>
      <div className="flex justify-content-between p-1 input-plus-icon-holder">
        <h4>{name}</h4>

        <div className="search-tabs-icons">
        <span
          onClick={() => {
            setThreeDots(!threeDots);
          }}
          className={threeDots ? 'icon active as-link' : 'icon as-link'}>
          <i className="fas fa-ellipsis-vertical" />
        </span>
        </div>
      </div>

      {threeDots && (
        <>
          {(name === 'Deliverables' || name === 'Tasks') && (
            <div className="m-3">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type !== 'none' && milestones?.length > 0
                      ? fixName(
                          milestones?.find(item => item.id.toString() === type)
                            ?.name || '',
                        )
                      : 'No milestone selected'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {milestones && milestones?.length > 0 ? (
                      milestones?.map((item, index) => (
                        <>
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.id.toString()}
                            active={item.id === type}>
                            {item.name
                              ? fixName(item.name)
                              : 'No milestone selected'}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="none"
                            active={type === 'none'}>
                            No milestone selected
                          </Dropdown.Item>
                        </>
                      ))
                    ) : (
                      <Dropdown.Item eventKey="none" active={type === 'none'}>
                        No milestone selected
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          {name === 'Tasks' && (
            <div className="m-3">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={eventKey => setDeliverableType(eventKey)}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {getDeliverableName()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {deliverables.length > 0 ? (
                      deliverables.map((item, index) => (
                        <>
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.id.toString()}
                            active={item.id.toString() === deliverableType}>
                            {fixName(item.name)}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="none"
                            active={deliverableType === 'none'}>
                            No milestone selected
                          </Dropdown.Item>
                        </>
                      ))
                    ) : (
                      <Dropdown.Item eventKey="none" active={type === 'none'}>
                        No milestone selected
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          <div
            className="category-btns-wrapper category-btns-wrapper-slider"
            style={{marginTop: '8px'}}>
            <CustomSlidingButtons
              items={milestoneButtons}
              activeButtons={filterType}
              onItemClick={e => {
                handleClickButtons(e);
              }}
              showItems={3}
              label={'name'}
              value={filterValue}
            />
          </div>
        </>
      )}

      <Input
        label=""
        name={'searchListing'}
        value={searchListing}
        onChange={e => handleChange(e, 'search')}
        placeholder={`Search for ${name}`}
        required={false}
        type={'text'}
      />

      <div
        className="billing-detail"
        style={{backgroundColor: 'rgb(237, 235, 229)'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h1
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            className="pt-2 pb-2 as-link">
            Add {name}
          </h1>
          {name === 'Tasks' && (
            <span
              className={'icon search-tabs-icons as-link'}
              style={{marginRight: '5px', color: '#52bd41'}}>
              Use full form
            </span>
          )}
          {/* <span
            className={'icon search-tabs-icons as-link'}
            style={{marginRight: '5px'}}>
            <i
              className="icon-plus"
              onClick={() => {
                handleListing();
              }}
            />
          </span> */}
        </div>
        <div
          className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
            isExpanded ? 'max-h-screen' : 'max-h-0'
          }`}>
          <hr class="border-t border-gray-300 pb-3"></hr>
          <div className="roi-form-input">
            <Form.Control
              label=""
              name={'title'}
              value={title}
              onChange={e => handleChange(e, 'title')}
              placeholder={`${name} title`}
              required={false}
              type={'text'}
            />

            <div className="flex justify-center items-center mt-3">
              <button
              style={{backgroundColor: '#52bd41'}}
                type="button"
                onClick={handleListing}
                className="text-white px-4 py-2 rounded-md">
                Add
              </button>
            </div>

            {/* {title && name === 'Tasks' && (
              <ul className="custom-suggestions invoices_suggestion">
                {taskList?.length > 0 ? (
                  taskList?.map(suggestion => (
                    <li
                      className="as-link"
                      key={'suggestion' + suggestion.id}
                      onClick={() => {
                        handleListing(suggestion);
                      }}>
                      {suggestion?.title}
                    </li>
                  ))
                ) : (
                  <li className="as-link">No tasks available.</li>
                )}
              </ul>
            )} */}
          </div>
        </div>
      </div>
      {name === 'Milestones' && milestones && milestones.length > 0 && (
        <MilestoneListing
          listing={milestones}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          setIsEditing={setIsEditing}
          setTitle={setTitle}
          searchListing={searchListing}
        />
      )}

      {name === 'Deliverables' &&
        decideDeliverableListing &&
        decideDeliverableListing.length > 0 && (
          <MilestoneListing
            listing={decideDeliverableListing}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setIsEditing={setIsEditing}
            setTitle={setTitle}
            searchListing={searchListing}
          />
        )}

      {name === 'Tasks' &&
        decideTasksListing &&
        decideTasksListing.length > 0 && (
          <MilestoneListing
            listing={decideTasksListing}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setIsEditing={setIsEditing}
            setTitle={setTitle}
            type="task"
            searchListing={searchListing}
          />
        )}

      {name === 'Milestones' && (
        <div className="bg-white rounded-md shadow-md mb-2 mt-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => setShowAttachments(!showAttachments)}>
            <h2 className="text-base font-semibold">Attachments</h2>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showAttachments ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div
              onClick={() => {
                setEditProject(true);
              }}
              className="p-4  pt-0 space-y-2 height-input">
              <Upload
                uploadFilesListing={uploadFilesListing}
                setUploadFilesListing={setUploadFilesListing}
                handleFiles={handleFiles}
                setShowFileComponent={setShowFileComponent}
                type={'dynamic_attachment'}
              />
            </div>
          </div>
        </div>
      )}
      {/* 
      {showFileComponent && (
        <ViewFile
          selectedFile={documentInfo}
          onClose={() => {
            setShowFileComponent(false);
          }}
        />
      )} */}
    </>
  );
};

export default CreateProjectMilestones;
