import React, {useState, useEffect, useRef} from 'react';
import Navbar from './Navbar';
import SliderComponent1 from './SliderComponent1';
import SliderComponent2 from './SliderComponent2';
import SliderComponent3 from './SliderComponent3';

import {setItem, getItem, removeItem} from '../../Utils/LocalStorage';

import SendInvitation from '../Front/StaticPages/SendInvitation';

import {Button, Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {DEFAULT_IMAGE} from '../../Utils/env';
import RegisterPhone from './RegisterPhone';
import {registerCompany} from '../../Constants/Images';
import CropImage from '../../Components/Cropper/CropImage';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {CustomModal} from '../../Components';
import CustomerProfile from './CustomerProfile';

const RegisterCompany = () => {
  let accountType = localStorage.getItem('accountType');
  const [profileImg, setProfileImg] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [companyData, setCompanyData] = useState({
  //   firstName: localStorage.getItem('firstName') ? localStorage.getItem('firstName') : '',
  //   lastName: localStorage.getItem('lastName') ? localStorage.getItem('lastName') :  '',
  //   name: '',
  //   contact: '',
  //   email: '',
  //   teamMembers: '',
  //   billing_email: '',
  // });

  const [companyData, setCompanyData] = useState({
    firstName: localStorage.getItem('firstName')
      ? localStorage.getItem('firstName')
      : '',
    lastName: localStorage.getItem('lastName')
      ? localStorage.getItem('lastName')
      : '',
    name: '',
    contact: '',
    email: '',
    teamMembers: '',
    billing_email: '',
  });

  const [customerData, setCustomerData] = useState({
    firstName: localStorage.getItem('firstName')
      ? localStorage.getItem('firstName')
      : '',
    lastName: localStorage.getItem('lastName')
      ? localStorage.getItem('lastName')
      : '',
  });

  const [companyLink, setCompanyLink] = useState('');

  const [currentStep, setStep] = useState(
    accountType && accountType === 'business'
      ? 1
      : accountType && accountType === 'customer'
      ? 'customerDeatils'
      : 'name',
  );
  // const [currentStep, setStep] = useState('name');
  const [validated, setValidated] = useState(false);

  const [systemRoles, setSystemRoles] = useState([]);
  const [workSpaceTypes, setWorkSpaceTypes] = useState([]);
  const [selectedCompanyDescription, setSelectedCompanyDescription] = useState(
    [],
  );
  const [showComponent, setShowComponent] = useState('');
  const [contact, setContact] = useState([]);

  const [button, setButton] = useState('business');
  const [ticketImg, setImageCreate] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');

  const [companyNameExist, setCompanyNameExist] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);

  const [isCheck, setIsCheck] = useState([]);
  const [catError, setCatError] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckValue, setIsCheckValue] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const customerButtonRef = useRef(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [file, setFile] = useState(null);
  const [showLoader, setshowLoader] = useState(false);
  const getSystemRoles = () => {
    commonApi
      .get_user_roles()
      .then(res => {
        if (res.status === 200) {
          if (res.user_roles?.length > 0) {
            const rolesWithInvites = res.user_roles.map(role => ({
              ...role,
              invites: '',
            }));
            setSystemRoles(rolesWithInvites);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateImage = e => {
    // setProfileImg();
    const imgData = e.target.files[0];
    if (!/^image\//.test(imgData.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(imgData.type)) {
      setFile(imgData);

      setShowModel('modal');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  // const updateImage = e => {
  //   // setProfileImg();
  //   const imgData = e.target.files[0];
  //   if (!/^image\//.test(imgData.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else {
  //     const objectUrl = URL.createObjectURL(imgData);

  //     let reader = new FileReader();
  //     reader.readAsDataURL(imgData);
  //     reader.onload = e => {
  //       const tempData = {
  //         ...customerData,
  //         profile_img: e.target.result,
  //       };
  //       setCustomerData(tempData);
  //     };
  //     //
  //     setProfileImg(objectUrl);
  //   }
  // };

  const worSpaceType = () => {
    commonApi
      .get_workspace_types()
      .then(res => {
        if (res.status === 200) {
          if (res.workspace_types?.length > 0) {
            setWorkSpaceTypes(res.workspace_types);
            setSelectedCompanyDescription(res.workspace_types[0].id);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const UpdateUserProfile = () => {
    const payload = {
      bio: '',
      firstName: companyData.firstName,
      instagram: '',
      lastName: companyData.lastName,
      linkedin: '',
      twitter: '',
      web: '',
      youtube: '',
    };

    commonApi
      .update_profile(payload)
      .then(response => {
        localStorage.setItem('firstName', response.user.first_name);

        localStorage.setItem('lastName', response.user.last_name);
        setStep(2);
        // setUpdateProfile(false)
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const registerUser = () => {
    const payload = {
      fcmToken: '',
      firstName: companyData.firstName,
      instagram: '',
      lastName: companyData.lastName,
      linkedin: '',
      phoneNumber: getItem('loginNum'),
      twitter: '',
      web: '',
      youtube: '',
    };
    commonApi
      .create_profile(payload)
      .then(response => {
        setItem('token', response.token);
        setItem('tokenAsad', response.token);
        setItem('bf_auth_token', response.token);
        setItem('user', response.user.id);
        setItem('id', response.user.id);
        setItem('chatUserId', response.user.id);
        setItem(
          'profilePic',
          response.user.profile_img ? response.user.profile_img : DEFAULT_IMAGE,
        );
        setItem('userName', response.user.unique_user_name);
        setItem('bio', response.user.bio);
        setItem('currentLocation', response.user.current_location);
        setItem(
          'currentLocationLatitude',
          response.user.current_location_latitude,
        );
        setItem(
          'currentLocationLongitude',
          response.user.current_location_longitude,
        );
        setItem('email', response.user.email);
        setItem('fcmToken', response.user.firebase_token);
        setItem('firstName', response.user.first_name);
        setItem('homeLocation', response.user.home_location);
        setItem('homeLocationLatitude', response.user.home_location_latitude);
        setItem('homeLocationLongitude', response.user.home_location_longitude);
        setItem('instagram', response.user.instagram);
        setItem('lastName', response.user.last_name);
        setItem('linkedin', response.user.linkedin);
        setItem('number', response.user.phone);
        setItem('qrCode', response.user.qr_code);
        setItem('web', response.user.web);
        setItem('youtube', response.user.youtube);
        setItem('twitter', response.user.twitter);
        setItem('otherProfile', response.user.other_profile);

        setStep(2);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const registerNewCompany = () => {
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);

    const transformedArray = systemRoles
      .filter(role => role.invites !== '') // Filter out objects with empty "invites"
      .map(({id, invites, default_alias}) => ({
        user_id: invites,
        role_type_id: id,
        alias: default_alias,
      }));

    const payload = {
      company_name: companyData.name,
      poc_name: companyData.name,
      email: companyData.email,
      no_of_members: companyData.teamMembers,
      company_type_id: selectedCompanyDescription[0],
      category_ids: isCheck.toString(''),
      members: transformedArray.length > 0 ? transformedArray : [],
      image: ticketImg?.length > 0 ? ticketImg.toString() : '',
      company_logo: companyLogo,
      billing_email: companyData.billing_email,
    };
    commonApi
      .create_company(payload)
      .then(response => {
        if (response.status === 200) {
          localStorage.removeItem('accountType');
          successMessage('Company has been successfully registered.');
          removeItem('signup');
          setTimeout(() => {
            // setshowLoader(false)
            window.location.href = '/';
          }, 600);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getSystemRoles();
    worSpaceType();
    // if(currentStep === 1 && localStorage.getItem('token')){
    //   setUpdateProfile(true)
    // }
    // eslint-disable-next-line
  }, []);

  const handleValidation = event => {
    const form = event.currentTarget;

    if (
      form.checkValidity() === false ||
      (currentStep === 'name' && button === '') ||
      (currentStep === 2 && selectedCompanyDescription.length === 0) ||
      (currentStep === 2 && isCheck.length < 3) ||
       (currentStep === 1 && companyNameExist)
    ) {
      event.preventDefault();
      event.stopPropagation();
      // if (currentStep === 2) {
      //   errorMessage('Please Select that describe your Company');
      // }

      if (currentStep === 2 && isCheck.length < 3) {
        setCatError(true);
      }

      if (currentStep === 'name') {
        console.log(button, form.checkValidity(),companyNameExist)
        errorMessage('Please Select Which one are you');
      }

      setValidated(true);
    } else {
      setValidated(false);

      if (currentStep === 'name') {
        event.preventDefault();

        if (button === 'business') {
          setStep(1);
        } else {
          setStep('customerDeatils');
        }
        localStorage.setItem('accountType', button);
      } else if (currentStep === 1) {
        event.preventDefault();
        if (
          currentStep === 1 &&
          localStorage.getItem('token') &&
          (localStorage.getItem('firstName') !== companyData.firstName ||
            localStorage.getItem('lastName') !== companyData.lastName)
        ) {
          UpdateUserProfile();
        } else if (localStorage.getItem('token')) {
          setStep(2);
        } else {
          registerUser();
        }
      } else if (currentStep === 'companyLink') {
        event.preventDefault();
        setStep('customerDeatils');
      } else if (currentStep === 2) {
        event.preventDefault();
        setStep(3);
      }
    }
  };
  const handleCustomerValidation = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      event.preventDefault();
      event.stopPropagation();
      if (getItem('token')) {
        UpdateCustomerProfile();
      } else {
        registerCustomerUser();
      }
    }
  };

  const UpdateCustomerProfile = async () => {
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    try {
      let image;
      if (profileImg) {
        var bodyFormData = new FormData();
        bodyFormData.append(
          'picture',
          profileImg.replace(/^data:image\/[a-z]+;base64,/, ''),
        );
        const response = await commonApi.upload_profile_picture(bodyFormData);
        image = response.user.profile_img ? response.user.profile_img : '';
      }
  
      const payload = {
        bio: '',
        firstName: customerData.firstName,
        instagram: '',
        lastName: customerData.lastName,
        linkedin: '',
        twitter: '',
        web: '',
        youtube: '',
        profile_image: image ? image : '',
      };
  
      const response = await commonApi.update_profile(payload);
      handleUserData(response);
    } catch (err) {
      console.error('Error creating profile:', err);
    }
  };

  const registerCustomerUser = async () => {
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    try {
      const payload = {
        fcmToken: '',
        firstName: customerData.firstName,
        instagram: '',
        lastName: customerData.lastName,
        linkedin: '',
        phoneNumber: getItem('loginNum'),
        twitter: '',
        web: '',
        youtube: '',
      };
      const response = await commonApi.create_profile(payload);
      handleUserData(response,profileImg ?'await' :'');
  
      if (profileImg) {
        var bodyFormData = new FormData();
        bodyFormData.append(
          'picture',
          profileImg.replace(/^data:image\/[a-z]+;base64,/, ''),
        );
        const response = await commonApi.upload_profile_picture(bodyFormData);
        handleUserData(response);
      }
    } catch (err) {
      console.error('Error creating profile:', err);
    }
  };

  const handleUserData = (response,type) => {
    setItem('token', response.token);
    setItem('tokenAsad', response.token);
    setItem('bf_auth_token', response.token);
    setItem('user', response.user.id);
    setItem('id', response.user.id);
    setItem('chatUserId', response.user.id);
    setItem(
      'profilePic',
      response.user.profile_img ? response.user.profile_img : DEFAULT_IMAGE,
    );
    setItem('userName', response.user.unique_user_name);
    setItem('bio', response.user.bio);
    setItem('currentLocation', response.user.current_location);
    setItem('currentLocationLatitude', response.user.current_location_latitude);
    setItem(
      'currentLocationLongitude',
      response.user.current_location_longitude,
    );
    setItem('email', response.user.email);
    setItem('fcmToken', response.user.firebase_token);
    setItem('firstName', response.user.first_name);
    setItem('homeLocation', response.user.home_location);
    setItem('homeLocationLatitude', response.user.home_location_latitude);
    setItem('homeLocationLongitude', response.user.home_location_longitude);
    setItem('instagram', response.user.instagram);
    setItem('lastName', response.user.last_name);
    setItem('linkedin', response.user.linkedin);
    setItem('number', response.user.phone);
    setItem('qrCode', response.user.qr_code);
    setItem('web', response.user.web);
    setItem('youtube', response.user.youtube);
    setItem('twitter', response.user.twitter);
    setItem('otherProfile', response.user.other_profile);

    if(!type){
      // successMessage('Account has been successfully created.');
      removeItem('signup');
      setTimeout(() => {
        window.location.href = '/';
        // setshowLoader(false)
      }, 600);
    }
   
  };

  const handleBackbtn = () => {
    setStep(currentStep - 1);
  };

  const handlePage = () => {
    setStep(currentStep - 1);
  };

  const handleNextPage = () => {
    if (currentStep < 3) {
      setStep(currentStep + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentStep > 1) {
      setStep(currentStep - 1);
    }
  };
  const handleSkipbtn = () => {
    if (currentStep < 3) {
      setStep(currentStep + 1);
    }
    // if(currentStep ===3 ){
    //   registerNewCompany()
    // }
  };

  const stepCustomer = () => {
    return (
      <>
        <div
          className="user-detail"
          style={{
            textAlign: 'center',
            paddingTop: '30vh',
          }}>
          <div className="input-container1 text-left">
            <div className="input-group">
              <Form.Label controlid="validationCustom01">
                Enter Your Company Link
              </Form.Label>

              <Form.Control
                type="text"
                value={companyLink}
                pattern="^\S.*$"
                required
                placeholder=""
                onChange={e => {
                  setCompanyLink(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Company Link is required.
              </Form.Control.Feedback>
            </div>
          </div>

          <Button
            className="new-btn btn-green"
            type="submit"
            // onClick={() => {
            //   setStep('customerDeatils');
            // }}
          >
            Next
          </Button>
          {/* <Button
          type="submit"
          style={{display: 'none'}}
          ref={buttonRef}></Button> */}
        </div>
      </>
    );
  };

  const StepCustomerProfilePage = () => {
    return (
      <>
        <div className="slider-component create_company_form">
          {/* <div className="slider-component-img"> */}
          {/* {!hide && ( */}
          <div
            className="slider-component-img"
            style={{
              backgroundImage: `url(${registerCompany})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
            {/* <img src={registerCompany} alt="" /> */}

            <div className="slider-component-img-text">
              Welcome to the ROI Family!
            </div>
          </div>
          {/* )} */}

          <div className="slider-component-content refferal-list-wrapper">
            {/* <div className="back-btn">
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  setStep('name');
                  handleFunctionReset();
                }}>
                Back
              </Button>
            </div> */}
            {/* {showLoader && <LoaderSpinner />} */}
            <div
              className="user-detail slider-content"
              // style={{
              //   textAlign: 'center',
              //   paddingTop: '10vh',
              // }}
            >
              <div className="input-container1 text-left">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleCustomerValidation}>
                  <div className="onboarding-profile create-profile">
                    <div className="profile-img">
                      <input
                        type="file"
                        accept="image/*"
                        name="profile_img"
                        onChange={e => updateImage(e)}
                      />
                      {profileImg && <img src={profileImg} alt="" />}
                      {!profileImg && <i className="fas fa-camera" />}
                    </div>
                    <h3>What’s your name?</h3>
                    <p>Yes, we use real names.</p>

                    <Form.Group className="fieldset mt-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="fname"
                        type="text"
                        // pattern="^\S.*$"
                        value={customerData.firstName}
                        onChange={e =>
                          setCustomerData({
                            ...customerData,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="First Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        First Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="fieldset">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        // pattern="^\S.*$"
                        name="lname"
                        type="text"
                        value={customerData.lastName}
                        onChange={e =>
                          setCustomerData({
                            ...customerData,
                            lastName: e.target.value,
                          })
                        }
                        placeholder="Last Name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Last Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      className="new-btn btn-green customer_btn"
                      type="submit"
                      ref={customerButtonRef}>
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            {showModel === 'modal' && file && (
              <CustomModal
                size="large"
                onClose={() => {
                  setShowModel('');
                  setFile(null);
                }}>
                <CropImage
                  type="profile"
                  ratio={25 / 24}
                  file={file}
                  setFile={setFile}
                  setCropImg={setShowModel}
                  setImgUrl={setProfileImg}
                  // setshowLoader={setshowLoader}
                />
              </CustomModal>
            )}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    check_company_name();
    // eslint-disable-next-line
  }, [companyData]);

  const check_company_name = () => {
    const payload = {
      company_name: companyData.name,
    };

    commonApi
      .check_company_name(payload)
      .then(res => {
        if (res.status === 200) {
          setCompanyNameExist(res.company_exist);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleFunctionReset = () => {
    removeItem('accountType');
    setCompanyData({
      firstName: localStorage.getItem('firstName')
        ? localStorage.getItem('firstName')
        : '',
      lastName: localStorage.getItem('lastName')
        ? localStorage.getItem('lastName')
        : '',
      name: '',
      contact: '',
      email: '',
      teamMembers: '',
      billing_email: '',
    });

    setCustomerData({
      firstName: getItem('firstName') ? getItem('firstName') : '',
      lastName: getItem('lastName') ? getItem('lastName') : '',
    });

    // const loginNum = getItem('loginNum');

    // localStorage.clear();
    // if (loginNum !== null) {
    //   setItem('loginNum', loginNum);
    // }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault(); 
    
    if (localStorage.getItem('id')) {
      window.location.reload();
    } 
 
  };

  useEffect(() => {
   
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = onBackButtonEvent;

    return () => {
      window.onpopstate = null;
    };
  }, []);


  return (
    <>
      <div
        className="new-page-wrapper register__company"
        style={{
          overflow: 'hidden',
        }}>
        {/* {setupAccount && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '50vh',
              transform: 'translateY(-50%)',
            }}>
       
            <Button
              className="new-btn btn-green"
              onClick={() => {
                setSetupAccount(false);
                setRegisterName(true);
              }}>
              SetUpAccount
            </Button>

            <Button className="new-btn btn-green ml-4" onClick={() => {}}>
              Schedule a Demo
            </Button>
          </div>
        )} */}
        {currentStep === 'customerDeatils' && (
          <Navbar
            type={'customer'}
            handleBackbtn={() => {
              setStep('name');
              setCustomerData({
                firstName: getItem('firstName') ? getItem('firstName') : '',
                lastName: getItem('lastName') ? getItem('lastName') : '',
              });
              setProfileImg('')
              removeItem('accountType');
            }}
            currentStep={currentStep}
            handleSkipbtn={handleSkipbtn}
            registerNewCompany={() => {
              customerButtonRef.current.click();
            }}
            setshowLoader={setshowLoader}
          />
        )}
        {currentStep === 'customerDeatils' && (
          <CustomerProfile
            customerData={customerData}
            setCustomerData={setCustomerData}
            buttonRef={customerButtonRef}
            validated={validated}
            profileImg={profileImg}
            handleCustomerValidation={handleCustomerValidation}
            updateImage={updateImage}
            setProfileImg={setProfileImg}
            setStep={setStep}
            handleBackbtn={() => {
              setStep('name');
              setCustomerData({
                firstName: getItem('firstName') ? getItem('firstName') : '',
                lastName: getItem('lastName') ? getItem('lastName') : '',
              });
              setProfileImg('')
              removeItem('accountType');
            }}
            ShowLoader={showLoader}
          />
        )}
        <Form
          noValidate
          validated={validated}
          onSubmit={handleValidation}
          style={{position: 'relative'}}
          className="register_company_form register_company_page_form">
          {currentStep === 'companyLink' && stepCustomer()}

          {currentStep !== 'companyLink' &&
            currentStep !== 'customerDeatils' && (
              <Navbar
                handleBackbtn={handleBackbtn}
                currentStep={currentStep}
                handleSkipbtn={handleSkipbtn}
                registerNewCompany={registerNewCompany}
                setshowLoader={setshowLoader}
              />
            )}

          {currentStep === 'name' && (
            <RegisterPhone setButton={setButton} button={button} />
          )}

          {/* {currentStep !== 'name' && formDots()} */}
          {currentStep === 1 && (
            <SliderComponent1
              type={'create-account'}
              companyData={companyData}
              setCompanyData={setCompanyData}
              setImageCreate={setImageCreate}
              ticketImg={ticketImg}
              companyLogo={companyLogo}
              setCompanyLogo={setCompanyLogo}
              companyNameExist={companyNameExist}
              currentStep={currentStep}
              handleFunctionReset={handleFunctionReset}
              setStep={setStep}
            />
          )}
          {currentStep === 2 && (
            <SliderComponent2
              // titleValidation={customerTitleValidation}

              // customerTypes={customerTypes}
              // setCustomerTypes={setCustomerTypes}

              isCheck={isCheck}
              setIsCheck={setIsCheck}
              catError={catError}
              workSpaceTypes={workSpaceTypes}
              selectedCompanyDescription={selectedCompanyDescription}
              setSelectedCompanyDescription={setSelectedCompanyDescription}
              currentStep={currentStep}
              handlePage={handlePage}
              setStep={setStep}
              setIsCheckValue={setIsCheckValue}
              isCheckValue={isCheckValue}
              isCheckAll={isCheckAll}
              setIsCheckAll={setIsCheckAll}
            />
          )}
          {currentStep === 3 && (
            <SliderComponent3
              systemRoles={systemRoles}
              setSystemRoles={setSystemRoles}
              contact={contact}
              setContact={setContact}
              showComponent={showComponent}
              setShowComponent={setShowComponent}
              currentStep={currentStep}
              handlePage={handlePage}
              setStep={setStep}
              showLoader={showLoader}
              type={'create-account'}
            />
          )}

          {currentStep !== 'customerDeatils' && (
            <>
              {(currentStep >= 1 || currentStep === 'name') &&
                currentStep !== 3 && (
                  <div
                    className={`responsive_next_btn text-center ${
                      currentStep === 'name' ? '' : 'navbar_bg'
                    }`}
                    style={{width: '100%', position: 'absolute'}}>
                    <Button className="new-btn btn-green " type="submit">
                      Next
                    </Button>
                  </div>
                )}

              {currentStep === 3 && (
                <div
                  className={`responsive_next_btn text-center ${
                    currentStep === 'name' ? '' : 'navbar_bg'
                  }`}
                  style={{width: '100%', position: 'absolute'}}>
                  <Button
                    className="new-btn btn-green "
                    onClick={() => {
                      registerNewCompany();
                    }}>
                    Get Started
                  </Button>
                </div>
              )}
            </>
          )}
          {/* {currentStep === 4 && <SliderComponent4 />}
          {currentStep === 5 && <SliderComponent5 />}

          {currentStep === 6 && <SliderComponent6 />}
          {currentStep === 7 && <SliderComponent7 />} */}

          {/* {currentStep === 1 && (
            <>
              <Navbar slider={slider} currentSlide={currentSlide} />

              <div className="row mb-5">
                <div className="">
                  <Slider
                    {...ComponenetsPreview}
                    ref={slider}
                    className="action-sliders snapshot-slider">
                    <SliderComponent1 />
                    <SliderComponent2 />
                    <SliderComponent3 />
                    <SliderComponent4 />
                    <SliderComponent5 />
                    <SliderComponent6 />
                    <SliderComponent7 />
                  </Slider>
                </div>
              </div>
            </>
          )} */}
        </Form>

        {/* {!setupAccount && !registerName && (
          <>
            <Navbar slider={slider} currentSlide={currentSlide} />

            <div className="row mb-5">
              <div className="">
                <Slider
                  {...ComponenetsPreview}
                  ref={slider}
                  className="action-sliders snapshot-slider">
                  <SliderComponent1 />
                  <SliderComponent2 />
                  <SliderComponent3 />
                  <SliderComponent4 />
                  <SliderComponent5 />
                  <SliderComponent6 />
                  <SliderComponent7 />
                </Slider>
              </div>
            </div>
          </>
        )} */}
      </div>

      {/* {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )} */}
    </>
  );
};

export default RegisterCompany;
