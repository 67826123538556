import {useState} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Form} from 'react-bootstrap';
import {drag_icon} from '../../Constants/Images';

const BluePrintLayout = () => {
  const [items, setItems] = useState([
    {
      id: '1',
      title: 'Office 1',
      showDetails: true,
      children: [
        {id: '1-1', title: 'Cubical', showDetails: false},
        {id: '1-2', title: 'Group/Item', showDetails: false},
      ],
    },
    {
      id: '2',
      title: 'Space',
      showDetails: false,
      children: [{id: '2-1', title: 'Storage', showDetails: false}],
    },
  ]);
  const [searchQry, setSearchQry] = useState('');

  // Handle drag-and-drop end event for both parents and children
  const handleDragEnd = result => {
    const {destination, source, type} = result;

    // If dropped outside the list (no destination)
    if (!destination) return;

    // Reordering parents
    if (type === 'PARENT') {
      const reorderedParents = Array.from(items);
      const [movedItem] = reorderedParents.splice(source.index, 1);
      reorderedParents.splice(destination.index, 0, movedItem);
      setItems(reorderedParents);
    }
    // Reordering children
    else if (type === 'CHILD') {
      const reorderedParents = Array.from(items);
      const parent = reorderedParents.find(
        item => item.id === source.droppableId,
      );
      const [movedChild] = parent.children.splice(source.index, 1);
      parent.children.splice(destination.index, 0, movedChild);
      setItems(reorderedParents);
    }
  };

  return (
    <>
      <div
        className="invite-page-search  fixed-width-inputs fix-width-forms "
        style={{paddingRight: '15px', margin: 0}}>
        <div className="search-form">
          <Form.Group className="search-input">
            <Form.Control
              onChange={e => {
                setSearchQry(e.target.value);
                // handleSearchQry(e.target.value);
              }}
              value={searchQry}
              type="text"
              placeholder={'Search'}
            />
          </Form.Group>
        </div>
      </div>
      <div className="rounded-lg shadow-md">
        <DragDropContext onDragEnd={handleDragEnd}>
          {/* Parent Items */}
          <Droppable droppableId="parentDroppable" type="PARENT">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((parent, parentIndex) => (
                  <Draggable
                    key={parent.id}
                    draggableId={parent.id}
                    index={parentIndex}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="border-b">
                        <div className="flex justify-between items-center  bg-white mb-2 p-2">
                          <div
                            className="flex justify-between items-center"
                            style={{
                              gap: '20px',
                            }}>
                            <img src={drag_icon} alt="" />
                            <span className="text-lg text-gray-800">
                              {parent.title}
                            </span>
                          </div>
                          <div>
                          <button
                          style={{margin: '0px 10px'}}
                            disabled={items?.[parentIndex]?.showDetails === false}
                            onClick={() => {
                              const updatedItems = [...items];
                                updatedItems[parentIndex].showDetails = false;
                              setItems(updatedItems);
                            }}
                            className='p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300'>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                viewBox="0 0 20 20">
                                <path
                                  fill="currentColor"
                                  d="m5 14l5-5l5 5l2-1l-7-7l-7 7z"></path>
                              </svg>
                          </button>
                          <button
                            style={{margin: '0px 10px'}}
                            disabled={items?.[parentIndex]?.showDetails === true}
                            onClick={() => {
                              const updatedItems = [...items];
                              updatedItems[parentIndex].showDetails = true; 
                              setItems(updatedItems);
                            }}
                            className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={20}
                                height={20}
                                viewBox="0 0 20 20">
                                <path
                                  fill="currentColor"
                                  d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"></path>
                              </svg>
                          </button>
                          </div>
                        </div>
                        <div
                          className="mt-4 mb-4"
                          style={{
                            textAlign: 'center',
                            color: '#5ABD42'
                          }}
                          onClick={() => {
                            const updatedItems = [...items];
                            updatedItems[parentIndex].showDetails =
                              !updatedItems[parentIndex].showDetails;
                            setItems(updatedItems);
                          }}>
                          {parent.showDetails ? 'Hide Space Details' :  'Show Space Details'}
                        </div>

                        {/* Show Parent Details */}
                        {parent.showDetails && (
                          <div className="pl-6 pb-2 text-sm text-gray-600">
                            {/* Child Items */}
                            <Droppable droppableId={parent.id} type="CHILD">
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}>
                                  {parent.children.map((child, childIndex) => (
                                    <Draggable
                                      key={child.id}
                                      draggableId={child.id}
                                      index={childIndex}>
                                      {provided => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="flex justify-between items-center p-2 bg-white mb-4"
                                         >
                                          <div className="flex justify-between items-center"
                                           style={{
                                            gap: '20px',
                                          }}>
                                            <img src={drag_icon} alt="" />
                                            <span style={{color: 'black'}} className="">
                                              {child.title}
                                            </span>
                                          </div>
                                          <div>
                                          <button
                                          style={{margin: '0px 10px'}}
                                          disabled={items[parentIndex]?.children[childIndex]?.showDetails === false}
                                            onClick={() => {
                                              const updatedItems = [...items];
                                              updatedItems[
                                                parentIndex
                                              ].children[
                                                childIndex
                                              ].showDetails = false;
                                              setItems(updatedItems);
                                            }}
                                            className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={20}
                                                height={20}
                                                viewBox="0 0 20 20">
                                                <path
                                                  fill="currentColor"
                                                  d="m5 14l5-5l5 5l2-1l-7-7l-7 7z"></path>
                                              </svg>
                                          </button>
                                          <button
                                          style={{margin: '0px 10px'}}
                                          disabled={items[parentIndex]?.children[childIndex]?.showDetails === true}
                                            onClick={() => {
                                              const updatedItems = [...items];
                                              updatedItems[
                                                parentIndex
                                              ].children[
                                                childIndex
                                              ].showDetails = true;
                                              setItems(updatedItems);
                                            }}
                                            className="p-2 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={20}
                                                height={20}
                                                viewBox="0 0 20 20">
                                                <path
                                                  fill="currentColor"
                                                  d="m5 6l5 5l5-5l2 1l-7 7l-7-7z"></path>
                                              </svg>
                                          </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default BluePrintLayout;
