import React from 'react';
import { calculateMilestoneProgress, fixName, truncateTitle } from '../../Utils/commonUtils';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import { NoProjectsFound } from '../../Utils/NoRecordConstant';
import { ProgressBar } from 'react-bootstrap';


const ListingOfProjects = props => {


  return (     
    <>
       <div className="project_list_view mt-3">
                            {props.projectListing && props.projectListing?.length > 0 ? (
                              props.projectListing.map((item, index) => {
                                const ProgressBarCount =
                                  calculateMilestoneProgress(item.milestones);
                                console.log(
                                  ProgressBarCount,
                                  'ProgressBarCount',
                                );
                                return (
                                  <div
                                    onClick={() => {
                                      props.handleProject('detail_Project', item)
                                      // props.setSecondaryComponent('detail_Project');
                                      // setSelectedProjectData(item);
                                    }}
                                    className="project_list_card as-link"
                                    index={item.id + index}>
                                    <div className="list_card_main">
                                      {/* <div className="project_img">
                                        <img src="" alt="" />
                                      </div> */}

                                      <div className="company_project_img">
                                        <img
                                          src={
                                            item?.company_detail?.company_logo
                                              ? item?.company_detail
                                                  ?.company_logo
                                              : 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="list_card_content">
                                        <div>
                                          <h2>{fixName(item.name)}</h2>
                                        </div>
                                        <p
                                          style={{
                                            fontSize: '14px',
                                            color: '#7E7B70',
                                          }}>
                                          {fixName(
                                            item?.company_detail?.company_name,
                                          )}
                                        </p>

                                        <div className="roi-service-icons">
                                          {Object.entries(item.tag_titles)
                                            .length > 0 &&
                                            item.tag_titles
                                              .slice(0, 3)
                                              .map((MiniItem, index) => (
                                                <div
                                                  className="service-icon-time"
                                                  key={index}>
                                                  {MiniItem && (
                                                    <span className="tags_title">
                                                      {truncateTitle(
                                                        MiniItem,
                                                        4,
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                              ))}
                                          {item.tag_titles.length > 3 && (
                                            <div className="w-29">
                                              <i
                                                className="fa-solid fa-ellipsis"
                                                style={{
                                                  color: 'rgb(10, 10, 10)',
                                                  paddingTop: '15px',
                                                }}></i>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="list_card_bottom">
                                      <div className="d-flex justify-content-between">
                                        <span>Next Delivery</span>

                                        <span>
                                          {ProgressBarCount?.upcomingDate
                                            ? ProgressBarCount?.upcomingDate
                                            : ''}
                                        </span>
                                      </div>
                                      <div className="project_bar">
                                        <ProgressBar
                                          className="task-bar"
                                          variant="success"
                                          now={
                                            ProgressBarCount?.percentagePassed &&
                                            ProgressBarCount?.percentagePassed
                                              ? ProgressBarCount?.percentagePassed
                                              : 0
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <NorecordFound
                                noRecordData={NoProjectsFound}
                                buttonPermesion={true}
                                ButtonAction={() => {
                                  props.handleProject('createProject');
                                }}
                              />
                            )}
                          </div>
    </>
  );
};

export default ListingOfProjects;
