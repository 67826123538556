import React, {useState, useEffect} from 'react';
import SlidingPanel from '../SlidingPanel';
import InvitePoc from './InvitePoc';
import {RoiLogo} from '../../Constants/Images';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import {UserProfileImg} from '../../Constants/Images';
const AddPoc = props => {
  const [builders, setBuilders] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [contact, setContact] = useState([]);
  const [pocTitle, setPocTitle] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [originalPocs, setOrginalPocs] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  const [audience, setAudience] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [refresh, setRefresh] = useState(false);
  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach(miniItem => {
        if (!builders.includes(miniItem.id)) {
          setBuilders(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };
  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  useEffect(() => {
    if (contact.length > 0) {
      setRefresh(!refresh);
    }
  }, [contact]);
  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          setCurrentComponent('');
          setBuilders([]);
          setAllBuilders([]);
          setOriginalDataOfMod([]);
          setPocTitle('');
          props.onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#f7f6f2',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
           <div className="create-sessionEvents primary_panel_content">
         
       
      
        <InvitePoc
          type={'refer'}
          removeCompnayCreator={'yes'}
          component={'businessProfile'}
          page={'userProfile'}
          moderator={builders}
          setModerator={setBuilders}
          selectedModData={allBuilders}
          setSelectedModData={setAllBuilders}
          title={pocTitle}
          setTitle={setPocTitle}
          contact={contact}
          setContact={setContact}
          originalDataOfMod={originalDataOfMod}
          setOriginalDataOfMod={setOriginalDataOfMod}
          audience={audience}
          setAudience={setAudience}
          selectedAudData={selectedAudData}
          setSelectedAudData={setSelectedAudData}
          originalDataOfAud={originalDataOfAud}
          setOriginalDataOfAud={setOriginalDataOfAud}
          handleCircle={handleCircle}
          forApiHit={refresh}
          onClick={() => setShowComponent('inviteUser')}
          updateCompanyDetails={() => {}}
          onClose={() => {
            setCurrentComponent('');
            setBuilders([]);
            setAllBuilders([]);
            setOriginalDataOfMod([]);
            setPocTitle('');
            props.onClose();
          }}
          // refreshFollowersApi={()=>{
          //   setRefresh(!refresh)
          // }}
          onCloseHitApi={() => {
            props.getUserData && props.getUserData();
          }}
          businessProfileId={props.user_id}
          existingPocIds={props.existingPocIds}
          otherType={props.onlyOneSelectUser ? true : false}
          openMessageComp={props.openMessageComp ? props.openMessageComp : ''}
        />
        </div>
      </SlidingPanel>

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => setShowComponent('')}
          onCloseHitApi={() => {
            props.getUserData && props.getUserData();
            !props.onlyOneSelectUser && props.onClose();
            setShowComponent('')
          }}
          hitApi={props.onlyOneSelectUser && true}
          addBusinessPOC={!props.onlyOneSelectUser && true}
          businessProfileId={props.user_id}
        />
      )}
    </>
  );
};

export default AddPoc;
