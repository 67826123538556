import React, {useState, useEffect, useRef} from 'react';
import {fixName, formatTimeee} from '../../../Utils/commonUtils';
import commonApi from '../../../Services/CommonService';
import Moment from 'react-moment';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import {twillio_icon} from '../../../Constants/Images';
import NorecordFound from '../../../Components/NoRecord/NorecordFound';
import {messagesRecordFound} from '../../../Utils/NoRecordConstant';
import SvgIconComponent from '../../../Constants/SvgIconComponent';
import { errorMessage } from '../../../Utils/ToastMessages';

const CallsInformation = props => {
  const {callHistoryList, showSpinner, callToPerson,setPhoneNumber } = props;
  const sectionRefs = useRef({});

  const groupByLetter = callHistoryList => {
    return callHistoryList.reduce((groups, item) => {
      // Extract the first letter of the name (first name or last name)
      const fullName = fixName(item?.first_name, item?.last_name);
      const firstLetter = fullName ? fullName[0].toUpperCase() : '';

      // If the first letter is a number or symbol, categorize it as "#"
      const groupKey = /^[A-Z]/.test(firstLetter) ? firstLetter : '#';

      // Create the group if it doesn't exist yet
      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }

      // Add the item to the appropriate group
      groups[groupKey].push(item);
      return groups;
    }, {});
  };

  // Sort the call history list by name
  const sortedCallHistory = [...props.allBuilderList].sort((a, b) => {
    const nameA = fixName(a?.first_name, a?.last_name).toUpperCase();
    const nameB = fixName(b?.first_name, b?.last_name).toUpperCase();

    // Check if names start with alphabetic letters or numbers
    const isAAlpha = /^[A-Z]/.test(nameA); // Check if the name starts with a letter
    const isBAlpha = /^[A-Z]/.test(nameB); // Check if the name starts with a letter

    // If A starts with a letter and B doesn't, A comes first
    if (isAAlpha && !isBAlpha) return -1;
    // If B starts with a letter and A doesn't, B comes first
    if (!isAAlpha && isBAlpha) return 1;

    // If both are alphabetic or both are numeric, compare alphabetically
    return nameA.localeCompare(nameB);
  });

  // Group the sorted list by the first letter
  const groupedByLetter = groupByLetter(sortedCallHistory);

  const scrollToLetter = letter => {
    if (sectionRefs.current[letter]) {
      sectionRefs.current[letter].scrollIntoView({behavior: 'smooth'});
    }
  };

  const staticGroups = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '#',
  ];

  const loginUserId = localStorage.getItem('id');

  const getUserDetails = (callDetails, loginUserId) => {
    const receiver = callDetails?.receiver_detail; // Safely access receiver_detail
    const sender = callDetails?.sender_detail; // Safely access sender_detail

    // Check if receiver exists and doesn't match the logged-in user's ID
    if (receiver && receiver.id !== loginUserId) {
      return {
        firstName: receiver.first_name || '',
        lastName: receiver.last_name || '',
        role: receiver.user_role || '',
        img: receiver.profile_img || null,
        userId: receiver.id,
        phoneNumber:callDetails?.receiver_phone,
      };
    }

    // Check if sender exists and doesn't match the logged-in user's ID
    if (sender && sender.id !== loginUserId) {
      return {
        firstName: sender.first_name || '',
        lastName: sender.last_name || '',
        role: sender.user_role || '',
        img: sender.profile_img || null,
        userId: sender.id,
        phoneNumber:callDetails?.sender_phone,
      };
    }

    // If no valid user details found, return null
    return {
      firstName: callDetails.receiver_phone,
      lastName: '',
      role: '',
      img: DEFAULT_IMAGE,
      recording_urls: callDetails.recording_urls,
      phone: '',
      date: callDetails.created_at,
      userId: '',
      phoneNumber:callDetails?.receiver_phone,
    };
  };

  // Example usage:

  const missCall = item => {
    if (
      item.status === 'no-answer' &&
      localStorage.getItem('id') === item.receiver_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {showSpinner && <LoaderSpinner />}

      {props.tabType === 'recent' && (
        <div className="session-wrapper history-sessions cursor-default calls-information-list-menu">
          {callHistoryList && Object.entries(callHistoryList)?.length > 0 ? (
            Object.entries(callHistoryList)?.map(([key, item], index) => {
              const userDetails = getUserDetails(item, loginUserId); // Call the function with item

              return (
                <div
                  key={index + 'ASDSAD' + item.id}
                  className="events-item p-3 mb-2 rounded-md as-link"
                  onClick={() => {
                          
                    if (userDetails && userDetails?.phoneNumber) {
                      setPhoneNumber(userDetails?.phoneNumber)
                      callToPerson(userDetails);
               
                    } else {
                      errorMessage('This user is not belong to our web');
                    
                    }
                  }}>
                  <div className="session-heading flex items-start">
                    <div className="persons-img-list mb-0 flex items-center flex-1">
                      <div className="img-item mb-0">
                        <img
                        
                          src={userDetails?.img || DEFAULT_IMAGE}
                          alt=""
                          className={`w-15 h-15 as-link rounded-full ${
                            index === 2 ? 'rounded-[17%]' : ''
                          }`}
                        />
                      </div>
                      <div className="flex-1">
                        <div className="flex justify-between gap-0">
                          <div
                            className="m-1"
                            style={{
                              color: missCall(item) && 'red',
                            }}>
                            {fixName(
                              userDetails?.firstName,
                              userDetails?.lastName,
                            )}
                          </div>
                          <div>
                            <SvgIconComponent icon={'info'} />
                          </div>
                        </div>
                        <div className="flex justify-between items-center mt-1">
                          <div>
                            {userDetails && <div>{userDetails?.role}</div>}
                          </div>
                          <div className="flex justify-between gap-3">
                            <div>
                              <Moment format="H:mmA">{item?.created_at}</Moment>
                            </div>
                            <div>
                              <SvgIconComponent
                                icon={
                                  item?.receiver_id ===
                                  localStorage.getItem('id')
                                    ? 'arrowDown'
                                    : 'arrow'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NorecordFound
              noRecordData={messagesRecordFound}
              buttonPermesion={true}
              ButtonAction={() => {
                props.setShowModelPrimary('Dialler');
              }}
            />
          )}
        </div>
      )}
      <div className="row">
        <div className="col-11">
          {props.tabType === 'contacts' && (
            <div className="session-wrapper history-sessions cursor-default">
              {groupedByLetter && Object.keys(groupedByLetter)?.length > 0 ? (
                Object.keys(groupedByLetter)?.map(letter => (
                  <div key={letter}>
                    {/* Render header for each letter */}
                    <div
                      className="letter-header"
                      ref={el => (sectionRefs.current[letter] = el)}
                      style={{marginTop: '20px', fontWeight: 'bold'}}>
                      {letter}
                    </div>
                    {groupedByLetter[letter].map((item, index) => (
                      <div
                        key={index + 'ASDSAD' + item.id}
                        className="events-item"
                        style={{
                          padding: '11px',
                          marginBottom: '10px',
                          borderRadius: '6px',
                        }}>
                        <div
                          className="session-heading"
                          style={{alignItems: 'unset'}}>
                          <div className="persons-img-list mb-0 d-flex align-items-center">
                            <div className="img-item mb-0">
                              <img
                                src={
                                  // item.receiver_detail?.profile_img ||
                                  DEFAULT_IMAGE
                                }
                                alt=""
                                style={{
                                  width: '60px',
                                  height: '60px',
                                  borderRadius: index === 2 ? '17%' : '50%',
                                }}
                              />
                            </div>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  gap: '200px',
                                }}>
                                <div className="m-0">
                                  {fixName(item?.first_name, item?.last_name)}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginTop: '3px',
                                }}>
                                <div>Customer</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <NorecordFound noRecordData={messagesRecordFound} />
              )}
            </div>
          )}
        </div>
        <div className="col-1">
          {props.tabType === 'contacts' && (
            <div style={{position: 'fixed'}}>
              {staticGroups.map((item, index) => (
                <div
                  key={index}
                  onClick={() => scrollToLetter(item)}
                  style={{
                    cursor: 'pointer',
                    fontSize: '8px',
                    fontWeight: 'bold',
                  }}>
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CallsInformation;
