import React, {useState, useEffect, useRef} from 'react';
import {CustomModal, CustomTabs, SlidingPanel} from '../../Components';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import {Button, Col, Form, Row} from 'react-bootstrap';
import CustomDropdown from '../../Components/CustomDropdown';
import AssigneeBuilders from '../../Components/SessionList/AssigneeBuilders';
import {labels} from '../../Constants/Translations';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
import {RoiLogo, Templates} from '../../Constants/Images';
import {fixName} from '../../Utils/commonUtils';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GeneralProperty from './GeneralProperty';
import commonApi from '../../Services/CommonService';
import BluePrint from './BluePrint';
import PropertyNotes from '../../Components/Property/PropertyNotes';
import PropertyPoc from '../../Components/Property/PropertyPoc';
import ConfirmationModel from '../../Components/ConfirmationModel/ConfirmationModel';

const CreateEditProperty = props => {

  const {propertyListing,setpropertyListing, setShowAreaComponent, showAreaComponent} = props;

  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [validated, setValidated] = useState(false);
  const [activeTab, setActveTab] = useState('General');
  const [properties, setProperties] = useState({});
  const [showLoader, setshowLoader] = useState(false);
  const [showDeleteModel, setShowDeleteModal] = useState(false);
  const buttonRef = useRef(null);
  const [pocAliase, setPocAliase] = useState([])
  const [deleteItem, setDeleteItem] = useState()
  const [pocList, setPocList] = useState([]);

  const tabs = [
    {key: 'General', name: 'General'},
    {key: 'Blueprint', name: 'Blueprint'},
    {key: 'POCs', name: 'POCs'},
    {key: 'Notes', name: 'Notes'},
  ];

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      createProperty();
      setValidated(false);
      clearData();
    }
  };

  const clearData = () => {
    setProperties(prevState => ({
      ...prevState,
      propertyName: '',
      location: '',
      SQFT: '',
      floors: '',
    }));
  };

  const createProperty = () => {
    const payload = {
      propertyName: properties?.propertyName,
      type: properties?.type,
      floors: properties?.floors,
      SQFT: properties?.SQFT,
      location: properties?.location,
    };

    return;
    commonApi
      .add_user_in_company(payload)
      .then(res => {})
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProfileTagsButtons = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  const handleTabClick = item => {
    setActveTab(item);
  };

  const deletePoc = ()=>{
    setPocList(previous => previous.filter(item => item.id !== deleteItem.id));
    setShowDeleteModal(false);
  }
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        <div
          style={{
            paddingTop: '20px',
          }}
          className="serach-filter-wrapper create-sessionEvents">
          <div className="create-ticket-form  primary_panel_content">
            <div className="messages-header mb-2 fixed-width-inputs black-head-form-pad-left">
              <div className="event-tabs tabs-style-2 mb-2">
                {' '}
                <ul>
                  <li className="f_700">
                    {props.editProject ? 'Update Property' : 'New Property'}
                  </li>
                </ul>
              </div>

              <div className="customer_imgs as-link">
                <img src={DEFAULT_IMAGE} alt="" />
              </div>
            </div>
            <div className="d-flex align-items-center fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right justify-content-center  w-100">
              <div
                className="create-ticket-tabs custom-create-ticket-tabs"
                style={{
                  width: 'auto',
                  margin: '0px',
                }}>
                <div className="event-tabs tabs-style-2 mb-0">
                  <ul>
                    {tabs.map(tab => (
                      <li>
                        <Button
                          key={tab.key}
                          className={`tab-button ${
                            activeTab === tab.key ? 'active' : ''
                          }`}
                          onClick={() => handleTabClick(tab.key)}>
                          {tab.name}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {activeTab === 'General' && (
              <Scrollbars
                className="custom-scroll"
                renderTrackHorizontal={props => (
                  <div {...props} style={{display: 'none'}} />
                )}
                renderTrackVertical={props => (
                  <div {...props} style={{display: 'none'}} />
                )}>
                <div className="fixed-width-inputs black-head-form-pad-left black-head-form-pad-right">
                  <GeneralProperty
                    validated={validated}
                    setValidated={setValidated}
                    setProperties={setProperties}
                    properties={properties}
                    buttonRef={buttonRef}
                    handle={handle}
                  />
                </div>
              </Scrollbars>
            )}
            <div className="fixed-width-inputs black-head-form-pad-left black-head-form-pad-right">
              {activeTab === 'Blueprint' && (
                <BluePrint
                  validated={validated}
                  setValidated={setValidated}
                  setProperties={setProperties}
                  properties={properties}
                  buttonRef={buttonRef}
                  handle={handle}
                  setpropertyListing={setpropertyListing}
                  propertyListing={propertyListing}
                  setShowAreaComponent={setShowAreaComponent}
                  showAreaComponent={showAreaComponent}
                />
              )}
              {activeTab === 'Notes' && (
                <PropertyNotes
                  
                />
              )}
              {activeTab === 'POCs' && (
                <PropertyPoc
                pocAliase={pocAliase}
                setPocAliase={setPocAliase}
                setDeleteItem={setDeleteItem}
                pocList={pocList}
                setPocList={setPocList}
                setShowDeleteModal={setShowDeleteModal}
                buttonRef={buttonRef}
                handle={handle}
                validated={validated}
                setValidated={setValidated}
                />
              )}


            </div>
          </div>

          <div className="filter-btn-wrapper mb-2">
            <div className="invite-btn text-center bg_transparent bottom-0 fixed-width-inputs fix-width-forms">
              <Button
                className={'btn-dark btn btn-primary'}
                type="submit"
                onClick={() => {
                  buttonRef.current.click();
                }}>
                Create Property
              </Button>
            </div>
          </div>
        </div>
      </SlidingPanel>

            {showDeleteModel && (
              <ConfirmationModel
                closeModel={() => {
                  setShowDeleteModal(false);
                }}
                discription={`Are you sure you want to delete this poc`}
                buttonOne={'Yes'}
                buttonTwo={'No Thanks'}
                ButtonOneAction={() => {
                  deletePoc();
                }}
              />
            )}


    </>
  );
};
export default CreateEditProperty;
