import {useEffect, useState} from 'react';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Moment from 'react-moment';
import {messagesRecordFound} from '../../Utils/NoRecordConstant';
import NorecordFound from '../NoRecord/NorecordFound';
import {fixName} from '../../Utils/commonUtils';
import SvgIconComponent from '../../Constants/SvgIconComponent';

const TwillioCallCenterSms = props => {
  const {callHistoryList22, showSpinner, setShowBuildersComponent, setUserChat, subTabType} = props;

  // const callHistoryList22 = [
  //   {
  //     id: '1',
  //     receiver_detail: {
  //       first_name: 'John',
  //       last_name: 'Doe',
  //       profile_img: 'https://example.com/john-profile.jpg',
  //     },
  //     receiver_phone: 'Team',
  //     status: 'Completed',
  //     duration: 120, // duration in seconds
  //     created_at: '2024-12-23T14:30:00Z',
  //   },
  //   {
  //     id: '2',
  //     receiver_detail: {
  //       first_name: 'Jane',
  //       last_name: 'Smith',
  //       profile_img: 'https://example.com/jane-profile.jpg',
  //     },
  //     receiver_phone: 'Customer',
  //     status: 'Missed',
  //     duration: null, // no duration for missed calls
  //     created_at: '2024-12-23T15:00:00Z',
  //   },
  //   {
  //     id: '3',
  //     receiver_detail: {
  //       first_name: 'Alice',
  //       last_name: 'Johnson',
  //       profile_img: null, // no profile image
  //     },
  //     receiver_phone: 'Customer',
  //     status: 'Completed',
  //     duration: 250, // duration in seconds
  //     created_at: '2024-12-23T16:30:00Z',
  //   },
  //   {
  //     id: '4',
  //     receiver_detail: {
  //       first_name: 'Bob',
  //       last_name: 'Williams',
  //       profile_img: 'https://example.com/bob-profile.jpg',
  //     },
  //     receiver_phone: 'Support',
  //     status: 'Missed',
  //     duration: null,
  //     created_at: '2024-12-23T17:45:00Z',
  //   },
  // ];

  return (
    <>
      <div className="session-wrapper history-sessions cursor-default">
        {Object.entries(callHistoryList22).length > 0 ? (
          Object.entries(callHistoryList22).length > 0 &&
          callHistoryList22.map((item, index) => (
            <div
              key={index + 'ASDSAD' + item.id}
              className="events-item as-link"
              style={{padding: '11px', marginBottom: '10px',
                borderRadius:'7px'
              }}>
              <div 
              onClick={()=>{
                setShowBuildersComponent('showMessage')
                setUserChat(item?.chat_with);
              }} 
              className="session-heading twillo-session-heading" style={{alignItems: 'unset'}}>
                <div className="persons-img-list persons-twillo-img-list mb-0 d-flex align-items-center">
                  <div className="img-item mb-0" 
                  style={{width: '60px'}}
                  >
                    <img
                       src={
                        item?.chat_with?.profile_img
                          ? item?.chat_with?.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt={item?.chat_with?.first_name}
                      style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: index == 2 && '17%',
                      }}
                    />
                  </div>
                  <div className="twillo-call-list-holder">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div className="m-0">
                      {fixName(
                        item?.chat_with?.first_name,
                        item?.chat_with?.last_name,
                      )}
                      </div>
                      <div className="" style={{
                        display:'flex',
                        justifyContent:'space-between',
                        gap:'10px'
                      }}>
                        <div>
                          <Moment format="HH:mm A">{item?.last_message?.timestamp}</Moment>
                        </div>
                        {/* <div>
                          <SvgIconComponent icon="arrowDown" />
                        </div> */}
                      </div>
                    </div>

                    <div className="">
                    {item?.last_message?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NorecordFound noRecordData={messagesRecordFound} />
        )}
      </div>
    </>
  );
};

export default TwillioCallCenterSms;
