import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap'; // Adjust based on your UI library
import CustomModal from '../CustomModal';

const RecentFilterModel = props => {
  const [checkFilterOption, setCheckFilterOption] = useState(props.modelFilter ? props.modelFilter : 'all');
  const [filterOptionValue, setFilterOptionValue] = useState(props.modelFilter ? props.modelFilter  : 'All');

  // Handle checkbox toggle
  const handleClickList = (id, value) => {
    setCheckFilterOption(id);
    setFilterOptionValue(value);
  };

  // Action to perform when filters are applied
  const submit = () => {
    props.setModelFilter(checkFilterOption);
    props.onClose();
  };

  return (
    <>
      <CustomModal
        className="shareables-workspace-modal"
        size="small"
        onClose={() => props.onClose()}>
        <div className="filter-wrapper">
          <div className="filter-category-list">
            <ul>
              {/* Loop over the predefined filter options */}
              {props.filterOptions.map(item => (
                <li
                  key={item.id}
                  className={
                    checkFilterOption.includes(item.id) ||
                    filterOptionValue.includes(item.title)
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    {item.title}
                    <input
                      type="checkbox"
                      id={item.id}
                      value={item.title}
                      onChange={() => handleClickList(item.id, item.title)}
                      checked={
                        checkFilterOption.includes(item.id) ||
                        filterOptionValue.includes(item.title)
                      }
                    />
                    <span />
                  </Form.Label>
                </li>
              ))}
            </ul>

            {/* Filter Button */}
            <div className="text-center">
              <Button
                type="submit"
                className="btn-dark"
                onClick={() => {
                  submit(1); // Adjust page number as needed
                  // setSearchFilterModal(false);
                }}>
                Filter
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RecentFilterModel;
