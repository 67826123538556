import React from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {useState, useEffect, useRef} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {project_management, RoiLogo, Templates} from '../../Constants/Images';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {
  capitalizeFirst,
  getTimeDifference,
  updateDurations,
  generateMembershipItem,
  transformDataForDate,
  getMaxDateFromToday,
  getStartEndDates,
  getDates,
  changeFileVariable,
  fixProjectTitle,
  formatDateToDDMMYY,
} from '../../Utils/commonUtils';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';

import commonApi from '../../Services/CommonService';
import CustomModal from '../CustomModal';
import moment from 'moment';
import MembershipDealItem from '../Deals/MembershipDealItem';
import Calender from '../FeedBack/Calender';
import {generateId} from '../../Utils/Helpers';
import CreateAction from '../Snapshot/CreateAction';
import CreateCatalog from '../Catalog/CreateCatalog';
import NorecordFound from '../NoRecord/NorecordFound';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import Upload from './Upload';
import ViewFile from '../UplioadFile/ViewFile';

const EditProject = props => {
  const {selectedProjectData, projectListingBoardData} = props;

  console.log(selectedProjectData, 'selectedProjectDataselectedProjectData');
  const [tabType, setTabType] = useState('offerings');
  const [fromCalendar, setFromCalendar] = useState(false);
  const [toCalendar, setToCalendar] = useState(false);

  const [milestoneTitle, setMilestoneTitle] = useState('');
  const [milestone_start_date, setmilestone_start_date] = useState('');
  const [milestone_end_date, setmilestone_end_date] = useState('');
  const [delivery_start_date, setdelivery_start_date] = useState('');
  const [delivery_end_date, setdelivery_end_date] = useState('');
  const [deliverableTitle, setDeliverableTitle] = useState('');
  const [projectName, setProjectName] = useState('');
  const [showDeleteModel, setShowDeleteModel] = useState('');

  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('Overview');

  const [indexes, setIndexes] = useState({});
  const [membershipList, setMembershipList] = useState([]);

  const [deleteData, setDeleteData] = useState({});
  // Invite States

  const buttonRef = useRef(null);

  const [milestones, setMilestones] = useState(
    transformDataForDate(selectedProjectData?.milestones),
  );

  console.log(milestones, 'kkkkkkkkkkkkkkkkkkkkkkkkkkk');
  console.log(selectedProjectData, 'mmmmmmmmmmmmmmm');

  const [activeMilestoneIndex, setActiveMilestoneIndex] = useState(null);
  const [activeDeliverableIndex, setActiveDeliverableIndex] = useState(null);
  const [productDetail, setProductDetail] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [searchOfferingTask, setSearchOfferingTask] = useState('');
  const today = new Date();
  const [showDateModal, setShowDateModal] = useState(false);
  // const [showModal, setShowModal] = useState({});
  const [projectDetail, setProjectDetail] = useState({});
  const [showComponent, setShowComponent] = useState('');

  const [editProject, setEditProject] = useState(false);

  const [mile_index, setMile_index] = useState(0);
  const [deli_index, setDeli_index] = useState(0);
  const [milestoneDropdown, setMilestoneDropdown] = useState('');
  const [deliverableDropdown, setDeliverableDropdown] = useState('');
  const [documentInfo, setDocumentInfo] = useState('');
  const [showModel, setshowModel] = useState('');
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [uploadFilesListing, setUploadFilesListing] = useState(
    projectListingBoardData?.upload_document.length > 0 
    ? projectListingBoardData?.upload_document 
    : []
  );

  useEffect(() => {
    setmilestone_start_date('');
    setmilestone_end_date('');
    setdelivery_start_date('');
    setdelivery_end_date('');
  }, [activeMilestoneIndex, activeDeliverableIndex]);

  const handleStartEndMilestoneDate = () => {
    const validRange =
      moment(milestone_start_date).isBefore(milestone_end_date);

    console.log(validRange, 'validRangevalidRangevalidRange');

    if (milestoneTitle !== '') {
      if (
        milestone_end_date !== '' &&
        milestone_start_date !== '' &&
        validRange
      ) {
        addMilestone(milestoneTitle);
      } else if (
        milestone_end_date !== '' &&
        milestone_start_date !== '' &&
        validRange === false
      ) {
        // Both dates are present but not valid -> Show error message
        errorMessage('Please select a valid range');
      } else if (milestone_end_date === '' || milestone_start_date === '') {
        // One of the dates is missing -> Show error message
        errorMessage('Please select both dates');
      }
    } else {
      errorMessage('Milestone title cannot be empty');
    }
  };

 

  const handleStartEndDeliverableDate = (mileindex, deliveryTitle) => {
    const validRange = moment(delivery_start_date).isBefore(delivery_end_date);

    console.log(validRange, 'validRangevalidRangevalidRange');

    if (deliverableTitle !== '') {
      if (
        delivery_end_date !== '' &&
        delivery_start_date !== '' &&
        validRange
      ) {
        addDeliverable(mileindex, deliveryTitle);
      } else if (
        delivery_end_date !== '' &&
        delivery_start_date !== '' &&
        validRange === false
      ) {
        // Both dates are present but not valid -> Show error message
        errorMessage('Please select a valid range');
      } else if (delivery_end_date === '' || delivery_start_date === '') {
        // One of the dates is missing -> Show error message
        errorMessage('Please select both dates');
      }
    } else {
      errorMessage('Deliverable title cannot be empty');
    }
  };

  const handleMilestoneSelect = selectedTitle => {
    setMilestoneDropdown(selectedTitle);

    const index = milestones.findIndex(item => item.id === selectedTitle);
    setMile_index(index);

    // setDeliverableDropdown('');
    setDeliverableDropdown(
      milestones[index]?.deliverables &&
        milestones[index]?.deliverables.length > 0
        ? milestones[index]?.deliverables[0].title
        : '',
    );
  };

  const handleOverViewSelect = e => {
    if (milestones && milestones.length > 0) {
      setMilestoneDropdown(milestones[0].id);
    }
    setTabType('offerings');

    if (e === 'Deliverables' || e === 'MileStones') {
      setActiveMilestoneIndex(null);
      setActiveDeliverableIndex(null);
    }
    if (
      (e === 'Tasks' || e === 'Offerings') &&
      milestones[0]?.deliverables &&
      milestones[0]?.deliverables.length > 0
    ) {
      // const milestoneIndex = milestones.findIndex(item => item.id === selectedTitle);

      const deliverableIndex = milestones[0]?.deliverables.findIndex(
        item => item.title === milestones[0]?.deliverables[0].title,
      );
      setMile_index(0);
      setDeli_index(deliverableIndex);
      setDeliverableDropdown(milestones[0]?.deliverables[0].title);
      setActiveDeliverableIndex(0);
      if (e === 'Tasks') {
        setTabType('tasks');
      } else {
        setTabType('offerings');
      }
    }
  };

  useEffect(() => {
    if (milestones && milestones.length > 0) {
      setMilestoneDropdown(milestones[0].id);
      setMile_index(0);
    }
  }, [dropdownId]);

  useEffect(() => {
    setSearchOfferingTask('');
  }, [dropdownId, showComponent]);

  const handleResest = () => {
    setDropdownId('Overview');
  };

  console.log(milestones[mile_index]?.deliverables, 'milestones[mile_index]?.deliverables')

  const handleDeliverableSelect = selectedTitle => {
    const index = milestones[mile_index]?.deliverables.findIndex(
      item => item.title === selectedTitle,
    );
    setDeli_index(index);
    setActiveDeliverableIndex(index);
    setDeliverableDropdown(selectedTitle);
  };

  const addDeliverable = (milestoneIndex, title) => {
    const trimmedTitle = title.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
      errorMessage('Deliverable title cannot be empty');
      return;
    }
    const newMilestones = [...milestones];
    const milestone = newMilestones[milestoneIndex];

    // Check if the deliverable with the same title already exists in the current milestone
    const deliverableExists = milestone.deliverables.some(
      d => d.title === title,
    );

    if (deliverableExists) {
      // Title already exists, show error
      errorMessage('Deliverable title already exists.');
      return; // Exit the function
    } else {
      // Deliverable does not exist, so add it
      milestone.deliverables.push({
        id: title,
        title: title,
        delivery_start_date: delivery_start_date,
        delivery_end_date: delivery_end_date,
        task_ids: [],
        offering_ids: [],
      });

      setDeliverableTitle('');
      setdelivery_start_date('');
      setdelivery_end_date('');
    }

    setMilestones(newMilestones);
  };
  const deleteDeliverable = item => {
    const newMilestones = [...milestones];
    const milestone = newMilestones[item?.mile_Index];

    // Find the index of the deliverable with the given title
    const deliverableIndex = milestone.deliverables.findIndex(
      d => d.title === item.title,
    );
    if (deliverableIndex !== -1) {
      // Deliverable exists, so remove it
      milestone.deliverables.splice(deliverableIndex, 1);
      setShowDeleteModel('');
    } else {
      // Deliverable does not exist, show error
      errorMessage('Deliverable title not found.');
      return; // Exit the function
    }

    setMilestones(newMilestones);
  };

  const addTaskOrOffering = (milestoneIndex, deliverableIndex, type, item) => {
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[milestoneIndex].deliverables[deliverableIndex];

    let membershipList = [];
    let memberShip = false;
    if (type === 'offering') {
      if (item.catalog_details && item.catalog_details.length > 0) {
        // Push all catalog_details items into offering_ids
        item.catalog_details.forEach(detail => {
          if (detail.catalog_type === 'membership') {
            membershipList.push(generateMembershipItem(detail));
            memberShip = true;
          } else {
            deliverable.offering_ids.push(detail);
          }
        });

        if (memberShip) {
          setMembershipList(membershipList);
          setIndexes({
            milestoneIndex: milestoneIndex,
            deliverableIndex: deliverableIndex,
          });
          setShowComponent('membershipLevel');
        }
      } else {
        // Push the single item into offering_ids

        if (item.catalog_type === 'membership') {
          membershipList.push(generateMembershipItem(item));

          setMembershipList(membershipList);
          setIndexes({
            milestoneIndex: milestoneIndex,
            deliverableIndex: deliverableIndex,
          });
          setShowComponent('membershipLevel');
        } else if (
          !deliverable.offering_ids.some(offering => offering.id === item.id)
        ) {
          deliverable.offering_ids.push(item);
        } else {
          errorMessage('You have already added this offering');
        }
      }
      setProductDetail([]);
      setSearchOfferingTask('');
    } else if (type === 'task') {
      if (!deliverable.task_ids.some(task_ids => task_ids.id === item.id)) {
        deliverable.task_ids.push(item);
        setTaskList([]);
        setSearchOfferingTask('');
      } else {
        errorMessage('You have already added this task');
      }
    }
    setMilestones(newMilestones);
  };

  const deleteTaskOrOffering = (
    milestoneIndex,
    deliverableIndex,
    type,
    itemIndex,
  ) => {
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[milestoneIndex].deliverables[deliverableIndex];

    if (type === 'offering') {
      deliverable.offering_ids.splice(itemIndex, 1);
    } else if (type === 'task') {
      deliverable.task_ids.splice(itemIndex, 1);
    }

    setMilestones(newMilestones);
  };
  const dropdownValues = [
    {title: 'Overview', id: 'Overview'},
    {id: 'MileStones', title: 'MileStones'},
    {id: 'Deliverables', title: 'Deliverables'},
    {id: 'Tasks', title: 'Tasks'},
    {id: 'Offerings', title: 'Offerings'},
    {id: 'Uploads', title: 'Uploads'},
  ];

  const renderProjeectButtons = (profile) => {
    const creator_id = selectedProjectData.created_by === localStorage.getItem('id');
        
    return (
      <>
        {creator_id ? (
          !editProject ? (
            <div className="search-tabs-icons createCircle d-flex gap-2">
            <span
              className="icon mt-0"
              onClick={() => {
                setEditProject(true);
              }}>
             <EditPencilSvg fill={'white'} h={20} w={20} />
            </span>
          </div>
          ) : (
            <img
              src={RoiLogo}
              onClick={() => setEditProject(false)}
              alt=""
              style={{ cursor: 'pointer' }}
            />
          )
        ) : (
          <img
            src={RoiLogo}
            alt=""
            style={{ cursor: 'pointer' }}
          />
        )}
      </>
    );
  };
  

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handleProjectDetail = () => {
    const data = {
      milestones:
        milestones && milestones.length > 0
          ? milestones.map(milestone => ({
              title: milestone.title,
              milestone_start_date:
                milestone.milestone_start_date &&
                milestone.milestone_start_date !== '0000-00-00' &&
                milestone.milestone_start_date !== ''
                  ? milestone.milestone_start_date
                  : '',

              milestone_end_date:
                milestone.milestone_end_date &&
                milestone.milestone_end_date !== '' &&
                milestone.milestone_end_date !== '0000-00-00'
                  ? milestone.milestone_end_date
                  : '',

              id: milestone?.id ? milestone?.id : '',

              deliverables:
                milestone.deliverables && milestone.deliverables.length > 0
                  ? milestone.deliverables.map(deliverable => {
                      const offeringData = deliverable.offering_ids.map(
                        item => ({
                          id: item?.level
                            ? item?.level?.frequency_id
                            : item.id || null,
                          date: item.duration || '',
                          type: item.catalog_type || item.type,
                        }),
                      );

                      const taskData = deliverable.task_ids.map(item => ({
                        id: item.id || null,
                        date: item.due_date || '',
                      }));

                      return {
                        title: deliverable.title,
                        id: deliverable?.deliverable_id
                          ? deliverable?.deliverable_id
                          : '',

                        delivery_start_date:
                          deliverable.delivery_start_date &&
                          deliverable.delivery_start_date !== '0000-00-00' &&
                          deliverable.delivery_start_date !== ''
                            ? deliverable.delivery_start_date
                            : '',

                        delivery_end_date:
                          deliverable.delivery_end_date &&
                          deliverable.delivery_end_date !== '0000-00-00' &&
                          deliverable.delivery_end_date !== ''
                            ? deliverable.delivery_end_date
                            : '',

                        offering_ids: offeringData || [],
                        task_ids: taskData || [],
                      };
                    })
                  : [],
            }))
          : [],
    };

    // const uploadFile = uplo

    const {
      name = '',
      id: project_id = '',
      tag_titles,
      team_alias = '',
      customer_ids = '',
      poc_ids = '',
    } = selectedProjectData || {};

    const payload = {
      name: projectName ? projectName : name,
      project_id,
      tag_titles,
      team_alias,
      customer_ids,
      poc_ids,
      milestones: data.milestones,
      deliverable_id: '',
      milestone_id: '',
      upload_document : uploadFilesListing
    };

    commonApi
      .create_update_projects(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);
            props.handleProjectAddEdit(res.data, 'edit');
            props.onClose();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handle = e => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      handleProjectDetail();
    }
  };

  const addMilestone = title => {
    // Trim the title to remove any leading/trailing whitespace
    const trimmedTitle = title.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
      errorMessage('Milestone title cannot be empty');
      return;
    }

    // Check if a milestone with the same title already exists
    const milestoneExists = milestones.some(m => m.title === trimmedTitle);

    if (!milestoneExists) {
      setMilestones([
        ...milestones,
        {
          id: trimmedTitle,
          title: trimmedTitle,
          milestone_start_date: milestone_start_date,
          milestone_end_date: milestone_end_date,
          deliverables: [],
        },
      ]);
      setMilestoneTitle('');
      setmilestone_start_date('');
      setmilestone_end_date('');
    } else {
      errorMessage('Milestone title already exists');
    }
  };

  const handleSaveMemberShip = () => {
    const Membership = updateDurations(membershipList, today);
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[indexes?.milestoneIndex].deliverables[
        indexes?.deliverableIndex
      ];

    deliverable.offering_ids = [...deliverable.offering_ids, ...Membership];
    setMilestones(newMilestones);

    setShowComponent('');
    setMembershipList([]);
    setIndexes({});
  };

  const deleteMilestone = index => {
    const newMilestones = [...milestones];

    // Remove the milestone at the specified index
    newMilestones.splice(index, 1);
    setShowDeleteModel('');
    setActiveMilestoneIndex(null);

    setMilestones(newMilestones);
  };

  const get_catalogs_for_invoices = value => {
    const payload = {search_query: value, type: 'web'};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          if (!value || value === '') {
            setProductDetail([]);
          } else {
            setProductDetail(res.catalog_data);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_actions_for_project_managemnet = value => {
    const payload = {search_query: value};
    commonApi
      .get_actions_for_project_managemnet(payload)
      .then(res => {
        if (res.status === 200) {
          if (!value || value === '') {
            setTaskList([]);
          } else {
            setTaskList(res.all_actions);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    setSearchOfferingTask('');
  }, [tabType]);
  useEffect(() => {
    handleResest();
    setMilestones(transformDataForDate(selectedProjectData?.milestones));
    setProjectName(selectedProjectData?.name);
  }, [selectedProjectData]);
  useEffect(() => {
    if (props.projectType === 'add') {
      setEditProject(true);
    }
  }, []);


  useEffect(()=>{
    setEditProject(false)
  },[selectedProjectData])

  const removeName = () => {
    if (deleteData.type === 'milestone') {
      deleteMilestone(deleteData.milestone_index);
    } else if (deleteData.type === 'deliverable') {
      deleteDeliverable(deleteData);
    } else if (deleteData.type === 'offering') {
      deleteTaskOrOffering(
        deleteData.milestone_index,
        deleteData.deliverable_index,
        deleteData.type,
        deleteData.Offering_index,
      );
    } else if (deleteData.type === 'task') {
      deleteTaskOrOffering(
        deleteData.milestone_index,
        deleteData.deliverable_index,
        deleteData.type,
        deleteData.Task_index,
      );
    }
    setDeleteData({
      model: false,
    });
  };

  const checkDateFormat = input => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    const isDateFormat = datePattern.test(input);

    const isValidDate = isDateFormat ? !isNaN(new Date(input)) : false;

    return isValidDate ? input : '0';
  };

  const handleDates = types => {
    const newMilestones = [...milestones];

    if (types === 'offeringTasks') {
      if (tabType === 'tasks' || dropdownId === 'Tasks') {
        milestones[projectDetail.index_Milestone].deliverables[
          projectDetail.index_Deliverable
        ].task_ids[projectDetail.index_Task].due_date = projectDetail.date;
      } else if (dropdownId === 'Offerings' || tabType === 'offerings') {
        milestones[projectDetail.index_Milestone].deliverables[
          projectDetail.index_Deliverable
        ].offering_ids[projectDetail.index_Offering].duration =
          projectDetail.date;
      }
    } else if (types === 'milestoneDeli') {
      if (projectDetail.type === 'deliverable') {
        const validRange = moment(projectDetail.delivery_start_date).isBefore(
          projectDetail.delivery_end_date,
        );

        if (
          projectDetail.delivery_end_date !== '' &&
          projectDetail.delivery_start_date !== '' &&
          validRange
        ) {
          milestones[projectDetail.index_Milestone].deliverables[
            projectDetail.index_Deliverable
          ].delivery_start_date = projectDetail.delivery_start_date;
          milestones[projectDetail.index_Milestone].deliverables[
            projectDetail.index_Deliverable
          ].delivery_end_date = projectDetail.delivery_end_date;

          setProjectDetail({
            modal: false,
          });
        } else if (
          projectDetail.delivery_end_date !== '' &&
          projectDetail.delivery_start_date !== '' &&
          validRange === false
        ) {
          errorMessage('Please select a valid range');
        } else if (
          projectDetail.delivery_end_date === '' ||
          projectDetail.delivery_start_date === ''
        ) {
          errorMessage('Please select both dates');
        }
      } else if (projectDetail.type === 'milestone') {
        const validRange = moment(projectDetail.milestone_start_date).isBefore(
          projectDetail.milestone_end_date,
        );

        if (
          projectDetail.milestone_end_date !== '' &&
          projectDetail.milestone_start_date !== '' &&
          validRange
        ) {
          milestones[projectDetail.index_Milestone].milestone_start_date =
            projectDetail.milestone_start_date;
          milestones[projectDetail.index_Milestone].milestone_end_date =
            projectDetail.milestone_end_date;
          setProjectDetail({
            modal: false,
          });
        } else if (
          projectDetail.milestone_end_date !== '' &&
          projectDetail.milestone_start_date !== '' &&
          validRange === false
        ) {
          errorMessage('Please select a valid range');
        } else if (
          projectDetail.milestone_end_date === '' ||
          projectDetail.milestone_start_date === ''
        ) {
          errorMessage('Please select both dates');
        }
      }
    }

    setMilestones(newMilestones);
  };

  useEffect(() => {
    setFromCalendar(false);
    setToCalendar(false);
  }, [projectDetail.modal, showDateModal]);

  const getSelectedDate = (milestoneDate, deliverableDtae) => {
    const date =
      projectDetail.type === 'milestone' ? milestoneDate : deliverableDtae;
    const getDateeeee =
      date &&
      date !== '' &&
      date !== '0000-00-00' &&
      moment(date, 'YYYY-MM-DD', true).isValid()
        ? new Date(date)
        : '';

    return getDateeeee;
  };


  
  const delete_document_files = () => {
    const deleteFile = uploadFilesListing?.length > 0 && uploadFilesListing?.filter((item)=> item.file !== documentInfo.file)
    setUploadFilesListing(deleteFile)
    setshowModel('');

  };

  const handleFiles = (item, type)=>{
    if(type === 'delete'){
    setshowModel('confirmation_Model')
    setDocumentInfo(item)
    }else if(type === 'upload'){
    setUploadFilesListing([
      ...uploadFilesListing,
      {
          file: item?.file,
          file_extension: item?.file_extension,
          file_title: item?.file_title
      },
    ]);
  }else if(type === 'open'){
    setShowFileComponent(true)
    
    setDocumentInfo(changeFileVariable(item))
  }
  }

  console.log(uploadFilesListing, 'uploadFilesListinguploadFilesListinguploadFilesListing')

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
          setMilestones([]);
        }}
        renderData={renderProjeectButtons}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}>
        <div
          className="serach-filter-wrapper create-sessionEvents"
          style={{
            // paddingLeft: '23px',
            paddingTop: '20px',
          }}>
          <div className="create-ticket-form  primary_panel_content">
            <div className="messages-header mb-2 fixed-width-inputs black-head-form-pad-left">
              <div className="event-tabs tabs-style-2 mb-2">
                {' '}
                <ul>
                  <li className="f_700">
                    {/* {props.editProject ? 'Update Project' : 'Create Project'} */}
                  </li>
                </ul>
              </div>

              <div className="search-tabs-icons">
                <img
                  className="template-img"
                  src={Templates}
                  alt=""
                  onClick={() => {}}
                />
              </div>
            </div>

            <div className=" create-template refferal-list-wrapper">
              <Scrollbars autoHide>
                <div className=" create-project-content fixed-width-inputs black-head-form-pad-left">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <div
                      style={{marginBottom: '72px'}}
                      className="customer-name-fields-holder">
                      <div className="fieldset">
                        {/* <Form.Label controlid="validationCustom01">
                          Project Name
                        </Form.Label> */}
                        <CustomDropdown
                          setDropdownId={setDropdownId}
                          dropdownId={dropdownId}
                          DropDownValues={dropdownValues}
                          itemName={['title']}
                          className={'mb-3'}
                          handleDropdownValue={e => {
                            handleOverViewSelect(e);
                          }}
                          type={'milestone'}
                        />
                      </div>

                      {dropdownId === 'MileStones' && (
                        <>
                          <div className="d-flex">
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  {!editProject && milestones?.length > 0
                                    ? 'Milestones'
                                    : editProject && 'Milestone Title'}
                                </Form.Label>
                                {editProject && (
                                  <span
                                    className={'icon search-tabs-icons as-link'}
                                    style={{marginRight: '5px'}}>
                                    <i
                                      className="icon-plus"
                                      onClick={() => {
                                        //   addMilesStone_list(milestoneTitle);
                                        // addMilestone(milestoneTitle);
                                        handleStartEndMilestoneDate();
                                      }}
                                    />
                                  </span>
                                )}
                              </div>
                              {editProject && (
                                <div className="input-plus-icon-holder">
                                  <Form.Control
                                    name="phase"
                                    value={milestoneTitle}
                                    pattern="^\S.*$"
                                    placeholder="Milestone Title"
                                    // onChange={handleInputChange}
                                    onChange={e => {
                                      setMilestoneTitle(e.target.value);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {editProject && (
                            <div className="d-flex mt-3">
                              <div
                                className="fieldset flex-1"
                                style={{margin: '0px'}}>
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlid="validationCustom01">
                                    Start Date
                                  </Form.Label>
                                </div>
                                <div className="input-plus-icon-holder">
                                  <div className="w-100">
                                    <Calender
                                      minDate={moment(today).format(
                                        'YYYY-MM-DD',
                                      )}
                                      maxDate={getMaxDateFromToday}
                                      setSelectedDate={e => {
                                        if (e) {
                                          setmilestone_start_date(e);
                                        } else {
                                          setmilestone_start_date('');
                                        }
                                      }}
                                      selectedDate={
                                        milestone_start_date
                                          ? new Date(milestone_start_date)
                                          : ''
                                      }
                                      showDate={true}
                                      showTime={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div
                                className="fieldset flex-1"
                                style={{margin: '0px'}}>
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlid="validationCustom01">
                                    End Date
                                  </Form.Label>
                                </div>
                                <div className="input-plus-icon-holder">
                                  <div className="w-100">
                                    <Calender
                                      minDate={moment(today).format(
                                        'YYYY-MM-DD',
                                      )}
                                      // maxDate={getMaxDateFromToday}
                                      setSelectedDate={e => {
                                        if (e) {
                                          setmilestone_end_date(e);
                                        } else {
                                          setmilestone_end_date('');
                                        }
                                      }}
                                      selectedDate={
                                        milestone_end_date
                                          ? new Date(milestone_end_date)
                                          : ''
                                      }
                                      showDate={true}
                                      showTime={false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="mb-3 milesstones-field-block mt-3">
                            {milestones &&
                            milestones.length > 0 &&
                            Object.entries(milestones).length > 0
                              ? milestones.map((milestone, mile_Index) => {
                                  const isActive =
                                    mile_Index === activeMilestoneIndex;

                                  return (
                                    <div
                                      key={mile_Index}
                                      className="d-flex flex-column mt-3">
                                      <div className="d-flex align-items-center phases_item">
                                        <div
                                          className="customer_name flex-1"
                                          style={{cursor: 'pointer'}}
                                          onClick={() =>
                                            setActiveMilestoneIndex(
                                              isActive ? null : mile_Index,
                                            )
                                          }>
                                          <div className="deliverables_list mt-0">
                                            <div className="deliverable_item">
                                              {fixProjectTitle(capitalizeFirst(milestone.title))}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              cursor: editProject
                                                ? 'pointer'
                                                : 'default',
                                              color: '#5ABD42',
                                              marginRight: '10px',
                                            }}
                                            onClick={() => {
                                              editProject &&
                                                setProjectDetail(prev => ({
                                                  ...prev,
                                                  modal: true,
                                                  type: 'milestone',
                                                  milestone_start_date:
                                                    getDates(
                                                      milestone.milestone_start_date,
                                                    ),
                                                  milestone_end_date: getDates(
                                                    milestone.milestone_end_date,
                                                  ),
                                                  index_Milestone: mile_Index,
                                                }));
                                            }}>
                                            {getStartEndDates(
                                              milestone.milestone_start_date,
                                              milestone.milestone_end_date,
                                            )}
                                          </div>
                                        </div>
                                        {editProject && (
                                          <span
                                            className="as-link cross-icon-field"
                                            onClick={() => {
                                              setDeleteData({
                                                milestone_index: mile_Index,
                                                type: 'milestone',
                                                model: true,
                                              });
                                            }}>
                                            <i
                                              className="fas fa-times text-secondary"
                                              style={{fontSize: '1.5rem'}}></i>
                                          </span>
                                        )}
                                      </div>

                                      {(isActive &&
                                        milestone[mile_Index]?.deliverables
                                          ?.length > 0 &&
                                        !editProject) ||
                                        (isActive && editProject && (
                                          <>
                                            <div className="d-flex mt-3">
                                              <div
                                                className="fieldset flex-1"
                                                style={{margin: '0px'}}>
                                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                                  <Form.Label controlId="validationCustom01">
                                                    {!editProject &&
                                                    milestone?.deliverables
                                                      ?.length > 0
                                                      ? 'Deliverables'
                                                      : editProject &&
                                                        'Deliverable Title'}
                                                  </Form.Label>
                                                  {editProject && (
                                                    <span
                                                      className="icon search-tabs-icons as-link"
                                                      style={{
                                                        marginRight: '5px',
                                                      }}
                                                      onClick={() =>
                                                        // addDeliverable(
                                                        //   activeMilestoneIndex,
                                                        //   deliverableTitle,
                                                        // )
                                                        handleStartEndDeliverableDate(
                                                          activeMilestoneIndex,
                                                          deliverableTitle,
                                                        )
                                                      }>
                                                      <i className="icon-plus" />
                                                    </span>
                                                  )}
                                                </div>
                                                {editProject && (
                                                  <div className="input-plus-icon-holder">
                                                    <Form.Control
                                                      name="phase"
                                                      value={deliverableTitle}
                                                      pattern="^\S.*$"
                                                      placeholder="Deliverable Title"
                                                      onChange={e =>
                                                        setDeliverableTitle(
                                                          e.target.value,
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            {editProject && (
                                              <div className="d-flex mt-3">
                                                <div
                                                  className="fieldset flex-1"
                                                  style={{margin: '0px'}}>
                                                  <div className="d-flex justify-content-between input-plus-icon-holder">
                                                    <Form.Label controlId="validationCustom01">
                                                      Start Date
                                                    </Form.Label>
                                                  </div>
                                                  <div className="input-plus-icon-holder">
                                                    <div className="w-100">
                                                      <Calender
                                                        minDate={moment(
                                                          today,
                                                        ).format('YYYY-MM-DD')}
                                                        // maxDate={getMaxDateFromToday}
                                                        setSelectedDate={e => {
                                                          if (e) {
                                                            const milestoneIndex =
                                                              milestones[
                                                                mile_Index
                                                              ];
                                                            const justThrowToast =
                                                              moment(
                                                                e,
                                                              ).isBefore(
                                                                milestoneIndex.milestone_start_date,
                                                              );

                                                            if (
                                                              justThrowToast
                                                            ) {
                                                              errorMessage(
                                                                'Your Deliverable start before the milestone.',
                                                              );
                                                            }

                                                            setdelivery_start_date(
                                                              e,
                                                            );
                                                          } else {
                                                            setdelivery_start_date(
                                                              '',
                                                            );
                                                          }
                                                        }}
                                                        selectedDate={
                                                          delivery_start_date
                                                            ? new Date(
                                                                delivery_start_date,
                                                              )
                                                            : ''
                                                        }
                                                        showDate={true}
                                                        showTime={false}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="fieldset flex-1"
                                                  style={{margin: '0px'}}>
                                                  <div className="d-flex justify-content-between input-plus-icon-holder">
                                                    <Form.Label controlId="validationCustom01">
                                                      End Date
                                                    </Form.Label>
                                                  </div>
                                                  <div className="input-plus-icon-holder">
                                                    <div className="w-100">
                                                      <Calender
                                                        minDate={moment(
                                                          today,
                                                        ).format('YYYY-MM-DD')}
                                                        // maxDate={getMaxDateFromToday}
                                                        setSelectedDate={e => {
                                                          if (e) {
                                                            const milestoneIndex =
                                                              milestones[
                                                                mile_Index
                                                              ];
                                                            const justThrowToast =
                                                              moment(e).isAfter(
                                                                milestoneIndex.milestone_end_date,
                                                              );

                                                            if (
                                                              justThrowToast
                                                            ) {
                                                              errorMessage(
                                                                'Your Deliverable exceeds the milestone end date.',
                                                              );
                                                            }

                                                            setdelivery_end_date(
                                                              e,
                                                            );
                                                          } else {
                                                            setdelivery_end_date(
                                                              '',
                                                            );
                                                          }
                                                        }}
                                                        selectedDate={
                                                          delivery_end_date
                                                            ? new Date(
                                                                delivery_end_date,
                                                              )
                                                            : ''
                                                        }
                                                        showDate={true}
                                                        showTime={false}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ))}

                                      {isActive &&
                                        milestone?.deliverables?.length > 0 && (
                                          <div className="mb-3">
                                            {milestone?.deliverables.length > 0
                                              ? milestone?.deliverables.map(
                                                  (
                                                    deliverable,
                                                    deliverable_Index,
                                                  ) => (
                                                    <div
                                                      key={deliverable_Index}>
                                                      <div className="d-flex align-items-center phases_item mt-3">
                                                        <div
                                                          className="customer_name flex-1"
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={() =>
                                                            setActiveDeliverableIndex(
                                                              activeDeliverableIndex ===
                                                                deliverable_Index
                                                                ? null
                                                                : deliverable_Index,
                                                            )
                                                          }>
                                                          <div className="deliverables_list mt-0">
                                                            <div className="deliverable_item">
                                                              {fixProjectTitle(capitalizeFirst(
                                                                deliverable.title,
                                                              ))}
                                                            </div>
                                                          </div>

                                                          <div
                                                            style={{
                                                              cursor:
                                                                editProject
                                                                  ? 'pointer'
                                                                  : 'default',
                                                              color: '#5ABD42',
                                                              marginRight:
                                                                '10px',
                                                            }}
                                                            onClick={() => {
                                                              editProject &&
                                                                setProjectDetail(
                                                                  prev => ({
                                                                    ...prev,
                                                                    modal: true,
                                                                    type: 'deliverable',
                                                                    delivery_start_date:
                                                                      getDates(
                                                                        deliverable.delivery_start_date,
                                                                      ),
                                                                    delivery_end_date:
                                                                      getDates(
                                                                        deliverable.delivery_end_date,
                                                                      ),
                                                                    index_Milestone:
                                                                      mile_Index,
                                                                    index_Deliverable:
                                                                      deliverable_Index,
                                                                  }),
                                                                );
                                                            }}>
                                                            {getStartEndDates(
                                                              deliverable.delivery_start_date,
                                                              deliverable.delivery_end_date,
                                                            )}
                                                          </div>
                                                        </div>
                                                        {editProject && (
                                                          <span
                                                            className="as-link cross-icon-field"
                                                            onClick={e => {
                                                              setDeleteData({
                                                                deliverable_index:
                                                                  deliverable_Index,
                                                                mile_Index:
                                                                  mile_Index,
                                                                title:
                                                                  deliverable.title,
                                                                type: 'deliverable',
                                                                model: true,
                                                              });
                                                            }}>
                                                            <i
                                                              className="fas fa-times text-secondary"
                                                              style={{
                                                                fontSize:
                                                                  '1.5rem',
                                                              }}></i>
                                                          </span>
                                                        )}
                                                      </div>
                                                      {isActive &&
                                                        activeDeliverableIndex ===
                                                          deliverable_Index && (
                                                          <>
                                                            <div className="event-tabs tabs-style-2 mb-0 mt-2">
                                                              <ul className="LabelTabs">
                                                                <li className="ml-20 d-flex align-items-center">
                                                                  <Button
                                                                    className={
                                                                      tabType ===
                                                                      'offerings'
                                                                        ? 'active'
                                                                        : ''
                                                                    }
                                                                    onClick={() =>
                                                                      setTabType(
                                                                        'offerings',
                                                                      )
                                                                    }>
                                                                    Offerings
                                                                  </Button>
                                                                </li>
                                                                <li className="ml-20 d-flex align-items-center">
                                                                  <Button
                                                                    className={
                                                                      tabType ===
                                                                      'tasks'
                                                                        ? 'active'
                                                                        : ''
                                                                    }
                                                                    onClick={() =>
                                                                      setTabType(
                                                                        'tasks',
                                                                      )
                                                                    }>
                                                                    Tasks
                                                                  </Button>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                            <div className="mt-3">
                                                              {editProject && (
                                                                <div className="input-plus-icon-holder">
                                                                  <Form.Control
                                                                    name="phase"
                                                                    value={
                                                                      searchOfferingTask
                                                                    }
                                                                    pattern="^\S.*$"
                                                                    placeholder={`Search ${tabType}`}
                                                                    onChange={e => {
                                                                      setSearchOfferingTask(
                                                                        e.target
                                                                          .value,
                                                                      );
                                                                      if (
                                                                        tabType ===
                                                                        'offerings'
                                                                      ) {
                                                                        get_catalogs_for_invoices(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                        );
                                                                      } else {
                                                                        get_actions_for_project_managemnet(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                        );
                                                                      }
                                                                    }}
                                                                  />
                                                                </div>
                                                              )}
                                                              {tabType ===
                                                                'offerings' &&
                                                                deliverable.offering_ids && (
                                                                  <div>
                                                                    <div className="mb-3">
                                                                      {searchOfferingTask ? (
                                                                        <ul className="custom-suggestions invoices_suggestion">
                                                                          <li
                                                                            className="as-link"
                                                                            key={
                                                                              'Add-offerings'
                                                                            }
                                                                            onClick={() => {
                                                                              setShowComponent(
                                                                                'createCatalog',
                                                                              );
                                                                            }}>
                                                                            Add
                                                                            Offerings
                                                                          </li>
                                                                          {productDetail.length >
                                                                            0 &&
                                                                            productDetail.map(
                                                                              suggestion => (
                                                                                <li
                                                                                  className="as-link"
                                                                                  key={
                                                                                    'suggestion' +
                                                                                    suggestion.id
                                                                                  }
                                                                                  onClick={() => {
                                                                                    addTaskOrOffering(
                                                                                      mile_Index,
                                                                                      deliverable_Index,
                                                                                      'offering',
                                                                                      suggestion,
                                                                                    );
                                                                                  }}>
                                                                                  {fixProjectTitle(capitalizeFirst(
                                                                                    suggestion.title,
                                                                                  ))}
                                                                                </li>
                                                                              ),
                                                                            )}
                                                                        </ul>
                                                                      ) : null}

                                                                      {searchOfferingTask &&
                                                                        productDetail.length ===
                                                                          0 && (
                                                                            <div className='no-records-middle-screen-holder'>
                                                                            <NorecordFound
                                                                              noRecordData={{
                                                                                img: project_management,
                                                                                title:
                                                                                  null,
                                                                                description:
                                                                                  'No offerings available.',
                                                                                button:
                                                                                  null,
                                                                              }}
                                                                            />
                                                                            {/* <p
                                                                              className={
                                                                                'noRecord-desc'
                                                                              }>
                                                                              No
                                                                              offerings
                                                                              available
                                                                            </p> */}
                                                                          </div>
                                                                        )}

                                                                      {deliverable
                                                                        .offering_ids
                                                                        .length >
                                                                        0 &&
                                                                      Object.entries(
                                                                        deliverable.offering_ids,
                                                                      ).length >
                                                                        0
                                                                        ? deliverable.offering_ids.map(
                                                                            (
                                                                              item,
                                                                              indexOffering,
                                                                            ) => (
                                                                              <div
                                                                                className="d-flex align-items-center phases_item mt-3"
                                                                                key={
                                                                                  indexOffering
                                                                                }>
                                                                                <div
                                                                                  className="customer_name flex-1"
                                                                                  style={{
                                                                                    backgroundColor:
                                                                                      'white',
                                                                                  }}>
                                                                                  <div className="alias_name">
                                                                                    {fixProjectTitle(capitalizeFirst(
                                                                                      item.title,
                                                                                    ))}
                                                                                  </div>
                                                                                  <div className="d-flex align-items-center">
                                                                                    <div
                                                                                      style={{
                                                                                        cursor:
                                                                                          editProject
                                                                                            ? 'pointer'
                                                                                            : 'default',
                                                                                        color:
                                                                                          '#5ABD42',
                                                                                        marginRight:
                                                                                          '10px',
                                                                                      }}
                                                                                      onClick={() => {
                                                                                        editProject &&
                                                                                          setShowDateModal(
                                                                                            true,
                                                                                          );

                                                                                        const formattedDuration =
                                                                                          checkDateFormat(
                                                                                            item.duration,
                                                                                          );

                                                                                        setProjectDetail(
                                                                                          prev => ({
                                                                                            ...prev,
                                                                                            date: formattedDuration,
                                                                                            index_Offering:
                                                                                              indexOffering,
                                                                                            index_Deliverable:
                                                                                              deliverable_Index,
                                                                                            index_Milestone:
                                                                                              mile_Index,
                                                                                          }),
                                                                                        );
                                                                                      }}>
                                                                                      {item.duration &&
                                                                                      item.duration !==
                                                                                        ''
                                                                                        ? checkDateFormat(
                                                                                            item.duration,
                                                                                          ) !==
                                                                                          '0'
                                                                                          ? formatDateToDDMMYY(
                                                                                              item.duration,
                                                                                            )
                                                                                          : 'Select date'
                                                                                        : 'Select date'}
                                                                                    </div>
                                                                                    {editProject && (
                                                                                      <span className="as-link cross-icon-field">
                                                                                        <i
                                                                                          className="fas fa-times text-secondary"
                                                                                          onClick={() => {
                                                                                            setDeleteData(
                                                                                              {
                                                                                                milestone_index:
                                                                                                  mile_Index,
                                                                                                deliverable_index:
                                                                                                  deliverable_Index,
                                                                                                type: 'offering',
                                                                                                Offering_index:
                                                                                                  indexOffering,
                                                                                                model: true,
                                                                                              },
                                                                                            );
                                                                                          }}
                                                                                          style={{
                                                                                            fontSize:
                                                                                              '1.5rem',
                                                                                          }}></i>
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            ),
                                                                          )
                                                                        : !editProject && (
                                                                          <div className='no-records-middle-screen-holder'>
                                                                            <NorecordFound
                                                                              noRecordData={{
                                                                                img: project_management,
                                                                                title:
                                                                                  null,
                                                                                description:
                                                                                  'No offerings available.',
                                                                                button:
                                                                                  null,
                                                                              }}
                                                                            />
                                                                            </div>
                                                                          )}
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              {tabType ===
                                                                'tasks' &&
                                                                deliverable?.task_ids && (
                                                                  <div>
                                                                    <div className="">
                                                                      {searchOfferingTask ? (
                                                                        <ul className="custom-suggestions invoices_suggestion">
                                                                          <li
                                                                            className="as-link"
                                                                            onClick={() => {
                                                                              setShowComponent(
                                                                                'create_task',
                                                                              );
                                                                            }}>
                                                                            Add
                                                                            Tasks
                                                                          </li>
                                                                          {taskList?.length >
                                                                            0 &&
                                                                            taskList.map(
                                                                              suggestion => (
                                                                                <li
                                                                                  className="as-link"
                                                                                  key={
                                                                                    'suggestion' +
                                                                                    suggestion.id
                                                                                  }
                                                                                  onClick={() => {
                                                                                    addTaskOrOffering(
                                                                                      mile_Index,
                                                                                      deliverable_Index,
                                                                                      'task',
                                                                                      suggestion,
                                                                                    );
                                                                                  }}>
                                                                                  {fixProjectTitle(capitalizeFirst(
                                                                                    suggestion.title,
                                                                                  ))}
                                                                                </li>
                                                                              ),
                                                                            )}
                                                                        </ul>
                                                                      ) : null}

                                                                      {editProject &&
                                                                        searchOfferingTask &&
                                                                        taskList?.length ===
                                                                          0 && (
                                                                            <div className='no-records-middle-screen-holder'>
                                                                          <NorecordFound
                                                                            noRecordData={{
                                                                              img: project_management,
                                                                              title:
                                                                                null,
                                                                              description:
                                                                                'No tasks available.',
                                                                              button:
                                                                                null,
                                                                            }}
                                                                          />
                                                                          </div>
                                                                        )}

                                                                      {Object.entries(
                                                                        deliverable?.task_ids,
                                                                      )
                                                                        ?.length >
                                                                      0
                                                                        ? deliverable.task_ids.map(
                                                                            (
                                                                              item,
                                                                              indexTask,
                                                                            ) => {
                                                                              return (
                                                                                <div
                                                                                  className="d-flex align-items-center mt-3"
                                                                                  key={
                                                                                    indexTask
                                                                                  }>
                                                                                  <div
                                                                                    className="customer_name flex-1"
                                                                                    style={{
                                                                                      backgroundColor:
                                                                                        'white',
                                                                                    }}>
                                                                                    <div className="alias_name">
                                                                                      {fixProjectTitle(capitalizeFirst(
                                                                                        item.title,
                                                                        ))}
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center">
                                                                                      <div
                                                                                        style={{
                                                                                          cursor:
                                                                                            editProject
                                                                                              ? 'pointer'
                                                                                              : 'default',
                                                                                          color:
                                                                                            '#5ABD42',
                                                                                          marginRight:
                                                                                            '10px',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                          editProject &&
                                                                                            setShowDateModal(
                                                                                              true,
                                                                                            );

                                                                                          setProjectDetail(
                                                                                            prev => ({
                                                                                              ...prev,
                                                                                              date:
                                                                                                item.due_date &&
                                                                                                item.due_date !==
                                                                                                  '0000-00-00 00:00:00'
                                                                                                  ? item.due_date
                                                                                                  : '',
                                                                                              index_Task:
                                                                                                indexTask,
                                                                                              index_Deliverable:
                                                                                                deliverable_Index,
                                                                                              index_Milestone:
                                                                                                mile_Index,
                                                                                            }),
                                                                                          );
                                                                                        }}>
                                                                                        {item.due_date &&
                                                                                        item.due_date !==
                                                                                          '0000-00-00 00:00:00'
                                                                                          ? formatDateToDDMMYY(
                                                                                              item.due_date,
                                                                                            )
                                                                                          : 'Select date'}
                                                                                      </div>
                                                                                      {editProject && (
                                                                                        <span className="as-link cross-icon-field">
                                                                                          <i
                                                                                            className="fas fa-times text-secondary"
                                                                                            onClick={() => {
                                                                                              setDeleteData(
                                                                                                {
                                                                                                  milestone_index:
                                                                                                    mile_Index,
                                                                                                  deliverable_index:
                                                                                                    deliverable_Index,
                                                                                                  type: 'task',
                                                                                                  Task_index:
                                                                                                    indexTask,
                                                                                                  model: true,
                                                                                                },
                                                                                              );
                                                                                            }}
                                                                                            style={{
                                                                                              fontSize:
                                                                                                '1.5rem',
                                                                                            }}></i>
                                                                                        </span>
                                                                                      )}
                                                                                      {/* </div> */}
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            },
                                                                          )
                                                                        : !editProject && (
                                                                          <div className='no-records-middle-screen-holder'>
                                                                            <NorecordFound
                                                                              noRecordData={{
                                                                                img: project_management,
                                                                                title:
                                                                                  null,
                                                                                description:
                                                                                  'No tasks available.',
                                                                                button:
                                                                                  null,
                                                                              }}
                                                                            />
                                                                            </div>
                                                                          )}
                                                                    </div>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </>
                                                        )}
                                                    </div>
                                                  ),
                                                )
                                              : !editProject && (
                                                <div className='no-records-middle-screen-holder'>
                                                  <NorecordFound
                                                    noRecordData={{
                                                      img: project_management,
                                                      title: null,
                                                      description:
                                                        'No deliverables available.',
                                                      button: null,
                                                    }}
                                                  />
                                                  </div>
                                                )}
                                          </div>
                                        )}
                                    </div>
                                  );
                                })
                              : !editProject && (
                                <div className='no-records-middle-screen-holder'>
                                  <NorecordFound
                                    noRecordData={{
                                      img: project_management,
                                      title: null,
                                      description: 'No milestones available.',
                                      button: null,
                                    }}
                                  />
                                  </div>
                                )}
                          </div>
                        </>
                      )}
                      {(dropdownId === 'Tasks' ||
                        dropdownId === 'Offerings' ||
                        dropdownId === 'Deliverables') &&
                        milestones.length > 0 && (
                          <>
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  Milestones
                                </Form.Label>
                              </div>
                            </div>

                            <CustomDropdown
                              setDropdownId={setMilestoneDropdown}
                              dropdownId={milestoneDropdown}
                              DropDownValues={milestones}
                              itemName={['title']}
                              className={'mb-3'}
                              type={'milestone'}
                              handleDropdownValue={handleMilestoneSelect}
                            />
                          </>
                        )}

                      {(dropdownId === 'Tasks' || dropdownId === 'Offerings') &&
                        milestones.length > 0 &&
                        mile_index !== null &&
                        mile_index !== -1 &&
                        milestones[mile_index].deliverables.length > 0 && (
                          <>
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  Deliverables
                                </Form.Label>
                              </div>
                            </div>

                            <CustomDropdown
                            setDropdownId={setDeliverableDropdown}
                            dropdownId={deliverableDropdown}
                            DropDownValues={milestones[mile_index]?.deliverables}
                            itemName={['title']}
                            type={'deliverable'}
                            className={'deliverable-dropdowns shadow-none mb-3'}
                            handleDropdownValue={handleDeliverableSelect}
                          />
                          
                          </>
                        )}

                      {
                        // dropdownId === 'Offerings' ? (
                        //   milestoneDropdown &&
                        //   deliverableDropdown &&
                        //   milestones.length > 0 &&
                        //   mile_index !== null &&
                        //   deli_index !== null ? (
                        //     milestones[mile_index]?.deliverables[deli_index]
                        //       ?.offering_ids?.length > 0 ? (
                        //       milestones[mile_index]?.deliverables[
                        //         deli_index
                        //       ]?.offering_ids.map((item, index) => {

                        //         return (
                        //           <div key={index}>
                        //             <div className="d-flex align-items-center mt-3">
                        //               <div
                        //                 className="customer_name flex-1"
                        //                 style={{backgroundColor: 'white'}}>
                        //                 <div className="alias_name">
                        //                   {capitalizeFirst(item.title)}
                        //                 </div>
                        //                 <div className="d-flex align-items-center">
                        //                   <div
                        //                     style={{
                        //                       cursor: editProject ? 'pointer' : 'default',
                        //                       color: '#5ABD42',
                        //                       marginRight: '10px',
                        //                     }}
                        //                     onClick={() => {
                        //                       if (editProject) {
                        //                         setShowDateModal(true);

                        //                             const formattedDuration = checkDateFormat(item.duration)

                        //                         setProjectDetail(prev => ({
                        //                           ...prev,
                        //                           date: formattedDuration,
                        //                           index_Offering: index,
                        //                           index_Deliverable: deli_index,
                        //                           index_Milestone: mile_index,
                        //                         }));
                        //                       }
                        //                     }}>
                        //                     {
                        //                       (item.duration &&
                        //                         item.duration !==
                        //                           '')
                        //                         ? checkDateFormat(
                        //                               item.duration,
                        //                           ) !==
                        //                           '0'
                        //                           ? getTimeDifference(
                        //                                 item.duration,
                        //                             )
                        //                           : 'Select date'
                        //                         : 'Select date'}

                        //                   </div>
                        //                   {editProject && (
                        //                     <span className="as-link cross-icon-field">
                        //                       <i
                        //                         className="fas fa-times text-secondary"
                        //                         onClick={() => {
                        //                           setDeleteData({
                        //                             milestone_index: mile_index,
                        //                             deliverable_index: deli_index,
                        //                             type: 'offering',
                        //                             Offering_index: index,
                        //                             model: true,
                        //                           });
                        //                         }}
                        //                         style={{fontSize: '1.5rem'}}
                        //                       />
                        //                     </span>
                        //                   )}
                        //                 </div>
                        //               </div>
                        //             </div>
                        //           </div>
                        //         );
                        //       })
                        //     ) : (
                        //       <div className="praise-list-item no-border pt-0">
                        //       <p className="text-center">
                        //         No {dropdownId} available
                        //       </p>
                        //       </div>
                        //     )
                        //   ) : (
                        //     <div className="praise-list-item no-border pt-0">
                        //     <p className="text-center">
                        //       No {dropdownId} available
                        //     </p>
                        //     </div>
                        //   )
                        // ) : dropdownId === 'Tasks' ? (
                        //   milestoneDropdown &&
                        //   deliverableDropdown &&
                        //   milestones.length > 0 &&
                        //   mile_index !== null &&
                        //   deli_index !== null ? (
                        //     milestones[mile_index]?.deliverables[deli_index]
                        //       ?.task_ids?.length > 0 ? (
                        //       milestones[mile_index]?.deliverables[
                        //         deli_index
                        //       ]?.task_ids.map((item, index) => {
                        //         return (
                        //           <div key={index}>
                        //             <div className="d-flex align-items-center mt-3">
                        //               <div
                        //                 className="customer_name flex-1"
                        //                 style={{backgroundColor: 'white'}}>
                        //                 <div className="alias_name">
                        //                   {capitalizeFirst(item.title)}
                        //                 </div>
                        //                 <div className="d-flex align-items-center">
                        //                   <div
                        //                     style={{
                        //                       cursor: editProject
                        //                         ? 'pointer'
                        //                         : 'default',
                        //                       color: '#5ABD42',
                        //                       marginRight: '10px',
                        //                     }}
                        //                     onClick={() => {
                        //                       editProject &&
                        //                         setShowDateModal(true);

                        //                       setProjectDetail(prev => ({
                        //                         ...prev,
                        //                         date:
                        //                           item.due_date &&
                        //                           item.due_date !==
                        //                             '0000-00-00 00:00:00'
                        //                             ? item.due_date
                        //                             : '',

                        //                         index_Task: index,
                        //                         index_Deliverable: deli_index,
                        //                         index_Milestone: mile_index,
                        //                       }));
                        //                     }}>
                        //                     {item.due_date && item.due_date !== ''
                        //                       ? getTimeDifference(item.due_date)
                        //                       : 'Select date'}
                        //                   </div>
                        //                   {editProject && (
                        //                     <span className="as-link cross-icon-field">
                        //                       <i
                        //                         className="fas fa-times text-secondary"
                        //                         onClick={() => {
                        //                           setDeleteData({
                        //                             milestone_index: mile_index,
                        //                             deliverable_index: deli_index,
                        //                             type: 'task',
                        //                             Task_index: index,
                        //                             model: true,
                        //                           });
                        //                         }}
                        //                         style={{fontSize: '1.5rem'}}
                        //                       />
                        //                     </span>
                        //                   )}
                        //                 </div>
                        //               </div>
                        //             </div>
                        //           </div>
                        //         );
                        //       })
                        //     ) : (
                        //       <div className="praise-list-item no-border pt-0">
                        //       <p className="text-center">
                        //         No {dropdownId} available
                        //       </p>
                        //       </div>
                        //     )
                        //   ) : (
                        //     <div className="praise-list-item no-border pt-0">
                        //     <p className="text-center">
                        //       No {dropdownId} available
                        //     </p>
                        //     </div>
                        //   )
                        // ) :
                        dropdownId === 'Deliverables' ||
                        dropdownId === 'Tasks' ||
                        dropdownId === 'Offerings' ? (
                          <>
                            {editProject &&
                              milestones.length > 0 &&
                              dropdownId !== 'Offerings' &&
                              dropdownId !== 'Tasks' && (
                                <>
                                  <div className="d-flex mt-3">
                                    <div
                                      className="fieldset flex-1"
                                      style={{margin: '0px'}}>
                                      <div className="d-flex justify-content-between input-plus-icon-holder">
                                        <Form.Label controlId="validationCustom01">
                                          {!editProject
                                            ? 'Deliverables'
                                            : 'Deliverable Title'}
                                        </Form.Label>
                                        <span
                                          className="icon search-tabs-icons as-link"
                                          style={{marginRight: '5px'}}
                                          onClick={() =>
                                            // addDeliverable(
                                            //   mile_index,
                                            //   deliverableTitle,
                                            // )
                                            handleStartEndDeliverableDate(
                                              mile_index,
                                              deliverableTitle,
                                            )
                                          }>
                                          <i className="icon-plus" />
                                        </span>
                                      </div>

                                      <div className="input-plus-icon-holder">
                                        <Form.Control
                                          name="phase"
                                          value={deliverableTitle}
                                          pattern="^\S.*$"
                                          placeholder="Deliverable Title"
                                          onChange={e =>
                                            setDeliverableTitle(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex mt-3">
                                    <div
                                      className="fieldset flex-1"
                                      style={{margin: '0px'}}>
                                      <div className="d-flex justify-content-between input-plus-icon-holder">
                                        <Form.Label controlId="validationCustom01">
                                          Start Date
                                        </Form.Label>
                                      </div>

                                      <div className="input-plus-icon-holder">
                                        <div className="w-100">
                                          <Calender
                                            minDate={moment(today).format(
                                              'YYYY-MM-DD',
                                            )}
                                            // maxDate={getMaxDateFromToday}
                                            setSelectedDate={e => {
                                              if (e) {
                                                const milestoneIndex =
                                                  milestones[mile_index];
                                                const justThrowToast = moment(
                                                  e,
                                                ).isBefore(
                                                  milestoneIndex.milestone_start_date,
                                                );

                                                if (justThrowToast) {
                                                  errorMessage(
                                                    'Your Deliverable start before the milestone.',
                                                  );
                                                }

                                                setdelivery_start_date(e);
                                              } else {
                                                setdelivery_start_date('');
                                              }
                                            }}
                                            selectedDate={
                                              delivery_start_date
                                                ? new Date(delivery_start_date)
                                                : ''
                                            }
                                            showDate={true}
                                            showTime={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="fieldset flex-1"
                                      style={{margin: '0px'}}>
                                      <div className="d-flex justify-content-between input-plus-icon-holder">
                                        <Form.Label controlId="validationCustom01">
                                          End Date
                                        </Form.Label>
                                      </div>

                                      <div className="input-plus-icon-holder">
                                        <div className="w-100">
                                          <Calender
                                            minDate={moment(today).format(
                                              'YYYY-MM-DD',
                                            )}
                                            // maxDate={getMaxDateFromToday}
                                            setSelectedDate={e => {
                                              if (e) {
                                                const milestoneIndex =
                                                  milestones[mile_index];
                                                const justThrowToast = moment(
                                                  e,
                                                ).isAfter(
                                                  milestoneIndex.milestone_end_date,
                                                );

                                                if (justThrowToast) {
                                                  errorMessage(
                                                    'Your Deliverable exeeds the milestone end date.',
                                                  );
                                                }

                                                setdelivery_end_date(e);
                                              } else {
                                                setdelivery_end_date('');
                                              }
                                            }}
                                            selectedDate={
                                              delivery_end_date
                                                ? new Date(delivery_end_date)
                                                : ''
                                            }
                                            showDate={true}
                                            showTime={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {milestones.length > 0 &&
                            mile_index !== null &&
                            deli_index !== null ? (
                              milestones[mile_index]?.deliverables?.length >
                              0 ? (
                                milestones[mile_index].deliverables.map(
                                  (item, index) => {
                                    return (
                                      <div key={index}>
                                        {dropdownId !== 'Offerings' &&
                                          dropdownId !== 'Tasks' && (
                                            <div className="d-flex align-items-center phases_item mt-3">
                                              <div
                                                className="customer_name flex-1"
                                                style={{cursor: 'pointer'}}
                                                onClick={() =>
                                                  setActiveDeliverableIndex(
                                                    activeDeliverableIndex ===
                                                      index
                                                      ? null
                                                      : index,
                                                  )
                                                }>
                                                <div className="deliverables_list mt-0">
                                                  <div className="deliverable_item">
                                                    {fixProjectTitle(capitalizeFirst(
                                                      item.title,
                                                    ))}
                                                  </div>
                                                </div>

                                                <div
                                                  style={{
                                                    cursor: editProject
                                                      ? 'pointer'
                                                      : 'default',
                                                    color: '#5ABD42',
                                                    marginRight: '10px',
                                                  }}
                                                  onClick={() => {
                                                    editProject &&
                                                      setProjectDetail(
                                                        prev => ({
                                                          ...prev,
                                                          modal: true,
                                                          type: 'deliverable',
                                                          delivery_start_date:
                                                            getDates(
                                                              item.delivery_start_date,
                                                            ),
                                                          delivery_end_date:
                                                            getDates(
                                                              item.delivery_end_date,
                                                            ),
                                                          index_Milestone:
                                                            mile_index,
                                                          index_Deliverable:
                                                            index,
                                                        }),
                                                      );
                                                  }}>
                                                  {getStartEndDates(
                                                    item.delivery_start_date,
                                                    item.delivery_end_date,
                                                  )}
                                                </div>
                                              </div>
                                              {editProject && (
                                                <span
                                                  className="as-link cross-icon-field"
                                                  onClick={e => {
                                                    setDeleteData({
                                                      deliverable_index: index,
                                                      mile_Index: mile_index,
                                                      title: item.title,
                                                      type: 'deliverable',
                                                      model: true,
                                                    });
                                                  }}>
                                                  <i
                                                    className="fas fa-times text-secondary"
                                                    style={{
                                                      fontSize: '1.5rem',
                                                    }}></i>
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        {activeDeliverableIndex === index && (
                                          <>
                                            {dropdownId === 'Deliverables' && (
                                              <div className="event-tabs tabs-style-2 mb-0 mt-2">
                                                <ul className="LabelTabs">
                                                  <li className="ml-20 d-flex align-items-center">
                                                    <Button
                                                      className={
                                                        tabType === 'offerings'
                                                          ? 'active'
                                                          : ''
                                                      }
                                                      onClick={() =>
                                                        setTabType('offerings')
                                                      }>
                                                      Offerings
                                                    </Button>
                                                  </li>

                                                  <li className="ml-20 d-flex align-items-center">
                                                    <Button
                                                      className={
                                                        tabType === 'tasks'
                                                          ? 'active'
                                                          : ''
                                                      }
                                                      onClick={() =>
                                                        setTabType('tasks')
                                                      }>
                                                      Tasks
                                                    </Button>
                                                  </li>
                                                </ul>
                                              </div>
                                            )}

                                            {dropdownId === 'Tasks' ? (
                                              <div
                                                className="fieldset flex-1"
                                                style={{margin: '0px'}}>
                                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                                  <Form.Label controlid="validationCustom01">
                                                    Tasks
                                                  </Form.Label>
                                                </div>
                                              </div>
                                            ) : dropdownId === 'Offerings' ? (
                                              <div
                                                className="fieldset flex-1"
                                                style={{margin: '0px'}}>
                                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                                  <Form.Label controlid="validationCustom01">
                                                    Offerings
                                                  </Form.Label>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                            <div
                                              className={
                                                dropdownId === 'Deliverables'
                                                  ? 'mt-3'
                                                  : ''
                                              }>
                                              {editProject && (
                                                <div className="input-plus-icon-holder">
                                                  <Form.Control
                                                    name="phase"
                                                    value={searchOfferingTask}
                                                    pattern="^\S.*$"
                                                    placeholder={`Search ${tabType}`}
                                                    onChange={e => {
                                                      setSearchOfferingTask(
                                                        e.target.value,
                                                      );
                                                      if (
                                                        tabType === 'offerings'
                                                      ) {
                                                        get_catalogs_for_invoices(
                                                          e.target.value,
                                                        );
                                                      } else {
                                                        get_actions_for_project_managemnet(
                                                          e.target.value,
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              )}
                                              {tabType === 'offerings' &&
                                                item.offering_ids && (
                                                  <div>
                                                    <div className="mb-3">
                                                      {searchOfferingTask ? (
                                                        <ul className="custom-suggestions invoices_suggestion">
                                                          <li
                                                            className="as-link"
                                                            key={
                                                              'Add-offerings'
                                                            }
                                                            onClick={() => {
                                                              setShowComponent(
                                                                'createCatalog',
                                                              );
                                                            }}>
                                                            Add Offerings
                                                          </li>
                                                          {productDetail.length >
                                                            0 &&
                                                            productDetail.map(
                                                              suggestion => (
                                                                <li
                                                                  className="as-link"
                                                                  key={
                                                                    'suggestion' +
                                                                    suggestion.id
                                                                  }
                                                                  onClick={() => {
                                                                    addTaskOrOffering(
                                                                      mile_index,
                                                                      index,
                                                                      'offering',
                                                                      suggestion,
                                                                    );
                                                                  }}>
                                                                  {fixProjectTitle(capitalizeFirst(
                                                                    suggestion.title,
                                                                  ))}
                                                                </li>
                                                              ),
                                                            )}
                                                        </ul>
                                                      ) : null}

                                                      {searchOfferingTask &&
                                                        productDetail.length ===
                                                          0 && (
                                                          <div className="no-results-content">
                                                            <NorecordFound
                                                                              noRecordData={{
                                                                                img: project_management,
                                                                                title:
                                                                                  null,
                                                                                description:
                                                                                  'No offerings available.',
                                                                                button:
                                                                                  null,
                                                                              }}
                                                                            />
                                                            {/* <p
                                                              className={
                                                                'noRecord-desc'
                                                              }>
                                                              No offerings
                                                              available
                                                            </p> */}
                                                          </div>
                                                        )}

                                                      {item.offering_ids
                                                        .length > 0 &&
                                                      Object.entries(
                                                        item.offering_ids,
                                                      ).length > 0
                                                        ? item.offering_ids.map(
                                                            (
                                                              item,
                                                              indexOffering,
                                                            ) => (
                                                              <div
                                                                className="d-flex align-items-center phases_item mt-3"
                                                                key={
                                                                  indexOffering
                                                                }>
                                                                <div
                                                                  className="customer_name flex-1"
                                                                  style={{
                                                                    backgroundColor:
                                                                      'white',
                                                                  }}>
                                                                  <div className="alias_name">
                                                                    {fixProjectTitle(capitalizeFirst(
                                                                      item.title,
                                                                    ))}
                                                                  </div>
                                                                  <div className="d-flex align-items-center">
                                                                    <div
                                                                      style={{
                                                                        cursor:
                                                                          editProject
                                                                            ? 'pointer'
                                                                            : 'default',
                                                                        color:
                                                                          '#5ABD42',
                                                                        marginRight:
                                                                          '10px',
                                                                      }}
                                                                      onClick={() => {
                                                                        editProject &&
                                                                          setShowDateModal(
                                                                            true,
                                                                          );

                                                                        const formattedDuration =
                                                                          checkDateFormat(
                                                                            item.duration,
                                                                          );

                                                                        setProjectDetail(
                                                                          prev => ({
                                                                            ...prev,
                                                                            date: formattedDuration,
                                                                            index_Offering:
                                                                              indexOffering,
                                                                            index_Deliverable:
                                                                              index,
                                                                            index_Milestone:
                                                                              mile_index,
                                                                          }),
                                                                        );
                                                                      }}>
                                                                      {item.duration &&
                                                                      item.duration !==
                                                                        ''
                                                                        ? checkDateFormat(
                                                                            item.duration,
                                                                          ) !==
                                                                          '0'
                                                                          ? formatDateToDDMMYY(
                                                                              item.duration,
                                                                            )
                                                                          : 'Select date'
                                                                        : 'Select date'}
                                                                    </div>
                                                                    {editProject && (
                                                                      <span className="as-link cross-icon-field">
                                                                        <i
                                                                          className="fas fa-times text-secondary"
                                                                          onClick={() => {
                                                                            setDeleteData(
                                                                              {
                                                                                milestone_index:
                                                                                  mile_index,
                                                                                deliverable_index:
                                                                                  index,
                                                                                type: 'offering',
                                                                                Offering_index:
                                                                                  indexOffering,
                                                                                model: true,
                                                                              },
                                                                            );
                                                                          }}
                                                                          style={{
                                                                            fontSize:
                                                                              '1.5rem',
                                                                          }}></i>
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ),
                                                          )
                                                        : !editProject && (
                                                          <div className='no-records-middle-screen-holder'>
                                                            <NorecordFound
                                                              noRecordData={{
                                                                img: project_management,
                                                                title: null,
                                                                description:
                                                                  'No offerings available.',
                                                                button: null,
                                                              }}
                                                            />
                                                            </div>
                                                          )}
                                                    </div>
                                                  </div>
                                                )}
                                              {tabType === 'tasks' &&
                                                item?.task_ids && (
                                                  <div>
                                                    <div className="">
                                                      {searchOfferingTask ? (
                                                        <ul className="custom-suggestions invoices_suggestion">
                                                          <li
                                                            className="as-link"
                                                            onClick={() => {
                                                              setShowComponent(
                                                                'create_task',
                                                              );
                                                            }}>
                                                            Add Tasks
                                                          </li>
                                                          {taskList?.length >
                                                            0 &&
                                                            taskList.map(
                                                              suggestion => (
                                                                <li
                                                                  className="as-link"
                                                                  key={
                                                                    'suggestion' +
                                                                    suggestion.id
                                                                  }
                                                                  onClick={() => {
                                                                    addTaskOrOffering(
                                                                      mile_index,
                                                                      index,
                                                                      'task',
                                                                      suggestion,
                                                                    );
                                                                  }}>
                                                                  {fixProjectTitle(capitalizeFirst(
                                                                    suggestion.title,
                                                                  ))}
                                                                </li>
                                                              ),
                                                            )}
                                                        </ul>
                                                      ) : null}

                                                      {editProject &&
                                                        searchOfferingTask &&
                                                        taskList?.length ===
                                                          0 && (
                                                            <div className='no-records-middle-screen-holder'>
                                                          <NorecordFound
                                                            noRecordData={{
                                                              img: project_management,
                                                              title: null,
                                                              description:
                                                                'No tasks available.',
                                                              button: null,
                                                            }}
                                                          />
                                                          </div>
                                                        )}

                                                      {Object.entries(
                                                        item?.task_ids,
                                                      )?.length > 0
                                                        ? item.task_ids.map(
                                                            (
                                                              item,
                                                              indexTask,
                                                            ) => {
                                                              return (
                                                                <div
                                                                  className="d-flex align-items-center mt-3"
                                                                  key={
                                                                    indexTask
                                                                  }>
                                                                  <div
                                                                    className="customer_name flex-1"
                                                                    style={{
                                                                      backgroundColor:
                                                                        'white',
                                                                    }}>
                                                                    <div className="alias_name">
                                                                      {fixProjectTitle(capitalizeFirst(
                                                                        item.title,
                                                                      ))}
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                      <div
                                                                        style={{
                                                                          cursor:
                                                                            editProject
                                                                              ? 'pointer'
                                                                              : 'default',
                                                                          color:
                                                                            '#5ABD42',
                                                                          marginRight:
                                                                            '10px',
                                                                        }}
                                                                        onClick={() => {
                                                                          editProject &&
                                                                            setShowDateModal(
                                                                              true,
                                                                            );

                                                                          setProjectDetail(
                                                                            prev => ({
                                                                              ...prev,
                                                                              date:
                                                                                item.due_date &&
                                                                                item.due_date !==
                                                                                  '0000-00-00 00:00:00'
                                                                                  ? item.due_date
                                                                                  : '',

                                                                              index_Task:
                                                                                indexTask,
                                                                              index_Deliverable:
                                                                                index,
                                                                              index_Milestone:
                                                                                mile_index,
                                                                            }),
                                                                          );
                                                                        }}>
                                                                        {item.due_date &&
                                                                        item.due_date !==
                                                                          '0000-00-00 00:00:00'
                                                                          ? formatDateToDDMMYY(
                                                                              item.due_date,
                                                                            )
                                                                          : 'Select date'}
                                                                      </div>
                                                                      {editProject && (
                                                                        <span className="as-link cross-icon-field">
                                                                          <i
                                                                            className="fas fa-times text-secondary"
                                                                            onClick={() => {
                                                                              setDeleteData(
                                                                                {
                                                                                  milestone_index:
                                                                                    mile_index,
                                                                                  deliverable_index:
                                                                                    index,
                                                                                  type: 'task',
                                                                                  Task_index:
                                                                                    indexTask,
                                                                                  model: true,
                                                                                },
                                                                              );
                                                                            }}
                                                                            style={{
                                                                              fontSize:
                                                                                '1.5rem',
                                                                            }}></i>
                                                                        </span>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            },
                                                          )
                                                        : !editProject && (
                                                          <div className='no-records-middle-screen-holder'>
                                                            <NorecordFound
                                                              noRecordData={{
                                                                img: project_management,
                                                                title: null,
                                                                description:
                                                                  'No tasks available.',
                                                                button: null,
                                                              }}
                                                            />
                                                            </div>
                                                          )}
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    );
                                  },
                                )
                              ) : (
                                !editProject && (
                                  <div className='no-records-middle-screen-holder'>
                                  <NorecordFound
                                    noRecordData={{
                                      img: project_management,
                                      title: null,
                                      description: `No ${dropdownId} available.`,
                                      button: null,
                                    }}
                                  />
                                  </div>
                                )
                              )
                            ) : (
                              <div className='no-records-middle-screen-holder'>
                              <NorecordFound
                                noRecordData={{
                                  img: project_management,
                                  title: null,
                                  description: `No ${dropdownId} available.`,
                                  button: null,
                                }}
                              />
                            </div>
                            )}
                          </>
                        ) : (
                          dropdownId === 'Overview' && (
                            <>
                              {editProject ? (
                                <div className="fieldset">
                                  <Form.Label controlid="validationCustom01">
                                    Project Name
                                  </Form.Label>
                                  <Form.Control
                                    name="title"
                                    value={projectName}
                                    pattern="^\S.*$"
                                    required
                                    placeholder="Add Project name"
                                    onChange={e => {
                                      setProjectName(e.target.value);
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Project name is required.
                                  </Form.Control.Feedback>
                                </div>
                              ) : (
                                <div className="fieldset">
                                  <div className="d-flex justify-content-between input-plus-icon-holder">
                                    <Form.Label controlId="validationCustom01">
                                      Project Name
                                    </Form.Label>
                                  </div>

                                  <div className="d-flex align-items-center phases_item mt-0">
                                    <div className="customer_name flex-1">
                                      <div className="deliverables_list mt-0">
                                        <div className="deliverable_item">
                                          {fixProjectTitle(capitalizeFirst(
                                            selectedProjectData.name,
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="fieldset">
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    Customer Name
                                  </Form.Label>
                                </div>

                                {selectedProjectData?.customer_details?.map(
                                  (item, index) => (
                                    <div className="d-flex align-items-center phases_item" style={{marginTop: '5px'}}>
                                      <div className="customer_name flex-1">
                                        <div className="deliverables_list mt-0">
                                          <div className="deliverable_item">
                                            <div
                                              key={index}
                                              className="deliverable_item">
                                              {fixProjectTitle(capitalizeFirst(
                                                item.first_name +
                                                  ' ' +
                                                  item.last_name,
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>

                              <div className="fieldset">
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    POC Name
                                  </Form.Label>
                                </div>

                                {selectedProjectData?.poc_details?.map(
                                  (item, index) => (
                                    <div className="d-flex align-items-center phases_item" style={{marginTop: '5px'}}>
                                      <div className="customer_name flex-1">
                                        <div className="deliverables_list mt-0">
                                          <div className="deliverable_item">
                                            <div
                                              key={index}
                                              className="deliverable_item">
                                              {fixProjectTitle(capitalizeFirst(
                                                item.first_name +
                                                  ' ' +
                                                  item.last_name,
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>

                              <div className="fieldset">
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    Teams
                                  </Form.Label>
                                </div>

                                {selectedProjectData?.team_alias_detail?.map(
                                  (item, index) => (
                                    <div className="d-flex align-items-center phases_item" style={{marginTop: '5px'}}>
                                      <div className="customer_name flex-1">
                                        <div className="deliverables_list mt-0">
                                          <div className="deliverable_item">
                                            <div
                                              key={index}
                                              className="deliverable_item">
                                              {fixProjectTitle(capitalizeFirst(item.role_alias))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            </>
                          )
                        )
                      }

                      {dropdownId === 'Uploads' &&
                      <Upload
                      uploadFilesListing={uploadFilesListing}
                      setUploadFilesListing={setUploadFilesListing}
                      handleFiles={handleFiles}
                      editProject={editProject}
                      setShowFileComponent={setShowFileComponent}
                      />
                      }

                      <Button
                        className="btn-dark"
                        type="submit"
                        style={{display: 'none'}}
                        ref={buttonRef}></Button>
                    </div>
                  </Form>
                </div>
              </Scrollbars>
            </div>
          </div>

          {editProject && (
            <div className="filter-btn-wrapper mt-2">
              <div
                className="invite-btn text-center bg_transparent pb-2 pt-2"
                style={{bottom: 0}}>
                <Button
                  style={{marginRight: '0px'}}
                  className="btn-dark mb-0"
                  type="submit"
                  onClick={() => {
                    handleClick();
                  }}>
                  {/* {props.editAction ? 'Update Task' : } */}
                  {'Update Project'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {deleteData.model && (
        <ConfirmationModel
          closeModel={() => {
            setDeleteData({
              model: false,
            });
          }}
          discription={`Are you sure you want to delete this
            ${deleteData.type} `}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeName();
          }}
        />
      )}
      {showDateModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowDateModal(false);
          }}>
          <div
            style={{overflowY: fromCalendar ? 'unset' : 'auto'}}
            className="filter-wrapper">
            <div className="filter-category-list">
              <ul style={{listStyleType: 'none', padding: 0}}>
                <li style={{marginBottom: '5px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{paddingRight: '15px'}}>
                      <Form.Label>Date</Form.Label>
                    </div>
                    {/* <Form.Control
                      value={projectDetail.date}
                      min={moment(today).format('YYYY-MM-DD')}
                      type="date"
                      placeholder="Select date"
                      onChange={e => {
                        setProjectDetail({
                          ...projectDetail,
                          date: e.target.value,
                        });
                      }}
                    /> */}
                    <div className="parent-container w-100">
                      <Calender
                        minDate={moment(today).format('YYYY-MM-DD')}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={e => {
                          if (e) {
                            setProjectDetail({
                              ...projectDetail,
                              date: e,
                            });
                          } else {
                            setProjectDetail({
                              ...projectDetail,
                              date: '',
                            });
                          }
                        }}
                        selectedDate={
                          projectDetail.date &&
                          projectDetail.date !== '0' &&
                          projectDetail.date !== '0000-00-00'
                            ? new Date(projectDetail.date)
                            : ''
                        }
                        showDate={true}
                        showTime={false}
                        typeOfModelShow={'something'}
                        setFromCalendar={setFromCalendar}
                        fromCalendar={fromCalendar}
                        setToCalendar={setToCalendar}
                        showCalendar={'fromCalendar'}
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    handleDates('offeringTasks');
                    setShowDateModal(false);
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {projectDetail.modal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setProjectDetail({
              modal: false,
            });
          }}>
          <div
            style={{overflowY: fromCalendar || toCalendar ? 'unset' : 'auto'}}
            className="filter-wrapper">
            <div className="filter-category-list">
              <ul style={{listStyleType: 'none', padding: 0}}>
                <li style={{marginBottom: '5px'}}>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <Form.Label>Start Date</Form.Label>
                    </div>
                    <div className="parent-container" style={{width: '80%'}}>
                      <Calender
                        minDate={moment(today).format('YYYY-MM-DD')}
                        // maxDate={getMaxDateFromToday}
                        setSelectedDate={e => {
                          if (e) {
                            if (projectDetail.type === 'milestone') {
                              setProjectDetail({
                                ...projectDetail,
                                milestone_start_date: e,
                              });
                            } else {
                              const justThrowToast = moment(e).isBefore(
                                projectDetail.milestone_start_date,
                              );
                              if (justThrowToast) {
                                errorMessage(
                                  'Your Deliverable start before the milestone.',
                                );
                              }
                              setProjectDetail({
                                ...projectDetail,
                                delivery_start_date: e,
                              });
                            }
                          } else {
                            if (projectDetail.type === 'milestone') {
                              setProjectDetail({
                                ...projectDetail,
                                milestone_start_date: '',
                              });
                            } else {
                              setProjectDetail({
                                ...projectDetail,
                                delivery_start_date: '',
                              });
                            }
                          }
                        }}
                        selectedDate={getSelectedDate(
                          projectDetail.milestone_start_date,
                          projectDetail.delivery_start_date,
                        )}
                        showDate={true}
                        showTime={false}
                        typeOfModelShow={'something'}
                        setFromCalendar={setFromCalendar}
                        fromCalendar={fromCalendar}
                        setToCalendar={setToCalendar}
                        showCalendar={'fromCalendar'}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <Form.Label>End Date</Form.Label>
                    </div>
                    <div className="parent-container" style={{width: '80%'}}>
                      <Calender
                        minDate={moment(today).format('YYYY-MM-DD')}
                        // maxDate={getMaxDateFromToday}
                        setSelectedDate={e => {
                          if (e) {
                            if (projectDetail.type === 'milestone') {
                              setProjectDetail({
                                ...projectDetail,
                                milestone_end_date: e,
                              });
                            } else {
                              const justThrowToast = moment(e).isAfter(
                                projectDetail.milestone_end_date,
                              );
                              if (justThrowToast) {
                                errorMessage(
                                  'Your Deliverable exceed the milestone end date.',
                                );
                              }
                              setProjectDetail({
                                ...projectDetail,
                                delivery_end_date: e,
                              });
                            }
                          } else {
                            if (projectDetail.type === 'milestone') {
                              setProjectDetail({
                                ...projectDetail,
                                milestone_end_date: '',
                              });
                            } else {
                              setProjectDetail({
                                ...projectDetail,
                                delivery_end_date: '',
                              });
                            }
                          }
                        }}
                        selectedDate={getSelectedDate(
                          projectDetail.milestone_end_date,
                          projectDetail.delivery_end_date,
                        )}
                        showDate={true}
                        showTime={false}
                        typeOfModelShow={'something'}
                        setToCalendar={setToCalendar}
                        toCalendar={toCalendar}
                        setFromCalendar={setFromCalendar}
                        showCalendar={'toCalendar'}
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    handleDates('milestoneDeli');
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'membershipLevel' && (
        <CustomModal
          size="small"
          onClose={() => {
            setMembershipList([]);
            setShowComponent('');
          }}
          className="availability-modal">
          <div
            className="ticket-dates-wrapper  meetup_date_repeat"
            style={{width: '-webkit-fill-available'}}>
            {membershipList.length > 0 &&
              membershipList.map((item, index) => (
                <MembershipDealItem
                  item={item}
                  ItemIndex={index}
                  // onSelectionChange={(index, levelId, durationId)=>{
                  //   handleMembershipChange(index, levelId, durationId)
                  // }}
                  setMembershipList={setMembershipList}
                  membershipList={membershipList}
                />
              ))}

            <div className="invite-btns  p-0 pt-3 text-center">
              <Button
                className="px-5"
                onClick={() => {
                  handleSaveMemberShip();
                }}>
                Select
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'createCatalog' && (
        <CreateCatalog
          onClose={() => {
            setShowComponent('');
          }}
          onCloseWithApiHit={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'create_task' && (
        <CreateAction
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}


{showModel === 'confirmation_Model' && (
    <ConfirmationModel
      closeModel={() => {
        setshowModel('');
      }}
      discription={
        'Are you Sure you want to delete this File ?'
      }
      buttonOne={'Yes'}
      buttonTwo={'No Thanks'}
      ButtonOneAction={() => {
        delete_document_files();
      }}
    />
  )}

{showFileComponent && (
                    <ViewFile
                      selectedFile={documentInfo}
                      onClose={() => {
                        setShowFileComponent(false);
                        // setSelectedFile(null);
                      }}
                    />
                  )}
    </>
  );
};

export default EditProject;
