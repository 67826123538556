import React, {useState} from 'react';
import Input from './Input/Input';
import {cameraicon} from '../Constants/Images';
import {Button} from 'react-bootstrap';
import Upload from './Project/Upload';
import {changeFileVariable} from '../Utils/commonUtils';
import EditPencilSvg from '../Svg/EditPencilSvg';
import {errorMessage} from '../Utils/ToastMessages';

const ContactDetail = ({
  showContactDetails,
  contactData,
  handleChange,
  type,
  uploadFilesListing,
  setUploadFilesListing,
  setShowFileComponent,
  setContactData,
}) => {
  const [editProject, setEditProject] = useState(true);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [pocDetail, setPocDetail] = useState({
    name: '',
    username: '',
    email: '',
    phoneNo: '',
    role: '',
    platform: '',
    url: '',
  });
  const [index, setIndex] = useState(null);

  const handleFiles = (item, type) => {
    if (type === 'delete') {
      //   setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  const handleListing = (e, type) => {
    if (type === 'social') {
      if (
        pocDetail.username !== '' &&
        pocDetail.platform !== '' &&
        pocDetail.url !== ''
      ) {
        if (index !== null) {
          const updatesocialListing = [...contactData.socialListing];
          updatesocialListing[index] = {...pocDetail};
          setContactData(prev => ({
            ...prev,
            socialListing: updatesocialListing,
          }));
          setIndex(null);
          setPocDetail({});
        } else {
          setContactData(prevData => ({
            ...prevData,
            socialListing: [...prevData.socialListing, pocDetail],
          }));
          setPocDetail({});
        }
      } else {
        errorMessage('Please enter values');
      }
    } else {
      if (
        pocDetail.name !== '' &&
        pocDetail.email !== '' &&
        pocDetail.phoneNo !== '' &&
        pocDetail.role !== ''
      ) {
        if (index !== null) {
          const updatedPocListing = [...contactData.pocListing];
          updatedPocListing[index] = {...pocDetail};
          setContactData(prev => ({...prev, pocListing: updatedPocListing}));
          setIndex(null);
          setPocDetail({});
        } else {
          setContactData(prevData => ({
            ...prevData,
            pocListing: [...prevData.pocListing, pocDetail],
          }));
          setPocDetail({});
        }
      } else {
        errorMessage('Please enter values');
      }
    }
  };

  const handle = e => {
    setPocDetail({
      ...pocDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (item, index, type) => {
    if (type === 'pocs') {
      setPocDetail({
        name: item.name,
        email: item.email,
        phoneNo: item.phoneNo,
        role: item.role,
      });
    } else {
      setPocDetail({
        platform: item.platform,
        url: item.url,
        username: item.username,
      });
    }
    setIndex(index);
  };

  const handleDelete = (item, type) => {
    if (type === 'pocs') {
      const updatedPocListing = contactData.pocListing.filter(
        (_, i) => i !== contactData.pocListing.indexOf(item),
      );
      setContactData(prev => ({...prev, pocListing: updatedPocListing}));
    } else {
      const updateSocialListing = contactData.socialListing.filter(
        (_, i) => i !== contactData.socialListing.indexOf(item),
      );
      setContactData(prev => ({...prev, socialListing: updateSocialListing}));
    }
  };

  return (
    <>
      {type !== 'attachment' && type !== 'social' && (
        <div
          style={{backgroundColor: type === 'pocs' ? '#EDEBE5' : 'white'}}
          className="rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => handleChange('open_closed')}>
            <h2 className="text-base font-semibold">
              {type === 'pocs' ? 'POCs' : 'Contact Details'}
            </h2>
            {type === 'pocs' && <img src={cameraicon} />}
            {/* <button className="text-gray-500">
            {showContactDetails ? '▲' : '▼'}
          </button> */}
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showContactDetails ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div className="p-4 pt-0 space-y-2 height-input">
              <Input
                type="text"
                label={''}
                name={'name'}
                value={type === 'pocs' ? pocDetail.name : contactData.name}
                onChange={e => {
                  if (type === 'pocs') {
                    handle(e, 'pocs');
                  } else {
                    handleChange('change_value', e);
                  }
                }}
                placeholder={type === 'pocs' ? 'Name' : 'Company Name'}
                feedback={
                  type === 'pocs'
                    ? 'Name is required'
                    : 'Company name is required'
                }
              />

              {type === 'company' && (
                <Input
                  type="text"
                  label={''}
                  name={'type'}
                  value={contactData.type}
                  onChange={e => {
                    handleChange('change_value', e);
                  }}
                  placeholder={'Contact Type'}
                  feedback={'Contact Type is required'}
                />
              )}

              <Input
                type="email"
                label={''}
                name={'email'}
                value={type === 'pocs' ? pocDetail.email : contactData.email}
                onChange={e => {
                  if (type === 'pocs') {
                    handle(e, 'pocs');
                  } else {
                    handleChange('change_value', e);
                  }
                }}
                placeholder={'Email'}
                feedback={'Email is required'}
              />

              <Input
                type="number"
                label={''}
                name={'phoneNo'}
                value={
                  type === 'pocs' ? pocDetail.phoneNo : contactData.phoneNo
                }
                onChange={e => {
                  if (type === 'pocs') {
                    handle(e, 'pocs');
                  } else {
                    handleChange('change_value', e);
                  }
                }}
                placeholder={'Phone number'}
                feedback={'Phone number is required'}
              />

              {type === 'company' && (
                <Input
                  type="text"
                  label={''}
                  name={'address'}
                  value={contactData.address}
                  onChange={e => {
                    handleChange('change_value', e);
                  }}
                  placeholder={'Address'}
                  feedback={'Address is required'}
                />
              )}

              {type === 'pocs' && (
                <Input
                  type="text"
                  label={''}
                  name={'role'}
                  value={pocDetail.role}
                  onChange={e => {
                    handle(e, 'pocs');
                  }}
                  placeholder={'Role'}
                  feedback={'Role is required'}
                />
              )}

              {type === 'pocs' && (
                <div className="flex justify-center items-center mt-3">
                  <button
                    type="button"
                    onClick={e => handleListing(e, 'pocs')}
                    className="bg-black text-white px-4 py-2 rounded-md">
                    Add POC
                  </button>
                </div>
              )}
              {type === 'pocs' && contactData?.pocListing?.length > 0 && (
                <>
                  {contactData?.pocListing.map((item, index) => (
                    <div
                      className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                      key={index}>
                      <div className="customer_name flex-1">
                        <div className="alias_name">{item.name}</div>
                        {/* <div style={{display: 'flex'}}>
                          <span
                            style={{padding: '2px'}}
                            onClick={() => handleEdit(item, index, 'pocs')}
                            className="as-link cross-icon-field">
                            <EditPencilSvg fill={'#a39f8d'} />
                          </span>
                          <span className="cross-icon-field">
                            <i
                              className="as-link fas fa-times"
                              onClick={() => handleDelete(item, 'pocs')}
                              style={{
                                fontSize: '1.5rem',
                                color: '#a39f8d',
                              }}></i>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {type === 'attachment' && (
        <div className="bg-white rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => handleChange('open_closed')}>
            <h2 className="text-base font-semibold">Attachments</h2>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showContactDetails ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div
              onClick={() => {
                setEditProject(true);
              }}
              className="p-4  pt-0 space-y-2 height-input">
              <Upload
                uploadFilesListing={uploadFilesListing}
                setUploadFilesListing={setUploadFilesListing}
                handleFiles={handleFiles}
                setShowFileComponent={setShowFileComponent}
                type={'dynamic_attachment'}
              />
            </div>
          </div>
        </div>
      )}
      {type === 'social' && (
        <div
          style={{backgroundColor: '#EDEBE5'}}
          className="rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => handleChange('open_closed')}>
            <h2 className="text-base font-semibold">Handles</h2>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showContactDetails ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div className="p-4 pt-0 space-y-2 height-input">
              <Input
                type="text"
                label={''}
                name={'platform'}
                value={pocDetail.platform}
                onChange={handle}
                placeholder={'Platform'}
                feedback={'Patform is required'}
              />

              <Input
                type="text"
                label={''}
                name={'username'}
                value={pocDetail.username}
                onChange={handle}
                placeholder={'Handle or username'}
                feedback={'Handle or username is required'}
              />

              <Input
                type="url"
                label={''}
                name={'url'}
                value={pocDetail.url}
                onChange={handle}
                placeholder={'URL'}
                feedback={'URL is required'}
              />

              <div className="flex justify-center items-center mt-3">
                <button
                  type="button"
                  onClick={e => handleListing(e, 'social')}
                  className="bg-black text-white px-4 py-2 rounded-md">
                  Add Social
                </button>
              </div>
              {contactData?.socialListing?.length > 0 && (
                <>
                  {contactData?.socialListing.map((item, index) => (
                    <div
                      className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                      key={index}>
                      <div className="customer_name flex-1">
                        <div className="alias_name">{item.username}</div>
                        {/* <div style={{display: 'flex'}}>
                          <span
                            style={{padding: '2px'}}
                            onClick={() => handleEdit(item, index, 'social')}
                            className="as-link cross-icon-field">
                            <EditPencilSvg fill={'#a39f8d'} />
                          </span>
                          <span className="cross-icon-field">
                            <i
                              className="as-link fas fa-times"
                              onClick={() => handleDelete(item, 'social')}
                              style={{
                                fontSize: '1.5rem',
                                color: '#a39f8d',
                              }}></i>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactDetail;
