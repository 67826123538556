import React, {useState, useEffect, useRef, Fragment} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import {CustomModal, SlidingPanel} from '../../../Components';
// import {DEFAULT_IMAGE} from '../../../Utils/env';
import SelectedReferUser from '../../../Components/Referral/SelectedReferUser';
import CustomTabs from '../../../Components/CustomTabs';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import {cameraicon, RoiLogo, upload_file} from '../../../Constants/Images';
import Slider from 'react-slick';
import EditPencilSvg from '../../../Svg/EditPencilSvg';
import ConfirmationModel from '../../../Components/ConfirmationModel/ConfirmationModel';
import IconModal from '../../../Components/SessionDetails/IconModal';
import ImagePreview from '../../../Components/Modals/ImagePreview';
import CropImage from '../../../Components/Cropper/CropImage';
import Upload from '../../../Components/Project/Upload';
import ViewFile from '../../../Components/UplioadFile/ViewFile';
import {changeFileVariable} from '../../../Utils/commonUtils';

const SendInvitation = props => {
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [PhoneNo, setPhonenNo] = useState(
    props.phoneNumber ? props.phoneNumber : '',
  );
  const [email, setEmail] = useState(
    props.phoneNumber ? props.phoneNumber : '',
  );

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const [participantArray, setParticipantArraytPhonenNo] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('Business');
  const [contactRelations, setContactRelations] = useState([]);
  const [pocName, setPocName] = useState('');
  const [relationType, setRelationType] = useState('1');
  const [showLoader, setShowLoader] = useState(false);
  const [emailToggle, setEmailToggle] = useState(false);

  const [showContactDetails, setShowContactDetails] = useState(true);
  const [showPOCs, setShowPOCs] = useState(true);
  const [showAdditionalDetail, setShowAdditionalDetail] = useState(true);
  const [showAttachments, setShowAttachments] = useState(true);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [index, setIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [tagTitleList, setTagTitleList] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [ticketImg, setTicketImg] = useState('');
  const [sliderImage, setSliderImage] = useState('');
  const [modelImg, setModelImg] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [file, setFile] = useState('');
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [uploadFilesListing, setUploadFilesListing] = useState([]);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [contactData, setcontactData] = useState({
    name: '',
    role: '',
    phoneNo: '',
    address: '',
    pocListing: [],
    website: '',
    timeZone: '',
    contactSource: '',
    birthDate: '',
    tags: [],
    image: '',
    attachments: []
  });
  const [pocDetail, setPocDetail] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
  });

  const handleChange = e => {
    setcontactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };
  const handlePoc = e => {
    setPocDetail({
      ...pocDetail,
      [e.target.name]: e.target.value,
    });
  };

  const resetPOc = () => {
    setPocDetail({
      name: '',
      email: '',
      phone: '',
      role: '',
    });
  };

  const handlePocListing = e => {
    if (
      pocDetail.name !== '' &&
      pocDetail.email !== '' &&
      pocDetail.phone !== '' &&
      pocDetail.role !== ''
    ) {
      if (index !== null) {
        const updatedPocListing = [...contactData.pocListing];
        updatedPocListing[index] = {...pocDetail};
        setcontactData(prev => ({...prev, pocListing: updatedPocListing}));
        setIndex(null);
        resetPOc();
      } else {
        setcontactData(prevData => ({
          ...prevData,
          pocListing: [...prevData.pocListing, pocDetail],
        }));
        resetPOc();
      }
    } else {
      errorMessage('Please enter values');
    }
  };

  const handleEdit = (item, index) => {
    setPocDetail({
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role,
    });
    setIndex(index);
  };
  const handleDelete = item => {
    const updatedPocListing = contactData.pocListing.filter(
      (_, i) => i !== contactData.pocListing.indexOf(item),
    );
    setcontactData(prev => ({...prev, pocListing: updatedPocListing}));
    setShowDeleteModal(prev => ({
      open: false,
      item: '',
    }));
  };

  useEffect(() => {
    if (tagTitleList?.length > 0) {
      setcontactData(prev => ({...prev, tags: tagTitleList}));
    }
  }, [tagTitleList]);

  useEffect(() => {
    if (ticketImg) {
      setcontactData(prev => ({...prev, image: ticketImg}));
    }
  }, [ticketImg]);

  useEffect(() => {
    if (uploadFilesListing.length > 0) {
      setcontactData(prev => ({...prev, attachments: uploadFilesListing}));
    }
  }, [uploadFilesListing]);

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      setCropImg(true);
      setShowModal('image_modal');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  const handleFiles = (item, type) => {
    console.log(item, 'itemitemitem')
    if (type === 'delete') {
      setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  const delete_document_files = () => {
    const deleteFile =
      uploadFilesListing?.length > 0 &&
      uploadFilesListing?.filter(item => item.file !== documentInfo.file);
    setUploadFilesListing(deleteFile);
    setShowModal('');
  };

  const buttonRef = useRef(null);
  // const inviteButton = useRef(null);
  const validateFields = useRef(false);
  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.type_individual[selected_lang],
  ];

  const handle = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      if (
        contactData.name === '' ||
        contactData.email === '' ||
        contactData.phoneNo === '' ||
        contactData.address === ''
      ) {
        errorMessage('Please enter contact fields');
      } else if (
        contactData.website === '' ||
        contactData.timeZone === '' ||
        contactData.contactSource === '' ||
        contactData.birthDate === ''
      ) {
        errorMessage('Please enter additional fields');
      }
      // if (emailToggle && PhoneNo === '' && email === '') {
      //   errorMessage('Please Add Phone or email');
      // }
    } else {
      event.preventDefault();
      setValidated(false);

      if (contactData.pocListing.length === 0) {
        errorMessage('Please add POCs');
      }else if(contactData.attachments.length === 0){
        errorMessage('Please add attachments')
      }else if(contactData?.tags.length === 0){
        errorMessage('Please add tags')
      }else if(contactData.image === ''){
        errorMessage('Please upload image')
      }

      // referOutside();
      // alert('d')
      checkInputType();

      //
    }
  };
  //

  const checkInputType = () => {
    // if (PhoneNo.match(/^\d+$/) || PhoneNo.match(/^\+\d+$/)) {
    //   // alert('Data contains only numbers.');
    //   checkExist(PhoneNo);
    // } else if (PhoneNo.match(/^[A-Za-z]+$/)) {
    //   // alert('Data contains only words (letters).');
    //   referOutside();
    // } else if (PhoneNo.match(/^[A-Za-z\d]+$/)) {
    //   // alert('Data contains both words and numbers.');
    //   referOutside();
    // } else {
    // alert('Data contains other characters or is empty.');

    if (props.addBusinessPOC) {
      addPocInBusinessProfile();
    } else {
      referOutside();
    }

    // }
  };

  const addPocInBusinessProfile = () => {
    let Payload = {
      poc_name: pocName,
      contact:
        /^\d+$/.test(PhoneNo) && !PhoneNo.includes('+')
          ? '+' + PhoneNo
          : PhoneNo,
      contact_email: emailToggle ? email : '',
      linked_user_id:
        props.addBusinessPOC && props.businessProfileId !== ''
          ? props.businessProfileId
          : '',
    };

    commonApi
      .add_single_user_in_linked_user_poc(Payload)
      .then(res => {
        if (res.status === 200 && res.success) {
          successMessage(res.message);
          props.onCloseHitApi && props.onCloseHitApi();
          props.onClose();
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referOutside = type => {
    let Payload = {
      poc_name: pocName,
      contact: type === 'number' ? '+' + PhoneNo : PhoneNo,
      fname: firstName,
      relationship_id: relationType,
      user_type: tabType === 'Business' ? 'business' : 'individual',
      invite_only: emailToggle,
      contact_email: emailToggle ? email : '',
    };

    if (type === 'number') {
      if (PhoneNo.includes('+')) {
        // setParticipantArraytPhonenNo(previous => [...previous, Payload]);
        sendnvitationOutIside(Payload);
      } else {
        // alert('')
        let PayloadData = {
          poc_name: pocName,
          contact: '+' + PhoneNo,
          fname: firstName,
          relationship_id: relationType,
          user_type: tabType === 'Business' ? 'business' : 'individual',
          invite_only: emailToggle,
          contact_email: emailToggle ? email : '',
        };
        // setParticipantArraytPhonenNo(previous => [...previous, PayloadData]);

        sendnvitationOutIside(PayloadData);
      }
    } else {
      sendnvitationOutIside(Payload);
    }
    // alert('2')
    if (props.contact) {
      props.setContact(previous => [...previous, Payload]);
    }

    setFirstName('');
    setPhonenNo('');
    setEmail('');
    setPocName('');
    setValidated(false);
    if (props.type === 'schedule') {
      const UpdatedArray = [...participantArray, Payload];
      props.setContact(previous => [...previous, Payload]);
      sendnvitationOutIside(UpdatedArray);
    }
  };

  const sendnvitationOutIside = Updated => {
    return;
    var Payload;

    // props.setContact(previous => [...previous, Updated]);
    if (props.type === 'schedule') {
      Payload = {
        contacts: [Updated],
      };
    } else {
      Payload = {
        contacts: [Updated],
      };
    }

    commonApi
      .send_invitation_outside(Payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (props.type === 'Catalog' || (props.hitApi && props.hitApi)) {
            props.onCloseHitApi && props.onCloseHitApi();
          }
          if (props.setContact) {
            props.setContact(previous => [...previous, Updated]);

            setParticipantArraytPhonenNo([]);
          }
          if (props.setCheckUserHitApi) {
            props.setCheckUserHitApi(!props.checkUserHitApi);
          }

          props.onCloseApiHit();
        }
        if (res.status === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    setShowLoader(true);
    getContactRelationTypes();
  }, []);
  useEffect(() => {
    setFirstName('');
    setPhonenNo(props.phoneNumber ? props.phoneNumber : '');
    setPocName('');
    setEmail(props.phoneNumber ? props.phoneNumber : '');
  }, [tabType]);

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {/* <img src={RoiLogo} alt="" /> */}
        <i className="icon-map-pin"></i>
      </>
    );
  };

  // how to check bootstrap validtaion on form when user press enter burron in react js

  // const tagList = [
  //   {
  //     id: '180',
  //     company_id: '257',
  //     title: 'New Test Tag',
  //     icon: '',
  //     description: 'NewTag',
  //     read_role_ids: ['710', '711'],
  //     update_role_ids: ['710', '711'],
  //     created_by: '1364',
  //     created_at: '2025-01-06 03:02:13',
  //     updated_by: '1364',
  //     updated_at: '2025-01-06 05:02:25',
  //     assign_details: ['2516'],
  //     role_titles: [
  //       {
  //         id: '710',
  //         role_alias: 'Customer',
  //         read: true,
  //         update: true,
  //       },
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: true,
  //       },
  //     ],
  //   },
  //   {
  //     id: '67',
  //     company_id: '257',
  //     title: 'you boy',
  //     icon: 'https://s3.amazonaws.com/build-dev/company_tags/1723207471764.jpg',
  //     description: 'www',
  //     read_role_ids: ['711'],
  //     update_role_ids: [],
  //     created_by: '1364',
  //     created_at: '2024-08-09 08:44:41',
  //     updated_by: '1364',
  //     updated_at: '2024-08-09 08:46:04',
  //     assign_details: ['3043', '2515', '3916', '1865', '2516'],
  //     role_titles: [
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: false,
  //       },
  //     ],
  //   },
  //   {
  //     id: '63',
  //     company_id: '257',
  //     title: 'title',
  //     icon: '',
  //     description: 'cxvxc',
  //     read_role_ids: ['711'],
  //     update_role_ids: [],
  //     created_by: '4185',
  //     created_at: '2024-08-09 08:25:43',
  //     updated_by: '0',
  //     updated_at: '2024-08-09 08:25:43',
  //     assign_details: ['3043', '1865', '2515', '3916', '2516'],
  //     role_titles: [
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: false,
  //       },
  //     ],
  //   },
  //   {
  //     id: '61',
  //     company_id: '257',
  //     title: 'Abcdefg ads adssd asdasd asd a da',
  //     icon: 'https://s3.amazonaws.com/build-dev/company_tags/172320473537.jpg',
  //     description: 'Abcdefg',
  //     read_role_ids: ['710', '711'],
  //     update_role_ids: ['711'],
  //     created_by: '1364',
  //     created_at: '2024-08-09 07:59:09',
  //     updated_by: '1364',
  //     updated_at: '2025-01-06 02:44:36',
  //     assign_details: ['3043', '2516'],
  //     role_titles: [
  //       {
  //         id: '710',
  //         role_alias: 'Customer',
  //         read: true,
  //         update: false,
  //       },
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: true,
  //       },
  //     ],
  //   },
  // ];
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="referal_page_content">
          <div className="refer_outside add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
              <p className="p-bold m-0 f_600_h">
                {props.snapshotIds ? 'Refer Outside ROI' : 'Add Contact'}
              </p>
            </div>

            {!props.addBusinessPOC && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="create-ticket-tabs w-100">
                  <CustomTabs
                    darkmode
                    tabs={ticketTabs}
                    active={tabType}
                    onSelect={item => {
                      setTabType(item);
                      validateFields.current = false;
                      setValidated(false);
                      setEmailToggle(false);
                    }}
                  />
                </div>
              </div>
            )}
            <Scrollbars className="custom-scroll">
              <div>
                <Form
                  className="send-refferal-form-input-holder"
                  noValidate
                  validated={validated}
                  onSubmit={handle}>
                  <div className=" p-4 rounded-md">
                    {/* Contact Details */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() =>
                          setShowContactDetails(!showContactDetails)
                        }>
                        <h2 className="text-base font-semibold">
                          {tabType === labels.type_individual[selected_lang] ? 'Contact Details' : 'Company Detail'}
                        </h2>
                        {/* <button className="text-gray-500">
                          {showContactDetails ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showContactDetails ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div className="p-4 pt-0 space-y-2 height-input">
                          <Form.Control
                            type="text"
                            value={contactData.name}
                            name="name"
                            // pattern="Se^\S.*$"
                            placeholder={tabType === labels.type_individual[selected_lang] ? 'Name' : 'Company Name'}
                            onChange={handleChange}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Name is required.
                          </Form.Control.Feedback>

                          <div className="roi-form-dropdown ">
                            {/* <Form.Label controlid="validationCustom01">
                            Relationship
                          </Form.Label> */}
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setRelationType(eventKey);
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {relationType
                                    ? contactRelations.find(
                                        item => item.id === relationType,
                                      )?.relations
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {contactRelations.map((item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.id.toString()}
                                      active={item.id === relationType}>
                                      {item.relations}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <Form.Control
                            type="email"
                            value={contactData.email}
                            name="email"
                            required
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Email'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="number"
                            name="phoneNo"
                            value={contactData.phoneNo}
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Phone number'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Phone number is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={contactData.address}
                            name="address"
                            required
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Address'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Address is required.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    {/* POCs */}
                    <div className="rounded-md shadow-md mb-2" style={{
                      backgroundColor:'#EDEBE5'
                    }}>
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() => setShowPOCs(!showPOCs)}>
                        <h2 className="text-base font-semibold">POCs</h2>
                        <div>
                          <img src={cameraicon} alt="" />
                        </div>

                        {/* <button className="text-gray-500">
                          {showPOCs ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showPOCs ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div className="p-4  pt-0 space-y-2 height-input">
                          <Form.Control
                            type="text"
                            value={pocDetail.name}
                            name="name"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Name'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Name is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="email"
                            value={pocDetail.email}
                            name="email"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Email'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Email is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="number"
                            value={pocDetail.phone}
                            name="phone"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Phone number'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Phone number is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={pocDetail.role}
                            name="role"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Role'}
                          />
                          <Form.Control.Feedback type="invalid">
                            Role is required.
                          </Form.Control.Feedback>
                          <div className="flex justify-center items-center mt-3">
                            <button
                              type="button"
                              onClick={handlePocListing}
                              className="bg-black text-white px-4 py-2 rounded-md">
                              Add POC
                            </button>
                          </div>
                          {contactData?.pocListing?.length > 0 && (
                            <>
                              {contactData?.pocListing.map((item, index) => (
                                <div
                                  className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                                  key={index}>
                                  <div className="customer_name flex-1">
                                    <div className="alias_name">
                                      {item.name}
                                    </div>
                                    <div style={{display: 'flex'}}>
                                      <span
                                        style={{padding: '2px'}}
                                        onClick={() => handleEdit(item, index)}
                                        className="as-link cross-icon-field">
                                        <EditPencilSvg fill={'#a39f8d'} />
                                      </span>
                                      <span className="cross-icon-field">
                                        <i
                                          className="as-link fas fa-times"
                                          onClick={() =>
                                            setShowDeleteModal({
                                              open: true,
                                              item,
                                            })
                                          }
                                          style={{
                                            fontSize: '1.5rem',
                                            color: '#a39f8d',
                                          }}></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {Additional Details} */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() =>
                          setShowAdditionalDetail(!showAdditionalDetail)
                        }>
                        <h2 className="text-base font-semibold">
                          Additional Details
                        </h2>
                        {/* <button className="text-gray-500">
                          {showAdditionalDetail ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showAdditionalDetail ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div className="p-4  pt-0 space-y-2 height-input">
                          <Form.Control
                            type="url"
                            value={contactData.website}
                            name="website"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Website '}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Website is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={contactData.timeZone}
                            name="timeZone"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Time zone'}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Time zone is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={contactData.contactSource}
                            name="contactSource"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Contact source'}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Contact source is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="date"
                            value={contactData.birthDate}
                            name="birthDate"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Birth Date'}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Birth Date is required.
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    {/* {Attachments} */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() => setShowAttachments(!showAttachments)}>
                        <h2 className="text-base font-semibold">Attachments</h2>
                        {/* <button className="text-gray-500">
                          {showAdditionalDetail ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showAttachments ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div
                          onClick={() => {
                            setEditProject(true);
                          }}
                          className="p-4  pt-0 space-y-2 height-input">
                          {/* <div
                            className="file-upload-wrapper as-link"
                            style={{
                              marginBottom: '15px',
                            }}>
                            {!documentInfo && (
                              <input
                                type="file"
                                className="as-link"
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx"
                                onChange={e => {
                                  // handleFileUpload(e);
                                }}
                              />
                            )}
                            {documentInfo && documentInfo?.length > 0 ? (
                              <>
                                <span
                                  // onClick={() => {
                                  //   setshowModel('confirmation_Model');
                                  // }}
                                  style={{
                                    width: '100%',
                                    textAlign: 'end',
                                    paddingRight: '11px',
                                    position: 'absolute',
                                    top: '7px',
                                  }}>
                                  {' '}
                                  <i className="fa-solid fa-xmark"></i>
                                </span>

                                <span style={{fontSize: '40px'}}>
                                  <i className="fa-solid fa-file"></i>
                                </span>
                              </>
                            ) : (
                              <img src={upload_file} alt="" />
                            )}
                            <span>Tap to upload</span>
                          </div> */}
                          <Upload
                            uploadFilesListing={uploadFilesListing}
                            setUploadFilesListing={setUploadFilesListing}
                            handleFiles={handleFiles}
                            setShowFileComponent={setShowFileComponent}
                            type={'contact_attachment'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="roi-tags-icons flex items-center roi-profile-block-tags-icons">
                      {/* {showPlusButtonDecesion(
              tagsListing?.tag_permission?.own_profile_permission,
              tagsListing?.tag_permission?.show_tag,
            ) === true && ( */}
                      <div
                        className="tag-icon as-link cursor-pointer"
                        // onClick={() => {
                        //   handleBlock('', 'view_tag');
                        // }}
                      >
                        <i
                          onClick={() => {
                            setShowModal('tags');
                          }}
                          className="icon-plus"></i>
                      </div>
                      {/* )} */}

                      <div className="flex-grow overflow-x-auto">
                        <Slider {...settings} className="whitespace-nowrap">
                          {contactData?.tags?.length > 0 &&
                            contactData?.tags.map((item, index) => (
                              <div className="inline-block px-2" key={index}>
                                <div className="tag-icon-time flex items-center space-x-2">
                                  <div
                                    className="newClass flex items-center space-x-2"
                                    style={{
                                      paddingLeft: '3px',
                                    }}>
                                    <span className="text-sm multi-line-ellipsis">
                                      {item}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                    <div className="bg-[#E8E6DF] w-20 h-20 flex items-center justify-center rounded-lg">
                      <div
                        style={{
                          height: '77px',
                          background: 'none',
                          borderRadius: '6px',
                        }}
                        className={`ticket-img-wrapper mb-0 m-0 `}>
                        {contactData?.image && (
                          <p
                            onClick={() => {
                              // setImgModal(true);
                              // if (sliderImage.length > 0) {
                              // setModelImg(sliderImage[0]);
                              setShowModal('image_modal');
                              // }
                            }}>
                            Onclick
                          </p>
                        )}

                        <input
                          type="file"
                          accept="image/*"
                          onChange={e => changeTicketImg(e)}
                        />
                        {contactData.image && (
                          <img
                            style={{borderRadius: '6px', border: 'none'}}
                            src={contactData?.image}
                            alt=""
                          />
                        )}
                        <i
                          style={{borderRadius: '6px'}}
                          className="icon-camera as-link"
                        />
                        {/* <img src={cameraicon} alt="Camera Icon" /> */}
                      </div>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    style={{display: 'none'}}
                    ref={buttonRef}>
                    {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                  </Button>
                </Form>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper bg_transparent bottom-0">
              {/* {!props.addBusinessPOC && ( */}
              <div className="text-center pt-1">
                <span
                  // id='hh'
                  className="as-link "
                  style={{
                    color: '#00bf4d',
                  }}
                  onClick={() => {
                    validateFields.current = true;
                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);

                    setEmailToggle(!emailToggle);
                  }}>
                  {tabType === labels.type_individual[selected_lang]
                    ? 'Invite Contact'
                    : 'Invite Business'}
                </span>
              </div>
              {/* )} */}
              <div className="invite-btns p-0 pt-1 text-center">
                <Button
                  type="submit"
                  style={{marginRight: '0px'}}
                  onClick={() => {
                    validateFields.current = false;

                    setEmailToggle(!emailToggle);

                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);
                  }}>
                  {props.snapshotIds
                    ? 'Send Refferral'
                    : tabType === labels.qr_type_business[selected_lang] &&
                      !props.addBusinessPOC
                    ? 'Add Contact'
                    : 'Add Contact'}
                </Button>
              </div>
            </div>
          </div>

          {participantArray.length > 0 && props.id && !props.type && (
            <div className="filter-btn-wrapper pt-3">
              <div className="invite-btns text-center p-0">
                <Button
                  disabled={participantArray.length > 0 ? false : true}
                  onClick={() => {
                    participantArray.forEach((item, index) => {
                      props.setContact(previous => [...previous, item]);
                    });
                    sendnvitationOutIside();

                    // successMessage(' Succesfully add to recipient');
                  }}>
                  Add to recipient
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          selectedAllUser={[]}
          users={[]}
          setUsers={[]}
          setSelectedAllUser={[]}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={participantArray}
          setReferOutSide={setParticipantArraytPhonenNo}
        />
      )}

      {showDeleteModal.open && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModal(prev => ({...prev, open: false}));
          }}
          discription={'Are you Sure you want to delete this POC ?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            handleDelete(showDeleteModal.item);
          }}
        />
      )}

      {showModal === 'tags' && (
        <IconModal
          closeModel={() => {
            setShowModal('');
          }}
          tagData={tagTitleList}
          setTagData={setTagTitleList}
        />
      )}

      {showModal === 'confirmation_Model' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModal('');
          }}
          discription={'Are you Sure you want to delete this File ?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            delete_document_files();
          }}
        />
      )}

      {showModal === 'image_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModal('');
            setFile(null);
          }}>
          <CropImage
            type="poc_image"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModal}
            imgUrl={ticketImg}
            cropImg={cropImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setShowLoader}
          />
        </CustomModal>
      )}

      {showFileComponent && (
        <ViewFile
          selectedFile={documentInfo}
          onClose={() => {
            setShowFileComponent(false);
            // setSelectedFile(null);
          }}
        />
      )}
    </>
  );
};

export default SendInvitation;
