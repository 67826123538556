import React, {useState, useEffect, useRef} from 'react';
import {CustomModal, CustomTabs, SlidingPanel} from '../../Components';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import {Button, Col, Form, Row} from 'react-bootstrap';
import CustomDropdown from '../../Components/CustomDropdown';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import BluePrintLayout from '../../Components/Property/BluePrintLayout';
import CreateArea from './createArea';

const BluePrint = props => {
  const {
    properties,
    setProperties,
    buttonRef,
    setShowAreaComponent,
    showAreaComponent,
  } = props;

  const [tabType, setTabType] = useState('Count');
  const [quantity, setQuantity] = useState(0);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const dropdownValues = [
    {title: 'Floor 1', id: 'Floor 1'},
    {title: 'Floor 2', id: 'Floor 2'},
    {title: 'Floor 3', id: 'Floor 3'},
  ];

  useEffect(() => {
    setProperties(prevState => ({
      ...prevState,
      floorDropdown: 'Floor 1',
    }));
  }, []);

  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-center  w-100">
          <div
            className="create-ticket-tabs custom-create-ticket-tabs"
            style={{
              width: 'auto',
            }}>
            <CustomTabs
              darkmode
              active={tabType}
              onSelect={item => setTabType(item)}
              tabs={[
                labels.blueprint_count[selected_lang],
                labels.bluerint_layout[selected_lang],
              ]}
            />
          </div>
        </div>
        <div className="fieldset">
          <div style={{display: 'flex'}}>
            <div style={{width: '100%'}}>
              <CustomDropdown
                setDropdownId={value =>
                  setProperties(prevState => ({
                    ...prevState,
                    floorDropdown: value,
                  }))
                }
                dropdownId={properties.floorDropdown}
                DropDownValues={dropdownValues}
                itemName={['title']}
              />
            </div>
            <div className="search-tabs-icons">
              <span className={showAreaComponent ? 'icon active' : 'icon'}>
                <i
                  className="icon-plus"
                  onClick={() => {
                    setShowAreaComponent(true);
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="messages-users-list">
          <Scrollbars>
            <div className="">
              {/* <Form noValidate validated={validated} onSubmit={handle}> */}

              <div className="fixed-width-inputs">
                {tabType === labels.blueprint_count[selected_lang] && (
                  <div className="bg-white rounded-lg p-3 shadow-sm">
                    <h3 className="text-lg font-bold mb-2">Offices</h3>
                    <p className="text-gray-600 mb-4">Furniture</p>
                    <div
                      className="flex items-center"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '218px',
                        backgroundColor: '#F9F8F5',
                      }}>
                      <div>
                        <span className="mr-2">Quantity</span>
                      </div>
                      <div className="flex items-center"
                      style={{
                        gap:'20px'
                      }}>
                        <button
                          className="text-gray-700 font-bold py-2 px-4 rounded-full"
                          onClick={handleDecrement}
                          disabled={quantity === 0}
                          style={{
                            width: '40px', // Set the width of the button
                            height: '40px', // Set the height of the button
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%', // Make it round
                            backgroundColor: '#E5E5E5', // Button background color
                            border: 'none', // Optional: Remove border for better look
                          }}>
                          -
                        </button>
                        <input
                          style={{
                            width: '70px',
                          }}
                          type="text"
                          className="border rounded px-4 py-2 w-16 text-center"
                          value={quantity}
                          readOnly
                        />
                        <button
                          className="text-gray-700 font-bold py-2 px-4 rounded-full"
                          onClick={handleIncrement}
                          style={{
                            width: '40px', // Set the width of the button
                            height: '40px', // Set the height of the button
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%', // Make it round
                            backgroundColor: '#E5E5E5', // Button background color
                            border: 'none', // Optional: Remove border for better look
                          }}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {tabType === labels.bluerint_layout[selected_lang] && (
                  <BluePrintLayout />
                )}
              </div>

              {/* </Form> */}
            </div>
          </Scrollbars>
          <Button
            type="submit"
            style={{display: 'none'}}
            ref={buttonRef}></Button>
        </div>
      </>
    </>
  );
};
export default BluePrint;
