import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import CustomDropdown from '../CustomDropdown';
import Scrollbars from 'react-custom-scrollbars';
import Input from '../Input/Input';

const AssosiationTabs = props => {
  const {
    handlePropertyListing,
    tabType,
    propertyListing,
    btnRef,
    form,
    validated,
    setForm,
    handle,
  } = props;

  const dropdownValues = [
    {title: 'Outdoors', id: 'Outdoors'},
    {title: 'Parking lot', id: 'Parking lot'},
    {title: 'Garage', id: 'Garage'},
    {title: 'Floor', id: 'Floor'},
    {title: 'Roof Top', id: 'Roof Top'},
  ];

  const SndropdownValues = [
    {title: 'Room', id: 'Room'},
    {title: 'Closet', id: 'Closet'},
    {title: 'Hallway', id: 'Hallway'},
    {title: 'Office', id: 'Office'},
    {title: 'Basement', id: 'Basement'},
  ];

  const itemsDropdown = [
    {title: 'Equipment', id: 'Equipment'},
    {title: 'Artwork', id: 'Artwork'},
    {title: 'Appliance', id: 'Appliance'},
    {title: 'Vehicle', id: 'Vehicle'},
    {title: 'Furniture', id: 'Furniture'},
    {title: 'Supplies', id: 'Supplies'},
    {title: 'Others', id: 'Others'},
  ];

  const handleInputChange = e => {
    const {name, value} = e.target;
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Scrollbars
        autoHide
        style={{overflowX: 'hidden', maxHeight: '100%'}} // Disable horizontal scroll
      >
        <div className="fixed-width-inputs black-head-form-pad-left black-head-form-pad-right">
        <div className="messages-users-list">
          <div className="create-ticket-form create-action-ticket-form">
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className="row">
                <div className="col-6">
                  <Input
                    label="Name"
                    name="name"
                    value={form.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    feedback="Name is required."
                  />
                </div>
                <div className="col-6">
                  <Input
                    label="Assign"
                    name="assign"
                    value={form.assign}
                    onChange={handleInputChange}
                    placeholder="Assign"
                    feedback="Assign is required."
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="fieldset">
                    {tabType === 'Area' ? (
                      <>
                        <Form.Label controlid="validationCustom01">
                          Type
                        </Form.Label>
                        <CustomDropdown
                          setDropdownId={value =>
                            setForm(prevState => ({
                              ...prevState,
                              type: value,
                            }))
                          }
                          dropdownId={form.type}
                          DropDownValues={dropdownValues}
                          itemName={['title']}
                        />
                      </>
                    ) : (
                      <>
                        <Form.Label controlid="validationCustom01">
                          Area
                        </Form.Label>
                        <CustomDropdown
                          setDropdownId={value =>
                            setForm(prevState => ({
                              ...prevState,
                              areaDropdown: value,
                            }))
                          }
                          dropdownId={form.areaDropdown}
                          DropDownValues={
                            propertyListing?.Area?.length > 0
                              ? propertyListing.Area?.map(item => item)
                              : []
                          }
                          itemName={['name']}
                          type={'Area'}
                        />
                      </>
                    )}
                  </div>
                </div>

                {(tabType === 'Area' || tabType === 'Space') && (
                  <div className="col-6">
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        Type
                      </Form.Label>
                      <CustomDropdown
                        setDropdownId={value =>
                          setForm(prevState => ({
                            ...prevState,
                            scndtype: value,
                          }))
                        }
                        dropdownId={form.scndtype}
                        DropDownValues={SndropdownValues}
                        itemName={['title']}
                      />
                    </div>
                  </div>
                )}
                {tabType !== 'Area' && tabType !== 'Space' && (
                  <div className="col-6">
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        Space
                      </Form.Label>
                      <CustomDropdown
                        setDropdownId={value =>
                          setForm(prevState => ({
                            ...prevState,
                            spaceDropdown: value,
                          }))
                        }
                        dropdownId={form.spaceDropdown}
                        DropDownValues={
                          propertyListing?.Space?.length > 0
                            ? propertyListing.Space?.map(item => item)
                            : []
                        }
                        itemName={['name']}
                        type={'Area'}
                      />
                    </div>
                  </div>
                )}
              </div>

              {(tabType === 'Space' || tabType === 'Item Name') && (
                <div className="row">
                  <div className="col-6">
                    <Input
                      label="Tags"
                      name="tags"
                      value={form.tags}
                      onChange={handleInputChange}
                      placeholder="Tags"
                      feedback="Tags is required."
                    />
                  </div>
                  <div className="col-6">
                    <div className="fieldset">
                      <Input
                        label="Dimension"
                        name="dimensions"
                        value={form.dimensions}
                        onChange={handleInputChange}
                        placeholder="Dimension"
                        feedback="Dimension is required."
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-6">
                  <Input
                    label="Associate Services"
                    name="associateServices"
                    value={form.associateServices}
                    onChange={handleInputChange}
                    placeholder="Associate Services"
                    feedback="Associate Services is required."
                  />
                </div>
                {tabType === 'Group' && (
                  <div className="col-6">
                    <Input
                      label="Associate Item"
                      name="associateItems"
                      value={form.associateItems}
                      onChange={handleInputChange}
                      placeholder="Associate Item"
                      feedback="Associate Item is required."
                    />
                  </div>
                )}
                {tabType === 'Item Name' && (
                  <div className="col-6">
                    <div className="fieldset">
                      <Form.Label controlid="validationCustom01">
                        Type
                      </Form.Label>
                      <CustomDropdown
                        setDropdownId={value =>
                          setForm(prevState => ({
                            ...prevState,
                            itemsType: value,
                          }))
                        }
                        dropdownId={form.itemsType}
                        DropDownValues={itemsDropdown}
                        itemName={['title']}
                      />
                    </div>
                  </div>
                )}
              </div>

              <Button
                type="submit"
                style={{display: 'none'}}
                ref={btnRef}></Button>
            </Form>

            <div className="create-ticket-wrapper fixed-width-inputs">
              {propertyListing?.[tabType]?.length > 0 && 
                <p className='mb-3'>Added {tabType}</p>
              }
              {tabType === 'Item Name' && propertyListing?.Item_Name?.length > 0 && 
                <p className='mb-3'>Added Item Name</p>
              }
              <div>
                {tabType !== 'Item Name'
                  ? propertyListing?.[tabType]?.length > 0 &&
                    propertyListing?.[tabType]?.map((item, index) => (
                      <div className="events-item" style={{borderRadius: '10px'}}>
                        <div key={index} className="events-place-like">
                          <span className="address as-link">{item.name}</span>
                          <div className="search-tabs-icons">
                            <span
                              onClick={() =>
                                handlePropertyListing(index, 'delete', item)
                              }
                              className="icon active">
                              <i className="fa-solid fa-trash-can" />
                            </span>
                            <span
                              onClick={() =>
                                handlePropertyListing(index, 'edit', item)
                              }
                              className="icon active">
                              <i className="fa-solid fa-pen-to-square" />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : propertyListing?.Item_Name?.length > 0 &&
                    propertyListing?.Item_Name?.map((item, index) => (
                      <div className="events-item" style={{borderRadius: '10px'}}>
                        <div key={index} className="events-place-like">
                          <span className="address as-link">{item.name}</span>
                          <div className="search-tabs-icons">
                            <span
                              onClick={() =>
                                handlePropertyListing(index, 'delete')
                              }
                              className="icon active">
                              <i className="fa-solid fa-trash-can" />
                            </span>
                            <span
                              onClick={() =>
                                handlePropertyListing(index, 'edit', item)
                              }
                              className="icon active">
                              <i className="fa-solid fa-pen-to-square" />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
        </div>
      </Scrollbars>
    </>
  );
};
export default AssosiationTabs;
