import React, {useState, useEffect, useRef} from 'react';
import { SlidingPanel } from '../../Components';
import { RoiLogo } from '../../Constants/Images';
import AssosiationTabs from '../../Components/Property/AssosiationTabs';
import { errorMessage } from '../../Utils/ToastMessages';
import { Button } from 'react-bootstrap';
import ConfirmationModel from '../../Components/ConfirmationModel/ConfirmationModel';


const CreateArea = (props) => {

      const {propertyListing, setpropertyListing} = props;
      const [form, setForm] = useState({});
      const [index, setIndex] = useState(null);
      const [tabType, setTabType] = useState('Area');
      const [validated, setValidated] = useState(false);
      const btnRef = useRef();
      const [showDeleteModal, setShowDeleteModal] = useState({})
    
      const tabs = [
        {name: 'Area', key: 'Area'},
        {name: 'Space', key: 'Space'},
        {name: 'Group', key: 'Group'},
        {name: 'Item Name', key: 'Item Name'},
      ];
    
      const handleTabClick = item => {
        if (tabType === 'Area') {
          if (propertyListing?.Area.length > 0 && item === 'Space') {
            setTabType(item);
          } else if (propertyListing?.Space.length > 0) {
            setTabType(item);
          } else if(propertyListing?.Area.length > 0 && item !== 'Space'){
            setTabType('Space')
            errorMessage('Please add Space')
          } else{
            errorMessage('Please add Area');
          }
        } else if (tabType === 'Space') {
          if (propertyListing?.Space.length > 0 || item === 'Area') {
            setTabType(item);
          } else {
            errorMessage('Please add Space');
          }
        } else if (tabType === 'Group') {
          setTabType(item);
        } else if (tabType === 'Item Name') {
          setTabType(item);
        }
      };
    
      // const clearForm = () => {
      //   setForm({
      //     type: tabType === 'Area' ? 'Outdoors' : '',
      //     scndtype: (tabType === 'Area' || tabType === 'Space') ? 'Room' : '',
      //     areaDropdown: tabType !== 'Area' && propertyListing?.Area?.[0]?.name || '',
      //     spaceDropdown: tabType !== 'Area' && tabType !== 'Space'
      //     ? propertyListing?.Space?.length > 0
      //       ? propertyListing?.Space?.[0]?.name
      //       : ''
      //     : '',
      //     itemsType: tabType === 'Item Name' ? 'Equipment' : '',
      //     associateServices: '',
      //     associateItems: '',
      //     tags: '',
      //     dimensions: '',
      //     assign: '',
      //     name: '',

      //   });
      // };
    
      const clearForm = () => {
        setForm({
          type: tabType === 'Area' ? 'Outdoors' : '',
          scndtype: tabType === 'Area' || tabType === 'Space' ? 'Room' : '',
          areaDropdown: tabType !== 'Area' 
            ? propertyListing?.Area?.[0]?.name || '' 
            : '',
          spaceDropdown: tabType !== 'Area' && tabType !== 'Space'
            ? propertyListing?.Space?.length > 0 
              ? propertyListing?.Space?.[0]?.name 
              : '' 
            : '',
          itemsType: tabType === 'Item Name' ? 'Equipment' : '',
          associateServices: '',
          associateItems: '',
          tags: '',
          dimensions: '',
          assign: '',
          name: '',
        });
      };
      
      useEffect(() => {
        setValidated(false);
        setIndex(null)
        clearForm()
      }, [tabType, propertyListing]);
    
      const handle = event => {
        const formm = event.currentTarget;
    
        // Perform validation
        if (formm.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true); // You may want to handle setting the validated state elsewhere
        } else {
          event.preventDefault(); // Prevent form from submitting normally
          setValidated(false);
    
          // Get form values
          const formData = {
            name: form?.name,
            type: form?.type,
            assign: form?.assign,
            scndType: form?.scndtype,
            areaDropdown: form?.areaDropdown,
            spaceDropdown: form?.spaceDropdown,
            associateServices: form?.associateServices,
            associateItems: form?.associateItems,
            itemsType: form?.itemsType,
            tags: form?.tags,
            dimensions: form?.dimensions,
          };
      
          if (index !== null) {
            // Handle update case
            if (tabType === 'Item Name') {
              setpropertyListing(prevState => {
                const updated = [...prevState.Item_Name];
                updated[index] = formData; // Update the item at the saved index
                return {...prevState, Item_Name: updated};
              });
            } else {
              setpropertyListing(prevState => {
                const updated = [...prevState[tabType]];
                updated[index] = formData; // Update the item at the saved index
                return {...prevState, [tabType]: updated};
              });
            }
            setIndex(null); // Reset index after update
          } else {
            // Handle create case
            if (tabType === 'Item Name') {
              setpropertyListing(prevState => ({
                ...prevState,
                Item_Name: [...prevState.Item_Name, formData],
              }));
            } else {
              setpropertyListing(prevState => ({
                ...prevState,
                [tabType]: [...prevState[tabType], formData],
              }));
            }
          }
          clearForm(); // Clear form after submission
        }
      };
    
      const handlePropertyListing = (index, type, item) => {
        if (type === 'delete') {
          setShowDeleteModal({
            model: true,
            index: index,
            item: item
          });
        } else {
          setForm(prevState => ({
            ...prevState,
            scndtype: item?.scndType,
            type: item?.type,
            assign: item?.assign,
            name: item?.name,
            areaDropdown: item?.areaDropdown,
            spaceDropdown: item?.spaceDropdown,
            itemsType: item?.itemsType,
            associateItems: item?.associateItems,
            associateServices: item?.associateServices,
            tags: item?.tags,
            dimensions: item?.dimensions,
          }));
          setIndex(index);
        }
      };

    const renderProfileTagsButtons = () => {
        return (
          <>
             <span
            className="as-link"
            style={{fontSize:'14px'}}
            onClick={() => {
              props.onClose();
            }}>Add BluePrint
            </span>
          </>
        );
      };

      const deleteListing =()=>{
        if (tabType !== 'Item Name') {
          const updated = propertyListing?.[tabType]?.filter(
            (_, i) => i !== showDeleteModal.index,
          );
          setpropertyListing(prevState => ({
            ...prevState,
            [tabType]: updated, // Update the specific dynamic key with the filtered array
          }));
        } else {
          const updated = propertyListing?.Item_Name?.filter(
            (_, i) => i !== showDeleteModal.index,
          ); // Remove item by index
          setpropertyListing(prevState => ({
            ...prevState,
            Item_Name: updated, // Directly update the filtered array to Item_Name
          }));
        }

        setShowDeleteModal({
          model: false,
        });

      }

return (
  <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>

<div
          style={{
            paddingTop: '20px',
          }}
          className="serach-filter-wrapper create-sessionEvents">
      <div className="create-ticket-form  primary_panel_content">

        <div className="d-flex align-items-center fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right justify-content-center  w-100">
          <div
            className="create-ticket-tabs custom-create-ticket-tabs"
            style={{
              width: 'auto',
              // margin: '0px',
            }}>
            <div className="event-tabs tabs-style-2 mb-0">
              <ul>
                {tabs.map(tab => (
                  <li>
                    <Button
                      key={tab.key}
                      className={`tab-button ${
                        tabType === tab.key ? 'active' : '#9b9787'
                      }`}
                      onClick={() => handleTabClick(tab.key)}>
                      {tab.name}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <AssosiationTabs
          tabType={tabType}
          setForm={setForm}
          form={form}
          validated={validated}
          handle={handle}
          propertyListing={propertyListing}
          setpropertyListing={setpropertyListing}
          btnRef={btnRef}
          handlePropertyListing={handlePropertyListing}
        />
       
      </div>

      <div className="filter-btn-wrapper mb-2">
          <div className="invite-btn text-center bg_transparent bottom-0 fixed-width-inputs fix-width-forms">
            <Button
              className={'btn-dark btn btn-primary'}
              type="submit"
              onClick={() => {
                btnRef.current.click();
              }}>
              
              {index !== null ? `Update ${tabType}` : `Add ${tabType}`}
              </Button>
          </div>
        </div>
      </div>            
            </SlidingPanel>
            {showDeleteModal.model &&

               <ConfirmationModel
                      closeModel={() => {
                        setShowDeleteModal({
                          model: false,
                        });
                      }}
                      discription={`Are you sure you want to delete this ${tabType}`}
                      buttonOne={'Yes'}
                      buttonTwo={'No Thanks'}
                      ButtonOneAction={() => {
                        deleteListing();
                      }}
                    />
}
                    </>
)
}
export default CreateArea;