import React, {useState, useEffect} from 'react';
import {selected_lang} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';

import {labels} from '../../Constants/Translations';
import {Button} from 'react-bootstrap';
import {FormattedText} from '../../Utils/Helpers';
import {
  formatDateTimeTodayPre,
} from '../../Utils/commonUtils';

const ProfileNotesListing = props => {
  const [showLoader, setshowLoader] = useState(true);
  const [notesData, setNotesData] = useState([]);
  const [showSendNotes, setShowSendNotes] = useState(false);
  const [slectedNote, setSlectedNote] = useState('');

  useEffect(() => {
    get_notes();
  }, []);
  console.log(props.userData, 'props.userDataprops.userDataprops.userData')

  const get_notes = q => {
    let data = {
      type: 'other',
      type_id: props.userData.id,
      data_type: 'all',
    //   filter: filterOptionValue.toString(','),
    //   search_query: q,
    };
    commonApi
      .get_notes(data)
      .then(res => {
        if (res.status) {
          setNotesData(res.notes);
          setTimeout(() => {
            setshowLoader(false);
          }, 1000);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const allNotesLines =
    Object.entries(notesData).length > 0
      ? Object.entries(notesData).map(([key, item]) =>
          item.description.split('\n'),
        )
      : [];

  const NoteItem = ({index, item, allNotesLines}) => {
    const [showMore, setShowMore] = useState(false);
    const showMoreClass = showMore ? 'show-all' : '';

    return (
      <div>
        <FormattedText
          className={`profile-description message-adjust-profile-desc${showMoreClass}`}
          content={item.description}
        />

        {((item.description && item.description.length > 250) ||
          allNotesLines[index].length > 3) && (
          <>
            {' '}
            <span
              className="show-more-link"
              onClick={() => setShowMore(!showMore)}>
              {!showMore
                ? labels.read_more[selected_lang]
                : labels.read_less[selected_lang]}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {showLoader && <LoaderSpinner />}
      <div className="mt-4 profile-notes-listing-container">
        {notesData && Object.entries(notesData).length ? (
          notesData.map((item, index) => {
            return (
              <div className="card-container messages-card-note-container">
                <div
                  className="padd-with-borderContaier as-link message-adjust-img-container"
                  onClick={() => {
                    if (item.image || item.video) {
                      setSlectedNote(item);
                    //   setShowModel('previewImgVideo');
                    }
                  }}>
                  {item.image && <img src={item.image} alt="" />}
                  {item.video && <video src={item.video} alt="" />}
                  <div className="d-flex align-items-center page-snapshots mt-2">
                    <p className="p-reg m-0 chatMsg">
                      <NoteItem
                        index={index}
                        item={item}
                        allNotesLines={allNotesLines}
                      />
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                  <span className="msg-date">
                    {formatDateTimeTodayPre(item.updated_at)}
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-results-wrapper">
            <div className="img-bg-wrapper">
              <img
                src={require('../../Assets/images/norecord/no-notes.png')}
                alt=""
              />
            </div>

            <div className="no-results-content">
              <h2>No Shared Notes</h2>
              {/* <p>Share notes with your network to stay on the same page</p> */}
              {/* {notesData.length === 0 && !showSendNotes && (
                <div className="btn-wrapper mt-3">
                  <Button
                    className="btn btn-dark"
                    onClick={() => {
                    //   setShowSendNotes(true);
                    }}>
                    Add Note
                  </Button>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileNotesListing;
